<template lang="pug">
  div(:class="[isAttached ? 'px-0' : 'tab-content']")
    //- tip-container(tips="company" :video="videoByStep")
    .titles(v-if="!isAttached")
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    group-container(
      :title="$t('text.email')"
      :subtitle="$t('text.what_email_should_be_shown_on_your_website')"
      commentField="company.email"
      :mandatory="true"
    )
      v-text-field(v-model="email" :rules="[rules.email]" validate-on-blur filled :readonly="isLocked" :error-messages="$t(errors.company.email)")

    group-container(
      :title="$t('text.phone_number')"
      :subtitle="$t('text.what_phone_number_should_be_shown_on_your_website')"
      commentField="company.phone_number"
    )
      v-text-field(
        v-model="model.phone_number"
        :rules="[rules.phone_number]"
        validate-on-blur
        filled
        :readonly="isLocked"
        :error-messages="$t(errors.company.phone_number)"
      )

    //- group-container(
    //-   :title="$t('text.address')"
    //-   :subtitle="$t('text.what_address_should_be_shown_on_your_website')"
    //-   commentField="company.address"
    //- )
    //-   v-text-field(v-model="model.address" filled :readonly="isLocked" placeholder="https://example.com")

    //- group-container.mb-8(
    //-   :title="$t('text.working_hours')"
    //-   :subtitle="$t('text.what_working_hours_should_be_shown_on_your_website')"
    //-   commentField="company.working_hours"
    //- )
    //-   v-radio-group.ib-1(v-model="model.working_hours" :mandatory="false" hide-details :disabled="isLocked")
    //-     v-radio(value="always")
    //-       template(v-slot:label) {{ $t('text.always_open') }}

    //-     v-radio(value="hours")
    //-       template(v-slot:label) {{ $t('text.open_for_selected_hours') }}

    //-     working-hours.ib-2.mt-5(v-if="isHoursSelected" :model="model.selected_hours")


    group-container.mb-8(
      :title="$t('text.socials_comment')"
      :subtitle="$t('text.what_social_icons_should_be_included_and_provide_links_for_them')"
      commentField="company.socials"
    )
      social-links(:model="model.social_links" @add-social="addNewSocial" :isLocked="isLocked" :rules="rules")
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import WorkingHours from './parts/WorkingHours';
import SocialLinks from './parts/SocialLinks';
import Btn from '@/components/common/buttons/Button';
import { genFields } from '@/components/forms/fieldGenerator';
import TipContainer from '@/components/forms/parts/TipContainer';

export default {
  components: { GroupContainer, WorkingHours, SocialLinks, Btn, TipContainer },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: { type: Boolean },
    comments: { type: Object },
    errors: null,
    isAttached: {
      type: Boolean,
      default: false,
    },
    videoByStep: {
      type: String,
    },
  },
  data() {
    /*eslint-disable*/
    return {
      title: 'text.contact_&_company_details',
      subtitle: 'text.provide_your_business_contact_information_and_company_details',
      rules: {
        email: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            (value?.trim().length && pattern.test(value)) ||
            this.$t('text.the_email_must_be_a_valid_email_address')
          );
        },
        phone_number: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const pattern =
            /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
          return (value?.trim().length && pattern.test(value)) || 'The phone number invalid';
        },
        address: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const pattern =
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
          return (value?.trim().length && pattern.test(value)) || 'The address invalid';
        },
      },
    };
  },
  computed: {
    isHoursSelected() {
      return this.model.working_hours == 'hours';
    },

    ...genFields('projects', ['company.email']),
  },
  mounted() {
    const foundGooglePlus = this.model.social_links.findIndex((link) => link.name === 'GooglePlus');
    if (foundGooglePlus !== -1) {
      this.model.social_links = this.model.social_links.filter(
        (link) => link.name !== 'GooglePlus'
      );
    }
  },
  methods: {
    addNewSocial() {
      this.model.social_links.push({
        name: this.$t('text.new_social_link'),
        url: null,
      });
    },

    addCustomCompanyField() {
      this.model.details.push({ id: this.$uuid.v1(), name: null, vallue: null });
    },
  },
};
</script>
<style lang="scss">
.company_details {
  display: flex;
  flex-direction: column;

  .add {
    margin-top: 20px;
    align-self: flex-start;

    label {
      color: $cl-primary;
    }
  }

  .item {
    .fieldname {
      margin-right: 10px;
      flex: 1 1 auto;
    }

    .value {
      flex: 3 0 auto;
    }
  }
}
</style>

import _ from 'lodash';
import Api from '@/plugins/api';

export const doGenerateText = (c, { _key, fallback, isAutoFilled }) => {
  let label = 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.';
  if (c[_key]) {
    label = c[_key];
  } else if (fallback || typeof a === 'boolean') {
    label = fallback;
  }
  if (c.is_auto_filled || isAutoFilled) {
    c[_key] = label;
  }
  return label;
};
export const setStorage = (sessionName, data) => {
  const storage = typeof data === 'object' ? JSON.stringify(data) : data;
  return localStorage.setItem(sessionName, storage);
};

export const getStorage = (sessionName) => {
  const value = localStorage.getItem(sessionName);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const removeStorage = (sessionName) => {
  localStorage.removeItem(sessionName);
};
/*eslint-disable*/
export const converToIframeYoutube = (youtubeAddr, query = '') => {
  // eslint-disabled
  if ([null, '', undefined, false].includes(youtubeAddr)) {
    return false;
  }
  if (!youtubeAddr.toString().includes('youtube')) {
    return false;
  }
  // eslint-disable-line
  let youtube_regex = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  // eslint-disabled
  let regex_match = youtubeAddr.match(youtube_regex);
  if (regex_match && regex_match.length >= 3) {
    let video_code = regex_match[2];
    return `https://www.youtube.com/embed/${video_code}${query}`;
  }
  return false;
};

export const checkDepartment = (department = {}) => {
  if (!Object.keys(department).length) {
    return;
  }
  return _.get(department, 'check', false);
};

export const checkDomain = async (url = '', method = 'get', params) => {
  try {
    return await Api[method](url, { params });
  } catch (e) {
    return e;
  }
};

export const getAllNestedElements = (arr, level) => {
  const result = [];

  function getNestedElements(elements, currentLevel) {
    if (currentLevel === level) {
      result.push(...elements);
      return;
    }

    elements.forEach((element) => {
      if (_.isArray(element.elements) && element.elements.length > 0) {
        getNestedElements(element.elements, currentLevel + 1);
      }
    });
  }

  getNestedElements(arr, 1);
  return result;
};
export const findRootObjectByUUID = (data, uuidToFind) => {
  for (const item of data) {
    if (item.uuid === uuidToFind) {
      return item;
    }

    if (item.elements && item.elements.length > 0) {
      const foundObject = findObjectByUUID(item.elements, uuidToFind);
      if (foundObject) {
        return item;
      }
    }
  }

  return null;
};

export const findObjectByUUID = (data, uuidToFind) => {
  for (const item of data) {
    if (item.uuid === uuidToFind) {
      return item;
    }

    if (item.elements && item.elements.length > 0) {
      const foundObject = findObjectByUUID(item.elements, uuidToFind);
      if (foundObject) {
        return foundObject;
      }
    }
  }

  return null;
};

export const countNestedLevels = (obj) => {
  if (!obj || typeof obj !== 'object' || !Array.isArray(obj.elements)) {
    return 0;
  }

  let maxLevel = 0;
  for (const element of obj.elements) {
    const level = countNestedLevels(element);
    maxLevel = Math.max(maxLevel, level);
  }

  return maxLevel + 1;
};

export const findNestedLevel = (arr, targetUuid, level = 0) => {
  for (const obj of arr) {
    if (obj.uuid === targetUuid) {
      return level;
    }
    if (obj.elements && obj.elements.length > 0) {
      const nestedLevel = findNestedLevel(obj.elements, targetUuid, level + 1);
      if (nestedLevel !== -1) {
        return nestedLevel;
      }
    }
  }
  return -1;
};

import AbstractDataPage from '@/components/pages/AbstractDataPage';
import ModMixin from './ModMixin';
import vuetize from '@/scripts/vuetize';
import Button from '@/components/tables/parts/ToolbarBtn';

export default {
  components: { dataPage: AbstractDataPage },
  mixins: [ModMixin],

  data: function () {
    return {
      formName: 'Form',
      formFolderPath: null,
      formFolderPathSuffix: null,
      formHasDocuments: false,
      baseTableActions: {
        add: { icon: 'ic-add', evclick: this.tableActionCreate, component: Button, large: true },
      },
      tableItemActions: {},
      baseTableItemActions: {
        edit: { icon: 'ic-mode-edit', click: this.tableItemActionEdit },
        delete: { icon: 'ic-delete', color: 'error', click: this.tableItemActionDelete },
      },
      formEvents: {},
      formBaseEvents: {
        'form-cancel': this.formActionCancel,
        'form-create': this.formActionCreate,
        'form-update': this.formActionUpdate,
      },
      toolbarTheme: 'theme--gradient',
      toolbarDark: true,
      tableElevation: 1,
      showPlaceholder: true,
    };
  },

  computed: {
    pageTitle() {
      return this.$t('text.projects');
    },
    // Bindable data 4 AbstractDataPage
    dataBind() {
      return {
        modName: this.modName,
        pageTitle: this.pageTitle,
        tableHeaders: this.tableHeaders,
        tableItems: this.dataItems,
        formComponent: this.formComponent,
        tableActions: vuetize(this.dtActions),
        tableItemActions: this.dtItemActions,
        formEvents: this.dtFormEvents,
        formBinds: this.formBinds,
        ref: 'dtp', // data page
        tableBinds: {
          toolbarTheme: this.toolbarTheme,
          toolbarDark: this.toolbarDark,
          elevation: this.tableElevation,
          tableOptions: this.tableOptions,
        },
        showPlaceholder: this.showPlaceholder,
      };
    },

    tableActions() {
      return [];
    },

    formBinds() {
      return {};
    },

    tableOptions() {
      return {};
    },

    // Data Table(dt) Actions 4 AbstractDataPage
    dtActions() {
      return _.merge(this.baseTableActions, this.tableActions);
    },

    // Data Table(dt) Item Actions 4 AbstractDataPage
    dtItemActions() {
      return _.merge(this.baseTableItemActions, this.tableItemActions);
    },

    // Bindable form events 4 AbstractDataPage
    dtFormEvents() {
      return _.merge(this.formBaseEvents, this.formEvents);
    },

    items() {
      return this.$store.getters[this.repoPath('ACTIVE_ITEMS')];
    },

    dataItems() {
      return this.items;
    },

    formComponentPath() {
      let folder = this.formFolderPath ? `${this.formFolderPath}/${this.modName}` : this.modName;
      if (this.formFolderPathSuffix) folder += `/${this.formFolderPathSuffix}`;
      return `${folder}/${this.formName}`;
    },

    // form component 4 AbstractDataPage
    formComponent() {
      return require(`@/pages/${this.formComponentPath}`).default;
    },
  },

  methods: {
    // TABLE ACTIONS (HEADER)
    tableActionCreate() {
      this.formCommit('CLEAR_FIELDS');
      this.formCommit('CLEAR_ERRORS');
      this.formCommit('UPDATE_STATE', { field: 'is_edit', value: false });
      this.formCommit('UPDATE_STATE', { field: 'show_form', value: true });
      //this.$refs.dtp.showForm = true

      if (this['onCreateClick'] && typeof this['onCreateClick'] == 'function') this.onCreateClick();
    },

    // TABLE ITEM ACTIONS (ITEM RIGHT SIDE)
    tableItemActionDelete(model) {
      if (confirm('Are you sure u want to remove this item?'))
        this.repoDispatch('API_DELETE', model);
    },

    tableItemActionEdit(model) {
      this.formCommit('CLEAR_FIELDS');
      this.formCommit('CLEAR_ERRORS');
      this.formCommit('SET_FIELDS', model);
      this.formCommit('UPDATE_STATE', { field: 'is_edit', value: true });
      this.formCommit('UPDATE_STATE', { field: 'show_form', value: true });
      //this.getRef('dtp').showForm = true

      if (this['onCreateClick'] && typeof this['onCreateClick'] == 'function') this.onEditClick();
    },

    // FORM EVENTS
    formActionCancel() {
      this.formCommit('UPDATE_STATE', { field: 'show_form', value: false });
      //this.getRef('dtp').showForm = false
    },

    formActionCreate(model) {
      this.repoDispatch('API_CREATE', model)
        .then(this._afterApi)
        .then(() => {
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.created_successfully'));
        })
        .catch(() => {
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.oops_server_error_try_again_later'));
        });
    },

    formActionUpdate(model) {
      this.repoDispatch('API_UPDATE', model)
        .then(this._afterApi)
        .then((res) => {
          console.error('UPDATE_RES', res);
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.created_successfully'));
        })
        .catch((err) => {
          console.error('UPDATE_RES', err);
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.oops_server_error_try_again_later'));
        });
    },

    _afterApi(responseModel) {
      this.formCommit('SET_FIELDS', responseModel);
      this.formCommit('UPDATE_STATE', { field: 'is_edit', value: true });
      //this.formActionCancel()
    },
  },
};

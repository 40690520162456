function liveChat() {
  return (function (w, d, u) {
    var s = d.createElement('script');
    s.async = true;
    s.src = u + '?' + ((Date.now() / 60000) | 0);
    var h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  })(window, document, 'https://cdn.bitrix24.eu/b2532231/crm/site_button/loader_11_lvpmg7.js');
}

export default liveChat;

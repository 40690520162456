<template lang="pug">
  div(:class="[isAttached ? 'px-0' : 'tab-content']")
    .titles.text-center(v-if="!isAttached")
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    group-container(
      :title="$t('text.logo')"
      :subtitle="$t('text.provide_the_information_regarding_your_logo')"
      :tip="$t('text.tip_contents.basic.logo')"
      commentField="branding.logo"
      :mandatory="true"
    )
      v-radio-group.ib-1(
        v-model="logo"
        :mandatory="false"
        :error-messages="$t(errors.branding.logo)"
      )
        v-radio(value="owned" :disabled="isLocked")
          template(v-slot:label) {{ $t('text.i_have_a_logo') }}

        media-uploader.ib-2(
          :documents="mediaLogo"
          v-if="isOwnedSelected"
          resourceName="projects"
          :uuid="projectUUID"
          @added="onAddedLogo"
          @deleted="onDeleted"
          :error-messages="(!mediaLogo.length && $t(errors.branding.media).length) ? $t(errors.branding.media) : null"
          :disabled="isLocked"
        )

        v-radio(value="create"  :disabled="isLocked")
          template(v-slot:label) {{ $t('text.i_dont_have_a_logo') }}

      v-radio-group.ib-2.radio-top-fix(v-if="isCreateSelected" v-model="logo_create" :mandatory="false" :error-messages="$t(errors.branding.logo_create)")
        .group-able-tip
          v-radio(value="free" :disabled="isLocked")
            template(v-slot:label) {{ $t('text.create_me_a_temporary_logo_free') }}
          tip-content(:tip="$t('text.tip_contents.basic.logo_free')")
          .ib-2
            .p.mb-3.mt-1.explain {{ $t('text.branding_free_logo_explain') }}

          .wrap(v-if="isFreeSelected")
            .ib-2
              v-text-field(filled v-model="free_word" :label="$t('text.word_or_symbol')" :readonly="isLocked" :error-messages="$t(errors.branding.free_word)")

        .group-able-tip
          v-radio(value="pro" :disabled="isChooserDisabled")
            template(v-slot:label) {{ $t('text.create_me_a_professional_logo_paid') }}
          tip-content(:tip="$t('text.tip_contents.basic.logo_paid')")
          .ib-2
            .p.mb-3.mt-1.explain {{ $t('text.branding_paid_logo_explain') }}

          .wrap.ib-2(v-if="isProSelected && isNotAllowDepartment")
            v-text-field(
              filled
              v-model="model.pro_word"
              :label="$t('text.word_or_symbol')"
              :readonly="isLocked"
              :error-messages="$t(errors.branding.pro_word)"
            )

            .p.mb-3.explain {{ $t('text.provide_a_description_here_for_example_add_a_triangle_before_my_brand_name') }}
            v-text-field(
              filled
              v-model="model.pro_description"
              :label="$t('text.description')"
              :readonly="isLocked"
              :error-messages="$t(errors.branding.pro_description)"
            )

            .p.mb-3.explain {{ $t('text.upload_an_example_of_the_symbol_that_you_want_to_use_in_your_logo') }}

            media-uploader(
              :documents="mediaSymbols"
              resourceName="projects"
              :uuid="projectUUID"
              @added="onAddedSymbol"
              @deleted="onDeleted"
              :disabled="isLocked"
              :error-messages="(!mediaSymbols.length && $t(errors.branding.media).length) ? $t(errors.branding.media) : null"
            )
    slot(name="attach")
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';
import MediaMixin from '@/components/forms/mixins/mediaMixin';
import { genFields } from '@/components/forms/fieldGenerator';
import TipContainer from '@/components/forms/parts/TipContainer';
import TipContent from '@/components/forms/parts/TipContent';
import domain from '@/scripts/domain';

export default {
  components: { GroupContainer, MediaUploader, TipContainer, TipContent },
  mixins: [MediaMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: { type: Boolean },
    comments: { type: Object },
    errors: null,
    isAttached: {
      type: Boolean,
      default: false,
    },
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.logo_&_branding',
      subtitle: 'text.provide_us_your_branding_assets_and_related_information',
    };
  },

  computed: {
    projectUUID() {
      return this.$store.state.forms.projects.fields.uuid;
    },
    isOwnedSelected() {
      return this.model.logo === 'owned';
    },
    isCreateSelected() {
      return this.model.logo === 'create';
    },
    isFreeSelected() {
      return this.model.logo_create === 'free';
    },
    isProSelected() {
      return this.model.logo_create === 'pro';
    },
    isNotAllowDepartment() {
      return !domain.isPL();
    },
    mediaLogo() {
      return this.media.filter((x) => x.metatype === 'logo');
    },
    mediaSymbols() {
      return this.media.filter((x) => x.metatype === 'symbol');
    },
    isChooserDisabled() {
      return this.isLocked;
    },

    ...genFields('projects', [
      'branding.logo',
      'branding.free_word',
      'branding.logo_create',
      'branding.media',
    ]),
  },
  watch: {
    logoRadios(val) {
      this.model.logo_create = this.isOwnedSelected ? null : this.model.logo_create;
    },
  },
  methods: {
    onAddedLogo(model) {
      this.addMedia(model, 'logo');
    },
    onDeleted(filename) {
      this.media = this.media.filter((x) => x.filename != filename);
    },
    onAddedSymbol(model) {
      this.addMedia(model, 'symbol');
    },
  },
};
</script>

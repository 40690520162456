<template lang="pug">
  .home
    | this is home
</template>

<script>
export default {
  mounted() {
    const routeTo =  (this.$store.state.auth.is_logged_in) ? 'projects' : 'login'
    this.$router.push(routeTo)
  }
}
</script>

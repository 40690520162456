<template lang="pug">
v-menu(
    ref="menu",
    :close-on-content-click="false",
    transition="scale-transition",
    offset-y,
    min-width="auto"
  )
    template(v-slot:activator="{ on, attrs }")
      v-text-field(
        v-model="dataLocal",
        :label="label",
        readonly,
        v-bind="attrs",
        v-on="on"
        clear-icon="ic-close"
        clearable
        hide-details
        @click:clear="resetValue"
      )
    v-date-picker.date-picker-filter(
      v-model="dataLocal",
    )
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
    },
  },
  computed: {
    dataLocal: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("update", { field: this.field, value });
        return value;
      },
    },
  },
  methods: {
    resetValue() {
      this.$emit("update", { field: this.field, value: '' });
    },
  },
};
</script>

export const DEPARTMENT_ID_LIVE = 99;
export const DEPARTMENT_ID_DEV = 999;
export const DEPARTMENT_ID_UK = 1;
export const DEPARTMENT_ID_RU = 2;
export const DEPARTMENT_ID_LT = 3;
export const DEPARTMENT_ID_PL = 4;
export const DEPARTMENT_ID_ES = 5;
export const DEPARTMENT_ID_BG = 6;
export const DEPARTMENT_ID_RO = 7;
export const DEPARTMENT_ID_HU = 8;
export const DEPARTMENT_ID_LV = 9;
export const DEPARTMENT_ID_COM = 50;

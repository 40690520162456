<template lang="pug">
  .extra-block.features
    .items.d-flex.flex-column(
      v-for="(item, key) in model.items"
      :key="item.id"
    )
      .separator.mt-2.mb-4(v-if="key > 0")
      v-text-field(
        filled
        :value="generateLorem(item, {_key: 'title'})"
        v-model="item.title"
        :label="$t('text.advantages_features_title')"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )
      v-textarea(
        v-model="item.description"
        :placeholder="$t('text.advantages_features_description')"
        rows="3"
        filled
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )

      media-uploader(
        :documents="item.media"
        resourceName="projects"
        :uuid="projectUUID"
        :return="item.id"
        @added="onAdded"
        @deleted="onDeleted"
        :disabled="disabled"
        :allowMultiple="false"
      )

    v-btn.capitalize(color="black" text rounded depressed  @click="addField" :disabled="disabled")
      v-icon(left) mdi-plus
      | {{ $t('text.add_item') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';

export default {
  components: { MediaUploader },
  mixins: [ExtraBlock],
  methods: {
    addField() {
      this.model.items.push({ id: this.$uuid.v4(), link: null, media: [] });
    },
    onAdded(model, id) {
      this.model.items.find((x) => x.id === id).media.push(model);
    },
    onDeleted(fileName, id) {
      this.model.items.find((x) => x.id === id).media = this.model.items
        .find((x) => x.id === id)
        .media.filter((x) => x.filename != fileName);
    },
  },
};
</script>

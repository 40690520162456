<template lang="pug">
  .extra-block.testimonials
    //- group-container(
    //-   :title="$t('text.Section title')"
    //-   :subtitle="$t('text.It_will_inform_users_what_the_section_is_about')"
    //- )
    //-   v-text-field(
    //-     filled 
    //-     v-model="model.title"
    //-     :placeholder="$t('We are trusted by')"
    //-     :readonly="disabled || isAutoFilled"
    //-     :disabled="isAutoFilled"
    //-   )
    group-container(
      :title="$t('text.List_of_testimonials')"
      :subtitle="$t('text.Fill_out_the_fields_of_your_testimonials_If_you_need_you_can_delete_any_item_or_add_new')"
    )
    div(
      v-for="(item, key) in model.items"
      :key="item.id"
    )

      hr.my-4(v-if="key > 0")
      div.grid.grid-cols-12.gap-4
        div.col-span-12(:class="['md:col-span-6']")
          label.block.text-222222.mb-4.text-16.font-medium {{$t('text.name')}}
          v-text-field(
            filled
            v-model="item.name"
            :label="$t('text.name')"
            :value="generateLorem(item, {_key: 'name'})"
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
          )
        div.col-span-12(:class="['md:col-span-6']")
          label.block.text-222222.mb-4.text-16.font-medium {{$t('text.date')}}
          v-text-field(
            filled
            v-model="item.date"
            :label="$t('text.date')"
            :value="generateLorem(item, {_key: 'date'})"
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
          )
        div.col-span-12
          label.block.text-222222.mb-4.text-16.font-medium {{$t('text.testimonial')}}
          v-textarea(
            filled
            v-model="item.text"
            :label="$t('text.text')"
            :value="generateLorem(item, {_key: 'text'})"
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
          )
        div.col-span-12
          label.block.text-222222.mb-4.text-16.font-medium {{$t('text.Photo')}}
          media-uploader(
            :documents="item.media"
            resourceName="projects"
            :return="item.id"
            :uuid="projectUUID"
            @added="onAdded"
            @deleted="onDeleted"
            :disabled="disabled"
          )
    //- v-btn(color="primary" rounded depressed  @click="addField" :disabled="disabled") {{ $t('text.add_item') }}
    div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addField" v-if="!disabled")
        v-icon(color="#222222") mdi-plus 
        | {{ $t("text.add_item")}}

</template>

<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin'
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader'
export default {
  components: { MediaUploader },
  mixins: [ ExtraBlock ],
  methods: {
    addField() {
      //console.log(this.model)
      this.model.items.push({ 
        id: this.$uuid.v4(),  
        name: null, 
        text: null, 
        date: null, 
        media: []  
      })
    },
    onAdded(model, id) {
      this.model.items.find(x => x.id === id).media.push(model)
    },
    onDeleted(fileName, id) {
      this.model.items.find(x => x.id === id).media = this.model.items.find(x => x.id === id).media.filter(x => x.filename != fileName)
    }
  }
}
</script>

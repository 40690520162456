//import _ from 'lodash'
//import router from '@/router'
//import store from '@/store'
import User from '.'
//import { methods as CompanyRepository } from '../repositories/company'
//import { methods as CurrencyRepository } from '../repositories/currency'

class AuthUser extends User {
  static parse(data) {
    return {
      ...super.parse(data),

      /*company: data.state && data.state.company_uuid
        ? CompanyRepository.find(data.state.company_uuid)
        : CompanyRepository.first(),

      state: data.state,
      settings: data.settings,
      */
      preferences: data.preferences
    }
  }

  /*
  getSelf() {
    return {
      'client': require('../repositories/client').methods,
      'employee': require('../repositories/employee').methods
    }[this.authenticableType].findByKey(this.authenticableId)
  }*/

  updateStates() {
    if (!this.state) {
      return
    }
  }
}

export default AuthUser

<template lang="pug">
  .tab-content
    .titles.text-center
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }} 
    slot(name="tips-container")
    .full-height.centerize(v-if="model.length == 0")
      .placeholder
        v-img.no-resp(:src="require('@/assets/images/placeholders/content.png')")
        p.text-center {{ $t('text.please_create_sitemap_first') }}
    .with-elements(v-else)
      group-container(
        v-if="isPolish"
        :title="$t('text.content_all_images')"
        :subtitle="$t('text.content_all_images_sub')"
      )
        v-text-field(v-model="content_all_images" filled :placeholder="$t('text.content_all_images_placeholder')"  :readonly="isLocked")
      div.w-full
        div.w-full.mb-5
          nested-tree(v-model="modifiedModel" @click="onTreeSelected" :activeUuid="selectedUuid" :contentError="contentError")
      div(
        v-for="c in contentItems" :key="c.uuid"
        v-if="selectedUuid == c.uuid"
      )
        v-checkbox(v-model="c.is_auto_filled" :label="labelAutoFilled" :readonly="isLocked")
        h2.text-left.content-title {{ c.name || c.title }} 
        div
          group-container(
            :title="$t('text.page_title')"
            :tip="$t('text.tip_contents.content.page_title')"
            :subtitle="$t('text.what_page_title_would_you_like_to_use_on_this_page')"
            :commentField="`content.${selectedUuid}-title`"
          )
            v-text-field(:value="c.title"
              @input="(v) => c.title = v"
              v-model="c.title"
              filled
              :placeholder="$t('text.business_name')"
              :readonly="isLocked" :error-messages="titleError")

          group-container(
            :title="$t('text.page_subtitle')"
            :tip="$t('text.tip_contents.content.page_subtitle')"
            :subtitle="$t('text.what_page_subtitle_would_you_like_to_use_on_this_page')"
            :commentField="`content.${selectedUuid}-subtitle`"
          )
            v-text-field(:value="generateLorem(c, {_key: 'subtitle'})" v-model="c.subtitle" filled :placeholder="$t('text.business_slogan_moto')" :readonly="isLocked || c.is_auto_filled" :disabled="c.is_auto_filled")

          group-container.mb-8(
            :title="$t('text.page_content')"
            :subtitle="$t('text.what_page_content_would_you_like_to_use_on_this_page')"
            :commentField="`content.${selectedUuid}-content`"
            :mandatory="true"
          )
            div.mb-6
              ckeditor(
                :editor="editor"
                :value="generateLorem(c, {_key: 'content'})"
                v-model="c.content"
                :disabled="isLocked || c.is_auto_filled"
                :config="editorConfig"
              )
              .error-text.margins(v-if="contentError") {{ $t(contentError) }}

          group-container(
            :title="$t('text.page_media')"
            :subtitle="$t('text.what_page_media_would_you_like_to_use_on_this_page')"
            :commentField="`content.${selectedUuid}-media`"
          )
            media-uploader(
              :documents="c.media"
              resourceName="projects"
              :uuid="projectUUID"
              @added="onAdded"
              @deleted="onDeleted"
              :disabled="isLocked"
            )
          content-extras.mt-8(:model="c.extras" :disabled="isLocked" :isAutoFilled="c.is_auto_filled")
</template>
<script>
import ContentExtras from './contentExtras/ContentExtras';
//- import ContentExtras from './contentExtras/ContentExtrasOld';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';
import NestedTree from './parts/NestedTree';
import TipContainer from '@/components/forms/parts/TipContainer';
import { genFields } from '@/components/forms/fieldGenerator';
import domain from '@/scripts/domain';
import { doGenerateText, setStorage, getStorage } from '@/helpers';

export default {
  name: 'content-tab',
  components: {
    GroupContainer,
    ContentExtras,
    MediaUploader,
    NestedTree,
    TipContainer,
  },
  props: {
    model: Array,
    default: [],
    isLocked: { type: Boolean },
    errors: null,
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.content',
      subtitle:
        'text.provide_us_the_content_texts_images_videos_other_that_you_want_to_use_in_your_website_pages',
      selectedUuid: null,

      collectedSitemap: null,
      editor: ClassicEditor,
      editorConfig: {
        resize_minHeight: 400,
        placeholder: this.$t('text.enter_your_copy_placeholder'),
      },
      labelAutoFilled: this.$t('text.i_dont_have_text_for_this_page_please_fill_in_lorem_text'),
    };
  },
  mounted() {
    if (this.contentItems[0]) {
      this.selectedUuid = this.contentItems[0]?.uuid;
    }
  },
  computed: {
    itemActiveByUuid() {
      return this.contentItems.findDeep('uuid', this.selectedUuid) || {};
    },
    modifiedModel() {
      let m = this.model;
      // disabling sub items if we have style.type == single
      if (this.type === 'single')
        m = this.model.map((x) => {
          x.elements = [];
          return x;
        });
      return m;
    },

    isPolish() {
      return domain.isPL();
    },
    titleError() {
      return [];
      // const titles = this.errors.sc_title ?? {};
      // return titles[this.selectedUuid] ?? [];
    },
    contentError() {
      // content
      const contents = _.get(this.errors, 'content', {});
      return contents[this.selectedUuid] ?? false;
    },
    projectUUID() {
      return this.$store.state.forms.projects.fields.uuid;
    },
    contentItems() {
      let items = [];
      this.model.eachDeep('elements', (item) => {
        console.error('ITEMS', item);
        item.title = item.title ?? item.name;
        if (!item.deleted) {
          //- item['is_auto_filled'] = item?.is_auto_filled ?? Boolean(getStorage(`autoFilled_${item.uuid}`))
          items.push(item);
        }
      });
      return items;
    },
    generateLorem() {
      return doGenerateText;
    },
    ...genFields('projects', ['only_one.content_all_images', 'style.type']),
  },
  methods: {
    onAdded(model) {
      const uuid = this.selectedUuid;
      this.model.mapDeep('elements', function (e, parent) {
        if (e.uuid === uuid) {
          e.media.push(model);
        }
        return e;
      });
    },
    onDeleted(fileName) {
      //console.log('deleting..', fileName)
      const uuid = this.selectedUuid;
      this.model.mapDeep('elements', function (e) {
        if (e.uuid === uuid) e.media = e.media.filter((x) => x.filename !== fileName);

        return e;
      });
    },
    onTreeSelected(uuid) {
      this.selectedUuid = uuid;
    },
    onChangeAutoFill(checked, content) {
      setStorage(`autoFilled_${content.uuid}`, checked); // todo binding from serve
      content.is_auto_filled = checked;
    },
  },
};
</script>
<style lang="scss">
.tab-content {
  .ck-content {
    min-height: 200px;
    max-height: 800px;
  }

  .content-title {
    padding-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
  }
}

.box-add-extras {
  /* color/typography/caption */

  border: 1px dashed #a0a0a0;
  border-radius: 15px;
  height: 144px;
  font-weight: 400;
  font-size: 16px;
  color: #a0a0a0;
}
</style>

<template lang="pug">
  .extra-block.gallery
    v-radio-group(v-model="model.priceType")
      .space-y-6
        div.relative.pl-8
          v-radio(:value="1" label="List of prices" class="-ml-8") 
          div.space-y-4(v-if="model.priceType == 1")
            div.grid.grid-cols-12.gap-4(v-for="(item, index) in model.items" :key="item.uuid")
              .col-span-12(v-if="index !== 0")
                hr
              .col-span-12(:class="['md:col-span-6']")
                v-text-field(:value="generateLorem(model, {_key: 'title'})" v-model="item.title" filled  :placeholder="$t('Website development')" :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
              .col-span-12(:class="['md:col-span-6']")
                v-text-field(:value="generateLorem(model, {_key: 'price'})" type="number" v-model="item.price" filled  :placeholder="$t('100 $')" :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
              .col-span-12
                  ckeditor(:editor="editor" :value="generateLorem(model, {_key: 'description'})" v-model="item.description" :disabled="disabled || isAutoFilled")
              .col-span-12
                div.flex.items-center.gap-2.cursor-pointer( @click="deleteItem(item.uuid)")
                  img.block.cursor-pointer.ml-auto(src="@/assets/images/delete.svg")
                  span.text-error.text-sm {{ $t('text.Delete') }}
            div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addNew()")
                v-icon(color="#222222") mdi-plus 
                | {{ $t("text.add_item")}}
        div.relative.pl-8
          v-radio(:value="2" class="-ml-8")
            template(v-slot:label)
              | Tabel of prices Upload an Excel file with all your services and their prices.
          media-uploader.mt-8(
            v-if="model.priceType == 2"
            :documents="model.media"
            resourceName="projects"
            :uuid="projectUUID"
            @added="onAdded"
            @deleted="onDeleted"
            :disabled="disabled"
            :acceptedFileTypes="['application/vnd.ms-excel']"
          )
</template>

<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader'
export default {
  mixins: [ ExtraBlock ],
  components: { MediaUploader },
  data() {
    return {
      editor: ClassicEditor,
    }
  },
  computed: {
    items() {
      return this.model.items
    }
  },
  methods: {
    onAdded(media) {
      this.model.media.push(media)
    },
    onDeleted(fileName, id) {
      this.model.media = this.model.media.filter(x => x.filename != fileName)
    },
    deleteItem(uuid) {
      this.model.items = this.model.items.filter(x => x.uuid != uuid)
    },
    addNew() {
      this.onAdded({
        uuid: this.$uuid.v4(),
        price: 1,
        title: 'New price',
        description: 'Describ price detail'
      })
    }
  }
}
</script>

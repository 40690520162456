import Store from '@/store';
export function generateFormFields(formName, fieldName) {
  const fields = Store.state.forms[formName][fieldName];
  const generated = {};
  Object.keys(fields).forEach((fieldName) => {
    generated[fieldName] = {
      get() {
        return fields[fieldName];
      },
      set(value) {
        return this.formDispatch('UPDATE_FIELD', {
          field: fieldName,
          value,
        });
      },
    };
  });
  return generated;
}

export function generateFields(formName, fields) {
  const generated = {};
  //const storeFields = Store.state.forms[formName]

  //console.log('wtf', formName, fields, store)
  /*
  fields.forEach((xfield) => {
    let fieldName = xfield
    let parent = false
    const fieldArray = xfield.split('.')
    
    if (fieldArray.length == 2) {
      fieldName = fieldArray[1]
      parent = fieldArray[0]
    }

    console.log(fieldName, parent, fieldArray, storeFields)
    generated[fieldName] = {
      get() {
        return (parent) ? storeFields[parent][fieldName] : storeFields[fieldName]
      },
      set(val) {
        (parent)
          ? storeFields[parent][fieldName] = val
          : storeFields[fieldName] = val;

        Store.commit(`forms/${formName}/SET_ERROR`, { field: fieldName, message: [], parent: parent })
      }
    }
  })*/

  return generated;
}

export function genFields(formName, fields) {
  const generated = {};
  const storeFields = Store.state.forms[formName].fields;

  fields.forEach((xfield) => {
    let fieldName = xfield;
    let parent = false;
    const fieldArray = xfield.split('.');

    if (fieldArray.length == 2) {
      fieldName = fieldArray[1];
      parent = fieldArray[0];
    }

    generated[fieldName] = {
      get() {
        return parent ? storeFields[parent][fieldName] : storeFields[fieldName];
      },
      set(val) {
        const invalidValue = [null, undefined, '']
        let tempVal = null
        const _initState = Store?.state?.forms[formName]?.__initial_state?.fields || {}
        const initVal = _initState[parent][fieldName]

        console.log('STORE', _initState)
        if(invalidValue.includes(val)) {
          tempVal = initVal
        }else {
          tempVal = val
        }

        const _nextVal = JSON.stringify(initVal)
        const _prevVal = JSON.stringify(tempVal)
        const isRemove = _nextVal.localeCompare(_prevVal) == 0
        const point = `${parent}_${fieldName}`
        Store.commit('SET_HISTORY_CHANGES', {point, isRemove})

        parent ? (storeFields[parent][fieldName] = val) : (storeFields[fieldName] = val);
        Store.commit(`forms/${formName}/SET_ERROR`, {
          field: fieldName,
          message: [],
          parent: parent,
        });
      },
    };
  });

  return generated;
}

<template lang="pug">
  v-card(flat)
    v-form
      v-container(fluid)
        v-select(
          :menu-props={offsetY: true}
          v-model="locale",
          :items="languages",
          item-text="name",
          item-value="code",
          :label="$t('text.language')"
        )
          template(v-slot:selection='{ item, index }')
            img.img-flag.mr-3(:src='require(`@/assets/images/flags/${item.icon}`)' style="height: 25px;")
            span.text-uppercase {{ item.code }}

          template(v-slot:item='{ active, item, attrs, on }')
            v-list-item(v-on='on' v-bind='attrs' #default='{ active }')
              v-list-item-content
                v-list-item-title.drop-down-language-item
                  img.img-flag.mr-3(:src='require(`@/assets/images/flags/${item.icon}`)' style="height: 25px;")
                  span {{ item.name }}


        v-btn(:dark="true", color="primary", depressed @click="formSubmit") {{ $t('text.save') }}
</template>
<script>
import { languagesFlag } from "@/i18n";

export default {
  mixins: [
    //FormMixin('login')
  ],
  data: function() {
    return {
      selectedItem: 0,
      languages: languagesFlag,
    };
  },
  computed: {
    pageTitle() {
      return "text.setting";
    },
    user() {
      return this.$store.state.auth.user;
    },
    locale: {
      get() {
        console.error("language", this.$store.state.config.language)
        return this.languages.find(
          (i) => i.code == this.$store.state.auth.user.preferences.locale
          // (i) => i.code === this.$store.state.config.language
        );
      },
      set(newValue) {
        this.$i18n.locale = newValue;
        return this.$store.commit("auth/SET_PREFERENCE", {
          key: "locale",
          value: newValue,
        });
      },
    },
  },
  methods: {
    formSubmit() {
      this.$store.dispatch("auth/UPDATE_PREFERENCES", [
        {
          key: "locale",
          value: this.locale.code,
        },
      ]);
    },
  },
};
</script>

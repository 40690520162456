<template lang="pug">
  .tab-content
    .titles.mb-10.text-center
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    slot(name="tips-container")
    group-container(
      :title="$t('text.Website_structure')"
      :subtitle="$t('text.there_are_2_ways_how_your_website_can_be_built')"
      commentField="style.website_type"
      :mandatory="true"
    )
      template(v-slot:subtitle="{ text }")
        div.flex.justify-between.items-center
          span {{ text }} 
      v-radio-group.ib-1(v-model="style.type" :mandatory="false" :disabled="isLocked" :error-messages="$t(errors.style.type)")
        v-radio(value="single")
          template(v-slot:label) {{ $t('text.create_me_a_one_page_website') }}

        .p.mb-5.mt-1.explain.ib-2 {{ $t('text.this_is_a_perfect_option_for_websites_with_little_information') }}

        v-radio(value="multi")
          template(v-slot:label) {{ $t('text.create_me_a_multi_page_website') }}

        .p.mt-1.explain {{ $t('text.the_classical_type_the_content_is_divided_to_separate_pages') }}
          br
          | {{ $t('text.this_type_is_a_perfect_fit_for_websites_with_more_information') }}

        //- .spacer(v-if="hasErrorType" style="height: 30px;")
        .error-text.mt-5(v-if="hasErrorType") {{ $t('text.please_select_one_option') }}

    group-container(
      :title="$t('text.Pages')"
      :subtitle="$t('text.Create_a_structure_for_your_website_pages_and_sub_pages_that_will_be_displayed_in_the_menu_Just_drag_drop')"
      commentField="sitemap.sitemap"
      :mandatory="true"
    )
      div(v-if="hasPages")
        nested-draggable(
          v-model="sitemap"
          :root="rootSitemap"
          :disabled="isLocked"
          :nestable="nestable"
        )
      div(v-else)
        v-radio-group.ib-1.space-y-4(v-model="isStandard" @change="changeTypeSite" :mandatory="false" :disabled="isLocked" :error-messages="$t(errors.style.type)")
          div
            v-radio(:value="true")
              template(v-slot:label) {{ $t('text.Standard') }}
            .p.mb-3.mt-1.explain.ib-2 {{ $t('text.It_includes_four_most_popular_pages_Home_About_Services_Contacts_This_structure_is_not_rigid_ie_you_can_rename_delete_or_add_additional_pages_if_you_need') }}
            //- .explain.ib-2
            //-   nested-draggable(v-model="sitemap" :disabled="isLocked" :nestable="nestable")
            //- .explain.ib-2
            //-   div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addNew()")
            //-     v-icon(color="#222222") mdi-plus
            //-     | {{ $t("text.add_new_page")}}
          div
            v-radio(:value="false")
              template(v-slot:label) {{ $t('text.Custom') }}
            .p.mb-5.mt-1.explain.ib-2
              | {{ $t('text.Add_any_pages_you_need_eg_Prices_Our_history_Work_etc_Then_you_can_rename_drag_delete_them_if_you_need') }}
            //- .explain.ib-2
            //-   div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addNew()")
            //-     v-icon(color="#222222") mdi-plus
            //-     | {{ $t("text.add_new_page")}}
      template(v-if="isHideBtnAdd")
        div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addNew()" v-if="hasPages")
          v-icon(color="#222222") mdi-plus
          | {{ $t("text.add_new_page")}}
      .error-text.mt-5(v-if="showError") {{ $t(errors.sitemap) }}
</template>
<script>
import AddComment from '@/components/forms/parts/CommentAdd';
import NestedDraggable from '@/components/forms/parts/NestedDraggable';
import FormMixin from '@front/mixins/FormMixin';
import TipContainer from '@/components/forms/parts/TipContainer';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import { genFields } from '@/components/forms/fieldGenerator';

function removeNested(array, uuid) {
  let idx = array.findIndex((o) => o.uuid == uuid);
  if (idx >= 0) {
    array.splice(idx, 1);
  } else {
    array.forEach(function (o) {
      removeNested(o.elements || [], uuid);
    });
  }
  return array;
}

export default {
  components: {
    NestedDraggable,
    TipContainer,
    AddComment,
    GroupContainer,
  },
  mixins: [FormMixin('projects')],
  props: {
    isLocked: { type: Boolean },
    isAttached: {
      type: Boolean,
      default: false,
    },
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.project_menu_sitemap',
      subtitle: 'text.create_a_sitemap_for_your_website_structure_pages_and_sub',
      isStandard: undefined,
      items: [],
      rootSitemap: [],
    };
  },
  computed: {
    isHideBtnAdd() {
      return !(
        this.$user().isAdmin() ||
        this.$user().isSuperAdmin() ||
        this.$user().isManager() ||
        this.$user().isDesigner()
      );
    },
    hasPages() {
      return this.sitemap?.filter((item) => !item.deleted).length > 0;
    },
    showError() {
      //- return this.errors.sitemap && Object.keys(this.errors.sitemap).length
      return this.errors.sitemap && this.errors.sitemap.length;
    },
    nestable() {
      return this.$store.state.forms.projects.fields.style.type === 'multi';
    },
    ...genFields('projects', ['style.type']),
    hasErrorType() {
      return Boolean(this.type) === false;
      //- return this.errors.style.type && this.errors.style.type.length;
    },
  },
  watch: {
    sitemap: {
      handler(val) {
        this.rootSitemap = val;
      },
      immediate: true,
    },
  },
  methods: {
    changeTypeSite(isStandard) {
      if (isStandard) {
        this.addStandard();
      } else {
        this.addNew();
      }
      this.$store.dispatch('forms/projects/UPDATE_FIELD', {
        field: 'sitemap',
        value: this.sitemap,
      });
    },
    addNew(name = null) {
      this.sitemap.push({
        is_auto_filled: false,
        is_new: true,
        deleted: false,
        name: name ?? this.$t('text.new_page'),
        elements: [],
        title: name ?? this.$t('text.new_page'),
        subtitle: null,
        content: null,
        media: [],
        uuid: this.$uuid.v4(),
        extras: [
          {
            id: 'block_media',
            added: false,
            items: [],
            title: '',
            description: '',
            media: [],
            linksVideo: [],
            uuid: this.$uuid.v4(),
          },
          {
            id: 'price',
            added: false,
            items: [],
            isListOfPrices: false,
            isTableOfPrices: false,
            media: [],
            priceType: 1,
            uuid: this.$uuid.v4(),
          },
          { id: 'features', added: false, items: [], uuid: this.$uuid.v4() },
          { id: 'partners', added: false, items: [], uuid: this.$uuid.v4() },
          { id: 'testimonials', added: false, items: [], uuid: this.$uuid.v4() },
          {
            id: 'gallery',
            added: false,
            title: null,
            description: null,
            media: [],
            uuid: this.$uuid.v4(),
          },
          { id: 'contacts', added: false, fields: [], uuid: this.$uuid.v4() },
          { id: 'registration_form', added: false, fields: [], uuid: this.$uuid.v4() },
        ],
      });
      this.isStandard = undefined;
    },
    addStandard() {
      [
        this.$t('text.homepage'),
        this.$t('text.sitemap_standard_about_us'),
        this.$t('text.sitemap_standard_services'),
        this.$t('text.sitemap_standard_contacts'),
      ].forEach(this.addNew);
    },
    addItems() {
      [
        this.$t('text.homepage'),
        this.$t('text.sitemap_standard_about_us'),
        this.$t('text.sitemap_standard_services'),
        this.$t('text.sitemap_standard_contacts'),
      ].forEach((name, index) => {
        let item = {
          name: 'Input',
        };
        if (index === 0) {
          item['elements'] = [{ name: 'Input' }];
        }
        this.items.push(item);
      });
    },
    async removeItem(item) {
      const confirmed = window.confirm(`Are you sure to delete page ${item.name}?`);
      if (!confirmed) {
        return;
      }
      this.sitemap = this.sitemap.filterDeep('elements', function (e, parent) {
        if (e.uuid === item.uuid) {
          e.deleted = true;
        }
        return e;
      });
      await this.$store.dispatch('forms/projects/UPDATE_FIELD', {
        field: 'sitemap',
        value: this.sitemap,
      });
      //- this.isStandard = undefined;
    },
  },
  mounted() {
    if (Boolean(this.type) === false) {
      this.style.type = 'single';
    }
    this.$root.$on('remove-item', this.removeItem);
    this.addItems();
  },
  destroyed() {
    this.$root.$off('remove-item', this.removeItem);
  },
};
</script>

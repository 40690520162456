import Vue from 'vue'
import store from '@/store'

export default () => {
  const token = Vue.$cookies.get('access_token')
  if (!token)
    return

  store.commit('auth/UPDATE_ACCESS_TOKEN', token)
  store.dispatch('auth/LOAD')
}
import BaseRepositoryState from './state'
import BaseRepositoryGetters from './getters'
import BaseRepositoryActions from './actions'
import BaseRepositoryMethods from './methods'
import BaseRepositoryMutations from './mutations'

/*
export default {
  RepositoryState,
  RepositoryGetters,
  RepositoryActions,
  RepositoryMethods,
  RepositoryMutations
}*/

export const RepositoryState = BaseRepositoryState
export const RepositoryGetters = BaseRepositoryGetters
export const RepositoryActions = BaseRepositoryActions
export const RepositoryMutations = BaseRepositoryMutations
export const RepositoryMethods = BaseRepositoryMethods

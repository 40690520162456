<template lang="pug">
  div(:class="[isAttached ? 'px-0' : 'tab-content']")
    .titles.mb-10.text-center(v-if="!isAttached")
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    //- slot(name="tips-container")
    //- group-container(
    //-   :title="$t('text.website_type')"
    //-   :subtitle="$t('text.there_are_2_ways_how_your_website_can_be_built')"
    //-   commentField="style.website_type"
    //-   :mandatory="true"
    //- )
    //-   template(v-slot:subtitle="{ text }")
    //-     div.flex.justify-between.items-center
    //-       span {{ text }} 
    //-       //- div.flex.gap-4.items-center.justify-end
    //-       //-   span.text-14 Dark/Light style
    //-       //-   v-switch.m-0(inset color="primary" hide-details :ripple="false") 
    //-   v-radio-group.ib-1(v-model="type" :mandatory="false" :disabled="isLocked" :error-messages="$t(errors.style.type)")
    //-     v-radio(value="single")
    //-       template(v-slot:label) {{ $t('text.create_me_a_one_page_website') }}

    //-     .p.mb-5.mt-1.explain.ib-2 {{ $t('text.this_is_a_perfect_option_for_websites_with_little_information') }}

    //-     v-radio(value="multi")
    //-       template(v-slot:label) {{ $t('text.create_me_a_multi_page_website') }}

    //-     .p.mb-2.mt-1.explain.ib-2 {{ $t('text.the_classical_type_the_content_is_divided_to_separate_pages') }}
    //-       br
    //-       br
    //-       | {{ $t('text.this_type_is_a_perfect_fit_for_websites_with_more_information') }}

    //-   .spacer(v-if="hasErrorType" style="height: 30px;")

    group-container.mt-5(
      :title="$t('text.website_style')"
      :tip="$t('text.tip_contents.style.website')"
      :subtitle="$t('text.choose_1_out_of_6_styles_for_your_website')"
      commentField="style.website_style"
      :mandatory="true"
    )
    style-chooser(@change="onStyleChange" v-model="style" :disabled="isLocked" :error-message="$t(errors.style.style)")

    group-container.mt-5(
      :title="$t('text.color_scheme')"
      :tip="$t('text.tip_contents.style.color')"
      :subtitle="$t('text.what_color_scheme_would_you_like_to_use_on_your_website')"
      commentField="style.color_scheme"
      :mandatory="true"
    )

      v-radio-group(v-model="color_scheme" :mandatory="false" :disabled="isLocked" :error-messages="$t(errors.style.color_scheme)")
        v-radio(value="perfect")
          template(v-slot:label) {{ $t('text.use_1_out_of_12_perfectly_balanced_colour_schemes_from_design_award_winners_recommended') }}

        style-color-scheme.mb-4(
          v-if="isSchemePerfectSelected"
          :disabled="isLocked"
          :error-messages="$t(errors.style.color_perfect_style)"
          @change="onColourChange"
          v-model="color_perfect_style"
        )

        v-radio(value="any")
          template(v-slot:label) {{ $t('text.use_any_colors_you_think_are_suitable') }}

        v-radio(value="logo")
          template(v-slot:label) {{ $t('text.use_the_colors_of_my_logo') }}

        v-radio.mb-2(value="colors")
          template(v-slot:label) {{ $t('text.use_these_colors_pick_at_least_2') }}

        color-picker.ib-2(
          v-if="isSchemeColorsSelected"
          :colors="colors_selected"
          :disabled="isLocked"
          :error-messages="$t(errors.style.colors_selected)"
        )

    group-container.mt-5(
      :title="$t('text.fonts')"
      :subtitle="$t('text.what_fonts_would_you_like_to_use_on_your_website_you_need_to_pick_two_fonts')"
      commentField="style.fonts"
      :mandatory="true"
    )
      h4.field-subtitle {{ $t('text.font_for_headings') }}
      v-radio-group(v-model="font_head" :mandatory="false" :disabled="isLocked" :error-messages="$t(errors.style.font_head)")
        v-radio(value="google")
          template(v-slot:label) {{ $t('text.use_1_out_of_1000_best_google') }}

        font-chooser.mt-1(
          v-if="isFontHeadGoogle"
          :apiKey="apiKey"
          :paragraph="false"
          :disabled="isLocked"
          @change="onFontHeadChange"
          v-model="font_head_google"
          id="heading"
          :error-message="$t(errors.style.font_head_google) || ''"
        )

        v-radio(value="any")
          template(v-slot:label) {{ $t('text.use_any_font_you_think_is_suitable') }}

        v-radio.mb-2(value="selected")
          template(v-slot:label) {{ $t('text.i_want_to_use_another_font') }}

        v-text-field.ib-2(
          filled
          v-if="isFontHeadSelected"
          v-model="font_head_selected"
          :label="$t('text.font_url')"
          :disabled="isLocked"
          style="margin-top:-5px"
          :error-messages="$t(errors.style.font_head_selected)"
        )


      h4.field-subtitle {{ $t('text.font_for_paragraphs') }}
      v-radio-group.ib-1(v-model="font_paragraph" :mandatory="false" :disabled="isLocked" :error-messages="$t(errors.style.font_paragraph)")
        v-radio(value="google")
          template(v-slot:label) {{ $t('text.use_1_out_of_1000_best_google') }}

        font-chooser.mt-1(
          v-if="isFontParagraphGoogle"
          :apiKey="apiKey"
          :paragraph="true"
          :disabled="isLocked"
          @change="onFontParagraphChange"
          v-model="font_paragraph_google"
          id="paragraph"
          :error-message="$t(errors.style.font_paragraph_google) || ''"
        )

        v-radio(value="any")
          template(v-slot:label) {{ $t('text.use_any_font_you_think_is_suitable') }}

        v-radio.mb-2(value="selected")
          template(v-slot:label) {{ $t('text.i_want_to_use_another_font') }}

        v-text-field.ib-2(
          filled
          v-if="isFontParagraphSelected"
          v-model="model.font_paragraph_selected"
          :label="$t('text.font_url')"
          :disabled="isLocked"
          :error-messages="$t(errors.style.font_paragraph_selected)"
          style="margin-top:-5px"
        )


    group-container.mt-8(
      :title="$t('text.websites_that_you_like')"
      :tip="$t('text.tip_contents.style.favorite')"
      :subtitle="$t('text.add_the_links_to_the_websites_that_you_like')"
      commentField="style.website_links"
      :mandatory="true"
    )
      //- url-field(name="link1" :isLocked="isLocked" :error="$t(errors.style.link1)")
      //- url-field(name="link2" :isLocked="isLocked" :error="$t(errors.style.link2)")
      //- url-field(name="link3" :isLocked="isLocked" :error="$t(errors.style.link3)")
      url-fields(name="website_like" :error="$t(errors.style.website_like)" :isLocked="isLocked")
    //- group-container.mt-8(
    //-   :title="$t('text.link_comments')"
    //-   :subtitle="$t('text.you_can_describe_us_here_what_did_you_like_on_those_websites')"
    //-   commentField="style.link_comments"
    //- )
    //-   v-textarea(v-model="link_comments" filled :readonly="isLocked")

    group-container.mt-8(
      :title="$t('text.your_comments')"
      :tip="$t('text.tip_contents.style.comment')"
      :subtitle="$t('text.you_can_tell_us_about_your_vision_describe_your_wishes_and_expectations_here')"
      commentField="style.comments"
    )
      v-textarea(v-model="model.comments" filled :readonly="isLocked")
</template>
<script>
import { STYLE } from '@constant';

import GroupContainer from '@/components/forms/parts/GroupContainer';
import StyleChooser from './parts/StyleChooser';
import StyleColorScheme from './parts/StyleColorScheme';
import UrlField from './parts/UrlField';
import UrlFields from './parts/UrlFields';
import FontChooser from './parts/FontChooser';
import ColorPicker from './parts/ColorPicker';
import { genFields } from '@/components/forms/fieldGenerator';
import TipContainer from '@/components/forms/parts/TipContainer';
import IconMode from '@/pages/projects/icons/IconMode';

export default {
  components: {
    GroupContainer,
    StyleChooser,
    StyleColorScheme,
    FontChooser,
    ColorPicker,
    TipContainer,
    UrlField,
    UrlFields,
    IconMode,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: { type: Boolean },
    comments: { type: Object },
    errors: null,
    isAttached: {
      type: Boolean,
      default: false,
    },
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.style_design',
      subtitle: 'text.tell_us_about_the_style_and_design_that_you_want_to_see_on_your_website',
    };
  },

  computed: {
    apiKey() {
      return process.env.VUE_APP_GOOGLE_FONT_API_KEY;
    },
    isSchemePerfectSelected() {
      return String(this.model.color_scheme) === String(STYLE.color_perfect);
    },
    isSchemeColorsSelected() {
      return String(this.model.color_scheme) === String(STYLE.color_colors);
    },
    isFontHeadGoogle() {
      return String(this.model.font_head) === String(STYLE.font_google);
    },
    isFontHeadSelected() {
      return String(this.model.font_head) === String(STYLE.font_selected);
    },
    isFontParagraphGoogle() {
      return String(this.model.font_paragraph) === String(STYLE.font_google);
    },
    isFontParagraphSelected() {
      return String(this.model.font_paragraph) === String(STYLE.font_selected);
    },

    ...genFields('projects', [
      'style.type',
      'style.style',
      'style.color_scheme',
      'style.color_perfect_style',
      'style.colors_selected',
      'style.font_head',
      'style.font_head_selected',
      'style.font_head_google',
      'style.font_paragraph',
      'style.font_paragraph_selected',
      'style.font_paragraph_google',
      'style.link_comments',
      //'style.link1', 'style.link2', 'style.link3'
      'style.links',
      'style.website_like',
    ]),

    // hasErrorType() {
    //   return this.errors.style.type && this.errors.style.type.length;
    // },
  },
  methods: {
    onStyleChange(model) {
      this.style = model;
    },
    onColourChange(model) {
      this.color_perfect_style = model;
    },

    onFontHeadChange(model) {
      this.font_head_google = model;
    },

    onFontParagraphChange(model) {
      this.font_paragraph_google = model;
    },
  },
};
</script>

import { RepositoryMutations, RepositoryActions, RepositoryGetters, RepositoryMethods, RepositoryState } from '@abs/repository'
import Project from './model'
// import RepositoryState from './state'

const state = RepositoryState({
  filters: {
    users: [],
    managers: [],
    start_date: undefined,
    end_date: undefined,
    project_statuses: [],
    page: 1,
    per_page: 10,
    q: undefined,
  }
})
const mutations = RepositoryMutations()
const actions = RepositoryActions(Project)
const getters = RepositoryGetters({
  API_NAME: () => 'projects',
  API_RESOURCE_NAME: () => 'project',
})
/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters
}
export const methods = RepositoryMethods('projects')

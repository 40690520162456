<template lang="pug">
  v-container(fluid)
    parent(v-bind="formBind" @close="$emit('form-cancel')")
      template(v-slot:main)
        .form-content.cpad.pt-6.w-full(:class="['px-mb md:pl-sideBar xl:pl-6 md:pr-6 xl:max-w-content xl:ml-sidebar xl:pr-6 w-full']")
          group-container(
            title="Translation Key"
          )
            v-text-field(@input="input" :value="key" filled :disabled="isEdit")
          group-container(
            v-for="(lang, i) in languages"
            :key="i"
            :title="lang.toUpperCase() + ' value'"
          )
            v-textarea(v-bind="binds(lang)" @input="setX(lang, $event)" :error-messages="errors[lang]" filled rows="3")
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import Button from '@/components/common/buttons/Button';
import { supportedLanguages } from '@/i18n';
import debounce from 'lodash/debounce';
import Api from '@/plugins/api';

export default {
  mixins: [FormMixin('translations')],
  components: { GroupContainer },

  computed: {
    languages() {
      return supportedLanguages;
    },
    isEdit() {
      return ['translationEdit'].includes(this.$route.name);
    },
    formActions() {
      let isMobile = this.$vuetify.breakpoint.xs;
      let save = {
        label: this.$t('text.save'),
        icon: 'ic-done',
        evinput: this.formBaseActionSave,
        component: Button,
        flat: true,
        small: true,
        color: '#00D079',
        loading: this.$store.getters.getCoordinate('saveBase'),
        order: isMobile ? 1001 : 99,
        class: isMobile ? 'w-100 my-1' : '',
        show: true,
      };
      let save_comments = {
        label: this.$t('Save Translation'),
        key: 'save',
        icon: 'ic-done',
        evinput: this.formBaseActionSave1,
        component: Button,
        filled: true,
        small: true,
        color: '#4262FF',
        class: isMobile ? 'w-100 my-1' : '',
        order: 99,
        loading: this.$store.getters.getCoordinate('saveBase'),
        show: true,
      };
      let options = {
        cancel: {
          label: this.$t('text.cancel'),
          key: 'cancel',
          icon: 'ic-close',
          evinput: this.formBaseActionCancel,
          component: Button,
          flat: true,
          small: true,
          color: '#FF9900',
          order: isMobile ? 1000 : 98,
          class: isMobile ? 'w-100 my-1' : 'ml-auto',
          show: true,
        },
      };
      if (this.isEdit) {
        return {
          ...options,
          save,
        };
      }
      return {
        ...options,
        save_comments,
      };
    },
    formBaseActions() {
      return {};
    },
  },

  mounted() {},

  methods: {
    saveComments() {
      console.log(this.formState.fields);
    },
    binds(lang) {
      return {
        value: this[lang],
      };
    },

    input: debounce(function (val) {
      let value = val?.trim();
      this.setVal('key', value);
    }, 800),

    setX: debounce(function (lang, val) {
      this.setVal(lang, val);
    }, 400),

    setVal(lang, value) {
      this[lang] = value;
    },

    async formBaseActionSave1() {
      try {
        this.$store.commit('SET_SPINNER', 'saveBase');
        await Api.post('translations/store', {
          translation: this.formState.fields,
        });
        this.$store.commit('ADD_NOTIFICATION', this.$t('text.created_successfully'));
        this.formBaseActionCancel();
      } catch (err) {
        console.error(err);
        if (err?.response?.data?.messages) {
          this.formDispatch('MERGE_ERRORS_BY_RESOURCE', err.response.data.messages);
        } else {
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.oops_server_error_try_again_later'));
        }
      } finally {
        this.$store.commit('REMOVE_SPINNER', 'saveBase');
      }
    },
  },
};
</script>

export default {
  methods: {
    addMedia(model, metatype) {
      model.uuid = this.$uuid.v4()
      model.metatype = metatype
      this.model.media.push(model)
    },
    onDeleted(uuid) {
      this.model.media = this.model.media.filter(x => x.uuid != uuid)
    }

  }
}
<template lang="pug">
  .tab-content
    .titles.mb-10.text-center
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    slot(name="tips-container")
      //- .full-height.centerize(v-if="categories.length == 0")
      //-   .placeholder
      //-     v-img.no-resp(:src="require('@/assets/images/placeholders/categories.png')")
      //-     p.text-center {{ $t('text.create_a_category_map_here') }}
      //-       br
      //-       | {{ $t('text.add_as_many_categories') }}
      //-     v-btn.mt-5(
      //-       color="primary" 
      //-       @click="addNew" 
      //-       :disabled="isLocked" 
      //-       rounded 
      //-       pill 
      //-       depressed 
      //-       large) 
      //-       | {{ $t('text.add_new_category') }}
      //-     .error-text.mt-5(v-if="showError") {{ $t(errors.categories) }}
    .with-elements
      group-container(
        :title="$t('text.Category_map')"
        :subtitle="$t('text.Category_map_is_the_structure_of_your_сategories_that_will_help_clients_navigate_in_products')"
        commentField="categories.categories"
        :mandatory="false"
      )
        nested-draggable.mt-4(
          :list="categories"
          :root="rootCategories"
          :disabled="isLocked"
          :nestable="true"
        )
        .error-text.mt-5(v-if="showError") {{ $t(errors.categories) }}
        div.pt-2.pb-10
          div(v-if="isHideBtnAdd" @click="addNew").mb-8.cursor-pointer.flex.items-center
            v-icon mdi-plus
            | {{ $t('text.add_new_category') }}
          div(v-if="isHideCheckBox")
            v-checkbox(
              v-model="display_categories_in_main_menu"
              hide-details
              :readonly="isLocked"
              :label="$t('text.Display_categories_in_the_site_menu')"
              @change="onCheckCategory"
            )
            .p.mb-2.mt-2.explain.ib-2
              | {{ $t('text.if_you_choose_this_checkbox_all_categories_will_be_placed_in_the_dropdown_menu_of_the_shop_page') }}
</template>
<script>
import NestedDraggable from '@/components/forms/parts/NestedDraggable';
import FormMixin from '@front/mixins/FormMixin';
import TipContainer from '@/components/forms/parts/TipContainer';
import AddComment from '@/components/forms/parts/CommentAdd';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import { DEPARTMENT_ID_PL } from '@constant/departments';

export default {
  components: { NestedDraggable, TipContainer, AddComment, GroupContainer },
  mixins: [FormMixin('projects')],
  props: {
    isLocked: false,
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.product_categories_online_shop',
      subtitle: 'text.lets_start_setting_up_your_online_store',
      rootCategories: [],
    };
  },
  computed: {
    isHideBtnAdd() {
      return !(
        this.$user().isAdmin() ||
        this.$user().isSuperAdmin() ||
        this.$user().isManager() ||
        this.$user().isDesigner()
      );
    },
    showError() {
      return this.errors.categories && this.errors.categories.length;
    },
    name() {
      return this.$t('text.new_category');
    },
    isHideCheckBox() {
      const departments = [DEPARTMENT_ID_PL];
      return !departments.includes(this.$store.state.config.department_id);
    },
  },
  watch: {
    categories: {
      handler(val) {
        this.rootCategories = val;
      },
      immediate: true,
    },
  },
  methods: {
    addNew() {
      this.categories.push({
        uuid: this.$uuid.v4(),
        is_new: true,
        elements: [],
        name: this.name,
        products: [],
      });

      this.$store.dispatch('forms/projects/UPDATE_FIELD', {
        field: 'categories',
        value: this.categories,
      });
    },
    removeItem(item) {
      this.categories = this.categories.filterDeep('elements', function (e, parent) {
        if (e.uuid === item.uuid) e.deleted = true;
        return e;
      });

      this.$store.dispatch('forms/projects/UPDATE_FIELD', {
        field: 'categories',
        value: this.categories,
      });
    },
    onCheckCategory(value) {
      this.$store.dispatch('forms/projects/UPDATE_FIELD', {
        field: 'display_categories_in_main_menu',
        value: value ? 1 : 0,
      });
    },
  },
  mounted() {
    this.$root.$on('remove-item', this.removeItem);
  },
  destroyed() {
    this.$root.$off('remove-item', this.removeItem);
  },
};
</script>

/**
 * Base state of the repository
 */
export default (state) => Object.assign({
  key: 'uuid',
  items: [],
  is_fetch: false,
  pagination: {
    count: 0,
    current_page: 1,
    links: {
      previous: null,
      next: null
    },
    per_page: 10,
    total: 0,
    total_pages: 0,
  },
  filters: {
    page: 1,
    per_page: 10,
    q: undefined,
  },
}, state)

import { mix } from 'mixwith';
import DocumentSchema from '@abs/document/schema';

const ProjectSchema = (superclass) =>
  class extends mix(superclass).with(DocumentSchema) {
    /**
     * Parse project data that came from API
     */
    static parse(data) {
      const d = super.parse(data);

      d.uuid = data.uuid;
      d.username = data.username;
      d.account_name = data.account_name;
      d.email = data.email;
      d.user_type_id = data.user_type_id;
      d.department_id = data.department_id;
      d.hide_user_in_dropdown_list = data.hide_user_in_dropdown_list;
      d.type = data.type;
      d.password = data.password;

      return d;
    }

    serialize(options = {}) {
      return {
        ...super.serialize(options),
        uuid: this.uuid,
        email: this.email,
        user_type_id: this.user_type_id,
        department_id: this.department_id,
        password: this.password,
        hide_user_in_dropdown_list: this.hide_user_in_dropdown_list,
        account_name: this.account_name,
        username: this.username,
      };
    }
  };

export default ProjectSchema;

<template lang="pug">
  v-container
    parent(v-bind="formBind" @close="$emit('form-cancel')")
      template(v-slot:main)
        div(class="form-content w-full grid sm:grid-cols-1 md:grid-cols-2 gap-10")
          div
            group-container(
              :title="$t('text.username')"
              :subtitle="$t('text.what_user_name_you_would_like_to_set_for_this_account')"
            )
              v-text-field(v-model="username" :error-messages="errors.username" filled)

            group-container(
              :title="$t('text.company_name')"
              :subtitle="$t('text.what_company_name_you_would_like_to_set_for_this_account')"
            )
              v-text-field(v-model="account_name" :error-messages="errors.account_name" filled)

            group-container(
              :title="$t('text.email')"
              :subtitle="$t('text.what_login_name_you_would_like_to_set_for_this_account')"
            )
              v-text-field(v-model="email" :error-messages="errors.email" filled)
            group-container(
              :title="$t('text.password')"
              :subtitle="$t('text.what_password_you_would_like_to_set_for_this_account')"
            )
              v-text-field(v-model="password" :error-messages="errors.password" type="password" filled :placeholder="$t('text.enter_password')")
              v-text-field(v-model="password_repeat" :error-messages="errors.password_repeat" type="password" filled :placeholder="$t('text.repeat_password')")
          div
            group-container(
              :title="$t('text.type')"
              :subtitle="$t('text.what_is_the_account_type')"
            )
              v-select(v-model="user_type_id" :menu-props={ offsetY: true } :error-messages="errors.user_type_id" :items="passiveUserTypes" filled)

            group-container(
              v-if="isDisplayToggleUser(user_type_id)"
              :title="$t('text.hide_user_in_dropdown_list')"
              :subtitle="$t('text.affecting_account_type_project_manager_and_project_designer')"
              class="mb-5"
            )
              v-switch(
                v-model="hide_user_in_dropdown_list"
                inset
                true-value="2"
                false-value="1"
                :label="`${displayUserDropdownList()}`"
                class="inline-block"
              )

            group-container(
              :title="$t('text.department')"
              :subtitle="$t('text.which_department_you_would_like_to_set_for_this_account')"
            )
              v-select(v-model="department_id" :menu-props={ offsetY: true } :items="passiveDepartments" filled)
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import PassivesMixin from '@/mixins/PassivesMixin';
import { ROLE_PROJECT_DESIGNER, ROLE_PROJECT_MANAGER } from '@constant/roles';

export default {
  mixins: [FormMixin('users'), PassivesMixin],
  components: { GroupContainer },
  watch: {
    user_type_id(val) {
      const allowShowToggles = [ROLE_PROJECT_DESIGNER, ROLE_PROJECT_MANAGER];
      if (allowShowToggles.includes(val)) {
        this.hide_user_in_dropdown_list = '2';
      }
    },
  },
  methods: {
    isDisplayToggleUser(userType) {
      const allowShowToggles = [ROLE_PROJECT_DESIGNER, ROLE_PROJECT_MANAGER];

      return allowShowToggles.includes(userType);
    },
    displayUserDropdownList() {
      return this.hide_user_in_dropdown_list !== '1'
        ? this.$t('text.show_user')
        : this.$t('text.hide_user');
    },
  },
};
</script>
<style lang="scss" scoped>
.form-content {
  padding: 30px 25px;
}
</style>

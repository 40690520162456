<template lang="pug">
  .extra-block.contacts
    //- group-container(
    //-     :title="$t('text.Section title')"
    //-     :subtitle="$t('text.It_will_inform_users_what_the_section_is_about')"
    //-   )
    //-   v-text-field(
    //-     filled 
    //-     v-model="model.title"
    //-     :placeholder="$t('We are trusted by')"
    //-     :readonly="disabled || isAutoFilled"
    //-     :disabled="isAutoFilled"
    //-   )
    div
      div(
        v-for="item in model.fields"
        :key="item.id"
      )
        label.block.text-222222.mb-4.text-16.font-medium {{$t('text.Email')}}
        v-text-field(v-model="item.email" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
        label.block.text-222222.mb-4.text-16.font-medium {{$t('text.Phone_number')}}
        v-text-field(:value="generateLorem(item, {_key: 'value'})" v-model="item.phone" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
        label.block.text-222222.mb-4.text-16.font-medium {{$t('text.Address')}}
        v-text-field(:value="generateLorem(item, {_key: 'value'})" v-model="item.address1" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
        label.block.text-222222.mb-4.text-16.font-medium {{$t('text.social_networks')}}
        div.grid.grid-cols-12.gap-4(v-for="(social, index) in item.socials" :key="index")
          div.col-span-3
            label.block.mb-2 {{ $t('text.Choose') }}
            v-select(:menu-props="{offsetY: true}" color="primary" filled v-model="social.type"  :items="['Instagram', 'Facebook']" hide-details)
          div.col-span-8
            label.block.mb-2 {{ $t('text.Link') }}
            v-text-field(v-model="social.value" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
          div.col-span-1.flex.items-center
            img.block.cursor-pointer(src="@/assets/images/delete.svg" @click="removeSocial(item, social)")
        div.cursor-pointer.flex.items-center.gap-2.text-black.mb-4(@click="addSocial(item, social)" v-if="!disabled")
          v-icon(color="#222222") mdi-plus 
          | {{ $t("text.add_item")}}
        label.block.text-222222.mb-4.text-16.font-medium {{$t('text.Address')}}
        v-textarea(row="6" :value="generateLorem(item, {_key: 'value'})" v-model="item.address2" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")
        div.flex.items-center.gap-2.cursor-pointer( @click="removeField(item)" v-if="!disabled")
          img.block.cursor-pointer.ml-auto(src="@/assets/images/delete.svg")
          span.text-error.text-sm {{ $t('text.Delete') }}

    //- v-btn(color="primary" @click="addField" rounded depressed :disabled="disabled") {{ $t('text.add_field') }}
    div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addField" v-if="!disabled")
      v-icon(color="#222222") mdi-plus
      | {{ $t("text.add_item")}}
    v-btn.ml-5(color="accent" @click="addCompanyFields" v-if="showCompanyButton" :disabled="disabled") {{ $t('text.add_company_fields') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';

export default {
  mixins: [ExtraBlock],
  computed: {
    showCompanyButton() {
      return this.$store.state.forms.projects.fields.company.details_show;
    },
  },
  methods: {
    addField() {
      this.model.fields.push({
        id: this.$uuid.v4(),
        name: '',
        value: null,
        socials: [],
      });
    },

    addCompanyFields() {
      const companyDetails = this.$store.state.forms.projects.fields.company.details;
      companyDetails.forEach((item) => {
        this.model.fields.push({ id: this.$uuid.v4(), name: item.name, value: item.value });
      });
    },

    removeField(item) {
      this.model.fields = this.model.fields.filter((x) => x.id !== item.id);
    },

    addSocial(item, social) {
      this.model.fields.forEach((field) => {
        if (field.id === item.id) {
          field.socials.push({ type: '', value: '', id: this.$uuid.v4() });
          this.model.fields.slice();
        }
      });
      //- let _item = this.model.fields
      this.model.fields.slice();
    },

    removeSocial(item, social) {
      this.model.fields.forEach((field) => {
        if (field.id === item.id) {
          field.socials = field.socials.filter((x) => x.id !== social.id);
          this.model.fields.slice();
        }
      });
      this.model.fields.slice();
    },
  },
};
</script>

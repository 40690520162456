<template lang="pug">
  .fill-height.d-block.pb-0.border-xxx
    template(v-if="isCompatibleBrowser")
      component.d-flex.flex-wrap.fill-height(
        :is="formComponent",
        v-on="formEvents",
        ref="form",
      )
    template(v-else)
      compatible-browser(:is-login="true" @click="onAcceptRisk")
</template>
<script>
import FormAdvanceMixin from '@front/mixins/FormAdvanceMixin';
import CompatibleBrowser from '@/components/CompatibleBrowser';
import { ACCEPT_RISK_BROWSER_KEY } from '@constant';

export default {
  components: {
    CompatibleBrowser,
  },
  mixins: [FormAdvanceMixin],
  data() {
    return {
      modName: 'projects',
      formHasDocuments: false,
      isCompatibleBrowser: true,
    };
  },
  created() {
    const isAcceptRisk = _.has(this.$user().preferences, 'accepting_the_risks');
    this.isCompatibleBrowser =
      isAcceptRisk ||
      !!sessionStorage.getItem(ACCEPT_RISK_BROWSER_KEY) ||
      this.$utils.checkCompatibleBrowser();
  },
  async mounted() {
    this.checkRedirect();
  },
  computed: {
    pageTitle() {
      return this.$t('text.projects');
    },
    formBinds() {
      return {};
    },
    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')];
    },
  },

  methods: {
    onAcceptRisk(value) {
      this.isCompatibleBrowser = value;
    },
    checkRedirect() {
      if (!this.$store.state.auth.is_logged_in) {
        return this.$router.push('login');
      } else if (this.$user().isClient()) {
        if (this.$user().project_count > 0) {
          if (this.$route.name === 'projectCreate') {
            this.$router.push({ name: 'projects' });
          }
          console.log('PROJECT_COUNT', this.$user().project_count);
          //- this.$router.push('/');
        } else {
          this.formCreateInit();
        }
      }
    },
    formCreateInit() {
      this.formCommit('UPDATE_STATE', {
        field: 'title',
        value: 'text.create_new_project',
      });
      this.formCommit('UPDATE_FIELD', {
        field: 'uuid',
        value: this.$uuid.v4(),
      });
      // const ev = vm.formGetter('IS_EDITING') ? 'form-update' : 'form-create';
      this.formCommit('SET_DEPARTMENT', this.$store.state.config.department_id);
    },
    fetchData(uuid) {
      this.repoDispatch('API_SHOW', uuid)
        .then(this._afterApi)
        .then(() => {
          this.formCommit('UPDATE_STATE', {
            field: 'title',
            value: this.formState.fields.basic.brand_name || 'text.create_new_project',
          });
          if (
            this.$store.state.config.department_id &&
            !this.formState.fields.basic.department_id
          ) {
            // with missing department_id
            console.error('NOT_BEFORE', this.$store.state.config.department_id);
            this.formCommit('SET_DEPARTMENT', this.$store.state.config.department_id);
          }
        });
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if ('projectCreate' === to.name) {
        //- vm.formCreateInit();
      } else {
        vm.fetchData(to.params.id);
      }
    });
  },
};
</script>

import {
  RepositoryState,
  RepositoryMutations,
  RepositoryActions,
  RepositoryGetters,
  RepositoryMethods,
} from '@abs/repository';
import Users from './model';

const state = RepositoryState({
  filters: {
    page: 1,
    per_page: 10,
    q: undefined,
    types: [],
    department_ids: [],
  },
});
const mutations = RepositoryMutations();
const actions = RepositoryActions(Users);
const getters = RepositoryGetters({
  API_NAME: () => 'users',
  API_RESOURCE_NAME: () => 'user',
});
/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
export const methods = RepositoryMethods('users');

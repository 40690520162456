import Store from '@/store'; //for workaround "nestedList"
import domainDepartment from '@/scripts/domain';

/**
 *  ALL the results must be as Boolean expression
 *  and must be returned inversely
 *  where: FALSE - qualifies validator, TRUE - rejects it
 *
 *  Every validator can have 3 input params as fallowing (ordering matters)
 *    @param {*} value - form/field value which requires this validation.
 *    @param {Array} params - validator paramters distinct by ':' symbol
 *    @param {Object} fields - nested form fields for some cases
 *    @param {Object} field - field name which is being checked
 *    @param {String} message - error message if not validates

 #################################################################
 #################################################################
 */

/**
 *   Qualifies required
 *   usage: email: ['required', 'Please enter your email.']
 */
export function required(value) {
  const isArray = Array.isArray(value);
  if (
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (isArray && value.length === 0)
  ) {
    return true;
  }
  return false;
}

/**
 *   Qualifies required and email
 *   usage: email: ['email', 'The email must be a valid email address.']
 */
export function email(value) {
  if (!required(value)) {
    let re = /\S+@\S+\.\S+/;
    return re.test(value) ? false : true;
  }

  return true;
}

/**
 *   Qualifies required with condition
 *   usage: some_radio: ['requiredif:fieldName:fieldNameValue', 'Please write normal code :).']
 */
export function requiredif(value, params, fields) {
  const fieldName = params[0];
  const fieldNameValue = params[1];
  const realValue = fields[fieldName];

  //console.log('requiredif', fieldName, fieldNameValue, realValue, value, params)

  if (realValue == fieldNameValue) return required(value);

  return false;
}

/**
 *   Qualifies with minimum count & if met another condition  (for arrays and/or "media uploaders")
 *   usage: some_radio: ['minIf:fieldName:fieldNameValue:count:meta', 'Please write normal code :).']
 */
export function minIf(value, params, fields) {
  const fieldName = params[0];
  const fieldNameValue = params[1];
  const count = parseInt(params[2]);
  const meta = params[3] ?? false;
  const realValue = fields[fieldName];
  let counter = 0;

  value.forEach((item) => {
    if (meta && item.metatype == meta) counter++;
    else if (!meta) counter++;
  });

  if (realValue == fieldNameValue) return counter < count;

  return false;
}

/**
 *   Qualifies minimum count (for arrays)
 *   usage: some_radio: ['min:count']
 */
export function min(value, params) {
  const count = parseInt(params[0]);

  if (value === null) {
    return true;
  }

  return value.length < count;
}

/**
 *   Qualifies minimum count (for arrays)
 *   usage: some_radio: ['min:count']
 */
export function max(value, params) {
  const count = parseInt(params[0]);

  if (value === null) {
    return true;
  }

  return value.length > count;
}

/**
 *   Qualifies url
 *   usage: url_field: ['url']
 */
export function url(value, params) {
  if (!value) return false;

  var res = value.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  const validUrl = res !== null;
  return !validUrl;
}

/**
 *   Qualifies urls
 *   usage: url_field: ['urls']
 */
export function urls(values, params) {
  console.error('URLS ', { values, params });
  if (!values || !Array.isArray(values)) {
    return false;
  }
  let _key = params[0];
  let validUrl = true;

  for (let i = 0; i < values.length; i++) {
    let value = values[i];
    const isString = typeof value === 'string';
    let _value = isString ? value : value[_key];
    if (!_value) {
      validUrl = false;
      break;
    }
    console.error('_VALUE', _value);
    let res = _value.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    validUrl = res !== null;
    if (!validUrl) {
      break;
    }
  }
  return !validUrl;
}

/*
 *   Qualifies domain, same as url jus without http(s)
 *   usage: url_field: ['domain']
 */
export function domain(value, params) {
  if (!value) return false;
  const patternLT =
    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9ąčęėžšūųį]{2,256}(\.[a-zA-Z]{2,61})+\b([a-zA-Z0-9]*)$/;
  const pattern = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9]{2,256}(\.[a-zA-Z]{2,61})+\b([a-zA-Z0-9]*)$/;

  const patternDomain = domainDepartment.isLT() ? patternLT : pattern;
  const validUrl = patternDomain.test(value);
  return !validUrl;
}

/*
 *   validates phone number
 *   usage: phone: ['Phone number is invalid']
 */
export function phone(value, params) {
  if (!value) return false;

  var res = value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g);
  const validPhone = res !== null;
  return !validPhone;
}

/**
 *  Checks equality (inversely) for value with a given field name,
 which stores as first item in params Array

 usage: password_repeat: ['equals:password', 'Passwords should match.']
 */
export function equals(value, params, fields) {
  return value !== fields[params[0]];
}

/**
 *   Qualifies required on nested list (for nested-arrays)
 *   usage: some_arr: ['nestedList:field:validator:property']
 */
export function nestedList(value, params, fields, field, message) {
  const listName = params[0];
  const validator = params[1];
  const listFieldName = params[2];

  fields[listName].eachDeep('elements', (x) => {
    const itemVal = x[listFieldName];
    let validates = false;
    if (this[validator]) {
      switch (validator) {
        case 'required':
          validates = required(itemVal);
          break;
        case 'min':
          validates = min(x[field], [listFieldName]);
          break;
      }
    }
    //console.log('nestedlist...', x, validates, listFieldName, itemVal, this.required(itemVal))
    if (validates) {
      Store.commit('forms/projects/SET_ERROR', { field: x.uuid, message: message, parent: field });
    }
  });

  // we returning always false as we inject errors by id
  return false;
}

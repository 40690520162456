import AbstractDataPage from '@/components/pages/AbstractDataPageAdvance';
import ModMixin from './ModMixin';
import vuetize from '@/scripts/vuetize';
import Button from '@/components/tables/parts/ToolbarBtn';
import lodash from 'lodash';

export default {
  components: { dataPage: AbstractDataPage },
  mixins: [ModMixin],

  data: function () {
    return {
      baseTableActions: {
        add: { icon: 'ic-add', evclick: this.tableActionCreate, component: Button, large: true },
      },
      tableItemActions: {},
      baseTableItemActions: {
        edit: { icon: 'ic-mode-edit', click: this.tableItemActionEdit },
        delete: { icon: 'ic-delete', click: this.tableItemActionDelete },
      },
      toolbarTheme: 'theme--gradient',
      toolbarDark: true,
      tableElevation: 1,
      showPlaceholder: true,
      baseTableActionsFilters: [],
    };
  },

  computed: {
    pageTitle() {
      return this.$t('text.projects');
    },
    // Bindable data 4 AbstractDataPage
    dataBind() {
      return {
        modName: this.modName,
        pageTitle: this.pageTitle,
        tableFilter: this.tableFilter,
        tablePagination: this.tablePagination,
        tableHeaders: this.tableHeaders,
        tableItems: this.dataItems,
        tableActions: this.dtActions,
        tableActionsFilters: this.dtActionsFilters,
        tableItemActions: this.dtItemActions,
        toolbarBinds: this.toolbarBinds,
        ref: 'dtp', // data page
        tableBinds: {
          toolbarTheme: this.toolbarTheme,
          toolbarDark: this.toolbarDark,
          elevation: this.tableElevation,
          tableFetching: this.tableFetching,
        },
        showPlaceholder: this.showPlaceholder,
      };
    },

    tableActions() {
      return [];
    },

    // Data Table(dt) Actions 4 AbstractDataPage
    dtActions() {
      return vuetize(_.merge(this.baseTableActions, this.tableActions));
    },

    dtActionsFilters() {
      return vuetize(_.merge(this.baseTableActionsFilters, this.tableActionsFilters));
    },

    tableActionsFilters() {
      return [];
    },

    // Data Table(dt) Item Actions 4 AbstractDataPage
    dtItemActions() {
      return _.merge(this.baseTableItemActions, this.tableItemActions);
    },

    items() {
      return this.$store.getters[this.repoPath('ACTIVE_ITEMS')];
    },

    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')];
    },

    tableFetching() {
      return this.$store.getters[this.repoPath('IS_FETCHING')];
    },

    tablePagination() {
      return this.$store.getters[this.repoPath('PAGINATION')];
    },

    dataItems() {
      return this.items;
    },

    toolbarBinds() {
      return {
        class: this.toolbarTheme,
        dark: this.toolbarDark,
      };
    },
  },

  watch: {
    tableFilter: {
      handler: function (val, oldVal) {
        this.tableFetchData();
      },
      deep: true,
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setFilters();
      vm.tableFetchData();
    });
  },

  methods: {
    setFilters() {
      if (Object.keys(this.$route.query).length) {
        this.repoCommit('SET_FILTERS', {
          page: parseInt(this.$route.query.page) || 1,
          per_page: parseInt(this.$route.query.per_page) || 10,
        });
      }
    },
    setFieldFilter({ field, value }) {
      this.repoCommit('UPDATE_FILTER', { field, value });
      this.repoCommit('UPDATE_FILTER', { field: 'page', value: 1 });
      this.tableFetchData();
    },

    tableFetchData: lodash.debounce(function () {
      this.$router.replace({ query: this.tableFilter }).catch(() => {});
      this.repoDispatch('API_FETCH');
      // .then(res => {
      //   this.tablePagination.total = res.meta.pagination.total
      //   this.tablePagination.total_pages = res.meta.pagination.total_pages
      // })
    }, 500),

    // TABLE ACTIONS (HEADER)
    tableActionCreate() {
      this.formCommit('CLEAR_FIELDS');
      this.formCommit('CLEAR_ERRORS');
      this.formCommit('UPDATE_STATE', { field: 'is_edit', value: false });
      this.formCommit('UPDATE_STATE', { field: 'show_form', value: true });
      //this.$refs.dtp.showForm = true

      if (this['onCreateClick'] && typeof this['onCreateClick'] == 'function') this.onCreateClick();
    },

    // TABLE ITEM ACTIONS (ITEM RIGHT SIDE)
    async tableItemActionDelete(model) {
      if (confirm('Are you sure u want to remove this item?')) {
        try {
          await this.repoDispatch('API_DELETE', model);
          await this.repoDispatch('API_FETCH');
        } catch (error) {
          console.log(error);
        }
      }
    },

    tableItemActionEdit(model) {
      this.formCommit('CLEAR_FIELDS');
      this.formCommit('CLEAR_ERRORS');
      this.formCommit('SET_FIELDS', model);
      this.formCommit('UPDATE_STATE', { field: 'is_edit', value: true });
      this.formCommit('UPDATE_STATE', { field: 'show_form', value: true });
      //this.getRef('dtp').showForm = true

      if (this['onCreateClick'] && typeof this['onCreateClick'] == 'function')
        this.onEditClick(model);
    },

    onCreateClick() {
      this.$router.push({ name: `${this.repoGetter('API_RESOURCE_NAME')}Create` });
    },

    onEditClick(model) {
      this.$router.push({
        name: `${this.repoGetter('API_RESOURCE_NAME')}Edit`,
        params: { id: model.uuid },
      });
    },
  },
};

<template lang="pug">
  .extra-block.partners
    .items.d-flex.flex-column(
      v-for="(item, key) in model.items"
      :key="item.id"
    )
      .separator.mt-2.mb-4(v-if="key > 0")
      .d-flex(:class="!$vuetify.breakpoint.xs ? 'flex-row' : 'flex-column'")
        .w-100
          v-text-field(
            filled
            :value="generateLorem(item, {_key: 'link'})"
            v-model="item.link"
            :label="$t('text.link')"
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
          )
          media-uploader(
            :documents="item.media"
            resourceName="projects"
            :uuid="projectUUID"
            :return="item.id"
            @added="onAdded"
            @deleted="onDeleted"
            :disabled="disabled"
            :allowMultiple="false"
          )
        .inline-block
          v-btn.ml-sm-3.mt-sm-3(v-if="!$vuetify.breakpoint.xs" icon @click="removeField(item)" :disabled="disabled")
            v-icon ic-delete
          div.d-flex.d-sm-block.justify-end(v-else)
            v-btn(
              rounded
              outlined
              small
              depressed
              color='error'
              @click="removeField(item)"
            ).text-capitalize
              | Remove
              v-icon(small right) ic-close
    v-btn.capitalize(color="black" text rounded depressed  @click="addField" :disabled="disabled")
      v-icon(left) mdi-plus
      | {{ $t('text.add_item') }}
    //- v-btn(color="primary" rounded depressed  @click="addField" :disabled="disabled") {{ $t('text.add_item') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';

export default {
  components: { MediaUploader },
  mixins: [ExtraBlock],
  methods: {
    addField() {
      this.model.items.push({ id: this.$uuid.v4(), link: null, media: [] });
    },
    removeField(item) {
      console.log('removeField ->', this.model.items);
      this.model.items = this.model.items.filter((x) => x.id != item.id);
    },
    onAdded(model, id) {
      this.model.items.find((x) => x.id === id).media.push(model);
    },
    onDeleted(fileName, id) {
      this.model.items.find((x) => x.id === id).media = this.model.items
        .find((x) => x.id === id)
        .media.filter((x) => x.filename != fileName);
    },
  },
};
</script>

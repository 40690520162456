import state from '../../page/state';

/**
 * Base mutations of the form
 */
export default (mutations) =>
  Object.assign(
    {
      UPDATE_FIELD(state, { field, value }) {
        //console.log('updating... ', field, value)
        state.fields[field] = value;
        const fe = state.validationErrors[field];
        if (value && fe && fe.length) {
          state.validationErrors[field] = [];
        }
      },

      CLEAR_ERRORS(state) {
        Object.keys(state.fields).forEach((key) => {
          state.validationErrors[key] = [];
        });
      },

      UPDATE_MODEL_FIELD(state, { field, value }) {
        state.model[field] = value;
      },

      UPDATE_STATE(state, { field, value }) {
        state[field] = value;
      },

      SET_ERROR(state, { field, message, parent = false }) {
        //console.log('eeeeeerrrr',field, message, parent)
        if (parent) {
          if (!state.validationErrors[parent]) state.validationErrors[parent] = {};

          state.validationErrors[parent][field] = message;
          return;
        }

        state.validationErrors[field] = message;

        state.validationErrors = { ...state.validationErrors };
      },

      SET_ERRORS(state, errors) {
        state.validationErrors = errors;
      },

      MERGE_ERRORS(state, errors) {
        state.validationErrors = Object.assign({}, state.validationErrors, errors);
      },

      PUSH_INTO_FIELD(state, { field_name, value }) {
        let field = state.fields[field_name];
        field.push(value);
        state.fields[field_name] = field;
      },

      SET_FIELDS(state, fields) {
        state.model = fields;
        _.forOwn(state.fields, function (value, key) {
          if (fields[key]) state.fields[key] = fields[key];
        });
      },

      CLEAR_FIELDS(state, fields) {
        const copy = JSON.parse(JSON.stringify(state.__initial_state.fields));

        for (let key in copy) {
          state.fields[key] = copy[key];
        }
        state.model = {};
        state.show_form = false;
      },
      SET_VALIDATING(state, validating = false) {
        state.validating = validating;
      },
      SET_TUTORIALS(state, tutorials = {}) {
        for (let key in tutorials) {
          state.tutorials[key] = tutorials[key];
        }
      },
      ACTIVE_TAB(state, tab = '') {
        state.current_tab = tab;
      },
    },
    mutations
  );

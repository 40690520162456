<template lang="pug">
  v-btn.--with-icon(
    :text="text"
    :small="small"
    @click="$emit('click')"
  )
    slot
      v-icon(v-if="icon" :size="size").mr-3 {{ icon }}
      | {{ label }}
</template>

<script>
export default {
  props: {
    label: '',
    icon: false,
    size: {
      type: Number,
      default: 20,
    },
    text: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>
import {FormState, FormGetters, FormMutations, FormActions} from '@front/modules/abstracts/form'
import Api from '@/plugins/api'

const state = FormState({
  fields: {
    email: '',
    siteUrl: '',
    locale: 'en'
  },
  isResetPasswordSucceed: false,
  message: '',
})
const mutations = FormMutations()
const getters = FormGetters({
  VALIDATES: (state) => {
    return {
      email: ['required|email', ['text.please_enter_your_email', 'text.the_email_must_be_a_valid_email_address']],
    }
  },
})

const actions = FormActions({
  async RESET_PASSWORD({dispatch, commit, state}) {
    await dispatch('VALIDATE_FIELDS')

    commit('auth/SET_LOADING', true, {root: true})

    Api
      .post('auth/send-mail-reset', {
        email: state.fields.email,
        site_url: state.fields.siteUrl,
        locale: state.fields.locale.toUpperCase(),
      })
      .then((response) => {
        commit('auth/SET_LOADING', false, {root: true})
        state.isResetPasswordSucceed = true
        state.message = response.message

        setTimeout(() => {
          dispatch('auth/RESET_PASSWORD_SUCCESS', [], {root: true})
          state.isResetPasswordSucceed = false
        }, 5000)
      })
      .catch((response) => {
        console.log('reset password RESPONSE', response.body)
        commit('auth/SET_LOADING', false, {root: true})
        if (response.body !== undefined) {
          let emailMessage = 'Wrong Email. Try again.';
          if (response.body.messages !== undefined && response.body.messages.email !== undefined) {
            emailMessage = response.body.messages.email
          } else {
            emailMessage = response.body.message
          }

          commit('SET_ERRORS', {
            email: [emailMessage]
          })
        }
        throw response
      })
  }
})

/**
 * Exports
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // getters
}

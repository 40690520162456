export default {
  data: function () {
    return {
      modName: null,
    };
  },

  computed: {
    repoState() {
      return this.$store.state.repositories[this.modName];
    },
    formState() {
      return this.$store.state.forms[this.modName];
    },
  },

  methods: {
    modPath(method, modName, modType) {
      if (!modName) {
        console.error('modName is not defined. [ModPathsMixin]\nRoute: ' + this.$route.fullPath);
        return;
      }
      console.info({ method, modName, modType });
      return `${modType}/${modName}/${method}`;
    },

    /**
     * Returns a reference from ($refs) by given path
     * This function minimazes ref calls.. ex:
     *  instead of using like this... (which is very long.. && || wrong??? )
     *    this.$refs.page.$refs.form.$refs.someField.$refs.someRef
     *  we use:
     *    this.getRef('page.form.someField.someRef')
     */
    getRef(refPath) {
      const path = refPath.split('.');
      let vue = this;
      path.forEach((ref) => {
        vue = vue.$refs[ref];
      });
      return vue;
    },

    // a.k.a repository path
    repoPath(method) {
      return this.modPath(method, this.modName, 'repositories');
    },

    // a.k.a form path
    formPath(method) {
      return this.modPath(method, this.modName, 'forms');
    },

    repoGetter(name, modelType = this.modName) {
      return this.$store.getters[this.modPath(name, modelType, 'repositories')];
    },

    repoDispatch(name, data) {
      return this.$store.dispatch(this.repoPath(name), data);
    },

    repoCommit(name, data) {
      return this.$store.commit(this.repoPath(name), data);
    },

    formGetter(name, modelType = this.modName) {
      return this.$store.getters[this.modPath(name, modelType, 'forms')];
    },

    formDispatch(name, data) {
      return this.$store.dispatch(this.formPath(name), data);
    },
    formCommit(name, data) {
      return this.$store.commit(this.formPath(name), data);
    },
  },
};

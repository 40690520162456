import { mix } from 'mixwith';
import DocumentSchema from '@abs/document/schema';
import moment from 'moment';
import Store from '@/store';
import i18n from '@/i18n';
//import Sitemap from './tabs/sitemap/model'
//import { methods as ProductTypeRepository } from '@models/product_types/repository'
//import { methods as ProductMaterialRepository } from '@models/product_materials/repository'

const ProjectSchema = (superclass) =>
  class extends mix(superclass).with(DocumentSchema) {
    /**
     * Parse project data that came from API
     */
    static parse(data) {
      const d = super.parse(data);

      d.name = data.name;
      d.email = data.user_email;
      d.username = data.user_name;
      d.is_locked = data.is_locked;
      d.status_id = data.status_id;
      d.designer_uuid = data.designer_uuid;
      d.manager_uuid = data.manager_uuid;
      d.status = Store.state.passives.project_statuses.find((x) => x.id == data.status_id);
      d.status_name = i18n.t('text.statuses.' + d.status.name_code);
      d.create_type = data.create_type;
      d.accepted_agreement = data.accepted_agreement;
      d.accepted_terms = data.accepted_terms;
      d.completed_at = data.completed_at && moment(data.completed_at.date);
      d.basic = data.basic;
      d.company = data.company;
      d.website = data.website;
      d.branding = data.branding;
      d.style = data.style;
      d.only_one = data.only_one ?? {};
      d.sitemap = data.sitemap ?? [];
      d.categories = data.categories ?? [];
      d.display_categories_in_main_menu = data.display_categories_in_main_menu ?? 0;
      d.payment = data.payment ?? [];
      d.extra_services = data.extra_services ?? [];
      d.comments = data.comments ?? {};
      d.finilize = data.finilize ?? false;
      d.delegate = data.delegate ?? {
        designer_uuid: data.designer_uuid,
        manager_uuid: data.manager_uuid,
        reminder: data.reminder_at,
        deadline: data.deadline_at,
      };
      d.fixes = data.fixes ?? [];
      // d.fixes_img = data.fixes_img; TODO: Remove after assemble api
      d.conversation = data.conversation;

      d.manager_name = Store.state.workers.find((x) => x.uuid == data.manager_uuid);
      d.designer_name = Store.state.workers.find((x) => x.uuid == data.designer_uuid);

      if (d.manager_name) d.manager_name = d.manager_name.getShortName();

      if (d.designer_name) d.designer_name = d.designer_name.getShortName();

      d.dev_url = data.dev_url;
      d.dev_password = data.dev_password;

      return d;
    }

    serialize(options = {}) {
      return {
        ...super.serialize(options),

        name: this.name,
        email: this.user_email,
        designer_uuid: this.designer_uuid,
        manager_uuid: this.manager_uuid,
        is_locked: this.is_locked,
        status_id: this.status_id,
        create_type: this.create_type,
        accepted_agreement: this.accepted_agreement,
        accepted_terms: this.accepted_terms,
        basic: this.basic,
        company: this.company,
        website: this.website,
        branding: this.branding,
        style: this.style,
        sitemap: this.sitemap,
        categories: this.categories,
        display_categories_in_main_menu: this.display_categories_in_main_menu,
        payment: this.payment,
        extra_services: this.extra_services,
        finilize: this.finilize,
        comments: this.comments,
        //delegate: this.delegate,
        fixes: this.fixes,
        // fixes_img: this.fixes_img,
        conversation: this.conversation,
        only_one: this.only_one,
      };
    }
  };

export default ProjectSchema;

export default {
  computed: {
    passiveDepartments() {
      const departmentPassives = this.$store.state.passives.departments;
      departmentPassives.unshift({
        name_code: 'live',
        id: 99,
      });
      return departmentPassives.map((item) => {
        return {
          text: item.name_code.toUpperCase(),
          value: item.id,
        };
      });
    },

    passiveBusinessCategories() {
      return this.$store.state.passives.business_categories.map((item) => {
        return {
          text: this.$t(`text.passives.business.${item.name_code}`),
          value: item.id,
        };
      });
    },

    passiveCurrencies() {
      return this.$store.state.passives.currencies.map((item) => {
        return {
          text: `${item.code} - ${item.name}`,
          value: item.id,
        };
      });
    },

    passiveUserTypes() {
      return this.$store.state.passives.user_types.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
  },
};

<template lang="pug">
  div
    div.nav-tips1(ref="tips")
      .relative
        .d-flex.justify-end.w-100.content-tips
          .tips.selector.space-y-4(v-if="tipList.length > 0")
            h2.w-full(v-if="videoCode") {{ $t('Watch tutorial') }}
            .video-wrapper(v-if="videoCode")
              iframe(class="video_43"
                :src="videoCode"
                title="YouTube video player"
                frameborder="0"
                allowfullscreen)
            h2.w-full {{ $t('text.tip_container_title') }}
            .wrapper
              ul
                li(
                  v-for="(tip, index) in tipList"
                  :key="index"
                ) {{ tip }}         
</template>
<script>
import { converToIframeYoutube } from '@/helpers';

export default {
  props: {
    tips: {
      type: String,
      require: true,
    },
    video: {
      type: String,
    },
  },

  computed: {
    tipList() {
      let showAdvices = ['create', 'services', 'submit'],
        tips = '';
      if (!showAdvices.includes(this.tips)) {
        tips = this.$t('text.tips.' + this.tips);
        if (this.tips === 'basic') {
          let array = this.$t('text.tips.' + 'company');
          array.forEach((value, index) => {
            if (!tips.includes(value)) {
              tips.push(value);
            }
          });
        }
      }
      const all = tips;
      const items = Array.isArray(all) ? all : typeof all === 'object' ? Object.values(all) : [];
      return items.filter((x) => ![null, undefined, '', false].includes(x));
    },
    videoCode() {
      return converToIframeYoutube(this.video, '?controls=0');
    },
  },
};
</script>
<style lang="scss">
.sidebar {
  will-change: min-height;
}
</style>
<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.nav-tips1 {
  max-width: 100%;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
  }

  .relative {
    position: relative;
    height: 100%;

    .content-tips {
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        // overflow-y: auto;
        // height: 75%;
      }
    }

    &:after {
      width: 100%;
      content: '';
      display: block;
      height: 20px;
      position: absolute;
      z-index: 99;
      left: 0;
      bottom: 0;
      pointer-events: none;
      @media #{map-get($display-breakpoints, 'md-and-down')} {
        opacity: 0;
      }
    }
  }
}

.tips {
  // overflow-y: auto;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    max-width: 100%;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #222222;
  }

  ul {
    list-style-type: none;

    li {
      padding: 5px 0px;
      position: relative;
      list-style-type: none;

      &::marker {
        background: red;
        content: '';
      }

      &::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #5f5f5f;
        position: absolute;
        top: 12px;
        left: -25px;
      }
    }
  }

  .wrapper {
    padding: 0 20px;
    border-radius: 10px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      //- overflow-y: auto;
      //- max-height: 100%;
    }
  }

  .video-wrapper {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .video_43 {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
}
</style>

<template lang="pug">
  .extra-block.gallery
    //- v-text-field.mt-4(:value="generateLorem(model, {_key: 'title'})" v-model="model.title" filled  :label="$t('text.title')" :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")

    //- .editor.mt-5
      ckeditor(:editor="editor" :value="generateLorem(model, {_key: 'description'})" v-model="model.description" :disabled="disabled || isAutoFilled")

    media-uploader.mt-8(
      :documents="model.media"
      resourceName="projects"
      :uuid="projectUUID"
      @added="onAdded"
      @deleted="onDeleted"
      :disabled="disabled"
      :allowMultiple="true"
    )
</template>

<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader'
export default {
  mixins: [ ExtraBlock ],
  components: { MediaUploader },
  data() {
    return {
      // editor: ClassicEditor
    }
  },
  methods: {
    onAdded(media) {
      this.model.media.push(media)
    },
    onDeleted(fileName, id) {
      this.model.media = this.model.media.filter(x => x.filename != fileName)
    }
  }
}
</script>

<template lang="pug">
  .tab-content
    .titles.text-center
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    slot(name="tips-container")
    //- group-container(
    //-   :title="$t('text.currency')"
    //-   :subtitle="$t('text.choose_a_currency')"
    //-   commentField="payment.currency"
    //- )
    //-   v-autocomplete(:items="passiveCurrencies"
    //-   v-model="model.currency_id" 
    //-   append-icon="ic-expand-more" 
    //-   filled :disabled="isLocked")
    group-container.mb-10(
      :title="$t('text.payment_gateway')"
      :tip="$t('text.tip_contents.payment.gateway')"
      :subtitle="$t('text.connect_a_gateway')"
      commentField="payment.gateway"
    )
      //-
        v-radio-group.ib-1(v-model="model.payment_gateway" :mandatory="false" hide-details :disabled="isLocked")
        v-radio(value="none")
        template(v-slot:label) None

      .d-flex.flex-column(v-if="polishPayments")
        //- v-checkbox(v-model="model.paypal" hide-details label="Paypal" :disabled="isLocked")
        //- .ib-2.mt-2(v-if="model.paypal")
        //-   v-text-field(v-model="model.paypal_id" filled label="E-mail" :readonly="isLocked")
        //-   v-text-field(v-model="model.paypal_merchant_id" filled label="Merchant Id" :readonly="isLocked")
          //-v-text-field(v-model="model.paypal_invoice_tag" filled label="Invoice Tag" :disabled="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.payu" hide-details label="PayU" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.payu')")
          .ib-2.mt-2(v-if="model.payu")
            v-text-field(v-model="model.payu_id" filled label="Id punktu płatności" :readonly="isLocked")
            v-text-field(v-model="model.payu_md5" filled label="Drugi klucz (MD5)" :readonly="isLocked")
            v-text-field(v-model="model.payu_client_id" filled label="OAuth (client_id)" :readonly="isLocked")
            v-text-field(v-model="model.payu_client_secret" filled label="OAuth (client_secret)" :readonly="isLocked")

            label.small.grey--text.text--darken-1 Wszystkie powyższe dane możesz znaleźć w panelu PayU przechodząc do:
              br
              | "Moje sklepy" -> Kliknij w nazwę sklepu -> "Punkty płatności"

        .group-able-tip.payment
          v-checkbox(v-model="model.przelewy24" hide-details label="Przelewy24" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.przelewy24')")
          .ib-2.mt-2(v-if="model.przelewy24")
            v-text-field(v-model="model.przelewy24_id" filled label="ID Sprzedawcy (zazwyczaj jest to też login)" :readonly="isLocked")
            v-text-field(v-model="model.pprzelewy24_crc" filled label="Klucz CRC" :readonly="isLocked")
            v-text-field(v-model="model.pprzelewy24_api" filled label="Klucz API" :readonly="isLocked")

            label.small.grey--text.text--darken-1 Klucz CRC oraz klucz API jest do odnalezienia po zalogowaniu w panelu przelewy24.pl w zakładce moje dane. W polu adres IP w swoim panelu prosimy o podanie %

      .d-flex.flex-column(v-else-if="ukPayments")
        .group-able-tip.payment
          v-checkbox(v-model="model.paypal" hide-details label="Paypal" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.paypal')")
          .ib-2.mt-2(v-if="model.paypal")
            v-text-field(v-model="model.paypal_id" filled label="Live Email address" :readonly="isLocked")
            v-text-field(v-model="model.paypal_merchant_id" filled label="Live Merchant Id" :readonly="isLocked")
            v-text-field(v-model="model.paypal_client_id" filled label="Live Client Id" :readonly="isLocked")
            v-text-field(v-model="model.paypal_client_secret" filled label="Live Secret Key" :readonly="isLocked")

        .group-able-tip.payment
          v-checkbox(v-model="model.stripe" hide-details label="Stripe" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.stripe')")
          .ib-2.mt-2(v-if="model.stripe")
            v-text-field(v-model="model.stripe_live_secret_key" filled label="Live Secret Key" :readonly="isLocked")
            v-text-field(v-model="model.stripe_live_pub_key" filled label="Live Publishable Key" :readonly="isLocked")
            v-text-field(v-model="model.stripe_test_secret_key" filled label="Test Secret Key" :readonly="isLocked")
            v-text-field(v-model="model.stripe_test_pub_key" filled label="Test Publishable Key" :readonly="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.paysera" hide-details label="Paysera" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.paysera')")
          .ib-2.mt-2(v-if="model.paysera")
            v-text-field(v-model="model.paysera_project_id" filled label="Project Id" :readonly="isLocked")
            v-text-field(v-model="model.paysera_sign_id" filled label="Sign Id" :readonly="isLocked")
            v-text-field(v-model="model.paysera_meta_code" filled label="Meta Code" :readonly="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.bank" hide-details label="Direct Bank" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.bank')")
          .ib-2.mt-2(v-if="model.bank")
            v-text-field(v-model="model.bank_iban" filled  label="Bank Iban" :readonly="isLocked")
            v-text-field(v-model="model.bank_swift" filled label="Bank Swift/BIC" :readonly="isLocked")
            v-text-field(v-model="model.bank_name" filled label="Bank Name" :readonly="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.cash" hide-details label="Cash on Delivery" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.cash')")

      .d-flex.flex-column(v-else)
        .group-able-tip.payment
          v-checkbox(v-model="model.paypal" hide-details label="Paypal" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.paypal')")
          .ib-2.mt-2(v-if="model.paypal")
            v-text-field(v-model="model.paypal_id" filled label="Live Email address" :readonly="isLocked")
            v-text-field(v-model="model.paypal_merchant_id" filled label="Live Merchant Id" :readonly="isLocked")
            v-text-field(v-model="model.paypal_client_id" filled label="Live Client Id" :readonly="isLocked")
            v-text-field(v-model="model.paypal_client_secret" filled label="Live Secret Key" :readonly="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.stripe" hide-details label="Stripe" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.stripe')")
          .ib-2.mt-2(v-if="model.stripe")
            v-text-field(v-model="model.stripe_live_secret_key" filled label="Live Secret Key" :readonly="isLocked")
            v-text-field(v-model="model.stripe_live_pub_key" filled label="Live Publishable Key" :readonly="isLocked")
            v-text-field(v-model="model.stripe_test_secret_key" filled label="Test Secret Key" :readonly="isLocked")
            v-text-field(v-model="model.stripe_test_pub_key" filled label="Test Publishable Key" :readonly="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.paysera" hide-details label="Paysera" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.paysera')")
          .ib-2.mt-2(v-if="model.paysera")
            v-text-field(v-model="model.paysera_project_id" filled label="Project Id" :readonly="isLocked")
            v-text-field(v-model="model.paysera_sign_id" filled label="Sign Id" :readonly="isLocked")
            v-text-field(v-model="model.paysera_meta_code" filled label="Meta Code" :readonly="isLocked")

        .group-able-tip.payment(v-if="isDisplayPaymentByCountry")
          v-checkbox(v-model="model.checkout2" hide-details label="2CheckOut" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.checkout2')")
          .ib-2.mt-2(v-if="model.checkout2")
            v-text-field(v-model="model.checkout2_account_number" filled label="Account Number" :readonly="isLocked")
            v-text-field(v-model="model.checkout2_secret_word" filled label="Secret Word" :readonly="isLocked")

        .group-able-tip.payment(v-if="isDisplayPaymentByCountry")
          v-checkbox(v-model="model.vapulus" hide-details label="Vapulus" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.vapulus')")
          .ib-2.mt-2(v-if="model.vapulus")
            v-text-field(v-model="model.vapulus_website_id" filled label="Website Id" :readonly="isLocked")

        .group-able-tip.payment(v-if="isDisplayPaymentByCountry")
          v-checkbox(v-model="model.amazon" hide-details label="Amazon Pay" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.amazon')")
          .ib-2.mt-2(v-if="model.amazon")
            v-text-field(v-model="model.amazon_seller_id" filled label="Seller Id" :readonly="isLocked")
            v-text-field(v-model="model.amazon_mws_access_key" filled label="MWS Access Key" :readonly="isLocked")
            v-text-field(v-model="model.amazon_mws_secret_key" filled label="MWS Secret Key" :readonly="isLocked")
            v-text-field(v-model="model.amazon_app_client_id" filled label="App Client Id" :readonly="isLocked")
            v-text-field(v-model="model.amazon_app_client_secret" filled label="App Client Secret" :readonly="isLocked")

        .group-able-tip.payment(v-if="isDisplayPaymentByCountry")
          v-checkbox(v-model="model.braintree" hide-details label="Braintree" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.braintree')")
          .ib-2.mt-2(v-if="model.braintree")
            v-text-field(v-model="model.braintree_private_key" filled label="Private Key" :readonly="isLocked")
            v-text-field(v-model="model.braintree_public_key" filled label="Public Key" :readonly="isLocked")
            v-text-field(v-model="model.braintree_merchant_id" filled label="Merchant Id" :readonly="isLocked")

        .group-able-tip.payment
          v-checkbox(v-model="model.bank" hide-details label="Direct Bank" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.bank')")
          .ib-2.mt-2(v-if="model.bank")
            v-text-field(v-model="model.bank_iban" filled  label="Bank Iban" :readonly="isLocked")
            v-text-field(v-model="model.bank_swift" filled label="Bank Swift/BIC" :readonly="isLocked")
            v-text-field(v-model="model.bank_name" filled label="Bank Name" :readonly="isLocked")
        .group-able-tip.payment
          v-checkbox(v-model="model.cash" hide-details label="Cash on Delivery" :disabled="isLocked")
          tip-content(:tip="$t('text.tip_contents.payment.cash')")
    div.mt-6(v-if="polishPayments")
      group-container(
        :title="'Dostawa'"
        commentField="payment.gateway"
      )
        v-text-field(v-model="model.shipping_gateway" filled
        :label="polishPayments ? 'Dostawa' : 'Delivery'" :readonly="isLocked")
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import Passives from '@/mixins/PassivesMixin';
import TipContainer from '@/components/forms/parts/TipContainer';
import TipContent from '@/components/forms/parts/TipContent';
import domain from '@/scripts/domain';

export default {
  components: { GroupContainer, TipContainer, TipContent },
  mixins: [Passives],
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: false,
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.payments_online_shop',
      subtitle: 'text.choose_your_currency_and_payment_gateway',
      count: 0,
    };
  },
  mounted() {
    const invalid = [null, undefined, '', false];
    this.$watch(
      'currencyCode',
      (value) => {
        if (this.polishPayments) {
          if (invalid.includes(value) && this.count == 0) {
            this.$store.state.forms.projects.fields.payment.currency_id = 4;
            this.count++;
          } else if (invalid.includes(value)) {
            this.$store.state.forms.projects.fields.payment.currency_id = 4;
            this.count++;
          }
        }
      },
      { immediate: true }
    );
  },
  computed: {
    currencyCode() {
      return this.$store.state.forms.projects.fields.payment.currency_id;
    },
    gate() {
      return this.model.payment_gateway;
    },
    showCredentials() {
      return this.gate && this.gate != 'none';
    },
    polishPayments() {
      return domain.isPL();
    },
    ukPayments() {
      return domain.isUK();
    },
    isDisplayPaymentByCountry() {
      const isUK = domain.isUK();
      const isRU = domain.isRU();
      const isLT = domain.isLT();
      const isES = domain.isES();

      return ! (isUK || isES || isRU || isLT);
    }
  },
};
</script>

<template lang="pug">
  div
    component(
      :is="formComponent",
      v-on="formEvents",
      ref="form",
    )
</template>
<script>
import FormAdvanceMixin from "@front/mixins/FormAdvanceMixin";

export default {
  mixins: [FormAdvanceMixin],
  data() {
    return {
      modName: "users",
      formHasDocuments: false,
    };
  },
  computed: {
    pageTitle() {
      return this.$t("text.users")
    },
    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')]
    },
  },

  methods: {
    fetchData(uuid) {
      this.repoDispatch("API_SHOW", uuid).then(this._afterApi)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (undefined !== to.params.id) {
        vm.fetchData(to.params.id);
      }
    });
  },
};
</script>

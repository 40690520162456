<template lang="pug">
  .copylink
    v-text-field(
      filled 
      ref="input"
      :label="label"
      :disabled="disabled"
      v-model="linkData"
      append-icon="mdi-content-copy"
      @click:append="copyToClipboard"
    )
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Copy link'
    },
    link: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      linkData: this.link
    }
  },

  methods: {
    copyToClipboard() {

      this.$refs.input.$refs.input.select()
      this.$refs.input.$refs.input.setSelectionRange(0, 99999)

      document.execCommand("copy")
    }
  }
}
</script>

<style lang="sass">

.copylink
  display: flex

</style>

<template lang="pug">
  .tab-content.delegate
    .grid.grid-cols-2
      div
        v-btn(
          v-if="isAllowImpersonate"
          color="primary"
          @click="onImpersonate"
        ) {{ $t('text.btn_impersonate') }}
      .timestamps
        .date {{ $t('text.username') }}: {{ formModel.username }}
        .date {{ $t('text.created_at') }}: {{ dates.created }}
        .date {{ $t('text.updated_at') }}: {{ dates.updated }}
        .date {{ $t('text.deadline_date') }}: {{ dates.deadline }}
        .date {{ $t('text.reminder_date') }}: {{ dates.reminder }}
    group-container(
      :title="$t('text.attached_project_manager')"
      :subtitle="$t('text.which_project_manager_is_responsable_for_this_project')"
    )
      v-select(
        :items="managers"
        :menu-props={ offsetY: true }
        v-model="model.manager_uuid"
        filled
        :disabled="!$user().isAdmin()"
        :loading="isManagerLoading"
        @focus="getProjectManager"
      )

    group-container(
      :title="$t('text.attached_designer')"
      :subtitle="$t('text.which_designer_is_responsable_for_this_project')"
    )
      v-select(
        :items="designers"
        :menu-props={ offsetY: true }
        v-model="model.designer_uuid"
        filled
        :disabled="!$user().isAdmin()"
        :loading="isDesignerLoading"
        @focus="getProjectDesigner"
      )

    group-container(
      :title="statusTitle"
      :subtitle="$t('text.which_status_do_you_want_to_change_for_this_project')"
    )
      v-select(:items="statusItems" :menu-props={ offsetY: true } v-model="currentStatus" filled)

    group-container(
      v-if="canSetTimestamps"
      :title="$t('text.reminder')"
      :subtitle="$t('text.set_a_date_from_which_point_to_start_reminding_you_about_this_project')"
    )
      v-menu(
        v-model="reminderMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      )
        template(v-slot:activator="{ on, attrs }")
          v-text-field(
            v-model="model.reminder"
            filled
            readonly
            v-bind="attrs"
            v-on="on"
          )

        v-date-picker(
          v-model="model.reminder"
          @input="reminderMenu = false"
        )


    group-container(
      v-if="canSetTimestamps"
      :title="$t('text.deadline_date')"
      :subtitle="$t('text.set_a_deadline_date_for_this_project')"
    )
      v-menu(
        v-model="deadlineMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      )
        template(v-slot:activator="{ on, attrs }")
          v-text-field(
            v-model="model.deadline"
            filled
            readonly
            v-bind="attrs"
            v-on="on"
          )

        v-date-picker(
          v-model="model.deadline"
          @input="deadlineMenu = false"
        )

    v-btn(
      v-if="$user().isEmployee()"
      color="info"
      rounded
      depressed
      @click="downloadAll",
      :loading="loadingDownloadImages") {{ $t('text.download_project_images') }} ({{imageCount}})
    br
    br
    v-btn(
      v-if="showInstallWP"
      color="primary"
      @click="install"
      rounded
      depressed
      :loading="isInstalling") {{ $t('text.install_wp') }}

    v-expansion-panels.mt-5(v-if="wpData")
      v-expansion-panel(
        v-for="(value,key) in wpData"
        :key="key"
      )
        v-expansion-panel-header {{ key }}
        v-expansion-panel-content.flex-column.pa-3(v-html="formattedValue(value)")
</template>
<script>
import Api from '@/plugins/api';
import User from '@models/user';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import statusMaps from '@models/projects/statusMaps.js';
import ModMixin from '@/mixins/ModMixin.js';
import * as ROLES from '@/constants/roles';
import router from '@/router';
import { IMPERSONATE, routes } from '@/router/routes';
import { LOGIN_IMPERSONATE } from '@constant';

export default {
  components: { GroupContainer },
  mixins: [ModMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      title: 'text.delegate_user',
      subtitle: '',
      reminderMenu: false,
      deadlineMenu: false,
      loadingDownloadImages: false,
      isManagerLoading: false,
      isDesignerLoading: false,
      modName: 'projects',
      managers: [],
      designers: [],
      impersonate: {},
    };
  },

  computed: {
    isAllowImpersonate() {
      const impersonate = this.$store.state.auth.impersonate_by_department_configurations;
      if(_.isEmpty(impersonate)){
        return false;
      }
      const allowPermissions = [
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPER_ADMIN,
        ROLES.ROLE_PROJECT_MANAGER,
      ];
      const roleAccess = impersonate.role ? impersonate.role.split(',') : [];
      const isAccessRole = roleAccess ? roleAccess.every((role) => allowPermissions.includes(+role)) : false;
      const isRightDepartment = window.location.hostname.includes(
        impersonate.department ? impersonate.department.toLowerCase() : ''
      );
      return isAccessRole && isRightDepartment;
    },
    showInstallWP() {
      let isAdmin = this.$user().isAdmin();
      let isSuperAdmin = this.$user().isSuperAdmin();
      if (isAdmin || isSuperAdmin) {
        return this.$user().isEmployee() && this.formModel.website.domain_name;
      }
      return false;
    },

    canSetTimestamps() {
      return this.$user().isManager() || this.$user().isAdmin();
    },
    dates() {
      return {
        created: this.formModel.createdAt.format('YYYY-MM-DD HH:mm'),
        updated: this.formModel.updatedAt.format('YYYY-MM-DD HH:mm'),
        reminder: this.formModel.delegate.reminder ?? this.$t('text.not_set'),
        deadline: this.formModel.delegate.deadline ?? this.$t('text.not_set'),
      };
    },
    statusTitle() {
      return 'Current Status: ' + this.$t('text.statuses.' + this.formModel.status.name_code);
    },

    currentStatus: {
      get() {
        return this.model.status_id ?? this.formModel.status.id;
      },
      set(val) {
        this.model.status_id = val;
      },
    },

    statusItems() {
      return statusMaps.getUserMaps(this.$user(), this.formModel.status.id);
    },

    formModel() {
      return this.$store.state.forms.projects.model;
    },

    isInstalling() {
      return this.$store.state.forms.projects.wp_install;
    },

    wpData() {
      return this.$store.state.forms.projects.wp_data;
    },

    config() {
      return this.$store.state.config;
    },

    workers() {
      return this.$store.state.workers;
    },
    // managers() {
    //   return this.workers
    //     .filter((x) => x.isManager())
    //     .map((x) => {
    //       return {
    //         text: x.name,
    //         value: x.uuid,
    //       };
    //     });
    // },
    // designers() {
    //   return this.workers
    //     .filter((x) => x.isDesigner())
    //     .map((x) => {
    //       console.log('designer', x);
    //       return {
    //         text: x.name,
    //         value: x.uuid,
    //       };
    //     });
    // },

    imageCount() {
      return this.findImageCount(this.formModel);
    },
  },

  mounted() {
    this.impersonate = this.$store.state.auth.impersonate_by_department_configurations;
    if (!this.managers.length && !this.designers.length) {
      this.getProjectManager();
      this.getProjectDesigner();
    }
  },

  methods: {
    install() {
      this.$store.dispatch('forms/projects/INSTALL_WP');
    },

    formattedValue(val) {
      if (typeof val === 'string' || val instanceof String)
        return val.replace(/(\r\n|\n|\r)/g, '<br />');
      else return val;
    },

    async downloadAll() {
      this.loadingDownloadImages = true;
      await this.formDispatch('DOWNLOAD_ALL_MEDIA');
      this.loadingDownloadImages = false;
    },

    findImageCount(object, key = 'media') {
      var count = 0;
      const $this = this;
      Object.keys(object).forEach(function (k) {
        if (k === key && object[k]) {
          count += object[k].length;
        }
        if (object[k] && typeof object[k] === 'object') {
          count += $this.findImageCount(object[k], key);
        }
      });
      return count;
    },

    async onImpersonate() {
      await this.$store.dispatch('auth/LOGOUT').then(() => {
        sessionStorage.setItem(LOGIN_IMPERSONATE, JSON.stringify(this.impersonate));
      });
      await this.$router.push({
        path: routes.impersonate.path,
        query: {
          client: this.formModel.email,
        },
      });
    },

    onFilterManager(managers) {
      if (!managers.length) return [];
      return managers
        .filter((x) => x.isManager())
        .map((x) => ({
          text: x.name,
          value: x.uuid,
        }));
    },

    onFilterDesigner(designers) {
      if (!designers.length) return [];
      return designers
        .filter((x) => x.isDesigner())
        .map((x) => ({
          text: x.name,
          value: x.uuid,
        }));
    },

    async getProjectManager() {
      this.isManagerLoading = true;
      try {
        const { data } = await Api.get('users/manager-active-list');
        const manager = data.map((res) => User.create(res));

        this.managers = this.onFilterManager(manager);
      } finally {
        this.isManagerLoading = false;
      }
    },

    async getProjectDesigner() {
      this.isDesignerLoading = true;
      try {
        const { data } = await Api.get('users/designer-active-list');
        const designers = data.map((res) => User.create(res));

        this.designers = this.onFilterDesigner(designers);
      } finally {
        this.isDesignerLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.timestamps {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>

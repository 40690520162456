<template>
    <div class=" flex items-center justify-center border h-full w-full">
        Version: &nbsp; <code class="text-error">{{ VERSION }}</code>
    </div>
</template>
<script>

export default {
    data() {
        return {
            VERSION: process.env.VUE_APP_VERSION
        }
    }
}
</script>


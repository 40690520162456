/**
 * Base actions of the repository
 */
import moment from 'moment';
import Api from '@/plugins/api';
import { getItemKey } from './helpers';

export default (Model, actions) => {
  return Object.assign(
    {
      /**
       * Send API request to save a document
       */
      API_FETCH({ dispatch, getters, commit, state }) {
        commit('UPDATE_STATE', { field: 'is_fetch', value: true });
        return Api.get(getters.API_FETCH_URL, { params: state.filters })
          .then((response) => {
            dispatch('SET_ITEMS', response.data);
            commit('UPDATE_STATE', { field: 'is_fetch', value: false });
            commit('UPDATE_STATE', { field: 'pagination', value: response.meta.pagination });
            return response;
          })
          .catch((e) => {
            commit('UPDATE_STATE', { field: 'is_fetch', value: false });
          });
      },
      /**
       * Set repository items
       */
      SET_ITEMS({ dispatch, commit }, items) {
        if (!items) {
          return;
        }
        items = items
          .map(Model.create, Model)
          .filter((item) => item !== null)
          .filter((item) => !item.isTransfer);

        commit('SET_ITEMS', items);
      },

      /**
       * Set repository items
       * Do not include transfer items
       */
      SET_TRANSACTION_ITEMS({ dispatch, commit }, items) {
        items = items.map(Model.create, Model).filter((item) => item !== null);

        commit('SET_ITEMS', items);
      },

      /**
       * Add more items to the repository
       *
       * TODO: Maybe update existing items instead of
       * ignoring them completely?
       */
      ADD_ITEMS({ dispatch, commit, state }, items) {
        items = items
          // Filter out duplicates
          .reduce((items, item) => {
            const itemKey = getItemKey(item, state.key);

            if (items.findIndex((uniqItem) => getItemKey(uniqItem, state.key) === itemKey) < 0) {
              return items.concat(item);
            }
            return items;
          }, [])
          // Filter out existing items
          .filter((item) => {
            const itemKey = getItemKey(item, state.key);

            return (
              state.items.findIndex((existingItem) => {
                return getItemKey(existingItem, state.key) === itemKey;
              }) === -1
            );
          })
          // Create models
          .map(Model.create, Model);

        if (!items.length) {
          return;
        }

        commit('ADD_ITEMS', items);
      },

      /**
       * Add new or update existing item in the repository.
       */
      ADD_ITEM({ getters, dispatch, commit }, itemData) {
        const item = getters.FIND_ITEM(itemData);

        if (item) {
          dispatch('UPDATE_ITEM', itemData);
        } else {
          const createdItem = Model.create(itemData);

          if (createdItem) {
            commit('ADD_ITEM', createdItem);
          }
          return createdItem;
        }
        return item;
      },

      /**
       * Update existing item
       */
      UPDATE_ITEM({ getters, commit, state }, itemData) {
        const item = getters.FIND_ITEM(itemData);

        if (item) {
          if (
            itemData.updated_at !== undefined &&
            moment(itemData.updated_at.date).unix() < item.updatedAt.unix()
          ) {
            return item;
          }
          item.update(itemData);
          commit('REFRESH_LIST');
        }
        return item;
      },

      DELETE_ARRFIELD_ITEM(ctx, { uuid, field, itemData }) {
        const model = ctx.getters.FIND_ITEM_BY_KEY(uuid);

        if (!model) return model;

        if (!Array.isArray(model[field]))
          throw new Error(`Item's field [${field}] is not an ARRAY`);

        model[field] = model[field].filter((el) => {
          return el !== itemData;
        });

        model.update(model);
      },

      /**
       * Send API request to create a document
       */
      API_CREATE({ commit, dispatch, getters, rootState }, itemData) {
        const transformedData = Model.serializeData(itemData);
        //return Promise.reject('Rejecting...')
        return Api.post(getters.API_CREATE_URL, {
          [getters.API_RESOURCE_NAME]: transformedData,
        }).then((responseModel) => {
          dispatch('ADD_ITEM', responseModel);
          return responseModel;
        });
      },

      /**
       * Send API request to save a document
       */
      API_UPDATE({ dispatch, getters, commit }, itemData) {
        const transformedData = Model.serializeData(itemData);
        //return
        /*if ('restoredFromActivity' in itemData) {
        transformedData.restoredFromActivity = itemData.restoredFromActivity
      }*/
        return Api.put(getters.API_UPDATE_URL(itemData), {
          [getters.API_RESOURCE_NAME]: transformedData,
        }).then((responseModel) => {
          dispatch('UPDATE_ITEM', responseModel);
          return responseModel;
        });
      },
      /**
       * Send API request to patch document
       */
      API_SHOW({ dispatch, getters }, itemData) {
        return Api.get(getters.API_SHOW_URL(itemData), {
          [getters.API_RESOURCE_NAME]: itemData,
        }).then((responseModel) => {
          dispatch('ADD_ITEM', responseModel);
          return getters.FIND_ITEM(responseModel);
        });
      },

      /**
       * Send API request to patch document
       */
      API_PATCH({ dispatch, getters }, itemData) {
        return Api.patch(getters.API_PATCH_URL(itemData), {
          [getters.API_RESOURCE_NAME]: itemData,
        }).then((responseModel) => {
          dispatch('UPDATE_ITEM', responseModel);
          return responseModel;
        });
      },

      /**
       * Send API request to archive document
       */
      API_ARCHIVE({ commit, dispatch, getters }, itemData) {
        return Api.post(getters.API_ARCHIVE_URL(itemData)).then((responseModel) => {
          dispatch('UPDATE_ITEM', responseModel);
          return responseModel;
        });
      },

      API_ARCHIVE_MANY({ commit, dispatch, getters }, keys) {
        return Api.post(getters.API_ARCHIVE_MANY_URL, {
          keys,
        }).then((responseModels) => {
          responseModels.forEach((responseModel) => {
            dispatch('UPDATE_ITEM', responseModel);
          });
          return responseModels;
        });
      },

      API_DELETE({ commit, dispatch, getters }, itemData) {
        return Api.delete(getters.API_DELETE_URL(itemData)).then((responseModel) => {
          if (responseModel) {
            dispatch('UPDATE_ITEM', responseModel);
          } else {
            commit('REMOVE_ITEM', itemData);
          }

          return responseModel;
        });
      },

      API_DELETE_MANY({ commit, dispatch, getters, state }, keys) {
        return Api.post(getters.API_DELETE_MANY_URL, {
          keys,
        }).then((updatedItemsData) => {
          keys.forEach((key) => {
            const updatedItemData = updatedItemsData.find(
              (updatedItemData) => getItemKey(updatedItemData, state.key) === key
            );
            if (updatedItemData) {
              dispatch('UPDATE_ITEM', updatedItemData);
            } else {
              commit('REMOVE_ITEM', {
                [state.key]: key,
              });
            }
          });
          return updatedItemsData;
        });
      },

      API_UNARCHIVE({ commit, dispatch, getters }, itemData) {
        return Api.post(getters.API_UNARCHIVE_URL(itemData)).then((updatedItemData) => {
          dispatch('UPDATE_ITEM', updatedItemData);
          return updatedItemData;
        });
      },

      API_UNARCHIVE_MANY({ commit, dispatch, getters }, keys) {
        return Api.post(getters.API_UNARCHIVE_MANY_URL, {
          keys,
        }).then((updatedItemsData) => {
          updatedItemsData.forEach((updatedItemData) => {
            dispatch('UPDATE_ITEM', updatedItemData);
          });
          return updatedItemsData;
        });
      },

      API_RESTORE({ commit, dispatch, getters }, itemData) {
        return Api.post(getters.API_RESTORE_URL(itemData)).then((updatedItemData) => {
          dispatch('UPDATE_ITEM', updatedItemData);
          return updatedItemData;
        });
      },

      API_RESTORE_MANY({ commit, dispatch, getters }, keys) {
        return Api.post(getters.RESTORE_MANY_URL, {
          keys,
        }).then((response) => {
          response.forEach((row) => {
            dispatch('UPDATE_ITEM', row);
          });
          return response;
        });
      },

      /*
    UPLOAD_DOCUMENT({commit, rootState}, data) {
      data.documents.forEach((document) => {
        if (typeof document !== 'undefined' && document !== null && typeof document.created_at === 'undefined') {
          let userUuid = rootState.auth.user.uuid

          const formData = new FormData()
          formData.append('document', document)

          Api.post(`${data.folderName}/upload-document/${data.uuid}/${userUuid}`, formData)
          .then((response) => {
            commit('ADD_DOCUMENTS', {
              document: response,
              type: data.folderName,
              itemUuid: data.uuid
            })
          })
        }
      })
    },*/

      API_DOCUMENT_DELETE(ctx, model) {
        return Api.post(ctx.getters.API_DOCUMENT_DELETE_URL(model), model)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          });
      },

      /*
    DECREASE_SUBSCRIBED_LIMIT({commit, getters}, { docUUID, moduleCode, decLimit }) {
      const data = {
        doc_uuid: docUUID,
        module_code: moduleCode,
        limit: decLimit
      }
      console.log('DECREASING LIMIT....')

      return Api.post(`${getters.API_RESOURCE_NAME}/decrease-limit`, data)
        .then(() => {
          this.commit('auth/DECREASE_ACCOUNT_PERMISSION_LIMIT', {
            resourceName: getters.API_RESOURCE_NAME,
            quantity: decLimit
          })
          let model = getters.FIND_ITEM_BY_KEY(docUUID)
          model.itemPaid = true
          return Promise.resolve()
        }, () => {
          // TODO show service unexpected popup error
          return Promise.reject()
        })
    }
    */
    },
    actions
  );
};

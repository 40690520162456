import ModMixin from './ModMixin';

export default {
  mixins: [ModMixin],

  data: function () {
    return {
      formName: 'Form',
      formFolderPath: null,
      formFolderPathSuffix: null,
      formEvents: {
        'form-cancel': this.formActionCancel,
        'form-create': this.formActionCreate,
        'form-update': this.formActionUpdate,
      },
    };
  },

  computed: {
    // pageTitle() {
    //   return 'Page Title'
    // },
    // Bindable data 4 AbstractDataPage
    // dataBind() {
    //   return {
    //     modName: this.modName,
    //     pageTitle: this.pageTitle,
    //     formComponent: this.formComponent,
    //     formEvents: this.dtFormEvents,
    //   }
    // },

    formComponentPath() {
      let folder = this.formFolderPath ? `${this.formFolderPath}/${this.modName}` : this.modName;
      if (this.formFolderPathSuffix) folder += `/${this.formFolderPathSuffix}`;
      return `${folder}/${this.formName}`;
    },

    // form component 4 AbstractDataPage
    formComponent() {
      return require(`@/pages/${this.formComponentPath}`).default;
    },
  },

  methods: {
    // FORM EVENTS
    formActionCancel() {
      this.$router.push({ name: this.modName, query: this.tableFilter });
    },

    formActionCreate(model) {
      this.$store.commit('SET_SPINNER', 'saveBase');
      this.repoDispatch('API_CREATE', model)
        .then(this._afterApi)
        .then(() => {
          this.$router.push({
            name: `${this.repoGetter('API_RESOURCE_NAME')}Edit`,
            params: { id: model.uuid },
          });
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.created_successfully'));
          this.$store.dispatch('auth/PRELOADED_DATA');
          this.$eventBus.$emit('change-tab');
        })
        .catch((response) => {
          if (response.data.messages)
            this.formDispatch('MERGE_ERRORS_BY_RESOURCE', response.data.messages);
          else
            this.$store.commit(
              'ADD_NOTIFICATION',
              this.$t('text.oops_server_error_try_again_later')
            );
        })
        .finally(() => {
          this.$store.commit('REMOVE_SPINNER', 'saveBase');
        });
    },

    formActionUpdate(model) {
      this.$store.commit('SET_SPINNER', 'saveBase');
      let modelToProcess = model;
      if (this.modName === 'projects') {
        const keyFormFormat = ['branding'];
        modelToProcess = this._resetBeforeUpdate(model, keyFormFormat);
      }
      this.repoDispatch('API_UPDATE', modelToProcess)
        .then(this._afterApi)
        .then(() => this.formDispatch('VALIDATE_FIELDS_TAB'))
        .then(() => this.formState.current_tab === 'website' && this.formDispatch('CHECK_DOMAIN'))
        .then((response) => {
          console.log('ResponseOnSuccess', response ?? '');
          this.$store.commit('ADD_NOTIFICATION', this.$t('text.updated_successfully'));
          this._afterActionUpdate(modelToProcess);
          // this.formDispatch('VALIDATE_FIELDS');
          this.$eventBus.$emit('change-tab');
        })
        .catch((error) => {
          console.error('ResponseOnFailure', error);
          if (error.data.messages) {
            // project.website.domain_name
            let msg = { ...error.data.messages };
            if (msg.hasOwnProperty('project.website.domain_name')) {
              msg['domain_name'] = msg['project.website.domain_name'];
              // this.formDispatch('MERGE_ERRORS_BY_RESOURCE', {website: msg})
              this.formDispatch('MERGE_ERRORS_BY_RESOURCE', error.data.messages);
            } else {
              this.formDispatch('MERGE_ERRORS_BY_RESOURCE', error.data.messages);
            }
          } else {
            this.$store.commit(
              'ADD_NOTIFICATION',
              this.$t('text.oops_server_error_try_again_later')
            );
          }
        })
        .finally(() => {
          this.$store.commit('REMOVE_SPINNER', 'saveBase');
          // this.formDispatch('VALIDATE_FIELDS')
        });
    },

    _resetBeforeUpdate(model, keys = []) {
      if (!keys.length) {
        return model;
      }
      keys.forEach((key) => {
        switch (key) {
          case 'branding':
            if (model[key].logo === 'owned') {
              model[key].logo_create = null;
              model[key].free_word = null;
              model[key].pro_description = null;
              model[key].pro_word = null;
            } else {
              if (model[key].logo_create === 'free') {
                model[key].pro_description = null;
                model[key].pro_word = null;
                model[key].media = [];
              } else {
                model[key].free_word = null;
              }
            }
            break;
          default:
            break;
        }
      });

      return model;
    },

    _afterApi(responseModel) {
      this.formCommit('CLEAR_FIELDS');
      // this.formCommit('CLEAR_ERRORS');
      this.formCommit('SET_FIELDS', responseModel);
      this.formCommit('UPDATE_STATE', { field: 'is_edit', value: true });
      this.formCommit('UPDATE_STATE', { field: 'show_form', value: true });
    },

    _afterActionUpdate(responseModel) {
      //console.log(responseModel)
    },
  },
};

import login from '@front/modules/auth/login/form'
import registration from '@front/modules/auth/registration/form'
import passwordReset from '@front/modules/auth/passwordReset/form'
import passwordUpdate from '@front/modules/auth/passwordUpdate/form'
import projects from '@models/projects/form'
import users from '@models/users/form'
import translations from '@models/translations/form'
import userPreference from '@models/user/preference/form'

export default {
  namespaced: true,
  modules: {
    login,
    registration,
    passwordReset,
    projects,
    users,
    translations,
    userPreference,
    passwordUpdate
  }
}
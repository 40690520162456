<template lang="pug">
  v-container(fluid)(class="flex flex-col justify-center" :class="[isCorrectDepartment ? 'fill-height' : '']")
    template(v-if="isCompatibleBrowser")
      div(v-if="isCorrectDepartment" class="text-center w-4/5")
        template(v-if="isAllowDepartment")
          div
            img(src="@/assets/images/firstscreenbanner.svg")
          div.mb-6
            v-btn(
              color="primary"
              rounded
              depressed
              to="/"
              :large="!$vuetify.breakpoint.mobile")
              | {{ linkTitle }}
        template(v-else)
          div
            h2(class="size-title font-semibold")
              | {{ $t('text.project_heading') }}
            p.mt-4.mb-0
              | {{ $t('text.project_sub_heading_1') }} {{ ' ' }}
              a(
                :href="tutorialLink"
                target="_blank"
                :class="[tutorialLink ? 'primary--text cursor--pointer text--underline': 'text--inherit']"
              )
                | {{ $t('text.video_tutorial') }}
              | , {{ $t('text.project_sub_heading_2') }}
            p.mt-8
              | {{ $t('text.project_click_button_below') }}
          div.my-6
            v-btn(
              color="primary"
              rounded
              depressed
              to="/"
              :large="!$vuetify.breakpoint.mobile")
              | {{ linkTitle }}
          div.mt-8
            img(src="@/assets/images/pif-letsstarted-banner.svg")
      section.mt-6(v-else)
        move-department(:domain="domainDepartment")
    template(v-else)
      compatible-browser(:is-login="isLogin" @click="onAcceptRisk")
</template>
<script>
import * as helpers from '@/helpers';
import domain from '@/scripts/domain';
import MoveDepartment from '@/components/MoveDepartment';
import CompatibleBrowser from '@/components/CompatibleBrowser';
import { ACCEPT_RISK_BROWSER_KEY } from '@constant';

export default {
  components: {
    CompatibleBrowser,
    MoveDepartment,
  },
  data() {
    return {
      isCompatibleBrowser: true,
      isCorrectDepartment: true,
      domainDepartment: '',
      tutorialLink: '',
    };
  },
  computed: {
    isAllowDepartment() {
      return domain.isPL();
    },
    hiddenTutorialLink() {
      // Add more department need to hidden
      return ![domain.isRO()].includes(true);
    },
    linkTitle() {
      return this.$t('text.start_your_website_form_here');
    },
    linkDescription() {
      return this.$t(
        'text.before_you_start_filling_out_the_form_please_watch_a_short_video_tutorial'
      );
    },
    isLogin() {
      return !!this.$user().uuid;
    },
  },
  watch: {
    '$i18n.locale'(val) {
      if (val) this.renderTutorialLink(val);
    },
  },
  mounted() {
    this.checkDepartment();
    if (this.$i18n.locale) this.renderTutorialLink(this.$i18n.locale);
    this.isCompatibleBrowser =
      _.has(this.$user().preferences, 'accepting_the_risks') ||
      this.$utils.checkCompatibleBrowser();
  },
  methods: {
    checkDepartment() {
      const checkDepartment = _.get(this.$user(), 'check_department', {});
      this.isCorrectDepartment = helpers.checkDepartment(checkDepartment);

      if (!this.isCorrectDepartment) {
        this.domainDepartment = checkDepartment.domain;
      }
    },
    renderTutorialLink(locale) {
      this.tutorialLink = this.hiddenTutorialLink
        ? this.$store.state.forms.projects?.tutorials[locale]?.tutorial ||
          'https://www.youtube.com/watch?v=fg9bn_7t6cE&ab_channel=WAcademyEurope'
        : null;
    },
    onAcceptRisk(value) {
      sessionStorage.setItem(ACCEPT_RISK_BROWSER_KEY, value);
      this.isCompatibleBrowser = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.cursor--pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.text--underline {
  text-decoration: underline !important;
}

.text--inherit {
  color: inherit !important;
  cursor: text;
}

.size-title {
  font-size: 42px;
}
</style>

<template lang="pug">
  v-data-table(
    depressed
    :headers="tableHeaders"
    :items="tableItems"
    :class="classes"
    mobile-breakpoint="200"
    :footer-props="footerProps"
    v-bind="tableOptions || {}"
  )
    template(v-slot:top)
      slot(name="table-actions")
        v-toolbar(elevation="0" dense  rounded v-bind="toolbarBinds")
          template(v-if="$vuetify.breakpoint.xs")
            h4 {{ title }}
          v-toolbar-title(v-else) {{ title }}
          v-spacer
          //- | {{ tableActions }}
          component(
            v-for="(action, index) in tableActions"
            :key="index"
            :is="action.component"
            :disabled="action.disabled ?? false"
            v-on="action._events"
            v-bind="action._props"
          )
      slot(name="table-top")



    template(v-slot:item.name="{ item }")
      slot(name="item-name" :item="item") {{ item.name }}
    template(v-slot:item.action="{ item }")
      slot(name="item-actions" :item="item")
        div.d-flex
          v-icon(
            v-for="(act, index) in tableItemActions"
            :key="index"
            class="mr-2"
            small
            :color="act.color"
            @click="act.click(item, self)"
            :disabled="act.disabled ?? false"
            v-bind="act"
          ) {{ act.icon }}
          slot(name="item-actions-append" :item="item")
    template(v-slot:no-data)
      .px-5.py-5.uppercase {{ $t('text.no_data') }}
</template>
<script>
export default {
  props: {
    tableHeaders: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableActions: {
      type: Array,
      default: () => {
        return {};
      },
    },
    tableItemActions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    elevation: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },

    toolbarDark: { type: Boolean, default: true },
    toolbarTheme: { type: String, default: 'theme--gradient' },
    tableOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    self() {
      return this;
    },
    classes() {
      //console.log('classes', this.elevation)
      return ['elevation-' + this.elevation];
    },
    toolbarBinds() {
      return {
        class: this.toolbarTheme,
        dark: this.toolbarDark,
      };
    },
  },
  data() {
    return {
      footerProps: {
        'items-per-page-text': this.$t('text.rows_per_page'),
        'items-per-page-options': [5, 10, 20, 30, 50],
      },
    };
  },
};
</script>

import {FormState, FormGetters, FormMutations, FormActions} from '@front/modules/abstracts/form'
import Api from '@/plugins/api'

const state = FormState({
  fields: {
    password: '',
    password_confirmation: '',
    code: ''
  },
  isUpdatePasswordSucceed: false,
  message: '',
})
const mutations = FormMutations()
const getters = FormGetters({
  VALIDATES: (state) => {
    return {
      password: ['required|min:8', ['text.please_enter_your_password', 'text.the_password_must_contain_at_least_8_character']],
      password_confirmation: ['required|equals:password', ['text.please_repeat_your_password', 'text.passwords_should_match']],
    }
  },
})

const actions = FormActions({
  async UPDATE_PASSWORD({dispatch, commit, state}) {
    await dispatch('VALIDATE_FIELDS')

    commit('auth/SET_LOADING', true, {root: true})
    Api
      .post('auth/reset-password', {
        code: state.fields.code,
        password: state.fields.password,
        confirm_password: state.fields.password_confirmation
      })
      .then((response) => {
        commit('auth/SET_LOADING', false, {root: true})
        state.isUpdatePasswordSucceed = true
        state.message = response.message

        setTimeout(() => {
          dispatch('auth/UPDATE_PASSWORD_SUCCESS', [], {root: true})
          state.isUpdatePasswordSucceed = false
        }, 5000)
      })
      .catch((response) => {
        console.log('update password RESPONSE', response.body)
        commit('auth/SET_LOADING', false, {root: true})
        if (response.body !== undefined) {
          let passwordMessage = 'Wrong Password. Try again.';
          if (response.body.messages !== undefined && response.body.messages.password !== undefined) {
            passwordMessage = response.body.messages.password
          } else {
            passwordMessage = response.body.message
          }

          commit('SET_ERRORS', {
            password: [passwordMessage]
          })
        }
        throw response
      })
  }
})

/**
 * Exports
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
  // getters
}

import i18n from '@/i18n';
import Api from '@/plugins/api';
import { FormActions, FormGetters, FormMutations, FormState } from '@front/modules/abstracts/form';
import domain from '@/scripts/domain';
import { checkDomain } from '@/helpers';
import { PROJECT_COMPLETED, PROJECT_READY_FOR_REVIEW } from '@models/projects/statuses';

const state = FormState({
  title: '',
  subtitle: '',
  wp_install: false,
  wp_data: null, // holds response after wp installs
  current_tab: '',
  fields: {
    uuid: null,
    name: null,
    is_locked: false,
    status_id: 1,
    manager_uuid: null,
    designer_uuid: null,
    create_type: 'site',
    accepted_agreement: false,
    accepted_terms: false,
    extra_services: [],
    comments: {},
    fixes: [],
    fixes_img: [],
    new_fixes: [],
    basic: {
      department_id: null,
      manager_uuid: null,
      brand_name: null,
      description: null,
      business_category_id: null,
    },
    company: {
      email: null,
      phone_number: null,
      address: null,
      working_hours: null,
      selected_hours: [
        { name: 'monday', time_from: null, time_till: null },
        { name: 'tuesday', time_from: null, time_till: null },
        { name: 'wednesday', time_from: null, time_till: null },
        { name: 'thursday', time_from: null, time_till: null },
        { name: 'friday', time_from: null, time_till: null },
        { name: 'saturday', time_from: null, time_till: null },
        { name: 'sunday', time_from: null, time_till: null },
      ],
      social_links: [
        { name: 'Facebook', url: null },
        { name: 'Instagram', url: null },
        { name: 'LinkedIn', url: null },
        { name: 'TikTok', url: null },
      ],
      details_show: false,
      details: [
        { id: 1, name: 'Company name', value: null },
        { id: 2, name: 'Company code', value: null },
        { id: 3, name: 'VAT code', value: null },
        { id: 4, name: 'Bank account number (IBAN)', value: null },
      ],
    },
    website: {
      domain_whmcs: false,
      domain_name: null,
      domain_registered: null,
      domain_care: null,
      domain_com: null,
      care_url: null,
      care_user: null,
      care_password: null,
      care_label: null,
      host_email: null,
      check_migrate_email: false,
    },
    branding: {
      logo: null,
      logo_create: null,
      free_word: null,
      pro_word: null,
      pro_description: null,
      media: [],
    },
    style: {
      type: null,
      style: null,
      color_scheme: null,
      colors_selected: [],
      color_perfect_style: null,
      font_head: null,
      font_head_google: null,
      font_head_selected: null,
      font_paragraph: null,
      font_paragraph_google: null,
      font_paragraph_selected: null,
      link1: null,
      link2: null,
      link3: null,
      links: [],
      website_like: [],
      comments: null,
      link_comments: null,
    },
    only_one: {
      //fields for specified system
      content_all_images: null, // a link to we to download all images of the content.
    },
    sitemap: [],
    sc_title: null, // workaround only for validator to collect "sitemap content title"
    content: null, // workaround only for validator to collect "sitemap content"
    categories: [],
    products: null, // workaround only for validator to collect "categories"
    display_categories_in_main_menu: false,
    payment: {
      currency_id: null,
    },
    delegate: {},
    conversation: false,
  },
  tutorials: {},
});
const mutations = FormMutations({
  SET_DEPARTMENT(state, value) {
    state.fields.basic.department_id = value;
  },
});
const getters = FormGetters({
  VALIDATES: (state) => {
    const isUKorCOM = domain.isCOM() || domain.isUK();
    const isPL = domain.isPL();
   
    return {
      basic: {
        // department_id: ['required', 'text.please_choose_a_department'],
        brand_name: ['required', 'text.please_enter_your_brand_name'],
        description: [
          'required',
          ['text.please_enter_your_description', 'text.please_enter_less_words_under_400_symbols'],
        ],
        // business_category_id: ['required', 'text.please_select_business_category'],

        logo: ['required', 'text.please_select_one_option'],
        media: ['minIf:logo:owned:1:logo', 'text.please_upload_your_logo'],
        logo_create: ['requiredif:logo:create', 'text.please_select_one_option'],
        free_word: ['requiredif:logo_create:free', 'text.please_enter_word_or_symbol'],
      },
      company: {
        email: [
          'email|required',
          ['text.the_email_must_be_a_valid_email_address', 'text.please_enter_your_email'],
        ],
        phone_number: ['phone', 'text.please_enter_valid_phone_number'],
      },
      website: {
        domain_name: [
          'domain|required',
          ['text.please_enter_valid_domain_name', 'text.please_enter_your_domain_name'],
        ],
        domain_registered: ['required', 'text.please_select_one_option'],
        domain_care: ['requiredif:domain_registered:provider', 'text.please_select_one_option'],
        care_url: [
          'requiredif:domain_care:getspace|url',
          ['text.please_enter_your_login_url', 'text.please_enter_valid_url_address'],
        ],
        care_user: ['requiredif:domain_care:getspace', 'text.please_enter_your_login_name'],
        ...(isUKorCOM
          ? {
              care_password: [
                'requiredif:domain_care:getspace',
                'text.please_enter_your_login_password',
              ],
            }
          : {
              care_label: ['requiredif:domain_care:getspace', 'text.please_enter_your_login_label'],
            }),
        host_email: [
          'email|required',
          [
            'text.the_email_must_be_a_valid_email_address',
            'text.please_enter_your_registered_email',
          ],
        ],
      },
      branding: {
        logo: ['required', 'text.please_select_one_option'],
        // media: ['minIf:logo:owned:1:logo', 'text.please_upload_your_logo'],
        media: [
          `minIf:logo:owned:1:logo${isPL ? '' : '|minIf:logo_create:pro:1:symbol'}`,
          'text.please_upload_your_logo',
        ],
        logo_create: ['requiredif:logo:create', 'text.please_select_one_option'],
        free_word: ['requiredif:logo_create:free', 'text.please_enter_word_or_symbol'],
        ...(!isPL && {
          pro_word: ['requiredif:logo_create:pro', 'text.please_enter_word_or_symbol'],
          pro_description: ['requiredif:logo_create:pro', 'text.please_enter_word_or_symbol'],
        }),
      },
      style: {
        style: ['required', 'text.please_select_one_option'],
        color_scheme: ['required', 'text.please_select_one_option'],
        color_perfect_style: ['requiredif:color_scheme:perfect', 'text.please_select_one_option'],
        colors_selected: ['minIf:color_scheme:colors:5', 'text.please_select_at_least_five_colors'],
        font_head: ['required', 'text.please_select_one_option'],
        font_head_selected: [
          'requiredif:font_head:selected|url',
          ['text.please_enter_the_desired_font_url', 'text.please_enter_valid_url_address'],
        ],
        font_head_google: ['requiredif:font_head:google', 'text.please_select_one_option'],
        font_paragraph: ['required', 'text.please_select_one_option'],
        font_paragraph_selected: [
          'requiredif:font_paragraph:selected|url',
          ['text.please_enter_the_desired_font_url', 'text.please_enter_valid_url_address'],
        ],
        font_paragraph_google: [
          'requiredif:font_paragraph:google',
          'text.please_select_one_option',
        ],
        // link1: ['url', 'text.please_enter_valid_url_address'],
        // link2: ['url', 'text.please_enter_valid_url_address'],
        // link3: ['url', 'text.please_enter_valid_url_address'],
        // links: ['url', 'text.please_enter_valid_url_address'],
        // website_like: ['required', 'text.please_enter_valid_url_address'],
        website_like: [
          'urls:link|required',
          'text.please_enter_the_website_like_or_the_url_address_invalid',
          'text.please_enter_valid_url_address',
        ],
      },
      sitemap: ['min:1', 'text.please_add_at_least_one_page'],
      // sitemap: {
      //   type: ['required', 'text.please_select_one_option'],
      //   website_type: 'text.please_add_at_least_one_page'
      // },

      // sc_title: ['nestedList:sitemap:required:title', 'text.please_enter_page_title'],
      content: ['nestedList:sitemap:required:content', 'text.please_enter_page_content'],

      //validations for shop
      ...(state.fields.create_type === 'shop'
        ? {
            categories: ['min:1', 'text.please_add_at_least_one_category'],
            products: [
              'nestedList:categories:min:1:products',
              'text.please_add_at_least_one_product_per_category',
            ],
          }
        : {}),
    };
  },
  // nestedList:products:min:1
});
const actions = FormActions({
  ACTIVE_TAB({ state, commit, dispatch }, payload) {
    commit('ACTIVE_TAB', payload);
  },
  LAUNCH({ commit, dispatch }) {
    Api.post('projects/launch', { project_uuid: state.fields.uuid }).then((model) => {
      dispatch('repositories/projects/UPDATE_ITEM', model, { root: true });
      commit('UPDATE_STATE', { field: 'show_form', value: false });
      commit('ADD_NOTIFICATION', i18n.t('text.project_launched'), { root: true });
      commit('ADD_NOTIFICATION', i18n.t('text.thank_you_for_using_our_service'), { root: true });
    });
  },
  COMPLETE({ state, commit, dispatch }) {
    if (state.model.status_id !== PROJECT_COMPLETED) {
      Api.post('projects/complete', { project_uuid: state.fields.uuid }).then((model) => {
        dispatch('repositories/projects/UPDATE_ITEM', model, { root: true });
        commit('UPDATE_STATE', { field: 'show_form', value: false });
        commit('ADD_NOTIFICATION', i18n.t('text.project_completed'), { root: true });
        commit('ADD_NOTIFICATION', i18n.t('text.thank_you_for_using_our_service'), { root: true });
      });
    }
  },
  FINILIZE(context) {
    return new Promise((resolve, reject) => {
      context.dispatch('VALIDATE_FIELDS').then(() => {
        const model = { ...context.state.fields, finilize: true };
        const success = function () {
          context.commit('UPDATE_STATE', { field: 'show_form', value: false });
          context.commit('ADD_NOTIFICATION', i18n.t('text.submited_successfully'), { root: true });
          resolve(true);
        };
        const error = () => {
          context.commit('ADD_NOTIFICATION', i18n.t('text.oops_server_error_try_again_later'), {
            root: true,
          });
        };
        if (context.getters.IS_EDITING) {
          context
            .dispatch('repositories/projects/API_UPDATE', model, { root: true })
            .then(() => {
              success();
              resolve(true);
            })
            .catch((response) => {
              error();
              // commit('MERGE_ERRORS', err.data.data, 'webiste')
              console.error('ERROR_FINILIZE', response);
              // project.website.domain_name
              let msg = { ...response.data.messages };
              console.error(context.state);
              if (msg.hasOwnProperty('project.website.domain_name')) {
                msg['domain_name'] = msg['project.website.domain_name'];
                context.commit('MERGE_ERRORS', { website: msg }, 'webiste');
              }
              reject(true);
            });
        } else {
          context
            .dispatch('repositories/projects/API_CREATE', model, { root: true })
            .then(() => {
              success();
              resolve(true);
            })
            .catch((err) => {
              error();
              reject(true);
            });
        }
      });
    });
  },
  SAVE_COMMENTS({ state, commit }) {
    commit('SET_SPINNER', 'saveComment', { root: true });
    Api.post('projects/save-comments', {
      project_uuid: state.fields.uuid,
      comments: state.fields.comments,
    }).then(() => {
      commit('ADD_NOTIFICATION', i18n.t('text.comments_have_been_saved'), { root: true });
      commit('REMOVE_SPINNER', 'saveComment', { root: true });
    });
  },
  SEND_FIXES({ state, commit, dispatch }) {
    if (state.model.status_id === PROJECT_READY_FOR_REVIEW) {
      Api.post('projects/send-fixes', {
        project_uuid: state.fields.uuid,
        fixes: state.fields.fixes,
      }).then((model) => {
        commit('UPDATE_STATE', { field: 'show_form', value: false });
        dispatch('repositories/projects/UPDATE_ITEM', model, { root: true });
        commit('ADD_NOTIFICATION', i18n.t('text.fixes_have_been_sent'), { root: true });
      });
    }
  },
  DELEGATE({ state, commit, dispatch }, _options = {}) {
    commit('SET_SPINNER', 'saveOptions', { root: true });
    Api.post('projects/delegate', {
      project_uuid: state.fields.uuid,
      delegate: state.fields.delegate,
    }).then((responseModel) => {
      //commit('UPDATE_STATE', { field: 'show_form', value: false })
      commit('ADD_NOTIFICATION', i18n.t('text.project_has_been_delegated'), { root: true });
      dispatch('repositories/projects/UPDATE_ITEM', responseModel, { root: true });
      commit('REMOVE_SPINNER', 'saveOptions', { root: true });
      _options?.func();
    });
  },
  INSTALL_WP({ state, commit, dispatch }) {
    state.wp_install = true;
    state.wp_data = null;
    Api.get('projects/install/' + state.fields.uuid).then((responseModel) => {
      state.wp_install = false;
      state.wp_data = responseModel;
      console.log('wp install response: ', responseModel);
    });
  },
  DOWNLOAD_ALL_MEDIA({ state, commit }, files) {
    const mediaAPI =
      process.env.VUE_APP_MEDIA_DOMAIN_API + '/projects/download-all-media/' + state.fields.uuid;
    return Api.download(mediaAPI, {
      params: files,
      responseType: 'arraybuffer',
      headers: {
        'x-api-key': process.env.VUE_APP_MEDIA_KEY_API,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data, { type: response.headers.get('Content-Type') }])
      );
      const fileName = response.headers
        .get('Content-Disposition')
        .split('filename=')[1]
        .split(';')[0];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  },

  DOMAIN_CHECK({ state }, domain_name) {
    return Api.post('projects/domain/check', {
      domain_name: domain_name,
      project_uuid: state.fields.uuid,
    });
  },

  async CHECK_DOMAIN({ state, commit }) {
    if (!state.model.website.domain_name) {
      return;
    }
    const params = {
      domain_name: state.model.website.domain_name,
      project_uuid: state.fields.uuid,
    };
    const currentTab = state.current_tab;

    const res = await checkDomain('projects/check-domain', 'get', params);
    const status = _.get(res, 'status', false);
    const message = _.get(res, 'message', '');

    if (!status) {
      commit('MERGE_ERRORS', { website: { domain_name: message } }, 'website');

      if (currentTab === 'submit') {
        return Promise.reject(new Error(message));
      }
    }
  },

  DOMAIN_REGISTER(
    { state, commit },
    { domain_name, com_country, com_city, com_street_address, com_zip, com_county } = {}
  ) {
    return Api.post('projects/domain/register', {
      project_uuid: state.fields.uuid,
      domain_name: domain_name,
      com_country,
      com_city,
      com_street_address,
      com_zip,
      com_county,
    })
      .then(() => {
        state.fields.website.domain_whmcs = true;
        state.fields.website.domain_name = domain_name;
        // TODO: update repostiory model..
        commit('ADD_NOTIFICATION', i18n.t('text.domain_registered_successfully'), { root: true });
      })
      .catch((err) => {
        const isDomainwhmcs = _.has(err.data.data, 'domain_whmcs');
        const formatMsgErr = isDomainwhmcs
          ? { domain_whmcs: i18n.t(`text.${err.data.data['domain_whmcs']}`) }
          : err.data.data;

        commit('MERGE_ERRORS', formatMsgErr, 'webiste');
        console.log('errors merged', state.validationErrors, err, err.data);
        commit('ADD_NOTIFICATION', i18n.t('text.domain_registered_error'), { root: true });
      });
  },
});

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-app>
    <notifications />
    <cookies v-if="showCookiePopup"></cookies>
    <loader v-if="isConfigPreloading"></loader>
    <div v-else-if="isBadConfig" class="full centerize">
      <h1>Bad config. Ask Support!</h1>
    </div>
    <template v-else-if="showApp">
      <drawer />
      <template v-if="isData">
        <topbar v-if="!isExtendHeader" />
        <v-main>
          <router-view></router-view>
        </v-main>
      </template>
      <loader v-else />
    </template>
    <public v-else-if="showPublic">
      <template v-slot:router>
        <v-main>
          <router-view></router-view>
        </v-main>
      </template>
    </public>
  </v-app>
</template>
<script>
import Public from '@front/pages/public/Index';
import Topbar from '@front/components/navigations/Topbar.vue';
import Drawer from '@front/components/navigations/Drawer.vue';
import Loader from '@front/components/common/Loader.vue';
import Notifications from '@front/components/Notifications.vue';
import Cookies from '@front/components/common/Cookies.vue';
import { loadLanguageAsync } from '@/i18n';
import { addScriptCustomScrollBar } from '@/scripts/scroll.js';
import domain from '@/scripts/domain';
import liveChat from '@/scripts/live-chat';
import hotjar from '@/scripts/hotjar';

export default {
  components: {
    Public,
    Loader,
    Topbar,
    Drawer,
    Notifications,
    Cookies,
  },
  beforeMount() {
    // TODO: Replace variable department
    const allowDepartmentKeys = ['uk'];
    const hostname = window.location.hostname;
    const keyHostname = hostname.substring(0, hostname.indexOf('.'));

    if (allowDepartmentKeys.includes(keyHostname)) {
      liveChat();
    }

    if (process.env.VUE_APP_ENABLE_HOTJAR === 'true') {
      hotjar();
    }
  },
  created() {
    // this.$watch('userLocale', this.loadTranslations, { immediate: true, deep: true });
  },
  mounted() {
    // this.changeFont();
    addScriptCustomScrollBar();
  },
  watch: {
    '$store.state.auth': {
      handler(val) {
        const liveChatEl = document.querySelector('[data-b24-crm-button-cont]');

        if (!liveChatEl) return;

        const isExitClass = liveChatEl.classList.contains('live-chat-custom-position');

        if (val.is_logged_in && !isExitClass) {
          if (!this.$cookies.get('cookie_popup') || this.$user().isClient()) {
            liveChatEl.classList.add('live-chat-custom-position');
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    isExtendHeader() {
      return ['projectEdit', 'projectCreate'].includes(this.$route.name);
    },
    language() {
      return this.$store.state.config.language;
    },
    userLocale() {
      let userLanguage = this.$store.state.auth?.user?.preferences?.locale;
      return userLanguage;
    },
    showCookiePopup() {
      return this.showApp && !this.isConfigPreloading && !this.$cookies.get('cookie_popup', false);
    },

    isConfigPreloading() {
      return this.$store.state.config_preloading;
    },

    isBadConfig() {
      return this.$store.state.config === false;
    },

    isAuthenticated() {
      return this.$store.state.auth.is_logged_in;
    },

    isData() {
      const auth = this.$store.state.auth;
      return !auth.is_preloading && auth.is_loaded;
    },

    showApp() {
      return this.isAuthenticated;
    },

    showPublic() {
      return !this.isAuthenticated;
    },
  },
  methods: {
    changeFont() {
      let element = document.querySelector('.v-application');
      element.classList.add('font-body-poppins');
      if (domain.isRU() || domain.isLV() || domain.isBG()) {
        element.classList.remove('font-body-poppins');
        element.classList.add('font-body-monteserrat');
      }
    },
    async loadTranslations(lan) {
      let configLanguage = this.$store.state.config.language;
      if (!lan) {
        // return
        lan = configLanguage;
      }
      await loadLanguageAsync(lan);
    },
  },
};
</script>
<style lang="scss">
.live-chat-custom-position {
  bottom: 80px !important;
}
</style>

import { FormState, FormGetters, FormMutations, FormActions } from '@front/modules/abstracts/form';

const state = FormState({
  title: 'Users',
  subtitle: 'Manage user',
  fields: {
    uuid: null,
    username: null,
    account_name: null,
    email: null,
    password: null,
    password_repeat: null,
    user_type_id: null,
    department_id: null,
    hide_user_in_dropdown_list: null,
  },
});
const mutations = FormMutations();
const getters = FormGetters({
  VALIDATES: (state) => {
    return {
      username: ['required', 'Please enter user name.'],
      account_name: ['required', 'Please enter your company.'],
      email: ['required', 'Please enter your email.'],
      //password: ['required', 'Please enter your password.'],
      password_repeat: ['equals:password', ['Passwords should match.']],
      user_type_id: ['required', 'Please select account type.'],
    };
  },
});
const actions = FormActions();

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};

<template lang="pug">
  .tab-content
    .titles.text-center
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    slot(name="tips-container")
    group-container(
      :title="$t('text.brand_name_site_title')"
      :subtitle="$t('text.what_is_the_name_of_your_brand_or_business')"
      :tip="$t('text.tip_contents.basic.brand_name')"
      commentField="basic.brand_name"
      :mandatory="true"
    )
      v-text-field(
        v-model="brand_name"
        filled
        :readonly="isLocked"
        :error-messages="$t(errors.basic.brand_name)")
    group-container(
      :title="$t('text.brief_description_of_your_brand_site_description')"
      :subtitle="$t('text.describe_what_you_do_in_a_few_words')"
      :tip="$t('text.tip_contents.basic.description')"
      commentField="basic.description"
      :mandatory="true"
    )
      v-textarea(v-model="description" filled :readonly="isLocked" :error-messages="$t(errors.basic.description)" counter="400")
    //- group-container(
    //-   v-if="showDepartments"
    //-   :title="$t('text.choose_a_department')"
    //-   :subtitle="$t('text.choose_which_country_will_manage_your_project')"
    //-   commentField="basic.department"
    //-   :mandatory="true"
    //- )
    //-   v-select(
    //-     v-model="department_id"
    //-     :items="passiveDepartments"
    //-     :readonly="lockedDepartment"
    //-     filled :menu-props={offsetY: true}
    //-   )
    group-container(
      :title="$t('text.your_project_manager')"
      :subtitle="$t('text.choose_your_project_manager_from_the_list_below')"
      commentField="basic.project_manager"
    )
      v-select(
        filled
        :items="managers"
        :readonly="lockedDepartment"
        v-model="manager_uuid"
        :menu-props={ offsetY: true }
        :loading="isManagerLoading"
        @focus="getProjectManager"
      )
    //- group-container(
    //-   :title="$t('text.business_category')"
    //-   :subtitle="$t('text.choose_a_category_matches_your_brand')"
    //-   commentField="basic.category"
    //-   :mandatory="true"
    //- )
      v-select(v-model="business_category_id" :menu-props={offsetY: true} :items="passiveBusinessCategories"  filled :readonly="isLocked" :error-messages="$t(errors.basic.business_category_id)")
    slot(name="attach")
</template>
<script>
import Api from '@/plugins/api';
import User from '@models/user';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import TipContainer from '@/components/forms/parts/TipContainer';
import Passives from '@/mixins/PassivesMixin';
import { genFields } from '@/components/forms/fieldGenerator';
import { getStorage } from '@/helpers';

export default {
  components: { GroupContainer, TipContainer },
  mixins: [Passives],
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: { type: Boolean },
    errors: null,
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    return {
      title: 'text.basic_information',
      subtitle: 'text.provide_some_basic_information_and_tell_us_what_you_do',
      users: [],
      managers: [],
      isManagerLoading: false,
    };
  },
  computed: {
    isClient() {
      return this.$user().isClient();
    },
    managerInvite() {
      const inviteBy = getStorage('inviteBy');
      const user = this.$store.state.workers.find((_user) => _user.uuid === inviteBy);
      return {
        user,
        invited: Boolean(user),
      };
    },
    lockedDepartment() {
      return this.isLocked || this.managerInvite.invited;
    },
    config() {
      return this.$store.state.config;
    },

    showDepartments() {
      return this.config.department_id == null;
    },

    // managers() {
    //   return this.$store.state.workers
    //     .filter((x) => {
    //       return this.config.department_id
    //         ? x.isManager() && x.department_id == this.config.department_id
    //         : x.isManager() && x.department_id == this.department_id;
    //     })
    //     .map((x) => {
    //       return {
    //         text: x.name,
    //         value: x.uuid,
    //       };
    //     });
    // },
    ...genFields('projects', [
      'basic.description',
      'basic.brand_name',
      'basic.business_category_id',
      'basic.manager_uuid',
      'basic.department_id',
    ]),
  },
  mounted() {
    if (!this.managers.length) {
      this.getProjectManager();
    }
  },

  watch: {
    brand_name(val) {
      this.$store.commit('forms/projects/UPDATE_STATE', {
        field: 'title',
        value: val && val !== '' ? val : 'Undefined project',
      });
    },
  },
  methods: {
    onFilterWorker(workers) {
      if (!workers.length) return [];

      return workers
        .filter((x) => x.isManager())
        .map((x) => ({
          text: x.name,
          value: x.uuid,
        }));
    },
    async getProjectManager() {
      this.isManagerLoading = true;
      try {
        const { data } = await Api.get('users/manager-active-list');
        const manager = data.map((res) => User.create(res));

        this.managers = this.onFilterWorker(manager);
      } catch (error) {
        console.error(error);
      } finally {
        this.isManagerLoading = false;
      }
    },
  },
};
</script>

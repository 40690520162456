<template>
  <parent v-bind="formBind" @close="$emit('form-cancel')">
    <template v-slot:main>
      <topbar>
        <template v-slot:sticky-mobile>
          <div class="w-full content-mobile-menu">
            <!-- w-full relative md:static md:pr-6 md:pl-sideBar xl:pl-1 mx-auto xl:pr-1 extend-ctl -->
            <div class="flex w-full justify-around md:hidden py-4 bg-main">
              <div
                v-for="(item, index) in tabItems"
                :key="index"
                class="cursor-pointer text-center"
                @click="activeTab = item.tab"
              >
                <div
                  :class="[
                    item.commentTab
                      ? 'accent--text '
                      : item.tab == activeTab
                      ? checkConditionShowErrorTab(item)
                        ? 'text-error'
                        : 'text-primary'
                      : checkConditionShowErrorTab(item)
                      ? 'text-error'
                      : 'text-A0A0A0',
                  ]"
                >
                  {{ index + 1 }}
                </div>
                <div
                  :class="[
                    item.tab == activeTab
                      ? checkConditionShowErrorTab(item)
                        ? 'bg-error w-6 h-2 rounded-xl'
                        : 'bg-primary w-6 h-2 rounded-xl'
                      : checkConditionShowErrorTab(item)
                      ? 'rounded-full bg-error w-2 h-2'
                      : 'rounded-full bg-EBEBEB w-2 h-2',
                  ]"
                  class=""
                ></div>
              </div>
            </div>
            <div class="block md:hidden">
              <v-menu max-height="45vh" offset-y>
                <template v-slot:activator="{ on, value }">
                  <v-list-item
                    :class="{
                      'bg-error bg-opacity-10': checkConditionShowErrorTab(activeTabItem),
                      'accent--text': activeTabItem.commentTab,
                    }"
                    :input-value="true"
                    class="relative"
                    color="primary"
                    v-on="on"
                  >
                    <v-list-item-icon class="my-0" style="align-self: center">
                      <component
                        :is="iconByTab[activeTabItem.iconVisual]"
                        :style="{
                          fill: activeTabItem.commentTab
                            ? '#b64e9a '
                            : checkConditionShowErrorTab(activeTabItem)
                            ? '#FF4242'
                            : '#4262ff',
                        }"
                      >
                      </component>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          :class="{ 'text-error': checkConditionShowErrorTab(activeTabItem) }"
                          class="text-14 font-semibold"
                        >
                          {{ activeTabItem.label }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- <div class="b-r bg-primary"></div> -->
                      <div
                        :class="
                          checkConditionShowErrorTab(activeTabItem) ? 'bg-error' : 'bg-primary'
                        "
                        class="b-r"
                      ></div>
                      <v-icon
                        :color="checkConditionShowErrorTab(activeTabItem) ? 'error' : 'primary'"
                      >
                        {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-list two-line>
                  <v-list-item-group v-model="activeTab" color="primary">
                    <v-list-item
                      v-for="(item, i) in tabItems"
                      :key="i"
                      :class="{
                        'bg-error bg-opacity-10 text-error': checkConditionShowErrorTab(item),
                        'accent--text': item.commentTab,
                        'pointer-events-none': activeTab == item.tab,
                      }"
                      :value="item.tab"
                      class="relative"
                      @change="() => onChangeStep(item, i + 1)"
                    >
                      <v-list-item-icon class="my-0" style="align-self: center">
                        <component
                          :is="iconByTab[item.iconVisual]"
                          :style="{
                            fill: item.commentTab
                              ? '#b64e9a '
                              : checkConditionShowErrorTab(item)
                              ? '#FF4242'
                              : activeTab == item.tab
                              ? '#4262ff'
                              : '#000000',
                          }"
                        >
                        </component>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span
                            :class="{
                              'text-error': item.errorTab,
                              'accent--text': item.commentTab,
                            }"
                            class="text-14 font-semibold"
                          >
                            {{ item.label }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div :class="activeTab == item.tab ? 'bg-primary' : ''" class="b-r"></div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </div>
        </template>
      </topbar>
      <div
        id="panel-menu"
        :class="[drawer ? 'opened' : '']"
        class="panel-mobile hidden md:block xl:hidden"
      >
        <div class="relative h-full w-full">
          <div class="absolute _icon-toggle z-10" @click="togglePanel">
            <IconToggle />
          </div>
          <div class="h-full w-full overflow-y-auto _content">
            <v-list two-line>
              <v-list-item-group v-model="activeTab" color="primary">
                <v-list-item
                  v-for="(item, i) in tabItems"
                  :key="i"
                  :class="{
                    'bg-error bg-opacity-10': checkConditionShowErrorTab(item),
                    'accent--text': item.commentTab,
                    'pointer-events-none': activeTab == item.tab,
                  }"
                  :value="item.tab"
                  @change="() => onChangeStep(item, i + 1)"
                >
                  <v-list-item-icon class="my-0" style="align-self: center">
                    <component
                      :is="iconByTab[item.iconVisual]"
                      :style="{
                        fill: item.commentTab
                          ? '#b64e9a '
                          : checkConditionShowErrorTab(item)
                          ? '#FF4242'
                          : activeTab == item.tab
                          ? '#4262ff'
                          : '#000000',
                      }"
                    >
                    </component>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        :class="{
                          'has-corrections': item.errorTab,
                          'accent--text': item.commentTab,
                        }"
                        class="text-14 font-semibold"
                      >
                        {{ item.label }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div
                      :class="
                        item.errorTab ? 'bg-error' : activeTab == item.tab ? 'bg-primary' : ''
                      "
                      class="b-r"
                    ></div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div
          id="sidebar-menu"
          class="fixed w-siderBar hidden xl:block z-2"
          style="top: 137px; height: calc(100vh - 137px)"
        >
          <div class="h-full overflow-x-hidden overflow-y-auto">
            <v-navigation-drawer
              :expand-on-hover="$vuetify.breakpoint.mobile"
              permanent
              width="100%"
            >
              <v-list two-line>
                <v-list-item-group v-model="activeTab" color="primary">
                  <v-list-item
                    v-for="(item, i) in tabItems"
                    :key="i"
                    :class="{
                      'bg-error bg-opacity-10': checkConditionShowErrorTab(item),
                      'accent--text': item.commentTab,
                      'pointer-events-none': activeTab == item.tab,
                    }"
                    :value="item.tab"
                    class="relative"
                    @change="() => onChangeStep(item, i + 1)"
                  >
                    <v-list-item-icon class="my-0" style="align-self: center">
                      <component
                        :is="iconByTab[item.iconVisual]"
                        :style="{
                          fill: item.commentTab
                            ? '#b64e9a '
                            : item.errorTab && checkConditionShowErrorTab(item)
                            ? '#FF4242'
                            : activeTab == item.tab
                            ? '#4262ff'
                            : '#000000',
                        }"
                      >
                      </component>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          :class="{
                            'has-corrections': checkConditionShowErrorTab(item),
                            'accent--text': item.commentTab,
                          }"
                          class="text-14 font-semibold"
                        >
                          {{ item.label }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div
                        :class="
                          checkConditionShowErrorTab(item)
                            ? 'bg-error'
                            : activeTab == item.tab
                            ? 'bg-primary'
                            : ''
                        "
                        class="b-r"
                      ></div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </div>
        </div>
        <div
          :class="{ 'xl:max-w-contentNoTips': !activeTabItem.tips }"
          class="grid-child-content xl:max-w-content xl:ml-sidebar w-full xl:px-8"
        >
          <div v-if="showDevUrl" class="bga-primary pa-5">
            Host:
            <a :href="modelData.dev_url" target="_blank"> {{ modelData.dev_url }}</a>
            <div v-if="!$user().isClient()" class="password">
              Pass: {{ modelData.dev_password }}
            </div>
          </div>
          <v-tabs
            v-model="activeTab"
            class="tabs-main px-mb md:pr-6 md:pl-sideBar xl:pr-0 xl:pl-0"
            show-arrows
          >
            <v-tabs-items v-model="activeTab" class="bg-main" touchless>
              <template v-for="item in tabItems">
                <v-tab-item
                  v-if="item.show"
                  :key="item.tab"
                  :value="item.tab"
                  class=""
                  reverse-transition="false"
                  transition="false"
                >
                  <component
                    :is="item.content"
                    v-if="item.content && item.tab === activeTabItem.tab"
                    class="pt-5 bg-main"
                    v-bind="getComponentBinds(item)"
                    @save="formBaseActionSave"
                    @submitting="onSubmitting"
                  >
                    <template v-slot:tips-container>
                      <div
                        :class="{ 'content-tips-mobile-empty': fixPaddingAdvices }"
                        class="content-tips-mobile block xl:hidden"
                      >
                        <TipContainer
                          v-if="activeTabItem.tips"
                          :tips="activeTabItem.tips"
                          :video="videoActiveStep"
                        >
                        </TipContainer>
                      </div>
                    </template>
                    <template v-slot:attach>
                      <div v-if="item.attach && item.attach.length > 0">
                        <component
                          :is="attach.content"
                          v-for="(attach, index) in item.attach"
                          :key="index"
                          class="pt-5"
                          is-attached
                          v-bind="getComponentBinds(attach)"
                        >
                          <template v-slot:tips-container></template>
                        </component>
                      </div>
                    </template>
                  </component>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-tabs>
        </div>
        <div
          v-if="activeTabItem.tips"
          class="fixed w-tips right-0 hidden xl:block z-2"
          style="height: calc(100vh - 136px); top: 137px"
        >
          <div class="flex justify-end h-full">
            <v-sheet
              :class="isAllowDepartment ? 'px-4 pt-4 pb-36 ' : 'pa-4'"
              class="h-full overflow-x-hidden overflow-y-auto"
            >
              <TipContainer
                v-if="activeTabItem.tips"
                :tips="activeTabItem.tips"
                :video="videoActiveStep"
              >
              </TipContainer>
            </v-sheet>
          </div>
        </div>
      </div>
    </template>
    <!-- <template v-slot:bottom v-if="!this.$user().isClient()" transition="fab-transition">
      <v-slide-y-reverse-transition>
        <div class="section chat" v-if="isShowChat">
          <tab-chat :model="conversation" :isLocked="isLocked" :errors="errors"> </tab-chat>
        </div>
      </v-slide-y-reverse-transition>
      <div class="chat-toggle" @click="toggleChat">
          <v-icon
            v-if="isShowChat"
            large
            color="white darken-2"
          >
            mdi-close
          </v-icon>
          <v-icon
            v-else
            large
            color="white darken-2"
          >
            mdi-message-text
          </v-icon>
      </div>
    </template> -->
  </parent>
</template>
<script>
import * as PStatus from '@models/projects/statuses';
import fieldGenerator from '@/components/forms/fieldGenerator';
import Button from '@/components/common/buttons/Button';
import VButton from '@/components/common/buttons/VBtn';
import FormMixin from '@front/mixins/FormMixin';
import domain from '@/scripts/domain';

import TabFirst from './wizard/AFirst';

import TabBasic from './wizard/Basic';
import TabCompany from './wizard/Company';

import TabWebsite from './wizard/Website';
import TabWebsiteCom from './wizard/WebsiteCom';

import TabBranding from './wizard/Branding';
import TabStyle from './wizard/Style';

import TabSitemap from './wizard/Sitemap';
import TabContent from './wizard/Content';
import TabCategories from './wizard/Categories';
import TabProducts from './wizard/Products';
import TabPayments from './wizard/Payments';
import TabServices from './wizard/Services';
import TabDelegate from './wizard/Delegate';
import TabFixes from './wizard/Fixes';
import TabChat from './wizard/Chat';
import TabSubmit from './wizard/Submit';
import { getStorage } from '@/helpers';

import IconType from './icons/IconType.vue';
import IconBasic from './icons/IconBasic.vue';
import IconDomain from './icons/IconDomain.vue';
import IconStyle from './icons/IconStyle.vue';
import IconContent from './icons/IconContent.vue';
import IconCategory from './icons/IconCategory.vue';
import IconProduct from './icons/IconProduct.vue';
import IconPayment from './icons/IconPayment.vue';
import IconServices from './icons/IconServices.vue';
import IconSubmit from './icons/IconSubmit.vue';
import IconFixes from './icons/IconFixes.vue';
import IconSiteMap from './icons/IconSM.vue';
import TipContainer from '../../components/forms/parts/TipContainer.vue';
import IconToggle from './icons/IconToggle.vue';
// import TipContainer from '../../components/forms/parts/TipContainer.vue';
import Topbar from '@front/components/navigations/Topbar.vue';
import VBtn from '../../components/common/buttons/VBtn.vue';

export default {
  components: {
    TabChat,
    Topbar,
    // TipContainer
    TipContainer,
    IconToggle,
    VBtn,
  },
  mixins: [FormMixin('projects')],

  data: function () {
    return {
      activeTab: 'create',
      actionTab: 'next',
      // activeTab: 'logo',
      showDelegateBtn: false,
      validateFields: false,
      isSubmitting: false,
      drawer: false,
      isShowChat: false,
      fixPaddingAdvices: false,
      tabsCheckErrors: [],
      stepTabs: [],
    };
  },
  computed: {
    _options() {
      return {
        contentClasses: this.activeTabItem.tips ? 'xl:max-w-content' : 'xl:max-w-contentNoTips',
      };
    },
    position() {
      // style="top: 136px; width: 290px; height: calc(100vh - 136px)"
      return {
        hHeader: 136,
      };
    },
    isShop() {
      return this.create_type == 'shop';
    },
    isFinilize() {
      return this.$store.state.forms.projects.model.finilize;
    },
    isAllowDepartment() {
      return domain.isUK();
    },
    sliderColor() {
      const { activeTab, tabItems = [] } = this;
      const activeItem = tabItems.find((tab) => tab.tab == activeTab);
      if (activeItem) {
        return activeItem.errorTab ? 'error' : 'primary';
      }
      return 'primary';
    },
    validationErrors() {
      const { validationErrors } = this.$store.state.forms.projects;
      return validationErrors || {};
    },
    iconByTab() {
      return {
        IconType: IconType,
        IconBasic: IconBasic,
        IconDomain: IconDomain,
        IconStyle: IconStyle,
        IconSiteMap: IconSiteMap,
        IconContent: IconContent,
        IconCategory: IconCategory,
        IconProduct: IconProduct,
        IconPayment: IconPayment,
        IconServices: IconServices,
        IconSubmit: IconSubmit,
        IconFixes: IconFixes,
      };
    },
    isNextFromContent() {
      if (this.isShop) {
        return 'categories';
      }
      if (this.showServices) {
        return 'services';
      }
      return 'submit';
    },
    isPrevFromSubmit() {
      if (this.showServices) {
        return 'services';
      }
      if (this.isShop) {
        return 'payment';
      }
      return 'content';
    },
    tabItems() {
      const { validationErrors } = this;
      console.error('Debug Log', { validationErrors });
      const tabBrandingName = 'branding';
      const tabCompanyName = 'company';
      const tabLogoName = 'style';
      const errorTab = (tab) => {
        try {
          let error = validationErrors[tab];
          if (error === undefined) return false;
          if (Array.isArray(error)) return !!error.length;

          // Remove key error when value valid
          Object.entries(error).forEach(([key, value]) => {
            if (!value.length) delete error[key];
          });

          if (Object.keys(error).length) {
            if (!this.tabsCheckErrors.includes(tab)) {
              if (tab === tabBrandingName || tab === tabCompanyName) {
                tab = 'basic';
              }
              if (tab === tabLogoName) {
                tab = 'logo';
              }
              this.tabsCheckErrors.push(tab);
            }
            return true;
          }
          return Object.keys(error).length > 0;
        } catch (e) {
          return false;
        }
      };
      const commentTab = (tab) => {
        const comment = this.comments[tab];
        if ([undefined, null, ''].includes(comment)) {
          return false;
        }
        if (Array.isArray(comment)) {
          return comment.length > 0;
        }
        let keys = Object.keys(comment);
        let isExist =
          keys.reduce((acc, k) => {
            if (comment[k]) {
              acc += 1;
            }
            return acc;
          }, 0) > 0;
        return keys.length > 0 && isExist;
      };
      const completedTab = (tab) => {
        return false;
      };

      return [
        {
          tab: 'fix',
          show: this.showFixes,
          label: this.$t('text.project_menu_fixes'),
          icon: 'ic-check-box',
          content: TabFixes,
          errorTab: false,
          commentTab: commentTab('fix'),
          completedTab: completedTab('fix'),
          iconVisual: 'IconFixes',
          tips: 'fixes',
          bindTutorial: 'fixes',
        },
        {
          tab: 'create',
          show: true,
          label: this.$t('text.project_menu_create'),
          icon: 'ic-call-split',
          content: TabFirst,
          next: 'basic',
          prev: false,
          errorTab: false,
          commentTab: commentTab('afirst'),
          completedTab: completedTab('create'),
          iconVisual: 'IconType',
          // tips: 'create',
        },
        {
          tab: 'basic',
          show: true,
          label: this.$t('text.Basic_and_Company'),
          icon: 'ic-fingerprint',
          content: TabBasic,
          bindModel: 'basic',
          next: 'website',
          prev: 'create',
          errorTab: errorTab('basic') || errorTab('company') || errorTab('branding'),
          commentTab: commentTab('basic'),
          completedTab: completedTab('basic'),
          iconVisual: 'IconBasic',
          tips: 'basic',
          attach: [
            {
              bindModel: 'company',
              content: TabCompany,
            },
            {
              bindModel: 'branding',
              content: TabBranding,
            },
          ],
          bindTutorial: 'basic_and_company',
        },
        {
          tab: 'website',
          show: true,
          label: this.$t('text.project_menu_resources'),
          icon: 'ic-globe',
          // content: domain.isCOM() ? TabWebsiteCom : TabWebsite,
          content: domain.isCOM() || domain.isUK() ? TabWebsiteCom : TabWebsite,
          bindModel: 'website',
          next: 'logo',
          prev: 'basic',
          errorTab: errorTab('website'),
          commentTab: commentTab('website'),
          completedTab: completedTab('website'),
          bindTutorial: 'resources',
          iconVisual: 'IconDomain',
          tips: 'resources',
        },
        {
          tab: 'logo',
          show: true,
          // label: this.$t('text.project_menu_logo'),
          label: this.$t('text.project_menu_style'),
          icon: 'ic-visibility',
          // content: TabBranding,
          // bindModel: 'branding',
          content: TabStyle,
          bindModel: 'style',
          next: 'sitemap',
          prev: 'website',
          // errorTab: errorTab('branding') || errorTab('style'),
          errorTab: errorTab('style'),
          commentTab: commentTab('style'),
          completedTab: completedTab('style'),
          attach: [
            // {
            //   bindModel: 'style',
            //   content: TabStyle,
            // },
          ],
          bindTutorial: 'logo_and_style',
          iconVisual: 'IconStyle',
          tips: 'style',
        },
        {
          tab: 'sitemap',
          show: true,
          label: this.$t('text.project_menu_sitemap'),
          icon: 'ic-sitemap',
          content: TabSitemap,
          errors: ['sitemap'],
          next: 'content',
          prev: 'logo',
          errorTab: errorTab('sitemap'),
          commentTab: commentTab('sitemap'),
          completedTab: completedTab('sitemap'),
          bindTutorial: 'sitemap',
          iconVisual: 'IconSiteMap',
          tips: 'sitemap',
        },
        {
          tab: 'content',
          show: true,
          label: this.$t('text.project_menu_content'),
          icon: 'ic-view-day',
          content: TabContent,
          bindModel: 'sitemap',
          next: this.isNextFromContent,
          prev: 'sitemap',
          class: ['no-highlight'],
          errors: ['sc_title', 'sc_content', 'content'],
          // errors: ['sc_content', 'content'],
          errorTab: errorTab('content'),
          commentTab: commentTab('content'),
          completedTab: completedTab('content') && completedTab('sc_title'),
          bindTutorial: 'content',
          iconVisual: 'IconContent',
          tips: 'content',
        },
        {
          tab: 'categories',
          show: this.isShop,
          label: this.$t('text.project_menu_categories'),
          icon: 'ic-make-group',
          content: TabCategories,
          errors: ['categories'],
          next: 'products',
          prev: 'content',
          class: ['no-highlight'],
          errorTab: errorTab('categories'),
          commentTab: commentTab('categories'),
          completedTab: completedTab('categories'),
          bindTutorial: 'categories',
          iconVisual: 'IconCategory',
          tips: 'categories',
        },
        {
          tab: 'products',
          show: this.isShop,
          label: this.$t('text.project_menu_products'),
          icon: 'ic-shopping-basket',
          content: TabProducts,
          bindModel: 'categories',
          next: 'payment',
          prev: 'categories',
          class: ['no-highlight'],
          errorTab: errorTab('products'),
          commentTab: commentTab('products'),
          completedTab: completedTab('categories'),
          bindTutorial: 'products',
          iconVisual: 'IconProduct',
          tips: 'products',
        },
        {
          tab: 'payment',
          show: this.isShop,
          label: domain.isPL() ? 'Płatności i dostawa' : this.$t('text.project_menu_payment'),
          icon: 'ic-payment',
          content: TabPayments,
          bindModel: 'payment',
          next: this.showServices ? 'services' : 'submit',
          prev: 'products',
          errorTab: errorTab('payment'),
          commentTab: commentTab('payment'),
          completedTab: completedTab('payment'),
          bindTutorial: 'payments',
          iconVisual: 'IconPayment',
          tips: 'payments',
        },
        {
          tab: 'services',
          show: this.showServices,
          label: this.$t('text.navigation_tab_services'),
          icon: 'ic-attach-money',
          content: TabServices,
          bindModel: 'extra_services',
          next: 'submit',
          prev: this.isShop ? 'payment' : 'content',
          iconVisual: 'IconServices',
          tips: 'services',
        },
        {
          tab: 'submit',
          show: this.$user().isClient(),
          label: this.$t('text.project_menu_submit'),
          icon: 'ic-flight',
          content: TabSubmit,
          next: false,
          prev: this.isPrevFromSubmit,
          errorTab: errorTab('submit'),
          commentTab: false,
          completedTab: completedTab('submit'),
          iconVisual: 'IconSubmit',
          // tips: 'submit',
        },
        {
          tab: 'options',
          show: !this.$user().isClient(),
          // show: true,
          label: this.$t('text.project_menu_options'),
          icon: 'ic-share',
          content: TabDelegate,
          bindModel: 'delegate',
          errorTab: errorTab('options'),
          commentTab: false,
          completedTab: completedTab('options'),
          iconVisual: 'IconFixes',
          tips: 'options',
        },
        //{ tab:'chat', show: !this.$user().isClient(), label: 'Chat', icon: 'ic-chat-bubble-outline', click: this.chatClick },
      ].filter((item) => item.show);
    },

    showServices() {
      return process.env.VUE_APP_EXTRA_SERVICES ?? false;
    },

    showDevUrl() {
      if (this.$user().isClient())
        return this.modelData.dev_url && this.status_id >= PStatus.PROJECT_READY_FOR_REVIEW;
      else return this.modelData.dev_url && this.status_id >= PStatus.PROJECT_READY_FOR_DEV;
    },

    activeTabItem() {
      // return this.tabItems.find((x) => x.tab == this.activeTab);

      return this.tabItems.find((x) => {
        if (x.tab === this.activeTab) {
          this.pushListTabsErrors(this.stepTabs, x);
          return x;
        }
      });
    },
    videoActiveStep() {
      const locale = this.$i18n.locale;
      const tutorials = this.$store.state.forms.projects?.tutorials[locale];
      const videoByStep = tutorials ? tutorials[this.activeTabItem?.bindTutorial] : null;
      return videoByStep;
    },
    showFixes() {
      return this.status_id >= PStatus.PROJECT_READY_FOR_REVIEW;
    },
    isFixesDisabled() {
      return this.status_id !== PStatus.PROJECT_READY_FOR_REVIEW || !this.$user().isClient();
    },

    isCompleteDisabled() {
      return (
        this.status_id !== PStatus.PROJECT_READY_FOR_REVIEW &&
        this.status_id !== PStatus.PROJECT_FIX_READY_FOR_REVIEW
      );
    },

    formDelegateBtn() {
      let isMobile = this.$vuetify.breakpoint.mobile;
      return this.showDelegateBtn
        ? {
            label: 'Save options',
            icon: 'ic-share',
            evinput: this.saveDelegations,
            component: Button,
            loading: this.$store.getters.getCoordinate('saveOptions'),
            filled: true,
            small: true,
            color: '#4262FF',
            order: 1,
            show: true,
            class: isMobile ? 'w-100 my-1' : '',
          }
        : false;
    },
    breakpoint() {
      // console.error('breakpoint ', this.$vuetify.breakpoint);
      return this.$vuetify.breakpoint;
    },
    breakpoint1() {
      return JSON.stringify(this.$vuetify.breakpoint).toString();
    },
    isTabFix() {
      return this.activeTab === 'fix';
    },
    formActions() {
      let isMobile = this.$vuetify.breakpoint.xs;
      let nonClient = {
        cancel: false,
        //save: false,
        save_comments: {
          label: this.$t('text.save_comments'),
          key: 'save_comments',
          icon: 'ic-done',
          evinput: this.saveComments,
          component: Button,
          filled: true,
          small: true,
          color: '#4262FF',
          class: isMobile ? 'w-100 my-1' : '',
          order: 99,
          loading: this.$store.getters.getCoordinate('saveComment'),
          show: true,
        },
        delegate: this.formDelegateBtn,
      };

      if (!this.$user().isSuperAdmin()) nonClient.save = false;

      if (this.$user().isDesigner()) {
        nonClient.save_comments = false;
        return nonClient;
      } else if (!this.$user().isClient()) return nonClient;

      // Client actions
      if (this.is_locked) {
        return {
          cancel: false,
          save: false,
          fixes: {
            label: this.$t('text.send_fixes'),
            key: 'fixes',
            icon: 'ic-done',
            evinput: this.saveFixes,
            component: Button,
            flat: true,
            small: true,
            color: 'primary',
            order: 99,
            disabled: this.isFixesDisabled,
            show: this.isTabFix,
            tabActive: this.activeTab,
          },
          complete: {
            label: this.$t('text.complete_application'),
            key: 'complete',
            // class: 'theme--gradient',
            icon: 'ic-done',
            evinput: this.launch,
            component: VButton,
            color: 'primary',
            order: 1,
            disabled: this.isCompleteDisabled,
            show: this.isTabFix,
            tabActive: this.activeTab,
          },
        };
      }
      return {
        prev: {
          label: this.$t('text.previous'),
          key: 'prev',
          icon: 'ic-chevron-left',
          evinput: this.previousClick,
          iconLeft: true,
          component: VButton,
          dark: true,
          small: false,
          color: 'primary',
          order: 1,
          disabled: !this.canPrev,
          class: isMobile ? 'my-1' : '',
          show: !this.isTabFix,
          tabActive: this.activeTab,
        },
        next: {
          label: this.$t('text.next'),
          key: 'next',
          icon: 'ic-chevron-right',
          evinput: this.nextClick,
          iconRight: true,
          component: VButton,
          dark: true,
          small: false,
          color: 'primary',
          order: 100,
          class: isMobile ? 'reversed my-1' : 'reversed',
          disabled: !this.canNext,
          show: !this.isTabFix,
          tabActive: this.activeTab,
        },
      };
    },

    canPrev() {
      return !!this.activeTabItem?.prev;
    },

    canNext() {
      return !!this.activeTabItem?.next;
    },

    isLocked() {
      if (this.$user().isSuperAdmin()) return false;

      return this.is_locked || !this.$user().isClient() ? true : false;
    },
    stepActive() {
      const { activeTab, tabItems = [] } = this;
      return tabItems.findIndex((t) => t.tab === activeTab) + 1 || 1;
    },
    comments() {
      return this.$store.state.forms.projects.fields.comments;
    },
    isHistoryChanged() {
      return this.$store.getters['isHistoryChanged'];
    },
  },

  methods: {
    formBaseActionSave() {
      if (this.$store.getters.getCoordinate('saveBase')) {
        return console.log('%c doing update, Please wait...!', 'background: #222; color: #bada55');
      }
      this.$store.commit('REMOVE_HISTORY_CHANGES');
      if (this.validateFields && this.formRef.validate()) {
        this.formDispatch('VALIDATE_FIELDS').then(() => {
          this.formEmitData();
        });
      } else if (!this.validateFields) {
        this.formEmitData();
      }
      // this.formDispatch('VALIDATE_FIELDS')
      //   .then(() => {

      //   })
      //   .catch(() => {
      //     // console.error(this.$store.state, this.$store.getters)
      //   });
    },
    togglePanel() {
      this.drawer = !this.drawer;
    },
    onSubmitting() {
      this.isSubmitting = true;
      // this.formDispatch('FINILIZE')
      this.tabItems.forEach((item) => {
        this.stepTabs.push(item.tab);
      });
    },
    fetchData(id) {
      this.repoDispatch('API_FIND', id);
    },

    getComponentBinds(tabItem) {
      // no-highlight
      const locale = this.$i18n.locale;
      const tutorials = this.$store.state.forms.projects?.tutorials[locale];
      const videoByStep = tutorials ? tutorials[tabItem?.bindTutorial] : null;
      return {
        model: tabItem.bindModel ? this[tabItem.bindModel] : {},
        isLocked: this.isLocked,
        errors: this.errors,
        tabItems: this.tabItems,
        activeTab: this.activeTab,
        class: tabItem.class || [],
        videoByStep: videoByStep,
      };
    },

    async onChangeStep(tab) {
      if (this.activeTab === tab.tab) {
        return;
      }
      try {
        this.activeTab = tab.tab;
        await this.formDispatch('ACTIVE_TAB', this.activeTab);
        // await this.formDispatch('VALIDATE_FIELDS')
      } catch (e) {
        console.log(e);
      } finally {
        this.showDelegateBtn = tab.tab == 'options' ? true : false;
        // this.isSubmitting = true
      }
    },
    saveComments() {
      this.formDispatch('SAVE_COMMENTS');
    },

    saveFixes() {
      this.formDispatch('SEND_FIXES');
    },

    saveDelegations() {
      this.formDispatch('DELEGATE');
    },

    launch() {
      this.formDispatch('LAUNCH');
    },

    complete() {
      this.formDispatch('COMPLETE');
    },

    // add tabs errors
    pushListTabsErrors(stepTabs, activeTab) {
      if (!stepTabs.includes(activeTab.tab)) {
        if (activeTab.tab === 'branding' || activeTab.tab === 'company') {
          activeTab.tab = 'basic';
        }
        this.stepTabs.push(activeTab.tab);
      }
    },

    previousClick() {
      if (!this.canPrev || this.$store.getters.getCoordinate('saveBase')) return;

      this.formBaseActionSave();
      this.pushListTabsErrors(this.stepTabs, this.activeTabItem);
      this.actionTab = 'prev';
    },

    nextClick() {
      if (!this.canNext || this.$store.getters.getCoordinate('saveBase')) return;
      this.formBaseActionSave();
      this.pushListTabsErrors(this.stepTabs, this.activeTabItem);
      this.actionTab = 'next';
    },
    onActionChangeTab(action) {
      if (action === 'next') {
        this.activeTab =
          !this.isShop && this.activeTab === 'content'
            ? this.isNextFromContent
            : this.activeTabItem.next;
      }

      if (action === 'prev') {
        this.activeTab =
          !this.isShop && this.activeTab === 'submit'
            ? this.isPrevFromSubmit
            : this.activeTabItem.prev;
      }
    },

    chatClick() {
      console.log('chat has been clicked...');
    },
    preventNav(e) {
      e.preventDefault();
    },
    checkFromInvite() {
      if (this.$user().isClient()) {
        const isEdit = this.$store.state.forms.projects.is_edit;
        if (!isEdit) {
          const inviteBy = getStorage('inviteBy');
          if (!inviteBy) {
            return;
          }
          console.error(this.$store.state.forms.projects.fields.basic);
          const user = this.$store.state.workers.find((_user) => _user.uuid == inviteBy);
          if (!user) {
            return;
          }
          this.$store.state.forms.projects.fields.basic.manager_uuid = user?.uuid ?? null;
          this.$store.state.forms.projects.fields.basic.department_id = user?.department_id ?? null;
          return user ? user.uuid : null;
        }
      }
    },
    checkPaymentCurrencyPolish() {
      let currency_id = this.$store.state.forms.projects.fields.payment.currency_id;

      const invalid = [null, undefined, '', false];
      if (domain.isPL()) {
        if (this.$store.state.forms.projects.is_edit == false) {
          this.$store.state.forms.projects.fields.payment.currency_id = 4;
          console.error(
            'SET_CURRECY_1',
            this.$store.state.forms.projects.fields.payment.currency_id
          );
        } else if (
          this.$store.state.forms.projects.is_edit == false &&
          [null, undefined, '', false].includes(currency_id)
        ) {
          this.$store.state.forms.projects.fields.payment.currency_id = 4;
          console.error(
            'SET_CURRECY_2',
            this.$store.state.forms.projects.fields.payment.currency_id
          );
        } else if (
          this.$store.state.forms.projects.is_edit == true &&
          [null, undefined, '', false].includes(currency_id)
        ) {
          this.$store.state.forms.projects.fields.payment.currency_id = 4;
          console.error(
            'SET_CURRECY_3',
            this.$store.state.forms.projects.fields.payment.currency_id
          );
        }
      } else if (invalid.includes(currency_id)) {
        let department_id = this.$store.state.config.department_id;
        if (department_id) {
          this.$store.state.forms.projects.fields.payment.currency_id = department_id;
        } else {
          this.$store.state.forms.projects.fields.payment.currency_id = 1;
        }
      }
    },
    saveFormHandler() {
      this.checkPaymentCurrencyPolish();
      this.formBaseActionSave();
    },
    listenActionChangeTab() {
      this.onActionChangeTab(this.actionTab);
    },
    toggleChat() {
      this.isShowChat = !this.isShowChat;
    },
    checkConditionShowErrorTab(item) {
      return (
        item.errorTab && this.tabsCheckErrors.includes(item.tab) && this.stepTabs.includes(item.tab)
      );
    },
  },
  mounted() {
    this.formDispatch('ACTIVE_TAB', this.activeTab);
    this.checkPaymentCurrencyPolish();
    this.checkFromInvite();
    this.$root.$on('save-form', () => {
      this.checkPaymentCurrencyPolish();
      this.formBaseActionSave();
    });
    this.$root.$on('cancel-form', this.formBaseActionCancel);

    this.$watch('isHistoryChanged', (isHistoryChanged) => {
      console.error('isHistoryChanged', isHistoryChanged);
      if (isHistoryChanged) {
        window.onbeforeunload = function (e) {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    });
    this.$eventBus.$on('save-form', this.saveFormHandler);
    this.$eventBus.$on('change-tab', this.listenActionChangeTab);
  },
  beforeMount() {},
  beforeDestroy() {
    window.onbeforeunload = null;
  },
  destroyed() {
    this.$root.$off('save-form', this.saveFormHandler);
    this.$root.$off('cancel-form', this.formBaseActionCancel);

    this.$eventBus.$off('save-form', this.saveFormHandler);
    this.$eventBus.$off('change-tab', this.listenActionChangeTab);
  },
  watch: {
    activeTabItem(val) {
      this.formDispatch('ACTIVE_TAB', val.tab);

      let showAdvices = ['create', 'services', 'submit'];
      if (showAdvices.includes(this.activeTab)) {
        this.fixPaddingAdvices = true;
      }
    },
  },
};
</script>
<style lang="scss">
.small-label {
  font-size: 13.6px;
}

.tabs-main.v-tabs {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    [role='tablist'] {
      //display: none;
    }
  }
  @media #{map-get($display-breakpoints, 'screen-only')} {
    & > [role='tablist'] {
      display: none;
    }
  }
}

.tab-commented {
  .v-icon {
    color: #b64e9a !important;
  }
}

.step-hover {
  &:hover {
    background: none !important;
  }

  .v-ripple__container {
    display: none !important;
  }
}

.b-r {
  width: 4px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>

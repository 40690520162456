<template>
   <svg width="32" height="102" viewBox="0 0 32 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1_31884)">
         <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5 4C5 7 5 11 10.5 21C12.2812 24.2386 14.5344 26.6905 16.767 29.1201C21.4283 34.1925 26 39.1675 26 51C26 62.8325 21.4283 67.8075 16.767 72.8799C14.5344 75.3095 12.2812 77.7614 10.5 81C5 91 5 95 5 98V51V4Z"
            fill="#FF9900" />
         <path
            d="M13.1699 46.0001C12.9837 46.1874 12.8791 46.4409 12.8791 46.7051C12.8791 46.9692 12.9837 47.2227 13.1699 47.4101L16.7099 51.0001L13.1699 54.5401C12.9837 54.7274 12.8792 54.9809 12.8792 55.2451C12.8792 55.5092 12.9837 55.7627 13.1699 55.9501C13.2629 56.0438 13.3735 56.1182 13.4954 56.1689C13.6172 56.2197 13.7479 56.2458 13.8799 56.2458C14.012 56.2458 14.1427 56.2197 14.2645 56.1689C14.3864 56.1182 14.497 56.0438 14.5899 55.9501L18.8299 51.7101C18.9237 51.6171 18.9981 51.5065 19.0488 51.3846C19.0996 51.2628 19.1257 51.1321 19.1257 51.0001C19.1257 50.868 19.0996 50.7373 19.0488 50.6155C18.9981 50.4936 18.9237 50.383 18.8299 50.2901L14.5899 46.0001C14.497 45.9063 14.3864 45.8319 14.2645 45.7812C14.1427 45.7304 14.012 45.7043 13.8799 45.7043C13.7479 45.7043 13.6172 45.7304 13.4954 45.7812C13.3735 45.8319 13.2629 45.9063 13.1699 46.0001Z"
            fill="white" />
      </g>
      <defs>
         <filter id="filter0_d_1_31884" x="0" y="0" width="32" height="102" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
               result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.627451 0 0 0 0 0.627451 0 0 0 0 0.627451 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_31884" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_31884" result="shape" />
         </filter>
      </defs>
   </svg>



</template>
<script>
export default {}
</script>


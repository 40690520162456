import Vue from 'vue';
import away from 'away';
import store from '@/store';
import * as ROLES from '@constant/roles';

/**
 * @var{integer} IDLE_TIME Max allowed minutes for user to stay idle before log out.
 */
const IDLE_TIME = 30;

/**
 * @var{Object} AUTH_BASIC_HEADERS The options to pass into a Vue-resource http call. Includes
 *    the headers used for login and token refresh and emulateJSON flag since we are hitting an
 *    OAuth server directly that can't handle application/json.
 */
const AUTH_BASIC_HEADERS = {
  emulateJSON: true,
};

const REFRESH_TOKEN_URL = '';

/**
 * Auth Plugin
 *
 * Handles login and token authentication using OAuth2.
 */
export default {
  /**
   * Install the Auth class.
   *
   * Creates a Vue-resource http interceptor to handle automatically adding auth headers
   * and refreshing tokens. Then attaches this object to the global Vue (as Vue.auth).
   *
   * @param {Object} Vue The global Vue.
   * @return {void}
   */
  install(Vue) {
    const $this = this;
    Vue.prototype.$auth = Vue.auth = this;
    Vue.prototype.$user = function () {
      return $this.getUser();
    };

    const timer = away(IDLE_TIME * 60 * 1000);
    timer.on('idle', () => {
      const user = $this.getUser();
      if (user && user.user_type_id !== ROLES.ROLE_CLIENT) {
        store.dispatch('auth/LOGOUT');
      }
    });

    // window.addEventListener('keydown', (e) => {
    //   if (e.keyCode === 76) {
    //     store.dispatch('auth/LOCK')
    //   }
    // })
  },

  /**
   * Set the Authorization header on a Vue-resource Request.
   *
   * @param {Request} request The Vue-Resource Request instance to set the header on.
   * @return {void}
   */
  setAuthHeaders(request) {
    const headers = this.getAuthHeaders();
    Object.keys(headers).forEach((key) => {
      request.headers.set(key, headers[key]);
    });
  },

  getAuthHeaders() {
    return {
      Authorization: 'Bearer ' + store.state.auth.access_token,
    };
  },

  /**
   * Set authenticated user
   *
   * @param {User} user
   */
  setUser(user) {
    this.user = user;
  },

  /**
   * Get authenticated user
   *
   * @return {User}
   */
  getUser() {
    return this.user;
  },

  /**
   * Retry the original request.
   *
   * Let's retry the user's original target request that had recieved a invalid token response
   * (which we fixed with a token refresh).
   *
   * @param {Request} request The Vue-resource Request instance to use to repeat an http call.
   * @return {Promise}
   */
  _retry(request) {
    this.setAuthHeader(request);

    return Vue.http(request)
      .then((response) => {
        return response;
      })
      .catch((response) => {
        return response;
      });
  },

  /**
   * Refresh the access token
   *
   * Make an ajax call to the OAuth2 server to refresh the access token (using our refresh token).
   *
   * @private
   * @param {Request} request Vue-resource Request instance, the original request that we'll retry.
   * @return {Promise}
   */
  _refreshToken(request) {
    return Vue.http
      .post(REFRESH_TOKEN_URL, {}, AUTH_BASIC_HEADERS)
      .then((response) => {
        store.commit('auth/UPDATE_ACCESS_TOKEN', response.body.access_token);
        return this._retry(request);
      })
      .catch((errorResponse) => {
        if (this._isInvalidToken(errorResponse)) {
          this.logout();
        }
        return errorResponse;
      });
  },

  /**
   * Check if the Vue-resource Response is an invalid token response.
   *
   * @private
   * @param {Response} response The Vue-resource Response instance received from an http call.
   * @return {boolean}
   */
  _isInvalidToken(response) {
    const status = response.status;
    const error = response.data ? response.data.message : 'Unkown Error';

    return (
      (status === 401 && ['invalid_credentials', 'invalid_pin'].indexOf(error) === -1) ||
      error === 'invalid_token'
    );
  },
};

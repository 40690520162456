<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="red" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.9202 13.62C18.8726 13.4973 18.8012 13.3851 18.7102 13.29L15.7102 10.29C15.5219 10.1017 15.2665 9.99593 15.0002 9.99593C14.7339 9.99593 14.4785 10.1017 14.2902 10.29C14.1019 10.4783 13.9961 10.7337 13.9961 11C13.9961 11.2663 14.1019 11.5217 14.2902 11.71L15.5902 13H7.00019C6.73497 13 6.48062 12.8947 6.29308 12.7071C6.10554 12.5196 6.00019 12.2652 6.00019 12V3.41002L7.29019 4.71002C7.38315 4.80375 7.49375 4.87814 7.61561 4.92891C7.73747 4.97968 7.86817 5.00582 8.00019 5.00582C8.1322 5.00582 8.2629 4.97968 8.38476 4.92891C8.50662 4.87814 8.61722 4.80375 8.71019 4.71002C8.80391 4.61706 8.87831 4.50645 8.92908 4.3846C8.97985 4.26274 9.00598 4.13203 9.00598 4.00002C9.00598 3.86801 8.97985 3.7373 8.92908 3.61544C8.87831 3.49358 8.80391 3.38298 8.71019 3.29002L5.71019 0.290018C5.61508 0.198978 5.50294 0.127613 5.38019 0.0800184C5.13672 -0.0199996 4.86365 -0.0199996 4.62019 0.0800184C4.49743 0.127613 4.38529 0.198978 4.29019 0.290018L1.29019 3.29002C1.10188 3.47832 0.996094 3.73372 0.996094 4.00002C0.996094 4.13188 1.02207 4.26245 1.07253 4.38427C1.12299 4.50609 1.19695 4.61678 1.29019 4.71002C1.38342 4.80326 1.49411 4.87722 1.61594 4.92768C1.73776 4.97814 1.86833 5.00411 2.00019 5.00411C2.26649 5.00411 2.52188 4.89832 2.71019 4.71002L4.00019 3.41002V12C4.00019 12.7957 4.31626 13.5587 4.87887 14.1213C5.44147 14.6839 6.20454 15 7.00019 15H15.5902L14.2902 16.29C14.1965 16.383 14.1221 16.4936 14.0713 16.6154C14.0205 16.7373 13.9944 16.868 13.9944 17C13.9944 17.132 14.0205 17.2627 14.0713 17.3846C14.1221 17.5065 14.1965 17.6171 14.2902 17.71C14.3831 17.8037 14.4937 17.8781 14.6156 17.9289C14.7375 17.9797 14.8682 18.0058 15.0002 18.0058C15.1322 18.0058 15.2629 17.9797 15.3848 17.9289C15.5066 17.8781 15.6172 17.8037 15.7102 17.71L18.7102 14.71C18.8012 14.6149 18.8726 14.5028 18.9202 14.38C19.0202 14.1366 19.0202 13.8635 18.9202 13.62V13.62Z" />
    </svg>
</template>
<script>
export default {}
</script>


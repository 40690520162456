<template>
  <div class="loader">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
    }
  }
}
</script>

<style lang="scss">
  .loader {
    display: flex;
    flex-grow: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    & > div {
      width: 18px;
      height: 18px;
      background-color: $cl-primary;
      margin: 10px;
      border-radius: 100%;
      display: inline-block;
      //box-shadow: 0px 0px 5px #4d641b;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
 
</style>
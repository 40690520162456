<template lang="pug">
  v-form.form(ref="form")
    .w-full
      .d-flex.flex-wrap.fill-height.w-100(:elevation="0" flat color="white")
        v-toolbar.content-pad(v-if="showToolbar" elevation="0" rounded v-bind="toolbarBinds")
          h4.form-title {{ $t(title) }}
          v-spacer
          toolbar-btn(icon="ic-close" @click="$emit('close')")

        .form-subtitle(v-if="showSubtitle") {{ $t(subtitle) }}
        //- v-spacer
        slot(name="main")
        .mt-auto.w-full
          div(:class="[subForm  ? ' ' : 'px-mb md:pl-sideBar md:pr-6 xl:max-w-content xl:ml-sidebar xl:px-14 w-full', _options.contentClasses ? _options.contentClasses : '']")
            .section.actions-footer
              .d-flex.space-x-2.flex-wrap.justify-between
                slot(name="section-actions")
                  template(v-for="(action, index) in formActions")
                    template(v-if="action.show")
                      component(
                        :key="index"
                        :is="action.component"
                        v-on="action._events"
                        v-bind="action._props"
                      )
        slot(name="bottom")
</template>
<script>
import ToolbarBtn from '@/components/tables/parts/ToolbarBtn';
import { VSpacer } from 'vuetify/lib';

export default {
  components: { ToolbarBtn, VSpacer },
  props: {
    formActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: { type: String },
    subtitle: { type: String },
    formElevation: { type: Number, default: 0 },
    showToolbar: { type: Boolean, default: false },
    showSubtitle: { type: Boolean, default: true },
    toolbarDark: { type: Boolean, default: true },
    toolbarTheme: { type: String, default: 'theme--gradient' },
    subForm: { type: Boolean, default: false },
    _options: { type: Object, default: () => ({}) },
  },
  computed: {
    toolbarBinds() {
      return {
        class: this.toolbarTheme,
        dark: this.toolbarDark,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.flash-box {
  //- background: #fff;
  border-radius: 20px;
  //- border: solid 1px red;
}

.actions-footer {
  //- background: #FFFFFF;
  //- padding: 25px 25px;
  //- padding: 10px 25px;
  position: relative;
  z-index: 2;
  z-index: 3;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    //- padding: 25px 15px;
  }
}
</style>

<template lang="pug">
  .register
    div.tform.mb-3 {{ $t('text.sign_up') }}
    div.label {{ $t('text.company') }}
    v-text-field(
      name="company"
      :placeholder="$t('text.company')"
      :error-messages="$t(errors.company)"
      v-model="company"
    )
    div.label  {{ $t('text.email_address') }}
    v-text-field(
      name="email"
      :placeholder="$t('text.email_address')"
      :error-messages="$t(errors.email)"
      v-model="email"
    )
    div.label {{ $t('text.password') }}
    v-text-field(
      name="password"
      :placeholder="$t('text.password')"
      type="password"
      :error-messages="$t(errors.password)"
      v-model="password"
    )
    div.label {{ $t('text.repeat_password') }}
    v-text-field(
      name="password_confirmation"
      :placeholder="$t('text.repeat_password')"
      type="password"
      :error-messages="$t(errors.password_confirmation)"
      v-model="password_confirmation"
    )
    div.mb-3
      vue-recaptcha(:sitekey="siteKey" :loadRecaptchaScript="loadRecaptchaScript" @verify="markRecaptchaAsVerified")
      div.error--text(ref = "error_message_recaptcha") {{ errorRecaptcha }}
    v-btn.submit(color="primary" block depressed=true hide-details large @click="register" :loading="isLoading" :disabled="isLoading") {{ $t('text.register') }}
    .bottom.text-center.pt-2
      div
        | {{ $t('text.already_have_an_account') }}
        router-link.primary--text.label(to="login") &nbsp; {{ $t('text.login_now') }}
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';
import Version from '@front/components/common/Version.vue';
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    Version,
    VueRecaptcha,
  },
  mixins: [FormMixin('registration')],
  data() {
    return {
      checkRecaptcha: false,
      errorRecaptcha: '',
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.auth.is_preloading;
    },

    policyTexts() {
      return {
        terms_of_services:
          '<a href="' +
          this.$t('text.url.terms') +
          '" class="primary--text" target="_blank">' +
          this.$t('text.terms_of_services') +
          '</a>',
        pricacy_policy:
          '<a href="' +
          this.$t('text.url.policy') +
          '" class="primary--text" target="_blank">' +
          this.$t('text.privacy_policy') +
          '</a>',
      };
    },

    siteKey() {
      return process.env.VUE_APP_DISABLED_RECAPCHA
        ? ''
        : process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY;
    },

    loadRecaptchaScript() {
      return !process.env.VUE_APP_DISABLED_RECAPCHA;
    },
  },

  methods: {
    register() {
      if (this.isLoading) {
        return;
      }
      if (process.env.VUE_APP_DISABLED_RECAPCHA) {
        this.formDispatch('REGISTER');
      } else {
        if (this.checkRecaptcha && !this.isLoading) {
          this.formDispatch('REGISTER');
        } else {
          this.errorRecaptcha = this.$t('text.check_recaptcha_message_error');
        }
      }
    },

    enterDown(event) {
      if (event.keyCode === 13) this.register();
    },

    markRecaptchaAsVerified(response) {
      this.errorRecaptcha = '';
      this.checkRecaptcha = response;
    },
  },

  mounted() {
    window.addEventListener('keypress', this.enterDown);
  },

  destroyed() {
    window.removeEventListener('keypress', this.enterDown);
  },
};
</script>

<template lang="pug">
  div
    v-data-table(
      :headers="tableHeaders",
      :items="tableItems",
      :class="classes",
      :loading="tableFetching",
      :items-per-page="tableFilter.per_page"
      :hide-default-footer="!$vuetify.breakpoint.xs",
      mobile-breakpoint="900"
    )
      template(v-slot:top)
        slot(name="table-actions")
          v-toolbar(elevation="0" rounded v-bind="toolbarBinds")
            v-toolbar-title {{ title }}
            v-spacer
            component(
              v-for="(action, index) in tableActions"
              :key="index"
              :is="action.component"
              v-on="action._events"
              v-bind="action._props"
            )
        slot(name="table-top")
        .pa-3
          div.rules-filter
            .rules-filter--item(v-for="(action, index) in tableActionsFilters", :key="index")
              component(
                :is="action.component"
                v-on="action._events"
                v-bind="action._props"
              )
      template(v-slot:item.name="{ item }")
        slot(name="item-name" :item="item")
          | {{ item.name }}
      template(v-slot:item.username="{ item }")
        slot(name="username" :item="item")
          div
            | {{ item.username }}
            p(v-if="isHideInDropdownList(item)" ).mb-0.text-xs.text-red-500
              | ({{ $t('text.hide_user_in_dropdown_list') }})
      template(v-slot:item.status_name="{ item }")
        select-option(v-model="item"
          :status_id.sync="item.status_id"
          :status_name.sync="item.status_name"
          :statusID="item.status_id"
          :statusName="item.status_name"
          :projects="tableItems"
          :projectUUID="item.uuid"
        )
      template(v-slot:item.department_id="{ item }")
        slot(name="department_id" :item="item")
          | {{ renderDepartmentText(item.department_id) }}
      template(v-slot:item.action="{ item }")
        slot(name="item-actions" :item="item")
          v-icon(
            v-for="(act, index) in tableItemActions"
            :key="index"
            class="mr-2" small
          @click="act.click(item, self)"
          ) {{ act.icon }}
          slot(name="item-actions-append" :item="item")
      template(v-slot:no-data)
        .px-5.py-5.uppercase {{ $t('text.no_data') }}
    template(v-if="!$vuetify.breakpoint.xs")
      .pagination.d-flex.justify-space-between
        .pagination__select.d-flex
          template(v-if="!$vuetify.breakpoint.xs")
            span.text-caption {{ $t('text.rows_per_page') }}
          v-select.small(
            :menu-props={ offsetY: true }
            persistent-hint,
            :items="itemsPerPage",
            v-model="tableFilter.per_page",
          )
        .d-flex.align-center
          template(v-if="!$vuetify.breakpoint.xs")
            span.text-caption {{ paginationText }}
          v-pagination(
            v-model="tableFilter.page",
            :length="tablePagination.total_pages"
            :total-visible="5"
          )
</template>
<script>
import SelectOption from '@/components/tables/parts/SelectOption';
import {
  DEPARTMENT_ID_BG,
  DEPARTMENT_ID_COM,
  DEPARTMENT_ID_DEV,
  DEPARTMENT_ID_ES,
  DEPARTMENT_ID_HU,
  DEPARTMENT_ID_LIVE,
  DEPARTMENT_ID_LT,
  DEPARTMENT_ID_PL,
  DEPARTMENT_ID_RO,
  DEPARTMENT_ID_RU,
  DEPARTMENT_ID_LV,
  DEPARTMENT_ID_UK,
} from '@/constants/departments';

export default {
  components: { SelectOption },
  props: {
    tableFilter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableActionsFilters: {
      default: () => {
        return {};
      },
    },
    tablePagination: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableHeaders: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableActions: {
      type: Array,
      default: () => {
        return {};
      },
    },
    tableItemActions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    elevation: {
      type: Number,
      default: 1,
    },
    tableFetching: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    modName: {
      type: String,
      default: null,
    },
    toolbarDark: { type: Boolean, default: true },
    toolbarTheme: { type: String, default: 'theme--gradient' },
  },

  data() {
    return {
      itemsPerPage: [10, 20, 30],
    };
  },

  computed: {
    paginationText() {
      const startItem = (this.tableFilter.page - 1) * this.tableFilter.per_page + 1;
      const endItem =
        this.tableFilter.page * this.tableFilter.per_page > this.tablePagination.total
          ? this.tablePagination.total
          : this.tableFilter.page * this.tableFilter.per_page;
      return startItem + '-' + endItem + ' of ' + this.tablePagination.total;
    },
    self() {
      return this;
    },
    classes() {
      //console.log('classes', this.elevation)
      return ['elevation-' + this.elevation];
    },
    toolbarBinds() {
      return {
        class: this.toolbarTheme,
        dark: this.toolbarDark,
      };
    },
  },

  methods: {
    isHideInDropdownList(item) {
      const hideUser = _.get(item, 'hide_user_in_dropdown_list', '1');
      return hideUser === '1';
    },
    renderDepartmentText(departmentId) {
      const departments = [
        {
          id: null,
          name: 'LIVE',
        },
        {
          id: DEPARTMENT_ID_LIVE,
          name: 'LIVE',
        },
        {
          id: DEPARTMENT_ID_DEV,
          name: 'DEV',
        },
        {
          id: DEPARTMENT_ID_UK,
          name: 'UK',
        },
        {
          id: DEPARTMENT_ID_RU,
          name: 'RU',
        },
        {
          id: DEPARTMENT_ID_LV,
          name: 'LV',
        },
        {
          id: DEPARTMENT_ID_LT,
          name: 'LT',
        },
        {
          id: DEPARTMENT_ID_PL,
          name: 'PL',
        },
        {
          id: DEPARTMENT_ID_ES,
          name: 'ES',
        },
        {
          id: DEPARTMENT_ID_BG,
          name: 'BG',
        },
        {
          id: DEPARTMENT_ID_RO,
          name: 'RO',
        },
        {
          id: DEPARTMENT_ID_HU,
          name: 'HU',
        },
        {
          id: DEPARTMENT_ID_COM,
          name: 'COM',
        },
      ];
      const departmentsMap = new Map();
      departments.forEach((department) => {
        departmentsMap.set(department.id, department.name);
      });
      return departmentsMap.get(departmentId);
    },
  },
};
</script>
<style lang="scss" scoped>
.rules-filter {
  display: flex;
  flex-wrap: wrap;

  .rules-filter--item {
    min-width: 260px;
    padding: 5px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100%;
      min-width: unset;
    }
  }
}
</style>

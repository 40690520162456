import { FormState, FormGetters, FormMutations, FormActions } from '@front/modules/abstracts/form';
import Api from '@/plugins/api';

const state = FormState({
  fields: {
    company: '',
    email: '',
    password: '',
    password_confirmation: '',
  },
  is_registering: false,
});
const mutations = FormMutations();
const getters = FormGetters({
  VALIDATES: (state) => {
    return {
      company: ['required', 'text.please_enter_your_company'],
      email: [
        'required|email',
        ['text.please_enter_your_email', 'text.the_email_must_be_a_valid_email_address'],
      ],
      password: [
        'required|min:8',
        ['text.please_enter_your_password', 'text.the_password_must_contain_at_least_8_character'],
      ],
      password_confirmation: [
        'required|equals:password',
        ['text.please_repeat_your_password', 'text.passwords_should_match'],
      ],
    };
  },
});
const actions = FormActions({
  async REGISTER({ dispatch, commit, state, rootState }) {
    await dispatch('VALIDATE_FIELDS').catch(() => {
      //state.is_registering = false
      throw new Error('Registration form rejected [validationErrors]');
    });

    commit('auth/SET_LOADING', true, { root: true });

    Api.post('auth/register', {
      company: state.fields.company,
      email: state.fields.email,
      password: state.fields.password,
      password_confirmation: state.fields.password_confirmation,
    })
      .then((response) => {
        //console.log('Register RESPONSE', response)
        const accessToken = response.access_token;
        const preloadedData = response.preloadedData;
        dispatch(
          'auth/AUTHENTICATE',
          { accessToken, preloadedData, redirect: true },
          { root: true }
        );
        //state.is_registering = false
      })
      .catch((response) => {
        commit('auth/SET_LOADING', false, { root: true });
        //state.is_registering = false
        Object.entries(response.body.messages).forEach(([key, value]) => {
          commit('MERGE_ERRORS', { [key]: `text.${value}` });
        });
      });
  },
});
//const getters = RepositoryGetters()

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
  // getters
};

<template lang="pug">
  .page
    //-.title.mb-5(v-if="title") {{ title }}
    slot
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      default: null,
    }
  }
}
</script>
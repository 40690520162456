<style lang="scss">@import './sass/sidebar.scss';</style>

<template lang="pug">
  #sidebar-navigation
    .logo
    ul.no-style.menu
      .item(v-for="(m, index) in menu" :key="index")
        router-link( :to="m.route")
          li {{ m.label }}

        router-link.sub(v-if="m.items" v-for="(item, index) in m.items" :key="index" :to="item.route")
          li  {{ item.label }}

      //- FOR ADMINS
      router-link(to="uploaded_history" v-if="isAdmin")
        li Uploaded History

    //-
      li Employees
      li Customers Search
      li Graphs
      li Documents
      li Settings

    .footer
      //-
        ul.no-style.mini-menu
          li Contact
          li.sep |
          li Privacy
          li.sep |
          li Terms

      .copy
        | &copy; WAcademy 2020

</template>

<script>

import { routes } from '@/router/routes'

export default {
  data() {
    return {
      menu: [
        { label: 'Projects', route: routes.projects.name },
        /*{ label: 'Analyse Data', route: routes.analyze.name },
        { label: 'Products', route: routes.products.name, items: [
          { label: 'Materials', route: routes.materials.name }
        ]},
        // { label: 'Sharables', route: routes.sharables.name },
        */
        /*
        { label: 'Employees', route: '#' },
        { label: 'Customers Search', route: '#' },
        { label: 'Graphs', route: '#' },
        { label: 'Documents', route: '#' },
        { label: 'Settings', route: '#' },
        */
      ]
    }
  },

  computed: {
    isAdmin() {
      const user = this.$store.state.auth.user
      return user && user.is_super
    }
  }
}

</script>
<template lang="pug">
  .tab-content
    //- .full-height.centerize(v-if="model.length == 0")
    //-   .placeholder
    //-     v-img.no-resp(:src="require('@/assets/images/placeholders/products.png')")
    //-     p.text-center {{ $t('text.please_create_a_categories_first_in_order_create_products') }}
    .with-elements
      .titles.text-center
        .maintitle {{ $t(title) }}
        .subtitle {{ $t(subtitle) }}
      slot(name="tips-container")
      group-container(
        :title="$t('text.currency')"
        :tip="$t('text.tip_contents.products.currency')"
        :subtitle="$t('text.choose_a_currency')"
        commentField="payment.currency"
      )
        v-autocomplete(
          :items="passiveCurrencies"
          v-model="currency_id"
          append-icon="ic-expand-more"
          filled
          :disabled="isLocked"
        )

      div.mb-5.w-full
        nested-tree(v-model="model" @click="onTreeSelected" :activeUuid="selectedUuid")
      v-sheet.chpad.border-primary.mb-6(
        v-for="c in categoryItems" :key="c.uuid"
        v-if="selectedUuid === c.uuid"
        :disabled="isLocked"
      )
        data-page(v-bind="dataBind" :disabled="isLocked")
          template(v-slot:table-actions)
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Auth from '@/plugins/auth';
import FormMixin from '@front/mixins/FormMixin';
import PageMixin from '@front/mixins/DataPageMixin';
import NestedTree from './parts/NestedTree';
import TipContainer from '@/components/forms/parts/TipContainer';
import Passives from '@/mixins/PassivesMixin';
import { genFields } from '@/components/forms/fieldGenerator';

export default {
  name: 'products-tab',
  props: {
    model: Array,
    default: [],
    isLocked: false,
    videoByStep: {
      type: String,
    },
  },
  components: {
    GroupContainer,
    FilePond: vueFilePond(FilePondPluginFileValidateType),
    NestedTree,
    TipContainer,
  },
  mixins: [PageMixin, Passives],
  data: function () {
    return {
      title: 'text.products',
      subtitle: 'text.add_products_to_your_created_categories',
      selectedUuid: null,
      // Products data table props...
      modName: 'projects',
      formName: 'ProductForm',
      formFolderPathSuffix: 'wizard',
      formHasDocuments: false,
      formModel: {},
      toolbarTheme: 'theme--none',
      toolbarDark: false,
      tableElevation: 0,
      showPlaceholder: false,
    };
  },
  mounted() {
    // if(this.items[0]) {
    //   console.error("NNN",this.items[0])
    //   // this.selectedUuid = this.items[0]
    // }
  },
  computed: {
    ...genFields('projects', ['payment.currency_id']),
    tableHeaders() {
      let mobileRows = !this.$vuetify.breakpoint.xs
        ? [
            {
              text: this.$t('text.description'),
              align: 'left',
              value: 'short_description',
              class: 'text-no-wrap',
              width: '15%',
            },
          ]
        : [];
      return [
        {
          text: this.$t('text.name'),
          align: 'left',
          value: 'name',
          class: 'text-no-wrap',
          width: '15%',
        },
        ...mobileRows,
        {
          text: this.$t('text.price'),
          align: 'left',
          value: 'price',
          class: 'text-no-wrap text-capitalize',
        },
        {
          text: this.$t('text.actions'),
          value: 'action',
          sortable: false,
          class: 'text-no-wrap',
          align: 'right',
          width: 80,
        },
      ];
    },
    tableActions() {
      return {
        add: {
          color: this.isDisabledBtn ? 'default' : 'primary',
          disabled: this.isDisabledBtn,
          class: this.isDisabledBtn ? 'cursor-not-allowed' : 'cursor-pointer',
        },
      };
    },
    isDisabledBtn() {
      return (
        this.$user().isAdmin() ||
        this.$user().isSuperAdmin() ||
        this.$user().isManager() ||
        this.$user().isDesigner()
      );
    },
    isPermissionRole() {
      return (
        this.$user().isAdmin() ||
        this.$user().isSuperAdmin() ||
        this.$user().isManager() ||
        this.$user().isDesigner()
      );
    },
    pageTitle() {
      return this.$t('text.category_products');
    },
    formBinds() {
      return {
        model: this.formModel,
        isLocked: this.isLocked,
        errors: this.formState.validationErrors,
      };
    },
    tableOptions() {
      return {
        itemsPerPage: -1,
      };
    },
    dtItemActions() {
      let editItem = {
          icon: 'ic-mode-edit',
          click: this.tableItemActionEdit,
          disabled: this.isDisabledBtn,
        },
        deleteItem = {};
      if (this.$user().user_type_id !== 3) {
        deleteItem = {
          icon: 'ic-delete',
          color: 'error',
          click: this.tableItemActionDelete,
          disabled: this.isDisabledBtn,
        };
      }
      const eyes = {
        icon: 'ic-visibility',
        color: 'primary',
        click: this.tableItemActionView,
      };
      return {
        ...(this.isPermissionRole && { eyes }),
        edit: editItem,
        delete: deleteItem,
      };
    },
    activeCategory() {
      return this.model.findDeep('uuid', this.selectedUuid);
    },
    items() {
      return this.activeCategory?.products;
    },
    categoryItems() {
      let items = [];
      this.model.eachDeep('elements', (item) => items.push(item));
      return items;
    },
  },
  methods: {
    // TABLE ACTIONS (HEADER)
    tableActionCreate() {
      //this.formCommit('CLEAR_FIELDS')
      this.formModel = {};
      this.$refs.dtp.showForm = true;
    },

    // TABLE ITEM ACTIONS (ITEM RIGHT SIDE)
    tableItemActionDelete(model) {
      if (confirm(this.$t('text.are_you_sure_u_want_to_remove_this_item'))) {
        const index = this.findProductIndex(model.uuid);
        this.activeCategory.products.splice(index, 1);
      }
    },

    tableItemActionEdit(model) {
      console.log('edit', model);
      this.formModel = model;
      this.getRef('dtp').showForm = true;
    },

    tableItemActionView(model) {
      this.formModel = model;
      this.getRef('dtp').showForm = true;
    },

    // FORM EVENTS
    formActionCancel() {
      this.getRef('dtp').showForm = false;
    },

    formActionCreate(model) {
      model.uuid = this.$uuid.v4();
      this.activeCategory.products.push(model);
      this.getRef('dtp').showForm = false;
    },

    findProductIndex(uuid) {
      return this.activeCategory.products.findIndex((x) => x.uuid == uuid);
    },

    formActionUpdate(model) {
      //console.log('form action update', model)
      const index = this.findProductIndex(model.uuid);
      this.activeCategory.products[index] = model;
      this.getRef('dtp').showForm = false;
    },

    onTreeSelected(uuid) {
      this.selectedUuid = uuid;
      this.$nextTick(() => {
        this.$refs.dtp.showForm = false;
      });
    },
  },
};
</script>

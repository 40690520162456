/**
 * Repository base methods
 */
 import { getStoreModule } from '@/store'

 export default (mod, methods = () => {}) => {

  const getContext = () => getStoreModule(['repositories', mod])

  return Object.assign({
    setModel(Model) {
      this.Model = Model
      return this
    },

    /**
     * Get all available items in the repository
     */
    getAvailableItems() {
      return getContext().getters.AVAILABLE_ITEMS
    },

    /*
    getAvailableCompanyItems() {
      return getContext().getters.AVAILABLE_COMPANY_ITEMS
    },
    */

    getActiveItems() {
      return getContext().getters.ACTIVE_ITEMS
    },

    /*
    getActiveCompanyItems() {
      return getContext().getters.ACTIVE_COMPANY_ITEMS
    },
    */

    /**
     * Add more items to the repository
     *
     * @return {Promise}
     */
    addItems(items) {
      return getContext().dispatch('ADD_ITEMS', items)
    },

    /**
     * Add single item to the repository
     *
     * @return {Model} item model instance
     */
    addItem(item) {
      getContext().dispatch('ADD_ITEM', item)
      return this.find(item)
    },

    first() {
      const items = getContext().getters.AVAILABLE_ITEMS
      return items.length ? items[0] : undefined
    },

    last() {
      const items = getContext().getters.AVAILABLE_ITEMS
      return items.length ? items[items.length - 1] : undefined
    },

    /**
     * Return array of matched items by specified props
     *
     * All given properties of item should match for it to be
     * considerend as valid match.
     */
    findMany(props) {
      return getContext().getters.AVAILABLE_ITEMS.filter((item) => {
        for (let prop in props) {
          if (item[prop] !== props[prop]) {
            return false
          }
        }
        return true
      })
    },

    /**
     * Find item by its key
     *
     * Returns undefined if item wasn't found.
     */
    findByKey(itemKey) {
      return getContext().getters.FIND_ITEM_BY_KEY(itemKey)
    },

    /**
     * Find item by its key, but takes
     * whole instance as a parameter
     */
    find(itemData) {
      const context = getContext()

      if (typeof itemData === 'undefined' || itemData === null) {
        return null
      }

      return context.getters.FIND_ITEM(itemData)
    },

    /**
     * Create new item instance
     */
    createItem(itemData) {
      if (typeof itemData === 'undefined' || itemData === null) {
        return null
      }

      if (typeof this.Model === 'undefined') {
        throw new Error(`setModel is missing for ${mod} repository.`)
      }

      return this.Model.create(itemData)
    },

    /**
     * Find item by its key, and create a new one
     * with given data if no item was found.
     *
     * Also adds created item to the repository.
     */
    findOrCreate(itemDataOrKeyValue, addToRepository = true) {
      if (typeof itemDataOrKeyValue === 'undefined' || itemDataOrKeyValue === null) {
        return null
      }
      let item = null
      if (typeof itemDataOrKeyValue !== 'object') {
        item = this.findByKey(itemDataOrKeyValue)
      } else {
        item = this.find(itemDataOrKeyValue)
      }

      if (item) {
        return item
      } else if (item !== null) {
        if (addToRepository) {
          return this.addItem(itemDataOrKeyValue)
        } else {
          return this.createItem(itemDataOrKeyValue)
        }
      }
    }
  }, methods(getContext))
}
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Api from '@/plugins/api';
import en from './en/index';

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  silentTranslationWarn: true,
});

export const supportedLanguages = ['lt', 'en', 'ru', 'pl', 'bg', 'ro', 'es', 'hu', 'lv'];

export const languagesFlag = [
  {
    name: 'English',
    code: 'en',
    icon: 'UnitedStates.svg',
  },
  {
    name: 'Lithuanian',
    code: 'lt',
    icon: 'Lithuania.svg',
  },
  {
    name: 'Russian',
    code: 'ru',
    icon: 'Russia.svg',
  },
  {
    name: 'Polish',
    code: 'pl',
    icon: 'Poland.svg',
  },
  {
    name: 'Bulgarian',
    code: 'bg',
    icon: 'Bulgaria.svg',
  },
  {
    name: 'Spanish',
    code: 'es',
    icon: 'Spain.svg',
  },
  {
    name: 'Romanian',
    code: 'ro',
    icon: 'Romania.svg',
  },
  {
    name: 'Hungary',
    code: 'hu',
    icon: 'Hungary.svg',
  },
  {
    name: 'Latvia',
    code: 'lv',
    icon: 'Latvia.svg',
  },
];

export const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  //axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (lang === undefined) {
    lang = 'en';
  }

  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }

  return Api.get('get-translation?lang=' + lang)
    .then((response) => {
      if (loadedLanguages.indexOf(lang) === -1) {
        loadedLanguages.push(lang);
      }
      i18n.setLocaleMessage(lang, response);
      setI18nLanguage(lang);
    })
    .catch(() => {
      const localeDefault = 'en';
      if (loadedLanguages.indexOf(localeDefault) === -1) {
        loadedLanguages.push(localeDefault);
      }
      i18n.setLocaleMessage(localeDefault, en);
      setI18nLanguage(lang);
    });
}

export default i18n;

<template>
  <v-btn text 
        outlined 
        :loading="loading"
        :color="color" 
        :style="{borderColor: `${color} !important`}"
        :class="btnClasses" 
        class="text-capitalize font-weight-bold text-default" 
        @click="$emit('input')" :disabled="disabled">
    {{ label }}
    <v-icon right v-if="icon" :small="small" :large="large">{{ icon }}</v-icon>
  </v-btn>
</template>


<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Default label'
    },
    icon: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'white'
    },
    small: {
      type: Boolean,
      defalut: false
    },
    large: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btnClasses: function() {
      return {
        'btn-flat': this.flat,
        'btn-small': this.small
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .text-default {
    letter-spacing: unset;
  }
</style>

<template lang="pug">
v-container(fluid)
  v-card(flat )
    v-stepper.top-sticky.w-100(v-show="!$vuetify.breakpoint.mobile" elevation="0" :value="currentStep")
      v-stepper-header
        v-stepper-step.step-hover(step="1" editable @click="setStep(1)") Filter Projects
        v-divider
        v-stepper-step.step-hover(step="2" editable @click="setStep(2)") Bulk Update Options
        v-divider
        v-stepper-step.step-hover(step="3" editable @click="setStep(3)") Summary
        v-divider
        v-stepper-step.step-hover(step="4" editable @click="setStep(4)") Results

      v-stepper-items
        v-stepper-content(step="1")
          projects-table(:tableActionsProp="tableActions" :title="pageTitle")
          v-btn(color="primary" @click="setStep(2)") Continue

        v-stepper-items
          v-stepper-content(step="2")
            v-card(class="mb-12" height="200px")
              v-form
                v-container
                  v-row
                    v-col(cols="12" md="4")
                      v-select(:items="this.managers" :menu-props={offsetY: true} 
                        v-model="manager" label="Manager" filled)
            v-btn.previous-btn(text @click="setStep(1)") Previous
            v-btn(color="primary" @click="setStep(3)") Continue

        v-stepper-items
          v-stepper-content(step="3")
            v-card(class="mb-12" height="200px")
              p {{ totalProjects }} projects will be updated
              p Update "Project Manager" to {{ selectedManagerName }}
            v-btn.previous-btn(text @click="setStep(2)") Previous
            v-btn(color="primary" @click='onSubmitHandler' :loading="isLoading") Submit
        
        v-stepper-items
          v-stepper-content(step="4")
            v-card(class="mb-12" height="200px") {{ responseMessage }}
            v-btn.previous-btn(text @click="setStep(3)") Previous
            router-link(to="/projects")
              v-btn(color="primary") Back to Projects
              
</template>

<script>
import PageMixin from '@front/mixins/DataPageAdvanceMixin';
import Button from '@/components/tables/parts/ToolbarBtn';
import ProjectsTable from './Index.vue';
import Api from '@/plugins/api';

export default {
  mixins: [PageMixin],
  components: { ProjectsTable },

  data: function() {
    return {
      modName: 'projects',
      formHasDocuments: false,
      tableItemActions: {
        delete: false,
      },
      manager: null,
      isLoading: false,
      responseMessage: '',
      currentStep: 1,
    };
  },
  computed: {
    _post() {
      return {
        manager: [this.manager],
      };
    },
    pageTitle() {
      return 'Bulk Update';
    },
    tableActions() {
      const video = {
        icon: 'ic-help',
        component: Button,
        link: 'https://www.youtube.com/watch?v=fg9bn_7t6cE&ab_channel=WAcademyEurope',
        target: '_blank',
      };
      return !this.$user().isClient()
        ? { video, add: false }
        : { video, add: { evclick: this.onAddButtonClick } };
    },

    workers() {
      return this.$store.state.workers;
    },

    managers() {
      return this.workers
        .filter((x) => x.isManager())
        .map((x) => {
          return {
            text: x.name,
            value: x.uuid,
          };
        });
    },

    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')];
    },

    totalProjects() {
      return this.$store.getters[this.repoPath('PAGINATION')].total;
    },

    selectedManagerName() {
      return this.manager ? this.workers.find((x) => x.uuid === this.manager).name : '';
    },
  },

  methods: {
    async onSubmitHandler() {
      console.log('Submit data', this.bulkUpDateFormData);
      console.log('Submit filter', this.tableFilter);
      const { page, per_page, ...conditions } = this.tableFilter;
      this.isLoading = true;

      await Api.put(`${this.modName}/bulk-update`, {
        conditions: conditions,
        updates: this._post,
      })
        .then((response) => {
          console.log('Response', response);
          this.responseMessage = `${this.totalProjects} projects updated`;
        })
        .catch((error) => {
          console.log('Error', error);
          this.responseMessage = 'Something went wrong';
        });

      this.isLoading = false;
      this.setStep(4);
    },

    setStep(step) {
      console.log('setStep', step);
      this.currentStep = step;
    },
  },

  mounted() {
    if (!this.$store.state.auth.is_logged_in) {
      return this.$router.push('login');
    }

    if (this.$user().isClient()) {
      return this.$router.push('/');
    }
  },
};
</script>
<style lang="scss" scoped>
.step-hover {
  &:hover {
    background: none !important;
  }
  .v-ripple__container {
    display: none !important;
  }
}

.previous-btn {
  margin-right: 10px;
}
</style>

<template lang="pug">
  data-table(v-bind="sharableBinds")
    template(v-slot:table-actions)
      FilePond.no-upload.dashed.mb-5(
        name="document"
        ref="pond"
        labelIdle="Click or Drop Media Maximum file size 2 MB"
        accepted-file-types="application/vnd.ms-excel"
        allowRevert="false"
        allowMultiple="true"
        instantUpload="false"
        :server="server"
      )
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Auth from '@/plugins/auth'
import ModMixin from '@/mixins/ModMixin.js'

export default {
  components: { DataTable, FilePond: vueFilePond(FilePondPluginFileValidateType) },
  mixins: [ ModMixin ],
  props: {
    documents: {
      type: Array,
      default: () => { return [] }
    },
    uuid: {
      type: String,
      default: null
    },
    resourceName: {
      type: String,
      default: null
    }
  },

  data: function() {
    return {
      modName: this.resourceName
    }
  },

  computed: {
    server() {
      return {
        headers: Auth.getAuthHeaders(),
        load: null,
        process: {
          url: process.env.VUE_APP_API + `/${this.resourceName}/${this.uuid}/document-upload`,
          onload: this.handleProcessFile
        },
        restore: null,
        fetch: null
      }
    },

    headers() {
      return [
        { text: 'Name', value: 'name', sortable: false, align: 'left', width: '70%' },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'action', sortable: false, align: 'right' },
      ]
    },

    itemActions() {
      return {
        delete: { icon: 'mdi-delete', click: this.tableItemActionDelete }
      }
    },

    sharableBinds() {
      return {
        elevation: 0,
        tableActions: [],
        tableItems: this.documents,
        tableHeaders: this.headers,
        tableItemActions: this.itemActions
      }
    },
  },

  methods: {
    handleProcessFile(resp) {
      resp = JSON.parse(resp)
      this.formCommit('PUSH_INTO_FIELD', {
        field_name: 'documents',
        value: resp
      })
    },

    processUpload() {
      return this.$refs.pond.processFiles()
    },

    deletable(model) {
      return {
        uuid: this.uuid,
        field: 'documents',
        itemData: model
      }
    },

    tableItemActionDelete(model) {
      if (confirm('Are you sure u want to remove this item?'))
        this.repoDispatch('API_DOCUMENT_DELETE', model)
          .then(() => {
            this.repoDispatch('DELETE_ARRFIELD_ITEM', this.deletable(model))
            this.formDispatch('DELETE_ARRFIELD_ITEM', this.deletable(model))
          })
    },
  },
}
</script>

<template>
  <v-card color="#fbfbfb" outlined>
    <v-slide-group show-arrows>
      <template v-slot:prev>
        <v-btn icon @click="prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:next>
        <v-btn icon @click="next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <v-tabs
        v-model="tabActive"
        align-with-title
        background-color="#fbfbfb"
        center-active
        flat
        outlined
      >
        <v-tabs-slider color="transparent" />
        <v-tab
          v-for="item in list"
          :key="item.uuid"
          :class="[
            isHighlightBtn(item) && 'border-error-tab',
            isHighlightBtn(item) && cloneActiveUuid === item.uuid && 'active',
          ]"
          :title="item.name"
          :value="item.name"
          active-class="tab-active"
          class="custom-slider"
          v-bind="binds(item)"
          @click="() => onActiveItem(item.uuid)"
        >
          <p class="mb-0 truncate">{{ item.name }}</p>
          <v-icon v-if="item.elements.length">mdi-chevron-down</v-icon>
        </v-tab>
      </v-tabs>
    </v-slide-group>
    <v-tabs-items v-model="tabActive" class="mt-1.5 relative overflow-visible">
      <v-tab-item
        v-for="item in list"
        v-show="tabActiveUuid === item.uuid"
        :key="`${item.uuid}`"
        :class="[!item.parent_uuid && 'custom-slider-nested ']"
        :data-uuid="item.uuid"
        :transition="false"
      >
        <nested-tree
          v-if="item.elements.length && tabActiveUuid === item.uuid"
          v-model="item.elements"
          :activeUuid="activeUuid"
          :class="[isChild ? '' : '']"
          is-child="is-child"
          @click="resendInput"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { has } from 'lodash';

export default {
  name: 'nested-tree',
  props: {
    value: {
      type: Array,
      required: true,
    },
    activeUuid: null,
    isChild: {
      type: Boolean,
      default: false,
    },
    contentError: null,
  },
  data() {
    return {
      tabActive: null,
      tabActiveUuid: '',
      cloneActiveUuid: '',
    };
  },
  computed: {
    list() {
      return this.value;
    },

    comments() {
      return this.$store.state.forms.projects.fields.comments;
    },
  },
  watch: {
    tabActiveUuid: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.cloneActiveUuid = newVal;
        }
      },
      deep: true,
    },
  },

  methods: {
    prev() {
      this.tabActiveUuid = '';
      this.cloneActiveUuid = '';
    },
    next() {
      this.tabActiveUuid = '';
      this.cloneActiveUuid = '';
    },
    onActiveItem(uuid) {
      if (uuid === this.cloneActiveUuid) {
        this.tabActiveUuid = '';
      } else {
        this.tabActiveUuid = uuid;
      }
      this.$emit('click', uuid);
    },
    binds(item) {
      const active = this.activeUuid === item.uuid;
      return {
        depressed: true,
        filled: !active,
        color: active ? 'primary' : '#EBEFFF',
        dark: active,
      };
    },

    size(item) {
      const c = item.elements.length;
      return c === 0 ? 1 : c;
    },

    resendInput(uuid) {
      this.$emit('click', uuid);
    },

    isHighlightBtn(item) {
      const isContent = has(item, 'content');
      const isProduct = has(item, 'products');
      const isCurrent = this.cloneActiveUuid === item.uuid;
      if (isContent) {
        return (!item.content && this.contentError && isCurrent) || !item.content;
      }

      if (isProduct) {
        return !item.products.length;
      }

      return false;
    },

    commentCount(item) {
      var count = 0;
      if (this.comments && this.comments.content)
        Object.keys(this.comments.content).forEach((x) => {
          if (x.startsWith(item.uuid)) count++;
        });
      return count;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-slider {
  &-root {
    max-height: 45px;
  }

  margin-left: 5px !important;
  margin-right: 5px;
  border-radius: 8px;
  background-color: #bdcaff;
}

:deep {
  .custom-slider-nested .v-tabs {
    .v-tabs-bar {
      max-height: 35px;
    }
  }
}

:deep {
  .v-slide-group {
    &.v-item-group {
      > .v-slide-group__prev {
        z-index: 1;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }

      > .v-slide-group__next {
        z-index: 1;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.tab-active {
  background-color: #4262ff;
  color: white;
}

.border-error-tab {
  &.active {
    background-color: rgba(239, 68, 68, 0.8);
    color: #fff !important;
  }

  background-color: rgba(239, 68, 68, 0.35);
  color: #ee6363 !important;
}

.tree-slide {
  background-color: #fbfbfb;
  margin-top: 5px;
}
</style>

<template lang="pug">
  v-radio-group.style-chooser(v-model="model" @change="$emit('change', model)" :disabled="disabled" :error-messages="errorMessage")
    div.grid.grid-cols-1.gap-4(:class="['md:grid-cols-2 2xl:grid-cols-3']")
      div.rows-style--item.relative(
        v-for="item in items"
        :key="item.id"
      )
        .h-full.shadow-xl.rounded-lg.overflow-hidden(:class="item.style == model ? 'row_active shadow-xl' : ''")
          v-img.no-resp.hover.relative(:src="require(`@/assets/images/website-styles/small/${item.image}`)")
          v-radio.opacity-0.absolute.top-0.left-0.w-full.h-full.z-2(:value="item.style")
          .content
            .information
              h5.mt-1.space-y-2
                //- | {{ item.name }} 
                span.block.text-16.font-semibold {{ item.name }}
                span.block {{ item.description }}
    v-dialog(
      v-model="dialog"
      max-width="80%"
    )
      div
        div.absolute.top-4.right-4.w-8.h-8.rounded-full.flex-center(style="background: rgba(0,0,0,0.8);")
          v-btn.ma-0(icon dark background="red" @click="dialog = false" color="white")
              v-icon ic-close
        v-sheet
          img.block.w-full(:src="require(`@/assets/images/website-styles/big/${preview.image}`)" alt="")
          //- v-img(:src="require(`@/assets/images/website-styles/big/${preview.image}`)" width="100%")

</template>

<script>
export default {
  props: ['value', 'disabled', 'errorMessage'],
  data: function() {
    return {
      model: this.value,
      dialog: false,
      previewItem: null,
      items: [
        {
          id: 1,
          name: this.$t('text.modern_and_corporate'),
          description: this.$t('text.Classic_style_with_simple_structure_Good_for_medical_law_or_goverment_websites'),
          
          title: 'Corporate',
          style: 'modern_corporate',
          image: 'modern_corporate.jpg',
        },
        {
          id: 2,
          name: this.$t('text.crazy_and_extravagant'),
          description: this.$t('text.Non_standard_futuristic_type_Good_for_spheres_connected_with_art_and_creativity'),
          // description: this.$t('text.Non-standard, futuristic type. Good for spheres connected with art and creativity'),
          title: 'Extravagant',
          style: 'crazy_extravagant',
          image: 'crazy_extravagant.jpg',
        },
        {
          id: 3,
          name: this.$t('text.colorful_and_playful'),
          description: this.$t('text.Style_with_bright_colors_and_soft_shapes_Good_for_businesses_work_with_children'),
          title: 'Colorful',

          style: 'colorful_playful',
          image: 'colorful_playful.jpg',
        },
        {
          id: 4,
          name: this.$t('text.elegant_and_beautiful'),
          description: this.$t('text.Graceful_sophisticated_style_Good_for_beauty_and_fashion_businesses'),
          // description: this.$t('text.Graceful, sophisticated style. Good for beauty and fashion businesses'),
          title: 'Elegant',
          style: 'elegant_beautiful',
          image: 'elegant_beautiful.jpg',
        },
        {
          id: 5,
          name: this.$t('text.oldfashioned_and_classical'),
          description: this.$t('text.Simple_and_airy_style_without_details_Good_for_highlighting_products_or_works'),
          // description: this.$t('text.Simple and airy style without details. Good for highlighting products or works'),
          title: 'Minimalistic',
          style: 'oldfashioned_classical',
          image: 'oldfashioned_classical.jpg',
        },
        {
          id: 6,
          name: this.$t('text.Old_fashioned'),
          description: this.$t('text.None_of_these_styles_The_designer_will_select_the_style_based_on_your_preferences'),
          // description: this.$t('text.None of these styles. The designer will select the style based on your preferences'),
          title: 'Old-fashioned',
          style: 'simple_minimalistic',
          image: 'simple_minimalistic.jpg',
        },
      ],
    };
  },
  computed: {
    preview() {
      return this.previewItem ?? this.items.first();
    },
  },

  methods: {
    setPreview(item, show = true) {
      this.previewItem = item
      this.dialog = show
    },
  },
};
</script>

<style lang="scss">
.style-chooser {
  .v-card {
    border-radius: 5px;
    //padding: 20px;
  }

  .col {
    //cursor: default;
  }

  .content {
    display: flex;
    align-items: flex-start;
    padding: $content-pad / 2;
  }
}

.image-preview {
  position: relative;

  .v-image {
    position: relative;
    z-index: 1;
  }

  .v-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    background: rgba($cl-primary, 0.5);
  }
}
.rows-style {
  .rows-style--item {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 50%;
    }
    @media (max-width: 380px) {
      width: 100%;
    }
  }
}

.row_active {
  background: #f5f7ff;
  box-shadow: 0px 0px 4px rgba(141, 141, 141, 0.25), 0px 2px 0px #4262ff !important;
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}
</style>

function hotjar() {
  let hjAppID = 1234567;
  if (typeof process.env.VUE_APP_HOTJAR_ID !== 'undefined') {
    hjAppID = process.env.VUE_APP_HOTJAR_ID;
  }
  console.log("Hotjar integrated ......");
  return (function(h, o, t, j, appHjID, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    console.log("appHjID = ", appHjID);
    h._hjSettings = { hjid: appHjID, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', hjAppID);
}

export default hotjar;

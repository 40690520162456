<template>
  <v-snackbar
    v-model="open"
    :timeout="200000"
    app
    color="transparent"
    content-class="cookies-info"
    elevation="0"
    max-width="100%"
    width="100%"
  >
    <v-alert class="ma-0" color="#F5F7FF" light>
      <v-row align="center">
        <v-col cols="12" md="9" sm="9">
          <div class="description">
            {{ $t('text.cookie_popup') }}
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <div class="d-flex items-center justify-center">
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed rounded @click="accept(true)">
              {{ $t('text.accept') }}
            </v-btn>
            <!--div class='px-1'></!--div>
            <v-btn depressed rounded filled color="primary" @click="accept(false)">{{ $t('text.decline') }}</!--v-btn-->
          </div>
        </v-col>
      </v-row>
    </v-alert>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      open: true,
    };
  },
  methods: {
    accept(accepted = false) {
      this.open = false;
      if (accepted) this.$cookies.set('cookie_popup', true);

      const liveChatEl = document.querySelector('[data-b24-crm-button-cont]');
      if (!liveChatEl) return;
      
      if (!this.$user().isClient() && liveChatEl.classList.contains('live-chat-custom-position')) {
        liveChatEl.classList.remove('live-chat-custom-position');
      }
    },
  },
};
</script>
<style lang="scss">
.cookies-info {
  padding: 0;
  border: 1px solid #eaeaea;
  border-radius: 10px;

  & + .v-snack__action {
    display: none;
  }

  .description {
    font-size: 14px;
  }
}
</style>

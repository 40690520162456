<template lang="pug">
  div.min-w-20.whitespace-nowrap
    //- div
    //-   v-select(:items="statusItems1" :loading="submitting" :disabled="submitting" :menu-props={offsetY: true} v-model="currentStatus" filled)
    div(v-if="hasAccess")
      template(v-if="statusItems1.length > 0")
        v-select(:items="statusItems1" dense small-chips
        hide-details :loading="submitting" 
        :disabled="submitting" :menu-props={offsetY: true} 
        v-model="currentStatus" filled :placeholder="statusName")
      template(v-else)  
        | {{ statusName }}
    div(v-else)
      | {{ statusName }}
    div
      v-dialog(
          v-model="dialog"
          max-width="38%"
        )
        v-card(class="text-center")
          v-card-title
          v-card-text {{ $t('text.which_status_do_you_want_to_change_for_this_project') }}
          v-card-actions
            v-spacer
              v-btn(color="error" text @click="disagree") {{ $t('text.disagree') }}
              v-btn(color="primary" text @click="agree1") {{ $t('text.agree') }}
</template>

<script>
import ModMixin from '@front/mixins/ModMixin';
import statusMaps from '@models/projects/statusMaps.js';
const statusPMCurrent = 'Check';

export default {
  props: {
    statusID: {
      type: Number | String,
      default: '',
    },
    projects: {
      type: Object | Array,
      default: [],
    },
    projectUUID: {
      type: String,
      default: '',
    },
    statusName: {
      type: String,
      default: '',
    },
    project: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      default: () => ({})
    },
  },
  mixins: [ModMixin],
  data() {
    return {
      modName: 'projects',
      dialog: false,
      submitting: false
    };
  },
  computed: {
    roleUser() {
      return this.$user().user_type_id
    },
    isUserAdmin() {
      return this.$user() && this.$user().isAdmin();
    },
    hasAccess() {
      let isAdmin = this.$user().isAdmin();
      let isSuperAdmin = this.$user().isSuperAdmin();
      let isManager = this.$user().isManager();
      let isDesigner = this.$user().isDesigner();
      return isAdmin || isSuperAdmin || isManager || isDesigner
    },
    statusItems1() {
      window.console.error({ project: this.project, stt: this.statusID })
      return statusMaps.getUserMaps(this.$user(), this.statusID)
    },
    currentStatus: {
      get() {
        return this.value.status_id
      },
      async set(val) {
        this.submitting = true
        await this.agree1(val)
        this.value.status_id = val
        this.submitting = false
      }
    },
    
    
  },
  methods: {
    updateStatus() {
      this.dialog = true;
    },
    async agree1(status_id) {
      return new Promise((resolve) => {
        const { uuid, designer_uuid, manager_uuid, reminder, deadline } = this.value
        console.log(this.value, status_id)
        let data = {
          "project_uuid": uuid,
          "delegate": {
              "designer_uuid": designer_uuid,
              "manager_uuid": manager_uuid,
              "reminder":reminder,
              "deadline":deadline,
              "status_id": status_id
            }
        }
        this.formCommit('UPDATE_FIELD', {
            field: 'uuid',
            value: data.project_uuid,
          });
        this.formCommit('UPDATE_FIELD', {
          field: 'delegate',
          value: data.delegate,
        });
        const func = () => {
          resolve(true)
        }
        this.$store.dispatch('forms/projects/DELEGATE', { func })
      })
    },
    

    disagree() {
      this.dialog = false;
    },
  },
};
</script>

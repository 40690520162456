import { FormState, FormGetters, FormMutations, FormActions } from '@front/modules/abstracts/form';
import Api from '@/plugins/api';

const state = FormState({
  fields: {
    email: '',
    password: '',
    // site_address: ''
  },
});
const mutations = FormMutations();
const getters = FormGetters({
  VALIDATES: (state) => {
    return {
      email: [
        'required|email',
        ['text.please_enter_your_email.', 'text.the_email_must_be_a_valid_email_address'],
      ],
      password: ['required', 'text.please_enter_your_password'],
    };
  },
});
const actions = FormActions({
  async LOGIN({ dispatch, commit, state }) {
    await dispatch('VALIDATE_FIELDS');

    //console.log('some some')
    // throw new Error('handled Error')

    commit('auth/SET_LOADING', true, { root: true });

    Api.post('auth/login', {
      email: state.fields.email,
      password: state.fields.password,
    })
      .then((response) => {
        console.log('LOGIN RESPONSE', response);
        const accessToken = response.access_token;
        const preloadedData = response.preloadedData;
        dispatch(
          'auth/AUTHENTICATE',
          { accessToken, preloadedData, redirect: true },
          { root: true }
        );
      })
      .catch((response) => {
        const { body } = response;

        commit('auth/SET_LOADING', false, { root: true });
        commit('SET_ERRORS', {
          email: [''],
          password: [_.get(body, 'message', '')],
        });
        throw response;
      });
  },
});

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
  // getters
};

<template lang="pug">
  .social-links
    .item(v-for="item in model")
      v-checkbox(v-model="item.check" hide-details :disabled="isLocked")
      .flex.flex-col
        label.font-bold.mb-1.font-medium {{item.name}}
        v-text-field(
          :rules="[rules.address]"
          validate-on-blur
          filled
          v-model="item.url"
          :disabled="!item.check"
          :readonly="isLocked"
          :placeholder="renderPlaceholder(item)"
        )
    v-btn.capitalize(
      @click="$emit('add-social')"
      small color="black"
      rounded
      depressed
      text
      :disabled="isLocked") {{ $t('text.add_new') }}
      v-icon(right) ic-add
</template>
<script>
import Btn from '@/components/common/buttons/Button';

export default {
  components: { Btn },
  props: {
    model: { type: Array },
    isLocked: { type: Boolean },
    rules: { type: Object },
  },
  filters: {
    toNameSpecial(v = '') {
      if (v.includes('vKontakte')) {
        return 'TikTok';
      }
      return v;
    },
  },
  methods: {
    renderPlaceholder(social) {
      const socialLinkPlaceholders = new Map()
        .set('facebook', 'https://www.facebook.com/wacademy_eu/')
        .set('instagram', 'https://www.instagram.com/wacademy_eu/')
        .set('linkedin', 'https://www.linkedin.com/wacademy_eu/')
        .set('tiktok', 'https://www.tiktok.com/@wacademy_eu/');

      return (
        socialLinkPlaceholders.get(social.name.toLowerCase()) ??
        'https://www.sociallink.com/@wacademy_eu/'
      );
    },
  },
};
</script>
<style lang="scss">
.social-links {
  // display: flex;
  flex-direction: column;

  .item {
    display: flex;
  }
}
</style>

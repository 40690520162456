<template lang="pug">
  div(:class="[isAttached ? 'px-0' : 'tab-content']")
    .titles.text-center
      .maintitle {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
    slot(name="tips-container")
    div.mb-4
      v-radio-group.ib-1(v-model="domain_com" :mandatory="false" :disabled="isLockedWhmcs" :error-messages="$t(errors.website.domain_com) || $t(errors.website.domain_name) || $t(errors.website.domain_registered)")
        v-radio(value="whmcs")
          template(v-slot:label) I want to register a new domain.
        domain-registrator.ib-2(v-if="isWhmcs" :is_locked="isLockedWhmcs" v-model="domain_name" :domain_whmcs="domain_whmcs" :error="$t(errors.domain_whmcs)" @saveForm)
        v-radio(value="owner")
          template(v-slot:label) I have already registered a domain.
        group-container.ib-2(
          v-if="isOwner"
          :title="$t('text.domain_name')"
          :subtitle="$t('text.enter_your_domain_name_here')"
          commentField="website.domain_name"
          :mandatory="true"
        )
          v-text-field(
            :label="$t('text.your_domain_name') + ' (example.com)'"
            :rules="[rules.domain_name]"
            v-model="domain_name"
            :readonly="isLocked"
            filled
            :error-messages="renderErrorMsg"
            @focusout="onFocusOutDomainField"
            @focusin="onFocusInDomainField"
          )

          v-radio-group.ib-1(v-model="domain_registered" :mandatory="false"  :disabled="isLocked" :error-messages="$t(errors.website.domain_registered)")
            v-radio(value="getspace")
              template(v-slot:label) {{ $t('text.i_have_registered_my_domain_name_at_getspace') }}
            v-radio(value="provider")
              template(v-slot:label) {{ $t('text.i_have_registered_my_domain_name_with_another_provider')}}

            v-radio-group.ib-2(
              v-if="isProviderSelected"
              v-model="domain_care"
              :mandatory="false"
              :disabled="isLocked"
              :error-messages="$t(errors.website.domain_care)"
            )
              v-radio(value="getspace")
                template(v-slot:label) {{ $t('text.please_take_care_of_my_domain_name_and_redirect_it_to_getspace_servers') }}

              .ib-2(v-if="isGetSpaceSelected")
                .p.mb-3.explain {{ $t('text.in_order_to_redirect_your_domain_name_to_getspace_server_we_need_login_details_to_domain_management_system') }}

                v-text-field(v-model="model.care_url" filled :label="$t('text.login_url_link')" :readonly="isLocked" :error-messages="$t(errors.website.care_url)")
                v-text-field(v-model="model.care_user" filled :label="$t('text.username')" :readonly="isLocked" :error-messages="$t(errors.website.care_user)")
                v-text-field(v-model="model.care_password" filled :label="$t('text.password')" :readonly="isLocked" :error-messages="$t(errors.website.care_password)")
                v-checkbox.mt-0(v-model="check_migrate_email" :readonly="isLocked")
                  template(v-slot:label) {{$t('text.check_migrate_email')}}
              v-radio(value="selfcare")
                template(v-slot:label) {{ $t('text.i_will_take_care_of_my_domain_name_myself_and_will_redirect_it_to_getspace_servers') }}

        //-v-text-field(filled)

    group-container(
      :title="$t('text.web_hosting')"
      :subtitle="$t('text.enter_the_email_you_used_to_register_at_getspace_it_will_help_us_to_identify_your_web_hosting_account')"
      commentField="website.web_hosting"
      :mandatory="true"
    )
      v-text-field(v-model="host_email" filled  :placeholder="$t('text.your_e-mail_at_getspace')" :readonly="isLocked" :error-messages="$t(errors.website.host_email)")
</template>
<script>
import { checkDomain } from '@/helpers';
import GroupContainer from '@/components/forms/parts/GroupContainer';
import { genFields } from '@/components/forms/fieldGenerator';
import TipContainer from '@/components/forms/parts/TipContainer';
import DomainRegistrator from '@/components/forms/parts/DomainRegistrator';

const hint = 'Only supported top-level-domains: .com, .eu, .net, .org';
export default {
  components: { GroupContainer, TipContainer, DomainRegistrator },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: { type: Boolean },
    comments: { type: Object },
    isAttached: {
      type: Boolean,
      default: false,
    },
    errors: null,
    videoByStep: {
      type: String,
    },
  },
  data: function () {
    /*eslint-disable*/
    return {
      title: 'text.website_resources',
      subtitle: 'text.information_regarding_technical_resources_of_your_website',
      domreg: null,
      isFocusDomainField: true,
      isValidatingDomainName: false,
      errorMsg: '',
      //domain_com: 'whmcs',
      hint,
      rules: {
        domain_name: (value) => {
          if (!value?.trim()) {
            return true;
          }
          //- a.host.endsWith('.com')
          // .com, .eu, .net, .org"
          const pattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9]{2,256}(\.[a-zA-Z]{2,61})+\b([a-zA-Z0-9]*)$/;
          return (value?.trim().length && pattern.test(value)) || 'The domain name invalid';
        },
        domain_top_level: (value) => {
          //- a.host.endsWith('.com')
          // .com, .eu, .net, .org"
          let _url = value.toString().trim();
          const isPassed =
            _url.endsWith('.com') ||
            _url.endsWith('.eu') ||
            _url.endsWith('.net') ||
            _url.endsWith('.org');
          return isPassed || hint;
        },
      },
    };
  },
  computed: {
    isLockedWhmcs() {
      let ex = [0, 1];
      return this.isLocked || ex.includes(this.errors.domain_whmcs) ? true : false;
    },
    isWhmcs() {
      return this.domain_com === 'whmcs';
    },
    isOwner() {
      return this.domain_com === 'owner';
    },
    isProviderSelected() {
      return this.model.domain_registered === 'provider';
    },
    isSelfcareSelected() {
      return this.model.domain_care === 'selfcare';
    },
    isGetSpaceSelected() {
      return this.model.domain_care === 'getspace';
    },
    renderErrorMsg() {
      if (this.errorMsg.length && !this.isFocusDomainField) return this.errorMsg;
      return this.$t(this.errors.website.domain_name);
    },
    ...genFields('projects', [
      'website.domain_name',
      'website.domain_registered',
      'website.domain_care',
      'website.care_url',
      'website.care_user',
      'website.care_password',
      'website.host_email',
      'website.domain_whmcs',
      'website.domain_com',
      'website.check_migrate_email',
    ]),
  },
  mounted() {
    if (!this.model.domain_com) this.model.domain_com = 'whmcs';
    if (!this.model.domain_registered) this.model.domain_registered = 'getspace';
  },
  watch: {
    'model.domain_registered'(val) {
      this.model.domain_care = !this.isProviderSelected ? null : this.model.domain_care;
    },
  },
  methods: {
    onFocusInDomainField() {
      this.isFocusDomainField = true;
      this.errorMsg = '';
    },
    onFocusOutDomainField() {
      this.isFocusDomainField = false;
      this.onCheckDomainExit();
    },
    async onCheckDomainExit() {
      if (!this.model.domain_name) {
        return;
      }
      this.isValidatingDomainName = true;
      const params = {
        domain_name: this.model.domain_name,
        project_uuid: this.projectUUID,
      };
      const res = await checkDomain('projects/check-domain', 'get', params);
      const status = _.get(res, 'status', false);
      this.errorMsg = _.get(res, 'message', '');

      this.errorMsg = status ? '' : _.get(res, 'message', '');
      this.isValidatingDomainName = false;
    },
  },
};
</script>

<template lang="pug">
  abstract-form.subform(v-bind="formBind" ref="form")
    template(v-slot:main)
      group-container(
        :title="$t('text.product_name')"
        :subtitle="$t('text.what_is_your_product_name')"
        :mandatory="true"
      )
        v-text-field(v-model="modelData.name" filled :readonly="isLocked" :rules="[v => !!v || $t('text.product_name_is_required')]")

      group-container(
        :title="$t('text.product_short_description')"
        :subtitle="$t('text.what_is_your_product_short_description')"
      )
        v-textarea(v-model="modelData.short_description" filled :readonly="isLocked" rows="2")

      group-container(
        :title="$t('text.product_description')"
        :subtitle="$t('text.what_is_your_product_description')"
      )
        //- v-textarea(v-model="modelData.description" filled :readonly="isLocked")
        .mb-5
          ckeditor(:editor="editor" v-model="modelData.description" :disabled="isLocked" :config="editorConfig")
            //- .error-text.margins(v-if="contentError") {{ $t(contentError) }}


      group-container(
        :title="$t('text.product_price')"
        :subtitle="$t('text.what_is_the_price_of_your_product')"
        :mandatory="true"
      )
        v-text-field(v-model="modelData.price" filled :readonly="isLocked" :rules="[v => parseFloat(v) == v || $t('text.product_price_is_required')]")

      group-container(
        :title="$t('text.product_comments')"
        :subtitle="$t('text.product_comments_sub')"
      )
        v-textarea(v-model="modelData.comments" filled :readonly="isLocked" rows="2")

      group-container(
        :title="$t('text.product_images')"
        :subtitle="$t('text.upload_your_product_images_here')"
      )

        media-uploader(
          :documents="modelData.media"
          resourceName="projects"
          :uuid="projectUUID"
          @added="onAdded"
          @deleted="onDeleted"
          :disabled="isLocked"
        )

      //-
        group-container(
          title="Comments (text.product_comments)"
          subtitle="You can leave a comment for yourself"
        )
          v-textarea(v-model="modelData.comments" filled :disabled="isLocked")
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import AbstractForm from '@/components/forms/AbstractForm';
//import Button from '@/components/common/buttons/Button'
import Button from '@/components/common/buttons/VBtn';
import ButtonText from '@/components/common/buttons/VBtnText';
import vuetize from '@/scripts/vuetize';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';
import Style from './Style';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { VSpacer } from 'vuetify/lib';

export default {
  components: { Style, AbstractForm, GroupContainer, MediaUploader },
  props: {
    subtitle: { type: String, default: 'Create product' },
    model: { type: Object, required: true },
    isLocked: false,
    errors: null,
  },
  data() {
    return {
      modelData: {
        uuid: this.model.uuid ?? null,
        name: this.model.name ?? null,
        short_description: this.model.short_description ?? null,
        description: this.model.description ?? null,
        price: this.model.price ?? null,
        media: this.model.media ?? [],
        comments: this.model.comments ?? null,
        is_new: this.model.is_new ?? true,
      },

      editor: ClassicEditor,
      editorConfig: {
        resize_minHeight: 400,
      },
    };
  },
  computed: {
    isPermissionRole() {
      return (
        this.$user().isAdmin() ||
        this.$user().isSuperAdmin() ||
        this.$user().isManager() ||
        this.$user().isDesigner()
      );
    },
    formActions() {
      const isMobile = this.$vuetify.breakpoint.xs;
      let extend = isMobile ? {} : { spacer: { component: VSpacer, order: 99 } };
      return {
        ...extend,
        // spacer: { component: 'v-spacer', order: 97 },
        cancel: {
          label: this.$t('text.All_products'),
          key: 'cancel',
          evinput: this.formActionCancel,
          component: ButtonText,
          filled: true,
          order: 98,
          class: '',
          dark: false,
          small: false,
          icon: 'mdi-arrow-left',
          show: true,
        },
        save: {
          label: this.$t('text.add_product'),
          key: 'save',
          evinput: this.formActionSave,
          component: ButtonText,
          color: 'primary',
          order: 100,
          small: false,
          show: true,
          disabled: this.isPermissionRole,
        },
      };
    },
    projectUUID() {
      return this.$store.state.forms.projects.fields.uuid;
    },
    formBind() {
      return {
        formActions: vuetize(this.formActions),
        formElevation: 0,
        showToolbar: false,
        showSubtitle: false,
        toolbarTheme: 'theme--none',
        toolbarDark: false,
        subForm: true,
      };
    },
  },

  methods: {
    formActionCancel() {
      this.$emit('form-cancel');
    },
    formActionSave() {
      if (this.$refs.form.$refs.form.validate()) this.formEmitData();
    },

    formEmitData() {
      const ev = this.modelData.uuid ? 'form-update' : 'form-create';
      this.$emit(ev, this.modelData);
    },

    onAdded(model) {
      this.modelData.media.push(model);
    },
    onDeleted(fileName, id) {
      this.modelData.media = this.modelData.media.filter((x) => x.filename != fileName);
    },
  },

  mounted() {},
};
</script>
<style lang="scss">
.subform {
  .section.actions-footer {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 25px 0px;
    }

    .d-flex {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        flex-wrap: nowrap !important;
        justify-content: space-between;
      }
    }
  }
}
</style>

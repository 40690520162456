<template lang="pug">
v-text-field(
  v-model="dataLocal",
  :label="label",
  clear-icon="ic-close"
  clearable
  hide-details
)
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    dataLocal: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("update", { field: this.field, value });
      },
    },
  },
};
</script>

<template lang="pug">
  v-select(
    :label="label",
    :items="options",
    multiple,
    hide-details
    @change="updateValue"
    v-model="dataSelect"
    clear-icon="ic-close"
    clearable
    @click:clear="resetValue"
    :menu-props="{offsetY: true}"
  )
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    value: {
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      dataSelect: this.value,
    };
  },
  methods: {
    updateValue(value) {
      this.$emit('update', { field: this.field, value });
    },
    resetValue() {
      this.dataSelect = [];
      this.$emit('update', { field: this.field, value: [] });
    },
  },
};
</script>

import User from '@models/user';

export default {
  SET_PRELOADED_DATA(state, preloadedData) {
    state.preloadedData = preloadedData;
  },

  SET_DRAWER(state, value) {
    state.menu_drawer = value;
  },

  SET_WORKERS(state, workers) {
    state.workers = workers.map((x) => User.create(x));
  },

  ADD_NOTIFICATION(state, message) {
    state.notifications.push(message);
  },

  SET_CONFIG(state, config) {
    state.config = config;
  },

  SET_CONFIG_PRELOAD(state, value) {
    state.config_preloading = value;
  },
  SET_SPINNER(state, coordinate) {
    if (state.coordinates.indexOf(coordinate) < 0) {
      state.coordinates.push(coordinate);
    }
  },
  REMOVE_SPINNER(state, coordinate) {
    console.error(state.coordinates, coordinate);
    state.coordinates = state.coordinates.filter((_coordinate) => _coordinate !== coordinate);
  },
  SET_HISTORY_CHANGES(state, { point, isRemove = false } = {}) {
    if (isRemove) {
      state.historyChanges = state.historyChanges.filter((_history) => _history !== point);
    } else {
      if (state.historyChanges.indexOf(point) < 0) {
        state.historyChanges.push(point);
      }
    }
  },
  REMOVE_HISTORY_CHANGES(state) {
    state.historyChanges = []
  },
};

import { getItemKey } from './helpers'
/**
 * Base mutations of the repository
 */
export default (mutations) => Object.assign({
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  UPDATE_FILTER(state, { field, value }) {
    state.filters[field] = value
  },
  UPDATE_STATE(state, { field, value }) {
    state[field] = value
  },

  SET_ITEMS(state, items) {
    state.items = items
  },

  CLEAR_ITEMS(state) {
    state.items = []
  },

  ADD_ITEMS(state, items) {
    state.items = state.items.concat(items)
  },

  ADD_ITEM(state, item) {
    state.items.push(item)
  },

  REFRESH_LIST(state) {
    state.items = state.items.slice()
  },

  /**
   * Remove items by their keys
   */
  REMOVE_ITEMS(state, itemsToRemove) {
    state.items = state.items.filter((item) => {
      const itemKey = getItemKey(item, state.key)

      // if item is not in the itemsToRemove array, keep it
      return itemsToRemove.findIndex((itemToRemove) => getItemKey(itemToRemove, state.key) === itemKey) === -1
    })
  },

  /**
   * Remove single item by its key
   */
  REMOVE_ITEM(state, itemToRemove) {
    state.items = state.items.filter((item) => getItemKey(item, state.key) !== getItemKey(itemToRemove, state.key))
  },

  ADD_DOCUMENTS(state, data) {
    state.items = state.items.filter((item) => {
      if (item.uuid === data.itemUuid) {
        item.documents.push(data.document)
      }
      return item
    })
  }
}, mutations)

<template lang="pug">
  .password-reset
    //- router-link(to="/")
    //-   .logo
    div.tform.text-center
      | {{ $t('text.reset_password')}}
      span.tform-sub.block.px-4 {{ $t('text.enter_your_email_address_to_recover_your_password') }} 
    .small(v-if="isShowMessageSuccess")
      br
      <v-alert border="left" colored-border color="success" elevation="2">{{ formState.message }}</v-alert>

    v-text-field(
      name="email"
      :placeholder="$t('text.email_address')"
      :error-messages="$t(errors.email)"
      v-model="email"
      type="email"
    )

    v-btn.submit.theme--gradient(
      color="primary"
      depressed=true
      large
      block
      @click="passwordReset"
      :loading="isLoading"
    ) {{ $t('text.submit') }}
    .bottom.text-center.py-5
      div.mb-3 {{ $t('text.dont_have_an_account') }}
        router-link.primary--text.label(to="register")  {{ $t('text.register_now') }}
      div
        router-link#btn-rp-login.block.primary--text.text-center.label(to="login") {{ $t('text.return_to_login') }}
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';

export default {
  mixins: [FormMixin('passwordReset')],

  computed: {
    isLoading() {
      return this.$store.state.auth.is_preloading;
    },

    isShowMessageSuccess() {
      return this.formState.isResetPasswordSucceed;
    },
  },

  methods: {
    passwordReset() {
      const locale = this.$i18n.locale;
      console.error('LOCALE', locale);
      this.formCommit('UPDATE_FIELD', {
        field: 'siteUrl',
        value: window.location.origin,
      });
      this.formCommit('UPDATE_FIELD', {
        field: 'locale',
        value: locale,
      });
      this.formDispatch('RESET_PASSWORD');
    },

    enterDown(event) {
      if (event.keyCode === 13) this.passwordReset();
    },
  },

  mounted() {
    window.addEventListener('keypress', this.enterDown);
  },

  destroyed() {
    window.removeEventListener('keypress', this.enterDown);
  },
};
</script>

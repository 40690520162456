<template lang="pug">
  parent
    //- Form component
    component(
      :is="formComponent"
      v-if="showFrm"
      v-on="formEvents"
      ref="form"
      v-bind="formBinds"
    )
    data-table(
      v-else-if="!showCustomPlaceholder"
      v-bind="dataTableBinds"
    )
      //-template(v-slot:item="{ item }")
        slot(name="item" :item="item")

      template(v-slot:item-name="{ item }")
        slot(name="item-name" :item="item")

      template(v-slot:table-actions)
        slot(name="table-actions")

      template(v-slot:table-top)
        slot(name="table-top")


    slot(v-else name="placeholder")
      .full-height.centerize
        .placeholder No Data
</template>
<script>
import AbstractPage from './AbstractPage';
import DataTable from '../tables/DataTable';

export default {
  //extends: AbstractPage,
  components: { parent: AbstractPage, DataTable },

  props: {
    pageTitle: {
      type: String,
      defalut: null,
    },
    tableHeaders: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableItemActions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableBinds: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formComponent: {
      type: Object,
      default: null,
    },
    formEvents: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formBinds: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modName: {
      type: String,
      default: null,
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      showForm: null,
    };
  },

  computed: {
    showCustomPlaceholder() {
      //console.log('placeeee', this.showPlaceholder)
      return this.tableItems.length == 0 && this.showPlaceholder;
    },
    showFrm() {
      return this.showForm ?? this.showFormState;
    },
    showFormState() {
      return this.modName ? this.$store.state.forms[this.modName].show_form : false;
    },

    dataTableBinds() {
      return {
        tableHeaders: this.tableHeaders,
        tableItems: this.tableItems,
        tableActions: this.tableActions,
        tableItemActions: this.tableItemActions,
        title: this.pageTitle,
        ...this.tableBinds,
      };
    },
  },
};
</script>

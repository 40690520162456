<template lang="pug">
  div(:class="[isAttached ? 'px-0' : 'tab-content']")
    .titles.text-center
      .maintitle {{ $t(title) }} 
      .subtitle {{ $t(subtitle) }}
    slot(name="tips-container")
    group-container(
      :title="$t('text.domain_name')"
      :subtitle="$t('text.enter_your_domain_name_here')"
      :tip="$t('text.tip_contents.website.domain')"
      commentField="website.domain_name"
      :mandatory="true"
    )
      v-text-field(
        :label="$t('text.your_domain_name') + ' (example.com)'"
        :rules="[rules.domain_name]"
        v-model="domain_name"
        :readonly="isLocked"
        filled
        :loading="isValidatingDomainName"
        :disabled="isValidatingDomainName"
        :error-messages="renderErrorMsg"
        @focusout="onFocusOutDomainField"
        @focusin="onFocusInDomainField"
      )

      v-radio-group.ib-1(v-model="domain_registered" :mandatory="false"  :disabled="isLocked" :error-messages="$t(errors.website.domain_registered)")
        v-radio(value="getspace")
          template(v-slot:label) {{ $t('text.i_have_registered_my_domain_name_at_getspace') }}
        v-radio(value="provider")
          template(v-slot:label) {{ $t('text.i_have_registered_my_domain_name_with_another_provider')}}

        v-radio-group.ib-2(
          v-if="isProviderSelected"
          v-model="domain_care"
          :mandatory="false"
          :disabled="isLocked"
          :error-messages="$t(errors.website.domain_care)"
        )
          v-radio(value="getspace")
            template(v-slot:label) {{ $t('text.please_take_care_of_my_domain_name_and_redirect_it_to_getspace_servers') }}

          .ib-2(v-if="isGetSpaceSelected")
            .p.mb-3.explain {{ $t('text.in_order_to_redirect_your_domain_name_to_getspace_server_we_need_login_details_to_domain_management_system') }}

            v-text-field(v-model="model.care_url" filled  :label="$t('text.login_url_link')" :readonly="isLocked" :error-messages="$t(errors.website.care_url)" :rules="[rules.address]")
            v-text-field(v-model="model.care_user" filled :label="$t('text.username')" :readonly="isLocked" :error-messages="$t(errors.website.care_user)")
            //- v-text-field(v-model="model.care_password" filled :label="$t('text.password')" :readonly="isLocked" :error-messages="$t(errors.website.care_password)")
            v-text-field(v-model="model.care_label" filled :label="$t('text.label')" :readonly="isLocked" :error-messages="$t(errors.website.care_label)")
            v-checkbox.mt-0(v-model="check_migrate_email" :readonly="isLocked")
              template(v-slot:label) {{$t('text.check_migrate_email')}}
          v-radio(value="selfcare")
            template(v-slot:label) {{ $t('text.i_will_take_care_of_my_domain_name_myself_and_will_redirect_it_to_getspace_servers') }}

      //-v-text-field(filled)

    group-container(
      :title="$t('text.web_hosting')"
      :tip="$t('text.tip_contents.website.hosting')"
      :subtitle="$t('text.enter_the_email_you_used_to_register_at_getspace_it_will_help_us_to_identify_your_web_hosting_account')"
      commentField="website.web_hosting"
      :mandatory="true"
    )
      v-text-field(v-model="host_email" :rules="[rules.email]" filled :placeholder="$t('text.your_e-mail_at_getspace')" :readonly="isLocked" :error-messages="$t(errors.website.host_email)")
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import { genFields } from '@/components/forms/fieldGenerator';
import TipContainer from '@/components/forms/parts/TipContainer';
import domain from '@/scripts/domain';
import { checkDomain } from '@/helpers';

export default {
  components: { GroupContainer, TipContainer },
  props: {
    model: {
      type: Object,
      required: true,
    },
    isLocked: { type: Boolean },
    comments: { type: Object },
    errors: null,
    isAttached: {
      type: Boolean,
      default: false,
    },
    videoByStep: {
      type: String,
    },
  },
  /*eslint-disable*/
  data: function () {
    return {
      title: 'text.website_resources',
      subtitle: 'text.information_regarding_technical_resources_of_your_website',
      domreg: null,
      domain_com: null,
      isFocusDomainField: true,
      isValidatingDomainName: false,
      errorMsg: '',
      rules: {
        email: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            (value?.trim().length && pattern.test(value)) ||
            this.$t('text.the_email_must_be_a_valid_email_address')
          );
        },
        phone_number: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const pattern =
            /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
          return (value?.trim().length && pattern.test(value)) || 'The phone number invalid';
        },
        address: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const pattern =
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
          return (value?.trim().length && pattern.test(value)) || 'The address invalid';
        },
        domain_name: (value) => {
          if (!value?.trim()) {
            return true;
          }
          const patternLT =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9ąčęėžšūųį]{2,256}(\.[a-zA-Z]{2,61})+\b([a-zA-Z0-9]*)$/;
          const pattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9]{2,256}(\.[a-zA-Z]{2,61})+\b([a-zA-Z0-9]*)$/;
          const patternDomain = domain.isLT() ? patternLT : pattern;

          return (value?.trim().length && patternDomain.test(value)) || 'The domain name invalid';
        },
      },
    };
  },
  computed: {
    projectUUID() {
      return this.$store.state.forms.projects.fields.uuid;
    },

    isProviderSelected() {
      return this.model.domain_registered == 'provider';
    },
    isSelfcareSelected() {
      return this.model.domain_care == 'selfcare';
    },
    isGetSpaceSelected() {
      return this.model.domain_care == 'getspace';
    },
    renderErrorMsg() {
      if (this.errorMsg.length && !this.isFocusDomainField) return this.errorMsg;

      return this.$t(this.errors.website.domain_name);
    },

    ...genFields('projects', [
      'website.domain_name',
      'website.domain_registered',
      'website.domain_care',
      'website.care_url',
      'website.care_user',
      'website.care_label',
      'website.care_password',
      'website.host_email',
      'website.domain_whmcs',
      'website.check_migrate_email',
    ]),
  },
  mounted() {
    if (!this.model.domain_registered) this.model.domain_registered = 'getspace';
  },
  watch: {
    'model.domain_registered'(val) {
      this.model.domain_care = !this.isProviderSelected ? null : this.model.domain_care;
    },
  },
  methods: {
    onFocusInDomainField() {
      this.isFocusDomainField = true;
      this.errorMsg = '';
    },
    onFocusOutDomainField() {
      this.isFocusDomainField = false;
      this.onCheckDomainExit();
    },
    async onCheckDomainExit() {
      if (!this.model.domain_name) {
        return;
      }
      this.isValidatingDomainName = true;
      const params = {
        domain_name: this.model.domain_name,
        project_uuid: this.projectUUID,
      };
      const res = await checkDomain('projects/check-domain', 'get', params);
      const status = _.get(res, 'status', false);
      this.errorMsg = _.get(res, 'message', '');

      this.errorMsg = status ? '' : _.get(res, 'message', '');
      this.isValidatingDomainName = false;
    },
  },
};
</script>

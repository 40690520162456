<template lang="pug">
  .tab-content.fixes
    .titles.text-center
      .maintitle {{ $t('text.Fixes') }}
      .subtitle {{ $t('text.Select_the_page_you_want_to_make_changes_to_and_add_your_comments') }}
    slot(name="tips-container")
    div.space-y-6.mb-6
      div.w-full
        div.w-full
          nested-tree(v-model="modifiedModel" @click="onTreeSelected" :activeUuid="selectedUuid")
      div(v-if="itemActiveByUuid.uuid")
        div.text-left.content-title {{ itemActiveByUuid.name }}
        div.py-4(v-for="(item) in renderFixesPerPage")
          group-container.relative.bg-white.px-6.py-4(
            :key="item.uuid"
            :title="$t('text.screenshot')"
            :subtitle="$t('text.Upload_the_screenshot_of_the_problem_youd_like_to_fix')"
          )
            img.block.cursor-pointer.absolute.top-2.right-2(v-if="isUserClient" src="@/assets/images/delete.svg" @click="deleteFixes(item.uuid)")
            v-textarea(rows="6" filled v-model="item.content" :disabled="disabled")
            media-uploader.media-fixes(
              :documents="item.img"
              resourceName="projects"
              :uuid="projectUUID"
              :uuid-content="item.uuid"
              @added="onAdded"
              @deleted="onDeleted"
              :disabled="disabled"
            )
        div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addNew" v-if="isUserClient" )
          v-icon(color="#222222") mdi-plus 
          | {{ $t("text.add_fix")}}
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';
import NestedTree from './parts/NestedTree';
import {
  PROJECT_COMPLETED,
  PROJECT_FIX_CHECK,
  PROJECT_FIX_DEV,
  PROJECT_FIX_FINAL_CHECK,
  PROJECT_FIX_IN_PROGRESS,
  PROJECT_FIX_READY_FOR_REVIEW,
  PROJECT_LAUNCH,
  PROJECT_LAUNCH_COMPLETED,
} from '@models/projects/statuses';

export default {
  components: { GroupContainer, MediaUploader, NestedTree },
  data() {
    return {
      statusId: 1,
      title: '',
      subtitle: '',
      items: [],
      selectedUuid: null,
    };
  },
  mounted() {
    let m = this.$store.state.forms.projects.fields.sitemap;
    this.statusId = this.$store.state.forms.projects.fields.status_id;
    if (m[0]) {
      this.selectedUuid = m[0]?.uuid;
    }
  },
  computed: {
    model: {
      get() {
        return this.$store.state.forms.projects.fields.fixes;
      },
      set(val) {
        this.$store.state.forms.projects.fields.fixes = val;
      },
    },
    fixes() {
      let items = this.$store.state.forms.projects.fields.fixes ?? [];
      return items.map((i) => ({
        uuid: i.uuid || this.$uuid.v4(),
        img: [],
        content: '',
        ...i,
      }));
    },
    renderFixesPerPage() {
      if (!this.fixes.length) return this.$store.state.forms.projects.fields.fixes;
      const foundIdxFix = this.$store.state.forms.projects.fields.fixes.findIndex((fix) =>
        fix.some((page) => page.pageUuid === this.selectedUuid)
      );

      return this.$store.state.forms.projects.fields.fixes[foundIdxFix];
    },
    modifiedModel() {
      return this.$store.state.forms.projects.fields.sitemap;
    },
    itemActiveByUuid() {
      let m = this.$store.state.forms.projects.fields.sitemap;
      return m.findDeep('uuid', this.selectedUuid) || {};
    },
    isUserClient() {
      return this.$user().isClient() && !this.disabled;
    },
    disabled() {
      const disableStatusIds = [
        PROJECT_COMPLETED,
        PROJECT_FIX_CHECK,
        PROJECT_FIX_DEV,
        PROJECT_FIX_IN_PROGRESS,
        PROJECT_FIX_FINAL_CHECK,
        PROJECT_FIX_READY_FOR_REVIEW,
        PROJECT_LAUNCH,
        PROJECT_LAUNCH_COMPLETED,
      ];
      return disableStatusIds.includes(this.statusId);
    },
    projectUUID() {
      return this.$store.state.forms.projects.fields.uuid;
    },
  },
  watch: {
    '$store.state.repositories.projects.items': {
      handler(val) {
        if (val.length && _.has(val[0], 'status_id')) {
          this.statusId = val[0].status_id;
        }
      },
      deep: true,
    },
  },

  methods: {
    onAdded(file, ...args) {
      const fixes = this.$store.state.forms.projects.fields.fixes;
      const findUuidIdx = _.findKey(args, 'uuidContent');
      const uuidContent = _.get({ ...args[findUuidIdx] }, 'uuidContent', '');
      const foundIdxFix = fixes.findIndex((fix) =>
        fix.some((page) => page.pageUuid === this.selectedUuid)
      );

      this.$store.state.forms.projects.fields.fixes[foundIdxFix].forEach((fix) => {
        if (fix.uuid === uuidContent) {
          fix.img.push(file);
        }
      });
    },
    onDeleted(filename, ...args) {
      const fixes = this.$store.state.forms.projects.fields.fixes;
      const findUuidIdx = _.findKey(args, 'uuidContent');
      const uuidContent = _.get({ ...args[findUuidIdx] }, 'uuidContent', '');
      const foundIdxFix = fixes.findIndex((fix) =>
        fix.some((page) => page.pageUuid === this.selectedUuid)
      );

      this.$store.state.forms.projects.fields.fixes[foundIdxFix].forEach((fix) => {
        if (fix.uuid === uuidContent) {
          fix.img = fix.img.filter((file) => {
            const fileName = file.filename;
            const replaceFileName = this.$utils.replaceStrBetweenTwoCharacter(
              fileName,
              '_',
              '.',
              `.${file.extension}`
            );

            return fileName === filename ? fileName !== filename : replaceFileName !== filename;
          });
        }
      });
    },
    deleteFixes(uuid) {
      const fixes = this.$store.state.forms.projects.fields.fixes;
      const foundIdxFix = fixes.findIndex((fix) =>
        fix.some((page) => page.pageUuid === this.selectedUuid)
      );
      const foundIdxFixContent = this.$store.state.forms.projects.fields.fixes[foundIdxFix];

      this.$store.state.forms.projects.fields.fixes[foundIdxFix] = foundIdxFixContent.filter(
        (item) => item.uuid !== uuid
      );

      this.$store.state.forms.projects.fields.fixes = [
        ...this.$store.state.forms.projects.fields.fixes.filter((fix) => fix.length),
      ];
    },
    addNew() {
      const fixes = this.$store.state.forms.projects.fields.fixes;
      const pageUuid = this.selectedUuid;
      // Check If Fixes are not any Item to Pages
      if (!this.fixes.length) {
        this.$store.state.forms.projects.fields.fixes.push([
          {
            uuid: this.$uuid.v4(),
            img: [],
            content: '',
            pageUuid,
          },
        ]);
        return;
      }
      // Check Fixes has not any item and add new item to page
      const foundIdxFix = fixes.findIndex((fix) => fix.some((page) => page.pageUuid === pageUuid));
      if (foundIdxFix === -1) {
        this.$store.state.forms.projects.fields.fixes.push([
          {
            uuid: this.$uuid.v4(),
            img: [],
            content: '',
            pageUuid,
          },
        ]);
        return;
      }
      // Check Page Fixes Exist Item and add more item
      this.$store.state.forms.projects.fields.fixes[foundIdxFix].push({
        uuid: this.$uuid.v4(),
        img: [],
        content: '',
        pageUuid,
      });
    },
    onTreeSelected(uuid) {
      this.selectedUuid = uuid;
    },
  },
};
</script>

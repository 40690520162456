<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#4262FF" stroke-width="2"/>
        <path d="M10.8565 15.5009C10.8576 15.2386 10.9618 14.9872 11.1465 14.8009C11.2395 14.7072 11.3501 14.6328 11.4719 14.5821C11.5938 14.5313 11.7245 14.5051 11.8565 14.5051C11.9885 14.5051 12.1192 14.5313 12.2411 14.5821C12.3629 14.6328 12.4735 14.7072 12.5665 14.8009C12.7512 14.9872 12.8554 15.2386 12.8565 15.5009C12.8561 15.7313 12.7761 15.9544 12.6302 16.1326C12.4843 16.3108 12.2813 16.4332 12.0556 16.479C11.8298 16.5249 11.5952 16.4914 11.3913 16.3842C11.1874 16.2771 11.0268 16.1029 10.9365 15.8909C10.884 15.7676 10.8568 15.635 10.8565 15.5009Z" fill="#4262FF"/>
        <path d="M10.3554 7.90239C10.8118 7.63907 11.3296 7.50061 11.8565 7.50095C12.6521 7.50095 13.4152 7.81702 13.9778 8.37963C14.5404 8.94224 14.8565 9.7053 14.8565 10.5009C14.8565 11.2966 14.5404 12.0597 13.9778 12.6223C13.4152 13.1849 12.6521 13.5009 11.8565 13.5009C11.5913 13.5009 11.3369 13.3956 11.1494 13.2081C10.9619 13.0205 10.8565 12.7662 10.8565 12.5009C10.8565 12.2357 10.9619 11.9814 11.1494 11.7938C11.3369 11.6063 11.5913 11.5009 11.8565 11.5009C12.0319 11.5002 12.204 11.4534 12.3555 11.3652C12.5071 11.277 12.6328 11.1505 12.72 10.9984C12.8073 10.8462 12.8529 10.6738 12.8525 10.4985C12.8521 10.3231 12.8055 10.1509 12.7175 9.99924C12.6296 9.84754 12.5032 9.72165 12.3512 9.63419C12.1992 9.54673 12.0269 9.50078 11.8515 9.50096C11.6762 9.50113 11.5039 9.54742 11.3521 9.63517C11.2003 9.72293 11.0742 9.84907 10.9865 10.0009C10.8481 10.2186 10.631 10.3744 10.3805 10.4358C10.13 10.4971 9.86544 10.4593 9.64214 10.3302C9.41883 10.2012 9.25404 9.9908 9.18218 9.74309C9.11032 9.49538 9.13694 9.22949 9.2565 9.00095C9.51996 8.54461 9.89898 8.16571 10.3554 7.90239Z" fill="#4262FF"/>
    </svg>
</template>
<script>
    export default {}
</script>

<template lang="pug">
    v-text-field(v-model="model" validate-on-blur :rules="[rules.address]" filled v-bind="binds" @click:append-outer="openLink")
</template>

<script>
import { genFields } from '@/components/forms/fieldGenerator'
import { url, required } from '@/modules/abstracts/form/validators'

export default {
  props: {
    name: { type: String },
    isLocked: { type: Boolean },
    error: { required: false }
  },
  data() {
    return {
      rules: {
        address: (value) => {
          if(!value?.trim()) {
            return true
          }
          /*eslint-disable*/
          const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/ 
          return value?.trim().length && pattern.test(value) || 'The address invalid'
        },
      },
    }
  },
  computed: {

    binds() {
      return {
        placeholder: 'https://www.example.com',
        readonly: this.isLocked,
        errorMessages: this.error,
        appendOuterIcon: !required(this.model) && !url(this.model) ? 'ic-globe' : ''
      }
    },


    model: {
      get() {
        return this[this.name]
      },
      set(val) {
        this[this.name] = val
      }
    },
    ...genFields('projects', [ 'style.link1', 'style.link2', 'style.link3' ]),
  },

  methods: {
    openLink() {
      window.open(this.model, '_blank').focus()
    }
  }
}
</script>

<template lang="pug">
  .login
    div.tform {{ $t('text.login') }}
    div.label.mb-2  {{ $t('text.email_address') }}
    v-text-field(
      name="email"
      :placeholder="$t('text.email_address')"
      :error-messages="$t(errors.email)"
      v-model="email"
      type="email"
    )
    div.label.mb-2 {{ $t('text.password') }}
    v-text-field(
      name="password"
      :placeholder="$t('text.password')"
      type="password"
      :error-messages="$t(errors.password)"
      v-model="password"
    )
    div.d-flex.justify-space-between.align-center.align-content-center.pb-5
      div
        v-checkbox.mt-0(:label="$t('text.Remember_me')" hide-details)
      div
        router-link.block.text-right.label(to="reset-password") {{ $t('text.forgot_password') }}


    vue-recaptcha(:sitekey="siteKey" :loadRecaptchaScript="loadRecaptchaScript" @verify="markRecaptchaAsVerified")
    div.error--text(ref = "error_message_recaptcha") {{ errorRecaptcha }}
    v-btn.submit.theme--gradient(
      color="primary"
      depressed=true
      hide-details
      block
      large
      @click="login"
      :loading="isLoading"
      :class="{'mt-5': loadRecaptchaScript}"
    ) {{ $t('text.login') }}
    .my-5
    .bottom.text-center
      | {{ $t('text.dont_have_an_account') }}
      router-link.label.primary--text.text-underline(to="register")  {{ $t('text.register_now') }}
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';
import Version from '@front/components/common/Version.vue';
import VueRecaptcha from 'vue-recaptcha';
import { LOGIN_IMPERSONATE } from '@constant';

export default {
  components: {
    Version,
    VueRecaptcha,
  },
  mixins: [FormMixin('login')],

  data() {
    return {
      checkRecaptcha: false,
      errorRecaptcha: '',
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.auth.is_preloading;
    },

    siteKey() {
      return process.env.VUE_APP_DISABLED_RECAPCHA
        ? ''
        : process.env.VUE_APP_GOOGLE_RECAPTCHA_SITEKEY;
    },

    loadRecaptchaScript() {
      return !process.env.VUE_APP_DISABLED_RECAPCHA;
    },
  },

  methods: {
    login() {
      sessionStorage.removeItem(LOGIN_IMPERSONATE);
      if (process.env.VUE_APP_DISABLED_RECAPCHA) {
        this.formDispatch('LOGIN');
      } else {
        if (this.checkRecaptcha) {
          this.formDispatch('LOGIN');
        } else {
          this.errorRecaptcha = this.$t('text.check_recaptcha_message_error');
        }
      }
    },

    enterDown(event) {
      if (event.keyCode === 13) this.login();
    },

    markRecaptchaAsVerified(response) {
      this.errorRecaptcha = '';
      this.checkRecaptcha = response;
    },
  },

  mounted() {
    const { username, password } = this.$route.query;
    if (username) {
      this.email = username ?? '';
    }
    if (password) {
      this.password = password ?? '';
    }
    const loginImpersonate = JSON.parse(sessionStorage.getItem(LOGIN_IMPERSONATE));

    if (loginImpersonate && Object.keys(loginImpersonate).length) {
      this.email = loginImpersonate.email;
      this.password = loginImpersonate.password;
    }

    window.addEventListener('keypress', this.enterDown);
  },

  destroyed() {
    window.removeEventListener('keypress', this.enterDown);
  },
};
</script>

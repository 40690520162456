<template lang="pug">
  .password-reset
    //- router-link(to="/")
    //-   .logo
    div.tform.text-center
      | {{ $t('text.update_password')}}
      span.tform-sub.block.px-4 {{ $t('text.please_repeat_your_password') }}
    .small(v-if="isShowMessageSuccess")
      br
      <v-alert border="left" colored-border color="success" elevation="2">{{ formState.message }}</v-alert>
    div.label.mb-2  {{ $t('text.password') }}
    v-text-field(name="password1" :placeholder="$t('text.password')" type="password" :error-messages="$t(errors.password)" v-model="password")
    div.label.mb-2  {{ $t('text.repeat_password') }}
    v-text-field(name="password_confirmation1" :placeholder="$t('text.repeat_password')" type="password" :error-messages="$t(errors.password_confirmation)" v-model="password_confirmation")

    v-btn.submit.theme--gradient(
      color="primary"
      depressed=true
      large
      block
      @click="passwordUpdate"
      :loading="isLoading"
    ) {{ $t('text.submit') }}
    .bottom.text-center.py-5
      div.mb-3 {{ $t('text.dont_have_an_account') }}
        router-link.primary--text.label(to="register")  {{ $t('text.register_now') }}
      div
        router-link#btn-rp-login.block.primary--text.text-center.label(to="login") {{ $t('text.return_to_login') }}
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';

export default {
  mixins: [FormMixin('passwordUpdate')],

  computed: {
    isLoading() {
      return this.$store.state.auth.is_preloading;
    },

    isShowMessageSuccess() {
      return this.formState.isUpdatePasswordSucceed;
    },
  },

  methods: {
    passwordUpdate() {
      this.formCommit('UPDATE_FIELD', {
        field: 'code',
        value: this.$route.query.code,
      });
      this.formDispatch('UPDATE_PASSWORD');
    },

    enterDown(event) {
      if (event.keyCode === 13) this.passwordUpdate();
    },
  },

  mounted() {
    window.addEventListener('keypress', this.enterDown);
  },

  destroyed() {
    window.removeEventListener('keypress', this.enterDown);
  },
};
</script>

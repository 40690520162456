<template lang="pug">
v-btn(:icon="icon && true" @click="$emit('click')" :color="color" :href="link" :target="target"  :elevation="label && '2'" align="center") {{ label }}
  v-icon(:small="small" :large="large") {{ icon }}
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    link: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<template lang="pug">
  v-container(fluid)
    data-page(v-bind="dataBind")
</template>
<script>
import PageMixin from '@front/mixins/DataPageAdvanceMixin';
import FilterTextField from '@/components/tables/parts/FilterTextField';
import { supportedLanguages } from '@/i18n';

export default {
  mixins: [PageMixin],

  data: function () {
    return {
      modName: 'translations',
      formHasDocuments: false,
    };
  },
  computed: {
    tableActionsFilters() {
      return [
        {
          evupdate: this.setFieldFilter,
          component: FilterTextField,
          field: 'q',
          label: this.$t('text.search'),
          value: this.tableFilter['q'],
        },
      ];
    },

    pageTitle() {
      return this.$t('text.menu_translations');
    },

    tableActions() {
      return {
        add: { color: 'white' },
      };
    },

    dataItems() {
      return this.items.map((x) => {
        var empty = [];
        supportedLanguages.forEach((i) => {
          if (!x[i] || x[i].isEmpty()) empty.push(i);
        });

        x.nonexists = empty.join(', ');

        return x;
      });
    },

    tableHeaders() {
      return [
        { text: 'Key', align: 'left', value: 'key', width: '80%' },
        { text: 'None', align: 'left', value: 'nonexists', sortable: false },
        { text: this.$t('text.actions'), value: 'action', sortable: false, align: 'right' },
      ];
    },
  },
  methods: {
    onCreateClick() {
      this.$router.push({ name: 'translationCreate' });
    },

    onEditClick(model) {
      this.$router.push({ name: 'translationEdit', params: { id: model.uuid } });
    },
  },
};
</script>

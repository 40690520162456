<template lang="pug">
  v-container(fluid)
    component(
      :is="formComponent",
      v-on="formEvents",
      ref="form",
    )
</template>
<script>
import FormAdvanceMixin from "@front/mixins/FormAdvanceMixin";
import { loadedLanguages } from '@/i18n'
import router from "../../router";

export default {
  mixins: [FormAdvanceMixin],
  data() {
    return {
      modName: "translations",
      formHasDocuments: false,
    };
  },
  computed: {
    pageTitle() {
      return this.$t("text.menu_translations")
    },
    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')]
    },
    formActions() {
      return {
        
      }
    }
  },

  methods: {
    fetchData(uuid) {
      this.repoDispatch("API_SHOW", uuid).then(this._afterApi)
    },

    _afterActionUpdate(responseModel) {
      const i18n = router.app.$options.i18n
      loadedLanguages.forEach((lang) => {
        let messageMerge = {
          text : []
        };
        messageMerge.text[responseModel.key] = responseModel[lang];
        i18n.mergeLocaleMessage(lang, messageMerge)
      })
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (undefined !== to.params.id) {
        vm.fetchData(to.params.id);
      }
    });
  },
};
</script>

<template lang="pug">
  .comments-wrapper(v-if="commentField")
    .admin-comment(v-if="showAddComments")
      v-btn(v-if="showBtn" color="primary" @click="add" rounded depressed)
        | {{ $t('text.add_comment') }}
        v-icon(right) ic-add
      v-textarea(v-else rows="1" v-model="model" filled :label="label")
        template(v-slot:append)
          v-btn(fab icon small @click="close")
            v-icon ic-close

    .client-comment.px-5(v-else-if="model")
      .small.accent--text {{ $t('text.a_comment_from_project_manager') }}
      p {{ model }}
</template>
<script>
import Btn from '@/components/common/buttons/Button';

export default {
  props: {
    commentField: { type: String },
  },
  components: { Btn },
  data: function () {
    return {
      showBtn: true,
    };
  },
  computed: {
    isClient() {
      return this.$user().isClient();
    },

    showAddComments() {
      return this.commentField && !this.$user().isClient() && !this.$user().isDesigner();
    },

    label() {
      let textLabel = this.field.name.capitalize().replace('_', ' ') + ' comment';
      switch (textLabel) {
        case 'Type comment':
          textLabel = this.$t('text.type_comment');
          break;
        case 'Project manager comment':
          textLabel = this.$t('text.project_manager_comment');
          break;
        case 'Brand name comment':
          textLabel = this.$t('text.brand_name_comment');
          break;
        case 'Description comment':
          textLabel = this.$t('text.description_comment');
          break;
        case 'Category comment':
          textLabel = this.$t('text.category_comment');
          break;
        case 'Email comment':
          textLabel = this.$t('text.email_comment');
          break;
        case 'Phone number comment':
          textLabel = this.$t('text.phone_number_comment');
          break;
        case 'Address comment':
          textLabel = this.$t('text.address_comment');
          break;
        case 'Working hours comment':
          textLabel = this.$t('text.working_hours_comment');
          break;
        case 'Socials comment':
          textLabel = this.$t('text.socials_comment');
          break;
        case 'Details comment':
          textLabel = this.$t('text.details_comment');
          break;
        case 'Index.vue name comment':
          textLabel = this.$t('text.domain_name_comment');
          break;
        case 'Web hosting comment':
          textLabel = this.$t('text.web_hosting_comment');
          break;
        case 'Logo comment':
          textLabel = this.$t('text.logo_comment');
          break;
      }

      return textLabel;
    },

    field() {
      const [tab, name] = this.commentField.split('.');
      return { tab, name };
    },

    comments() {
      return this.$store.state.forms.projects.fields.comments;
    },

    model: {
      get() {
        const exists =
          this.comments[this.field.tab] && this.comments[this.field.tab][this.field.name];
        return exists ? this.comments[this.field.tab][this.field.name] : null;
      },
      set(val) {
        if (Array.isArray(this.comments[this.field.tab])) {
          // case tab is basic
          this.comments[this.field.tab] = {
            [this.field.name]: val,
          };
        } else {
          this.comments[this.field.tab][this.field.name] = val;
        }
      },
    },
  },
  methods: {
    close() {
      this.showBtn = true;
      this.comments[this.field.tab][this.field.name] = null;
    },
    add() {
      if (!this.comments[this.field.tab]) this.comments[this.field.tab] = {};

      this.showBtn = false;
    },
  },

  mounted() {
    //console.log(this.$store.state.forms.projects.fields)
    //console.log('commentfield: ' + this.commentField, this.field)
    if (this.showAddComments && this.commentField && this.model) this.showBtn = false;
  },
};
</script>
<style lang="scss">
.comments-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  .client-comment {
    width: 100%;
  }

  .admin-comment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .v-input__append-inner {
    position: absolute;
    top: -10px;
    right: 5px;
  }
}
</style>

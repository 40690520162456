<template lang="pug">
  .extra-block.contacts
    .company_details.mt-5
      label.font-bold.mb-1.font-medium {{ $t('text.contract_information_extra_block_heading') }}
      v-text-field(
        filled
        :value="generateLorem(model, {_key: 'title'})"
        v-model="model.title"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
        :placeholder="$t('text.contract_information_extra_block_heading')"
      )
      .d-sm-flex.item.mb-10.mb-sm-0(
        v-for="item in model.fields"
        :key="item.id"
      )
        .d-flex.flex-column.w-full
          label.font-bold.mb-1.font-medium {{ $t('text.extra_block_contact_field_name') }}
          v-text-field(
            :value="generateLorem(item, {_key: 'name', fallback: $t('text.extra_block_contact_field_name')})"
            v-model="item.name"
            filled
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
            :placeholder="$t('text.extra_block_contact_field_name_placeholder')"
          )
        div.mx-1
        .d-flex.flex-column.w-full
          label.font-bold.mb-1.font-medium {{ $t('text.extra_block_contact_field_phone') }}
          v-text-field(
            :value="generateLorem(item, {_key: 'value'})"
            v-model="item.value"
            filled
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
            :placeholder="$t('text.extra_block_contact_field_phone_placeholder')"
          )
        v-btn.ml-sm-3.mt-sm-3(v-if="!$vuetify.breakpoint.xs" icon @click="removeField(item)" :disabled="disabled")
          v-icon ic-delete
        div.d-flex.d-sm-block.justify-end(v-else)
          v-btn(rounded outlined small depressed color='error').text-capitalize
            | Remove
            v-icon(small right) ic-close


    v-btn.capitalize(color="black" text rounded depressed  @click="addField" :disabled="disabled")
      v-icon(left) mdi-plus
      | {{ $t('text.add_item') }}
    //- v-btn(color="primary" @click="addField" rounded depressed :disabled="disabled") {{ $t('text.add_field') }}
    v-btn.ml-5(color="accent" @click="addCompanyFields" v-if="showCompanyButton" :disabled="disabled") {{ $t('text.add_company_fields') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';

export default {
  mixins: [ExtraBlock],
  computed: {
    showCompanyButton() {
      return this.$store.state.forms.projects.fields.company.details_show;
    },
  },
  methods: {
    addField() {
      this.model.fields.push({
        id: this.$uuid.v4(),
        name: '',
        value: null,
      });
    },

    addCompanyFields() {
      const companyDetails = this.$store.state.forms.projects.fields.company.details;
      companyDetails.forEach((item) => {
        this.model.fields.push({ id: this.$uuid.v4(), name: item.name, value: item.value });
      });
    },

    removeField(item) {
      this.model.fields = this.model.fields.filter((x) => x.id != item.id);
    },
  },
};
</script>

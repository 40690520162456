import * as validators from './validators';
import i18n from '@/i18n';

export default (actions) => {
  return Object.assign(
    {
      DELETE_ARRFIELD_ITEM(ctx, { field, itemData }) {
        const filtered = ctx.state.fields[field].filter((el) => {
          return itemData !== el;
        });
        ctx.commit('UPDATE_FIELD', {
          field: field,
          value: filtered,
        });
      },

      // merging by resource we mean that we need to skip first iteration, because first level is resource name
      MERGE_ERRORS_BY_RESOURCE({ commit }, errors) {
        var remapedKeys = function (items, value) {
          var obj = {};
          obj[items[0]] = value;
          if (items.length > 1) {
            var nkeys = remapedKeys(items.slice(1), value);
            obj[items[0]] = nkeys;
            return obj;
          }
          return obj;
        };

        Object.keys(errors).forEach((err) => {
          const l = err.split('.').slice(1);
          const val = i18n.t('text.' + errors[err]);
          const remaped = remapedKeys(l, val);
          console.error('rempaed', remapedKeys(l, val, 0));
          commit('MERGE_ERRORS', remaped);
        });
      },

      UPDATE_FIELD({ dispatch, commit }, inputField) {
        commit('UPDATE_FIELD', inputField);
        dispatch('VALIDATE_FIELD', inputField);
      },

      UPDATE_MODEL_FIELD({ dispatch, commit }, inputField) {
        commit('UPDATE_MODEL_FIELD', inputField);
      },

      VALIDATE_FIELD(ctx, { field, value, parent = false }) {
        const validatable = parent
          ? ctx.getters.VALIDATABLES[parent][field]
          : ctx.getters.VALIDATABLES[field];

        // if validatable is not set for the field, doing nothing / everything is ok!
        if (!validatable) return;

        if (!Array.isArray(value) && _.isObject(value)) {
          //console.log('diving deep', field, value, parent)
          ctx.state.validationErrors[field] = {};
          //diving deep
          const valfields = Object.keys(value);
          valfields.forEach((valfield) => {
            ctx.dispatch('VALIDATE_FIELD', {
              field: valfield,
              value: value[valfield],
              parent: field,
            });
          });

          return;
        }

        //console.log('comecome', field, value, parent)

        const vldbs = extractValidatables(validatable);

        //console.log('vldbs', vldbs)
        vldbs.forEach((vldb) => {
          if (!_.has(validators, vldb.type)) {
            console.warn(
              `VALIDATOR TYPE [${vldb.type}] IS NOT DEFINED: in modules/documents/form/validators.js`
            );
            return;
          }

          let stateFields = parent ? ctx.state.fields[parent] : ctx.state.fields;

          //overriding by options
          if (vldb.options.root) stateFields = ctx.state.fields;

          //console.log('testing validation: ', vldb, value, field, stateFields)
          if (validators[vldb.type](value, vldb.params, stateFields, field, vldb.message))
            ctx.commit('SET_ERROR', {
              field: field,
              message: vldb.message,
              parent: parent,
            });
        });
      },

      async VALIDATE_FIELDS(ctx, only = false) {
        return new Promise((resolve, reject) => {
          ctx.commit('SET_VALIDATING', true);
          ctx.commit('SET_ERRORS', {});
          const fieldNames = only ? only : Object.keys(ctx.state.fields);
          fieldNames.forEach((fieldName) => {
            ctx.dispatch('VALIDATE_FIELD', {
              field: fieldName,
              value: ctx.state.fields[fieldName],
            });
          });
          ctx.getters.HAS_ERRORS ? reject('rejecting with validation errors') : resolve();
          ctx.commit('SET_VALIDATING', false);
        });
      },

      VALIDATE_FIELDS_TAB(ctx) {
        const currentTab = ctx.state.current_tab;
        const projectType = ctx.state.fields.create_type;

        if (currentTab === 'create') return;

        const basicTab = ['basic', 'branding', 'company'];
        const websiteTab = ['website'];
        const styleTab = ['style'];
        const sitemapTab = ['sitemap'];
        const contentTab = ['content'];
        const categoriesTab = ['categories'];
        const productsTab = ['products'];
        const submitTab =
          projectType === 'shop'
            ? [].concat(
                basicTab,
                websiteTab,
                styleTab,
                sitemapTab,
                contentTab,
                categoriesTab,
                productsTab
              )
            : [].concat(basicTab, websiteTab, styleTab, sitemapTab, contentTab);
        const validationFields = new Map()
          .set('basic', basicTab)
          .set('website', websiteTab)
          .set('logo', styleTab)
          .set('sitemap', sitemapTab)
          .set('content', contentTab)
          .set('categories', categoriesTab)
          .set('products', productsTab)
          .set('payment', [])
          .set('services', [])
          .set('submit', submitTab);

        const getValidation = validationFields.get(currentTab) ?? [];
        getValidation.forEach((fieldName) => {
          ctx.dispatch('VALIDATE_FIELD', {
            field: fieldName,
            value: ctx.state.fields[fieldName],
          });
        });

        if (currentTab === 'content') {
          const cloneErrorTab = Object.entries(ctx.state.validationErrors).map(([key, value]) => {
            const checkContentValue = key === 'content' ? { ...value } : value;
            return value && Object.keys(value).length && { [key]: checkContentValue };
          });

          const sitemapTree = flattenElement(ctx.state.fields.sitemap, 'elements');
          const sitemapValid = sitemapTree.filter((item) => item.content);
          const parseArrToObj = { ...cloneErrorTab.filter((error) => error) };
          const errorContent = {};
          Object.values(parseArrToObj).forEach((value) => _.merge(errorContent, value));

          if (sitemapValid.length && Object.keys(errorContent).length) {
            const sitemapIds = sitemapValid.map((item) => item.uuid);

            if (errorContent.content && Object.keys(errorContent.content).length) {
              sitemapIds.forEach((validId) => delete errorContent.content[validId]);
            }
          }
          ctx.commit('SET_ERROR', {
            field: 'content',
            message: errorContent.content,
          });
        }
        if (currentTab === 'products') {
          const cloneErrorTab = Object.entries(ctx.state.validationErrors).map(([key, value]) => {
            const checkContentValue = key === 'products' ? { ...value } : value;
            return value && Object.keys(value).length && { [key]: checkContentValue };
          });

          const error = {};
          const categoriesTree = flattenElement(ctx.state.fields.categories, 'elements');
          const categoriesInValid = categoriesTree.filter((item) => !item.products.length);
          const parseArrToObj = {
            ...cloneErrorTab.filter((error) => error && _.isObject(error)),
          };

          Object.values(parseArrToObj).forEach((value) => _.merge(error, value));

          if (categoriesInValid.length) {
            const categoryIds = categoriesInValid.map((item) => item.uuid);

            if (categoryIds.length) {
              error.products = _.pickBy(error.products, (value, key) => categoryIds.includes(key));
            }
          }

          if (!categoriesInValid.length) error.products = {};

          ctx.commit('SET_ERROR', {
            field: 'products',
            message: error.products,
          });
        }
      },
    },
    actions
  );
};

/*
RETURN exp:
[
  { type:"required", params:[], message:'error message' },
  { type:"equals", params:['password'], message:'error message' },
  ....
  { type: .... }
]
*/
const flattenElement = (tree, key) => {
  let children = [];

  return tree
    .map((m) => {
      if (m[key] && m[key].length) {
        children = [...children, ...m[key]];
      }
      return m;
    })
    .concat(children.length ? flattenElement(children, key) : children);
};

function extractValidatables(validatable) {
  //console.log('calidat', validatable)
  const types = validatable[0].split('|');
  const errorMsgs = validatable[1];
  const options = validatable[2] ?? {};
  let ret = [];

  types.forEach((type, index) => {
    const params = type.split(':');
    ret.push({
      type: params[0],
      params: params.slice(1),
      message: Array.isArray(errorMsgs) ? errorMsgs[index] : errorMsgs,
      options,
    });
  });
  //console.log('calidat ret', ret)
  return ret;
}

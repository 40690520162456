<template lang="pug">
  .content-extras(ref="extras")
    v-sheet.extra-sheet.border-primary.mb-4(
      v-for="extra in model"
      v-if="extra.added"
      :key="extra.id"
    )

      group-container(v-bind="extraTitles(extra.id)")

      v-btn.close(icon small @click="closeExtra(extra.id)" :disabled="disabled")
        v-icon(small) ic-close

      component(
        :is="extraComponent(extra.id)"
        :model="extra"
        :disabled="disabled"
        :isAutoFilled="isAutoFilled"
      )


    group-container(
      :title="$t('text.page_extras')"
      :subtitle="$t('text.what_extra_block_of_information_would_you_like_to_use_on_this_page')"
    )

    draggable(
      tag="div"
      :list="items"
      :group="{ name: 'extras' }"
      animation="300" :fallbackOnBody="true" swapThreshold="0.9"
      ghostClass="ghost"
      handle=".handle"
      @change="reOrder"
      :disabled="disabled"
    )
      v-card.d-sm-flex.align-center.pa-6.mb-4.relative(
        v-for="(extra, index) in sortedITems"
        :key="extra.id"
        elevation="0"
        outlined 
        style="border-color: #4262FF;"
      )
        v-btn.handle(icon small :disabled="disabled" absolute top right v-if="$vuetify.breakpoint.xs")
          v-icon(small) ic-open-with
        .d-flex.flex-column
          h2.name {{ $t(extra.name_key) }}
          .desc {{ $t(extra.description_key) }}
        v-spacer
        div.d-flex.justify-end.flex-wrap.border   
          v-btn.handle(icon small :disabled="disabled" v-if="!$vuetify.breakpoint.xs")
            v-icon ic-open-with
          div(style="width: 100%; height: 10px;") 
          v-btn.mt-5(
            large
            @click="addExtra(extra)" 
            v-if="!extra.added" 
            rounded
            color="primary"
            width="175px" 
            depressed
            :block="$vuetify.breakpoint.xs"
            :disabled="disabled") {{ $t('text.add') }} 
              v-icon() ic-add

</template>


<script>
import GroupContainer from '@/components/forms/parts/GroupContainer'
import ExtraFeatures from './Features'
import ExtraGallery from './Gallery'
import ExtraContacts from './Contacts'
import ExtraRegistration from './Registration'
import ExtraTestimonials from './Testimonials'
import ExtraPartners from './Partners'

import DragState from "@/components/forms/parts/DragState"
import draggable from "vuedraggable"
import vuetize from '@/scripts/vuetize'

import { doGenerateText } from '@/helpers';
export default {
  components: { GroupContainer, draggable, DragState },
  props: {
    model: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAutoFilled: {
      type: Boolean,
      default: false,
    },
    
  },
  data: function() {

    return {
      items: [
        {
          id: 'testimonials',
          name_key: 'text.testimonials',
          description_key: 'text.add_testimonials_of_your_existing_customers_and_show_your_website_visitors_that_you_are_good_at_what_you_do',
          name: this.$t('text.testimonials'),
          description: this.$t('text.add_testimonials_of_your_existing_customers_and_show_your_website_visitors_that_you_are_good_at_what_you_do'),
          component: ExtraTestimonials,
          added: false,
          order: 6,
        },
        {
          id: 'gallery',
          name_key: 'text.gallery',
          description_key: 'text.add_an_image_gallery_of_your_team_work_or_office_title_description_photos',
          name: this.$t('text.gallery'),
          description: this.$t('text.add_an_image_gallery_of_your_team_work_or_office_title_description_photos'),
          component: ExtraGallery,
          added: false,
          order: 1
        },
        {
          id: 'features',
          name_key: 'text.advantages_features',
          description_key: 'text.tell_your_website_visitors_why_you_are_better_than_others_pictures_description',
          name: this.$t('text.advantages_features'),
          description: this.$t('text.tell_your_website_visitors_why_you_are_better_than_others_pictures_description'),
          component: ExtraFeatures,
          added: false,
          order: 2
        },
        {
          id: 'partners',
          name_key: 'text.partner_logos',
          description_key: 'text.add_logos_of_your_partners_and_show_your_website_visitors_how_trustworthy_you_are_logos_links',
          name: this.$t('text.partner_logos'),
          description: this.$t('text.add_logos_of_your_partners_and_show_your_website_visitors_how_trustworthy_you_are_logos_links'),
          component: ExtraPartners,
          added: false,
          order: 3
        },
        {
          id: 'contacts',
          name_key: 'text.contacts',
          description_key: 'text.provide_your_contact_details_and_encourage_your_website_visitors_to_contact_you_your_contact_information_will_be_taken',
          name: this.$t('text.contacts'),
          description: this.$t('text.provide_your_contact_details_and_encourage_your_website_visitors_to_contact_you_your_contact_information_will_be_taken'),
          component: ExtraContacts,
          added: false,
          order: 4
        },
        {
          id: 'registration_form',
          name_key: 'text.registration_form',
          description_key: 'text.add_a_registration_form_generate_leads_and_get_in_touch_your_customers_create_form_fields',
          name: this.$t('text.registration_form'),
          description: this.$t('text.add_a_registration_form_generate_leads_and_get_in_touch_your_customers_create_form_fields'),
          component: ExtraRegistration,
          added: false,
          order: 5
        }
      ]
    }
  },
  computed: {
    sortedITems() {
      this.sortItems()
      return this.items
    },

    leftExtras() {
      let count = 0
      Object.keys(this.model).forEach((key) => {
        if (!this.model[key].added) count++
      })
      return count
    }
  },

  methods: {

    sortItems() {
      this.model.forEach((extra, index) => {
        const item = this.items.find(x => x.id == extra.id)
        item.added = extra.added
        item.order = index
      })

      this.items = this.items.sort((a, b) => {
        return a.order - b.order
      })
    },

    extraComponent(id) {
      return this.items.find(x => x.id == id).component
      //return this.items[extra.id].component
    },

    extraTitles(id) {
      const ex = this.items.find(x => x.id == id)
      return {
        title: ex.name,
        subtitle: ex.description
      }
    },

    addExtra(extra) {
      extra.added = true
      this.model.map(x => {
        if (x.id == extra.id)
          x.added = true
        return x
      })
    },
    closeExtra(id) {
      this.items.find(x => x.id == id).added = false
      this.model.map(x => {
        if (x.id == id)
          x.added = false
      })
    },

    reOrder(state) {
      console.log(state)
      this.model.move(state.moved.oldIndex, state.moved.newIndex)
      //this.model[state.moved.element.id].order = state.moved.newIndex
      //this.model['features'].order = 6


      /*this.items.forEach((x, index) => {
        this.model[x.id].order = index
      })*/
    }
  },
  mounted() {
    console.log('extras mounted', this.model)
  }
}
</script>

<style lang="scss">
.content-extras {
  .v-card {
    border-radius: 5px;
    //padding: 20px;
  }

  .content{
    display: flex;
    align-items: flex-start;
    padding: $content-pad / 2;

  }

  .v-input.v-input--checkbox {
    margin-top: 0px;
    padding-top: 0px;
  }

  .extra-sheet {
    position: relative;
    padding: 30px;
    // padding: 0px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 10px 10px;
      
    }
    .field-title {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding-right: 30px;
      }
    }
    
    .close {
      position: absolute;
      top: 28px;
      right: 20px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        top: 10px;
        right: 10px;
      }
    }

  }
  .name {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px !important;
      color: #3f3f3f;
    }
  .desc {
    font-size: 16px;
  }
  .extra-block {

    .separator {
      height: 10px;
      background: rgba($cl-primary, 0.5);
    }

    .separator:nth-last-child(1) {
      display: none;
    }
  }
}
</style>

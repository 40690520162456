<template>
  <v-alert border="left" outlined prominent type="warning">
    <v-row align="center">
      <v-col class="grow">
        <p>
          {{ $t('text.message_redirect_client_user_to_a_right_department_first_sentence') }}
          <a :href="domain"> {{ domain }} </a> .
          <br />
          {{ $t('text.message_redirect_client_user_to_a_right_department_second_sentence') }}
        </p>
        <p class="mb-0">
          {{ $t('text.message_redirect_client_user_to_a_right_department_third_sentence') }}
        </p>
      </v-col>
      <v-col class="shrink">
        <v-btn color="warning" rounded @click="onRedirect">
          {{ $t('text.redirect_now_button') }}
          <v-icon right> mdi-play</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  name: 'MoveDepartment',
  props: {
    domain: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    onRedirect() {
      if (!this.domain.length) return;
      window.location.href = this.domain;
    },
  },
};
</script>
<style scoped></style>

export const PROJECT_NEW = 1
export const PROJECT_FREE = 2
export const PROJECT_CHECK = 3
export const PROJECT_REQUIRES_CORRECTIONS = 4
export const PROJECT_READY_FOR_DEV = 5
export const PROJECT_DEV = 6
export const PROJECT_IN_PROGRESS = 7
export const PROJECT_FINAL_CHECK = 8
export const PROJECT_READY_FOR_REVIEW = 9
export const PROJECT_FIX_CHECK = 10
export const PROJECT_FIX_DEV = 11
export const PROJECT_FIX_IN_PROGRESS = 12
export const PROJECT_FIX_FINAL_CHECK = 13
export const PROJECT_FIX_READY_FOR_REVIEW = 14
export const PROJECT_LAUNCH = 80
export const PROJECT_LAUNCH_COMPLETED = 90
export const PROJECT_COMPLETED = 99
export const PROJECT_CANCELED = 100

<template>
  <v-btn @click="$emit('input')" class="capitalize" :filled="filled" depressed :disabled="disabled" :color="color" :small="small"
    :dark="dark">
    <template v-if="iconRight">
      {{ label }}
      <v-icon :dark="dark" v-if="icon" right>{{ icon }}</v-icon>
    </template>
    <template v-else>
      <v-icon :dark="dark" v-if="icon" left>{{ icon }}</v-icon>
      {{ label }}
    </template>
  </v-btn>
</template>


<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Default label'
    },
    color: {
      type: String,
      default: 'white'
    },
    small: {
      type: Boolean,
      defalut: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    dark: false,
    hiddenXS: false
  },

  computed: {
    hidden_xs() {
      return this.hiddenXS && this.$vuetify.breakpoint.xs
    },

    icon_right() {
      return !this.hidden_xs && this.iconRight
    },

    icon_left() {
      return !this.hidden_xs && this.iconLeft
    }
  },
}



</script>


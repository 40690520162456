<template>
  <div>
    <!-- <slot name="tips-container"></slot> -->
    <div class="tab-content tab-first">
      <div class="d-flex full-height">
        <div class="flex-grow-1">
          <div class="flex h-full full-height flex-column justify-center">
            <h2 class="text-left text-222222 text-center text-5xl mt-sm-10 mb-7 font-semibold">
              {{ $t('text.let_start_by_choosing_what_to_create') }}
            </h2>
            <h3 class="mb-9 mt-1 text-222222 font-medium text-center size-lg text-2xl">
              {{ $t('text.site_type_description') }}
            </h3>
            <div class="mx-auto" style="max-width: 500px; width: 100%">
              <v-radio-group v-model="createType" :disabled="isLocked" :mandatory="false">
                <div class="grid grid-cols-2 gap-6" style="gap: 2.5rem">
                  <div
                    :class="[
                      createType === 'site'
                        ? 'border-primary border shadow-xl primary text-white'
                        : '',
                    ]"
                    class="relative shadow1"
                  >
                    <img class="block mx-auto" src="@/assets/images/first1.svg" />
                    <p class="text-24 text-center capitalize px-2">{{ $t('text.website') }}</p>
                    <v-radio class="absolute top-0 left-0 w-full h-full opacity-0" value="site">
                      <template v-slot:label>
                        <div class="text-bigger capitalize px-2">
                          {{ $t('text.website') }}
                        </div>
                      </template>
                    </v-radio>
                  </div>
                  <div
                    :class="[
                      createType === 'shop'
                        ? 'border-primary border shadow-xl primary text-white'
                        : '',
                    ]"
                    class="relative shadow1"
                  >
                    <img class="block mx-auto" src="@/assets/images/first2.svg" />
                    <p class="text-24 text-center capitalize px-2">{{ $t('text.online_store') }}</p>
                    <v-radio class="absolute top-0 left-0 w-full h-full opacity-0" value="shop">
                      <template v-slot:label>
                        <div class="text-bigger capitalize px-2">
                          {{ $t('text.online_store') }}
                        </div>
                      </template>
                    </v-radio>
                  </div>
                </div>
              </v-radio-group>
            </div>
            <add-comment commentField="afirst.type"></add-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddComment from '@/components/forms/parts/CommentAdd';

export default {
  components: { AddComment },
  props: {
    isLocked: { type: Boolean },
    tabItems: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: () => '',
    },
  },
  data: function () {
    return {
      title: 'text.what_we_will_create',
      subtitle: 'text.choose_wisely',
    };
  },
  mounted() {},
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
    createType: {
      get() {
        return this.$store.state.forms.projects.fields.create_type;
      },
      set(value) {
        this.$store.dispatch('forms/projects/UPDATE_FIELD', {
          field: 'create_type',
          value,
        });
      },
    },
  },
};
</script>
<style lang="scss">
.tab-first {
  .text-title {
    font-size: 30px;
    font-weight: 600;
  }

  .comments-wrapper {
    width: 100%;
  }

  .radio-group {
    align-items: flex-start;

    .v-radio {
      justify-content: center;

      label.v-label {
        flex: 0 0 auto;
      }

      margin-bottom: 0px !important;
      padding: 10px 10px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 1.5em;
    }

    .radio-group {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
</style>

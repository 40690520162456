<template>
  <div class="p-10 w-full">
    <div class="mt-12">
      <h3 class="title-compatible-browser">
        {{ $t('text.browser_something_wrong') }}
      </h3>
      <div class="mt-6 text-center font-medium sm:text-lg md:text-2xl">
        <p class="mb-0">{{ $t('text.browser_description_warning') }}</p>
        <p class="mb-0">{{ $t('text.browser_description_suggest') }}</p>
      </div>
      <div class="compatible-version">
        <p class="mb-0">{{ $t('text.browser_minimum_versions') }}:</p>
        <p>{{ displayBrowserVersion }}</p>
      </div>
      <div class="text-center mt-6">
        <p class="browser-note">{{ $t('text.browser_note') }}</p>
        <v-btn class="mt-4" color="primary" depressed large rounded @click="onAcceptRisk">
          {{ $t('text.proceed_accept_risk_btn') }}
        </v-btn>
      </div>
      <div class="mt-5">
        <img
          alt="Compatible Browser"
          class="m-auto sm:w-2/3 md:w-2/5 max-w-fit"
          src="@/assets/images/warning-page-bottom-banner.svg"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BROWSER_COMPATIBLE, BROWSER_VERSION } from '@constant';

export default {
  name: 'CompatibleBrowser',
  props: {
    isLogin: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    displayBrowserVersion() {
      let chainBrowsers = '';
      this.browserCompatible.forEach(({ name, version }) => {
        chainBrowsers += ` ${name} ${version} /`;
      });
      return chainBrowsers.slice(0, -1);
    },
  },
  data() {
    return {
      browserCompatible: [
        {
          name: BROWSER_COMPATIBLE.chrome,
          version: BROWSER_VERSION.chrome,
        },
        {
          name: BROWSER_COMPATIBLE.safari,
          version: BROWSER_VERSION.safari,
        },
        {
          name: BROWSER_COMPATIBLE.firefox,
          version: BROWSER_VERSION.firefox,
        },
        {
          name: BROWSER_COMPATIBLE.edge,
          version: BROWSER_VERSION.edge,
        },
        {
          name: BROWSER_COMPATIBLE.ios,
          version: BROWSER_VERSION.ios,
        },
        {
          name: BROWSER_COMPATIBLE.android,
          version: BROWSER_VERSION.android,
        },
      ],
    };
  },
  methods: {
    onAcceptRisk() {
      if (this.isLogin) {
        this.$api.post('users/accept-the-risks');
      }
      this.$emit('click', true);
    },
  },
  destroyed() {
    this.$root.$off('click', this.onAcceptRisk);
  },
};
</script>
<style lang="scss" scoped>
.title-compatible-browser {
  font-size: 42px;
  font-weight: 600;
  text-align: center;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 28px;
  }
}

.compatible-version {
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  font-weight: normal;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
  }
}

.browser-note {
  margin-top: 24px;
  font-size: 16px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 15px;
    font-size: 12px;
  }
}
</style>

<template lang="pug">
  .tab-content.submit
    .full-height.centerize.flex-column
      //- slot(name="tips-container")
      img.block.max-w-full.mx-auto(style="max-width: 250px;")(:src="require('@/assets/images/finalstep.svg')")
      h1.maintitle {{ $t(title) }}
      .t-small.my-2 {{ $t('text.Congratulations_you_are_at_the_finish_line_Time_to_submit_your_form') }}
      .d-flex.flex-column(style="color: #5F5F5F;")
        v-checkbox(v-model="accepted_agreement" hide-details :disabled="isLocked")
          template(v-slot:label)
            span.t-small {{ $t('text.I_have_provided_all_the_information_that_I_want_to_put_on_my_website_and_I_realize_that_I_will_not_be_able_to_send_additional_content') }}

        .d-flex
          v-checkbox(v-model="accepted_terms" hide-details :disabled="isLocked")
            template(v-slot:label)
              .div-slot
                | {{ $t('text.i_have_read_and_i_agree', policyTexts) }} 
                //- span.t-small I have read and I agree to Terms & Conditions
                a(
                  target="_blank"
                  :href="$t('text.url.terms')"
                  @click.stop
                ) {{ $t('text.read_more') }}
      //- .my-8
      //-   | {{ $t('text.after_submitting_you_will_not_be_able_to_edit_this_application_form') }}
      v-btn.my-8.normal-case.tracking-normal(:loading="submitting" large depressed color="primary" :disabled="isDisabled" @click="finilize")
        | {{ $t('text.submit_application') }}
</template>
<script>
import FormMixin from '@front/mixins/FormMixin';
import { PROJECT_COMPLETE, PROJECT_EDIT } from '@/router/routes';

export default {
  mixins: [FormMixin('projects')],
  props: {
    isLocked: false,
  },
  data: function () {
    return {
      title: 'text.final_step',
      subtitle: 'text.after_submitting_you_will_not_be_able_to_edit_this_application_form',
      submitting: false,
    };
  },
  methods: {
    gotoTerms() {
      window.open('https://getspace.ie/terms-conditions/', '_blank');
    },

    finilize() {
      this.$emit('submitting');
      this.submitting = true;
      this.formDispatch('VALIDATE_FIELDS')
        .then(() =>
          this.formDispatch('CHECK_DOMAIN').then(() => {
            this.formDispatch('FINILIZE')
              .then(async (res) => {
                let isUpdated = await this.$store.dispatch('auth/LOAD');
                if (isUpdated) {
                  this.$router.push({ name: PROJECT_COMPLETE }).then();
                } else {
                  let uuid = this.$store.state.forms.projects.fields.uuid;
                  this.$router.replace({ name: PROJECT_EDIT, params: { id: uuid } }).then();
                }
              })
              .finally(() => {
                this.submitting = false;
              });
          })
        )
        .catch((error) => {
          console.error('Error finalize->', error);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
  computed: {
    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')];
    },
    isDisabled() {
      return this.accepted_agreement && this.accepted_terms && !this.isLocked ? false : true;
    },
    policyTexts() {
      return {
        agree_with_terms: this.$t('text.agree_with_terms'),
        //'<a href="'+ this.$t('text.url.terms') +'" class="primary--text" target="_blank" @click.stop>'+ this.$t('text.agree_with_terms') +'</a>',
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.maintitle {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: $cl-black-text;
}

.subtitle {
}
</style>


export default (getters) => Object.assign({
  VALIDATES: () => {
    return {}
  },

  VALIDATABLES: (state, getters) => {
    return getters.VALIDATES
  },

  HAS_ERRORS: (state) => {
    const errors = countErrors(state.validationErrors)
    return errors > 0
  },

  IS_EDITING: (state) => {
    return state.is_edit
  }
}, getters)


function countErrors(errors) {
  let errCount = 0
  Object.keys(errors).forEach((e) => {
    // debugger
    if (typeof errors[e] === 'string') errCount += 1
    else if (!Array.isArray(errors[e]) && _.isObject(errors[e])) errCount += countErrors(errors[e])
    else errCount += errors[e].length
  })

  return errCount
}
import Vue from 'vue';
import UAParser from 'ua-parser-js';
import { BROWSER_COMPATIBLE, BROWSER_VERSION } from '@constant';

const utils = () => {
  Vue.prototype.$utils = {
    replaceStrBetweenTwoCharacter(str = '', character1 = '', character2 = '', replaceTo = '') {
      const regex = new RegExp(character1 + '.*' + character2);
      return str.replace(regex, String(replaceTo));
    },
    checkCompatibleBrowser() {
      const userAgent = navigator.userAgent;
      const { browser, os } = UAParser(userAgent);
      const osName = String(os.name).toLocaleLowerCase();
      const browserName = String(browser.name).toLocaleLowerCase();
      const browserVer = parseFloat(browser.version);
      const browserCompatible = Object.keys(BROWSER_COMPATIBLE)
        .map((key) => ({ [key]: key }))
        .reduce((result, item) => {
          const key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        }, {});
      if (osName === 'ios') {
        const iOSBrowser = browserName.replace('mobile ', '');
        if (iOSBrowser === browserCompatible.chrome && browserVer >= BROWSER_VERSION.chrome) {
          return true;
        }
        if (iOSBrowser === browserCompatible.firefox && browserVer >= BROWSER_VERSION.firefox) {
          return true;
        }
        return iOSBrowser === browserCompatible.safari && browserVer >= BROWSER_VERSION.safari;
      }
      if (osName === 'android') {
        const androidBrowser = browserName.replace('mobile ', '');

        if (androidBrowser === browserCompatible.chrome && browserVer >= BROWSER_VERSION.chrome) {
          return true;
        }
        return (
          androidBrowser === browserCompatible.firefox && browserVer >= BROWSER_VERSION.firefox
        );
      }
      if (browserName === browserCompatible.chrome && browserVer >= BROWSER_VERSION.chrome) {
        return true;
      }
      if (browserName === browserCompatible.firefox && browserVer >= BROWSER_VERSION.firefox) {
        return true;
      }
      if (browserName === browserCompatible.safari && browserVer >= BROWSER_VERSION.safari) {
        return true;
      }
      return browserName === browserCompatible.edge && browserVer >= BROWSER_VERSION.edge;
    },
  };
};
Vue.use(utils);

<template lang="pug">
  .field-container
    h2.field-title
      span.red--text(v-if="mandatory") *&nbsp;
      | {{ title }}
      tip-content(v-if="tip" :tip="tip")

    .field-subtitle.mb-2
      slot(name="subtitle" :text="subtitle")
        | {{ subtitle }}
    slot
    add-comment.my-4(:commentField="commentField")

</template>

<script>
import AddComment from './CommentAdd'
import TipContent from './TipContent';

export default {
  components: { AddComment, TipContent },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    commentField: {
      type: String,
      default: null
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: ''
    }
  }
}
</script>

/*"Manager": {

  "check-if-designer-attached": { PROJECT_REQUIRES_CORRECTIONS, PROJECT_DEV }
  "check-if-designer-no":       { PROJECT_REQUIRES_CORRECTIONS, PROJECT_READY_FOR_DEV }
  "final-check":                { PROJECT_READY_FOR_REVIEW, PROJECT_DEV },
  "fix-check":                  { PROJECT_READY_FOR_REVIEW, PROJECT_FIX_DEV },
  'fix-final-check':            { PROJECT_FIX_READY_FOR_REVIEW, PROJECT_FIX_DEV  }

}

"Designer": {

  "dev": { PROJECT_IN_PROGRESS, PROJECT_CHECK },
  "in_progress": { PROJECT_FINAL_CHECK, PROJECT_CHECK },
  "fix_dev": { PROJECT_FIX_IN_PROGRESS, PROJECT_FIX_CHECK },
  "fix_in_progress": { PROJECT_FIX_FINAL_CHECK, PROJECT_FIX_CHECK }


}*/
import * as pStatus from './statuses'
import Store from '@/store'
import i18n from '@/i18n'

export default {

  managerMaps: [
    // { ifstatus: pStatus.PROJECT_CHECK, attached: true, items:[ pStatus.PROJECT_REQUIRES_CORRECTIONS, pStatus.PROJECT_DEV ] },
    { ifstatus: pStatus.PROJECT_CHECK, attached: true, items:[ pStatus.PROJECT_REQUIRES_CORRECTIONS, pStatus.PROJECT_READY_FOR_DEV ] },
    { ifstatus: pStatus.PROJECT_CHECK, attached: false, items:[ pStatus.PROJECT_REQUIRES_CORRECTIONS, pStatus.PROJECT_READY_FOR_DEV ] },
    { ifstatus: pStatus.PROJECT_FINAL_CHECK, attached: true, items:[ pStatus.PROJECT_READY_FOR_REVIEW, pStatus.PROJECT_DEV ] },
    { ifstatus: pStatus.PROJECT_FIX_CHECK, attached: true, items:[ pStatus.PROJECT_READY_FOR_REVIEW, pStatus.PROJECT_FIX_DEV ] },
    { ifstatus: pStatus.PROJECT_FIX_FINAL_CHECK, attached: true, items:[ pStatus.PROJECT_FIX_READY_FOR_REVIEW, pStatus.PROJECT_FIX_DEV ] },
    { ifstatus: pStatus.PROJECT_COMPLETED, attached: true, items:[ pStatus.PROJECT_LAUNCH ] },
    { ifstatus: pStatus.PROJECT_LAUNCH, attached: true, items:[ pStatus.PROJECT_LAUNCH_COMPLETED ] }
  ],

  designerMaps: [
    { ifstatus: pStatus.PROJECT_DEV, items:[ pStatus.PROJECT_IN_PROGRESS, pStatus.PROJECT_CHECK ] },
    { ifstatus: pStatus.PROJECT_IN_PROGRESS, items:[ pStatus.PROJECT_FINAL_CHECK, pStatus.PROJECT_CHECK ] },
    { ifstatus: pStatus.PROJECT_FIX_DEV, items:[ pStatus.PROJECT_FIX_IN_PROGRESS, pStatus.PROJECT_FIX_CHECK ] },
    { ifstatus: pStatus.PROJECT_FIX_IN_PROGRESS, items:[ pStatus.PROJECT_FIX_FINAL_CHECK, pStatus.PROJECT_FIX_CHECK ] },
    { ifstatus: pStatus.PROJECT_LAUNCH, items:[ pStatus.PROJECT_LAUNCH_COMPLETED ] }
  ],

  mapStatuses: function(items) {
    let results = []
    items.forEach(x => {
      results.push({
        text: i18n.t('text.statuses.' + Store.state.passives.project_statuses.find(y => y.id == x).name_code),
        value: x
      })
    })
    return results
  },

  mapManager: function(status_id) {
    const attached_designer = Store.state.forms.projects.fields.designer_uuid
    let map1 = (attached_designer)
      ? this.managerMaps.find(x => x.ifstatus == status_id && x.attached)
      : this.managerMaps.find(x => x.ifstatus == status_id && !x.attached);
    let map = this.managerMaps.find(x => x.ifstatus == status_id && x.attached)
    return (map) ? this.mapStatuses(map.items) : []
  },

  mapManager1: function(status_id) {
    const attached_designer = Store.state.forms.projects.fields.designer_uuid
    let map = (attached_designer)
      ? this.managerMaps.find(x => x.ifstatus == status_id && x.attached)
      : this.managerMaps.find(x => x.ifstatus == status_id && !x.attached);

    return (map) ? this.mapStatuses(map.items) : []
  },

  mapDesigner: function(status_id) {
    const map = this.designerMaps.find(x => x.ifstatus == status_id)
    return (map) ? this.mapStatuses(map.items) : []
  },

  mapAdmin: function() {
    return Store.state.passives.project_statuses.map(x => {
      return {
        text: i18n.t('text.statuses.' + x.name_code),
        value: x.id
      }
    })
  },


  getUserMaps: function(user, status_id) {
    if (user.isDesigner())
        return this.mapDesigner(status_id)
      else if (user.isManager())
        return this.mapManager(status_id)
      else if (user.isAdmin())
        return this.mapAdmin()
      else
        return []
  }
}

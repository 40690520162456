import { mix } from 'mixwith';
import DocumentSchema from '@abs/document/schema';

const TranslationsSchema = (superclass) =>
  class extends mix(superclass).with(DocumentSchema) {
    /**
     * Parse project data that came from API
     */
    static parse(data) {
      const d = super.parse(data);

      d.id = data.id;
      d.key = data.key;
      d.en = data.en;
      d.bg = data.bg;
      d.es = data.es;
      d.lt = data.lt;
      d.lv = data.lv;
      d.pl = data.pl;
      d.ro = data.ro;
      d.ru = data.ru;
      d.hu = data.hu;

      return d;
    }

    serialize(options = {}) {
      return {
        ...super.serialize(options),
        id: this.id,
        key: this.key,
        en: this.en,
        bg: this.bg,
        es: this.es,
        lt: this.lt,
        lv: this.lv,
        pl: this.pl,
        ro: this.ro,
        ru: this.ru,
        hu: this.hu,
      };
    }
  };

export default TranslationsSchema;

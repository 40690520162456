import router from '@/router';
import _ from 'lodash';
import { ERROR_404, routes } from '@/router/routes';
import { HOME, LET_GET_STARTED, PROJECTS } from '@/router/routes';
import Api from '@/plugins/api';
import AuthUser from '@models/user/authUser';
import i18n from '@/i18n';

export default {
  AUTHENTICATE({ commit, dispatch, state }, { accessToken, preloadedData, redirect = false }) {
    commit('UPDATE_ACCESS_TOKEN', accessToken);
    commit('UPDATE_ACCESS_TOKEN_COOKIE');

    dispatch('INIT', preloadedData).then(() => {
      commit('SET_LOADED');

      // if user has zero projects we redirecting to get started page.
      if (state.user && state.user.project_count === 0 && state.user.isClient()) {
        router.push({ name: LET_GET_STARTED });
        return;
      }

      if (redirect) {
        const queryRedirect = router.history.current.query.redirect ?? false;
        const _defaultPage = { name: PROJECTS };
        router.push(_defaultPage);
        // router.push(queryRedirect ? { path: queryRedirect } : _defaultPage);
      }
    });
  },

  INIT({ dispatch, commit, state }, preloadedData = null) {
    // clearing old data
    commit('forms/projects/UPDATE_STATE', { field: 'show_form', value: false }, { root: true });
    commit('repositories/projects/CLEAR_ITEMS', null, { root: true });

    // Use preloaded data if possible, load everything if not
    if (!preloadedData) {
      return;
    }
    if ('tutorials' in preloadedData) {
      commit('forms/projects/SET_TUTORIALS', preloadedData.tutorials, { root: true });
    }
    if ('passives' in preloadedData) {
      //console.log('a')
      commit('passives/LOAD_DATA', preloadedData.passives, { root: true });
    }

    if ('workers' in preloadedData) commit('SET_WORKERS', preloadedData.workers, { root: true });

    /**
     * Set account data
     */
    if ('account' in preloadedData) commit('SET_ACCOUNT', preloadedData.account);

    /**
     * Set info impersonate data
     */
    if ('impersonate_by_department_configurations' in preloadedData)
      commit('SET_IMPERSONATE', preloadedData.impersonate_by_department_configurations);

    /**
     * Set user data
     */
    if ('user' in preloadedData) {
      dispatch('SET_USER', preloadedData.user);
      const routerPages = Object.values(routes).map((route) => route);
      const pageCurrent = _.find(routerPages, ['name', router.history.current.name]);
      if (pageCurrent && pageCurrent.roles.length) {
        if (!pageCurrent.roles.includes(preloadedData.user.user_type_id))
          router.push({ name: ERROR_404 }).catch(() => {});
      }
      // if ('projects' in preloadedData.user)
      //   dispatch('repositories/projects/SET_ITEMS', preloadedData.user.projects, { root: true })
    }

    if ('admin' in preloadedData && preloadedData.user.is_admin) {
      const admin = preloadedData['admin'];

      if ('users' in admin) dispatch('repositories/users/SET_ITEMS', admin.users, { root: true });
    }

    // commit('SET_PRELOADED_DATA', {})

    /* Real time updates */
    //Echo.connect()
  },

  SET_USER({ commit }, user) {
    commit('SET_USER', AuthUser.create(user));
    if (user.preferences.locale) {
      i18n.locale = user.preferences.locale;
    }
  },

  LOGOUT({ commit, state }, { redirect = true } = {}) {
    // state.is_logged_in = false
    Api.post('auth/logout');
    commit('CLEAR_ALL_DATA');

    // localStorage.removeItem('state.auth')
    // Echo.disconnect()

    if (redirect) {
      router.push({ path: '/login', query: { redirect: router.history.current.path } });
    }
  },

  async LOAD({ dispatch, commit, state }) {
    return new Promise((resolve) => {
      commit('SET_PRELOADING', true);
      Api.get('auth/data')
        .then((response) => {
          const accessToken = state.access_token;
          const preloadedData = response.preloadedData;
          dispatch('AUTHENTICATE', { accessToken, preloadedData });
          commit('forms/projects/SET_TUTORIALS', response.preloadedData.tutorials, { root: true });
          resolve(true);
        })
        .catch(() => {
          dispatch('LOGOUT');
          resolve(false);
        });
    });
  },

  PRELOADED_DATA({ dispatch, state }) {
    return new Promise((resolve) => {
      Api.get('auth/data')
        .then((response) => {
          const accessToken = state.access_token;
          const preloadedData = response.preloadedData;
          dispatch('AUTHENTICATE', { accessToken, preloadedData });
          resolve(true);
        })
        .catch((error) => {
          console.log('PRELOADED_DATA ERROR', error);
          resolve(false);
        });
    });
  },

  UPDATE_PREFERENCES({ dispatch, commit, state }, preferences) {
    // commit('SET_PRELOADING', true)
    Api.post('auth/preference', preferences);
  },

  RESET_PASSWORD_SUCCESS({ dispatch, commit, state }, preferences) {
    router.push({ path: '/login' });
  },

  UPDATE_PASSWORD_SUCCESS({ dispatch, commit, state }, preferences) {
    router.push({ path: '/login' });
  },

  CHECK_SESSION() {
    return new Promise((resolve) => {
      Api.get('auth/check-time-token')
        .then((response) => resolve(response))
        .catch((error) => resolve(error));
    });
  },
};

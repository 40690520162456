<template lang="pug">
  div
    .extra-block.text-block-media
      group-container(
        :title="$t('text.text_block_section_title')"
        :subtitle="$t('text.text_block_section_sub_title')"
      )
        v-text-field.mt-4(:value="generateLorem(model, {_key: 'title'})" :placeholder="$t('text.placeholder_text_block_media_title')" v-model="model.title" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled || disabled")
      group-container(
        :title="$t('text.text_block_section_description')"
        :subtitle="$t('text.text_block_section_sub_description')"
      )
        v-textarea(rows="3" filled :value="generateLorem(model, {_key: 'description'})" :placeholder="$t('text.placeholder_text_block_media_description')" v-model="model.description" :readonly="disabled || isAutoFilled" :disabled="isAutoFilled || disabled")

      group-container(
        :title="$t('text.text_block_section_images')"
        :subtitle="$t('text.text_block_add_images_for_this_section')"
      )
        media-uploader.mt-8(
          :documents="model.media"
          resourceName="projects"
          :uuid="projectUUID"
          @added="onAdded"
          @deleted="onDeleted"
          :disabled="disabled"
          :allowMultiple="false"
        )
      //- group-container(
      //-   :title="$t('Video')"
      //-   :tip="$t('text.tip_contents.content.video')"
      //-   :subtitle="$t('Add a youtube link to your video.')"
      //- )
      //-     template(v-for="item in model.linksVideo")
      //-         div.relative.flex
      //-             v-text-field(:key="item.uuid" filled v-model="item.url" placeholder="https://www.youtube.com/watch?v=8bawRnTYGb8")
      //-                 template(v-slot:append)
      //-                     img.block.cursor-pointer(src="@/assets/images/delete.svg" @click="deleteLink(item.uuid)")

      //- div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addNewLink()")
      //-     v-icon(color="#222222") mdi-plus 
      //-     | {{ $t("text.add_link")}}
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import ExtraBlock from '@/mixins/ExtraBlockMixin';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';

export default {
  mixins: [ExtraBlock],
  components: { MediaUploader, GroupContainer },
  data() {
    return {
      editor: ClassicEditor,
      links: [],
    };
  },
  methods: {
    onAdded(media) {
      this.model.media.push(media);
    },
    onDeleted(fileName, id) {
      this.model.media = this.model.media.filter((x) => x.filename != fileName);
    },
    addNewLink() {
      let uuid = this.$uuid.v4();
      this.model.linksVideo.push({ uuid, url: '' });
    },
    deleteLink(uuid) {
      this.model.linksVideo = this.model.linksVideo.filter((x) => x.uuid != uuid);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tab-content tab-first"},[_c('div',{staticClass:"d-flex full-height"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"flex h-full full-height flex-column justify-center"},[_c('h2',{staticClass:"text-left text-222222 text-center text-5xl mt-sm-10 mb-7 font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('text.let_start_by_choosing_what_to_create'))+" ")]),_c('h3',{staticClass:"mb-9 mt-1 text-222222 font-medium text-center size-lg text-2xl"},[_vm._v(" "+_vm._s(_vm.$t('text.site_type_description'))+" ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"500px","width":"100%"}},[_c('v-radio-group',{attrs:{"disabled":_vm.isLocked,"mandatory":false},model:{value:(_vm.createType),callback:function ($$v) {_vm.createType=$$v},expression:"createType"}},[_c('div',{staticClass:"grid grid-cols-2 gap-6",staticStyle:{"gap":"2.5rem"}},[_c('div',{staticClass:"relative shadow1",class:[
                    _vm.createType === 'site'
                      ? 'border-primary border shadow-xl primary text-white'
                      : '',
                  ]},[_c('img',{staticClass:"block mx-auto",attrs:{"src":require("@/assets/images/first1.svg")}}),_c('p',{staticClass:"text-24 text-center capitalize px-2"},[_vm._v(_vm._s(_vm.$t('text.website')))]),_c('v-radio',{staticClass:"absolute top-0 left-0 w-full h-full opacity-0",attrs:{"value":"site"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-bigger capitalize px-2"},[_vm._v(" "+_vm._s(_vm.$t('text.website'))+" ")])]},proxy:true}])})],1),_c('div',{staticClass:"relative shadow1",class:[
                    _vm.createType === 'shop'
                      ? 'border-primary border shadow-xl primary text-white'
                      : '',
                  ]},[_c('img',{staticClass:"block mx-auto",attrs:{"src":require("@/assets/images/first2.svg")}}),_c('p',{staticClass:"text-24 text-center capitalize px-2"},[_vm._v(_vm._s(_vm.$t('text.online_store')))]),_c('v-radio',{staticClass:"absolute top-0 left-0 w-full h-full opacity-0",attrs:{"value":"shop"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-bigger capitalize px-2"},[_vm._v(" "+_vm._s(_vm.$t('text.online_store'))+" ")])]},proxy:true}])})],1)])])],1),_c('add-comment',{attrs:{"commentField":"afirst.type"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
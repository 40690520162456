import { mix } from 'mixwith'
import Document from '@abs/document'
import UsersSchema from './schema'

/**
 * User model
 */
class Users extends mix(Document).with(UsersSchema) {
  static create(data) {
    return new this(this.parse(data))
  }
}

export default Users

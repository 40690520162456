<template lang="pug">
v-container(fluid)
  v-card(flat)
    .profile-setting
      .row.justify-center
        .col-md-4.col-12
          v-list
            v-list-item(link="")
              v-list-item-content
                v-list-item-title.title
                  | {{ user.name }}
                v-list-item-subtitle {{ user.email }}
              v-list-item-action
                v-icon mdi-menu-down
          v-divider
          v-list(nav="", dense="")
            v-list-item-group(v-model="selected", color="primary")
              v-list-item(v-for="(item, i) in list", :key="i" :value="i")
                v-list-item-content
                  v-list-item-title(v-text="item.text") 

        .col-md-8.col-12
          component(:is="view.component" v-bind="list[selected].props || {}")
</template>

<script>
import formPreferences from '@/pages/profile/FormPreferences';
import AffiliateLink from './AffiliateLink.vue';

export default {
  components: {
    formPreferences,
  },
  data() {
    return {
      selected: 'account',
    };
  },
  computed: {
    pageTitle() {
      return this.$t('text.menu_profile');
    },
    view() {
      const active = this.list[this.selected];
      return {
        component: active?.component ?? false,
      };
    },
    list() {
      let isManager = this.$user().isManager();
      const affiliatelink = {
        text: this.$t('Affiliate link'),
        component: AffiliateLink,
        props: {
          userId: this.user?.uuid,
        },
      };
      const _include = isManager ? { affiliatelink } : {}
      return {
        account: {
          text: this.$t('text.account_information'),
          component: formPreferences,
        },
        emailSetting: {
          text: this.$t('text.email_settings'),
        },
        ..._include,
      };
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<template lang="pug">
  v-snackbar(
      :timeout="timeout" 
      :value="opened" 
      color="#6F87FF" 
      min-width="0"
    ) {{ message }} 
    //- v-icon(dense) ic-done
</template>


<script>
export default {
  data() {
    return {
      message: null,
      opened: false,
      timeout: 4000,
    }
  },

  computed: {
    notifications() {
      return this.$store.state.notifications
    },
    hasNotification() {
      return this.notifications.length > 0
    }
  },

  methods: {

    push() {
      console.log('doing push...', this.notifications)
      if (!this.hasNotification)
        return

      this.message = this.notifications.shift()
      this.opened = true
      this.closeAfter(2500)


      const $this = this
      setTimeout(() => {
        $this.push()
      }, 3000)
    },

    closeAfter(ms) {
      const $this = this
      setTimeout(() => {
        $this.opened = false
      }, ms)
    }
  },

  watch: {
    notifications: function() {
      if (!this.opened)
        this.push()
    }
  }
}
</script>



<style lang="scss">
.push-notification {
  position: fixed;
  bottom: 0px;
  left: -350px;
  padding:15px 20px;
  margin: $body-pad;
  letter-spacing: 1px;
  z-index: 999;
  transition: left 0.5s;

  &.open {
    left: 0px;
  }
}

</style>

<template lang="pug">
  .hide-late(ref='template' style="display: inline;")
    slot

</template>

<script>
export default {
  props: {
    // how many seconds to pass to hide a template
    after: {
      type: Number,
      default: 5 // in seconds
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.$refs.template)
        this.$refs.template.style.display = 'none'
    }, this.after * 1000)
  }
}
</script>
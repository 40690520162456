import { FormState, FormGetters, FormMutations, FormActions } from '@front/modules/abstracts/form';

const state = FormState({
  title: 'Translations',
  subtitle: 'Manage translation',
  fields: {
    uuid: null,
    id: null,
    key: null,
    en: null,
    bg: null,
    es: null,
    lt: null,
    lv: null,
    pl: null,
    ro: null,
    ru: null,
    hu: null,
  },
});
const mutations = FormMutations();
const getters = FormGetters({
  VALIDATES: (state) => {
    return {};
  },
});
const actions = FormActions({});

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};

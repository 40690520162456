<template lang="pug">
  .extra-block.gallery
    //- group-container(
    //-     :title="$t('text.Section title')"
    //-     :subtitle="$t('text.It_will_inform_users_what_the_section_is_about')"
    //-   )
    //-     v-text-field.mt-4(:value="generateLorem(model, {_key: 'title'})" v-model="model.title" filled  :placeholder="$t('We are trusted by')" :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")

    group-container(
        :title="$t('text.Images')")
    media-uploader(
      :documents="model.media"
      resourceName="projects"
      :uuid="projectUUID"
      @added="onAdded"
      @deleted="onDeleted"
      :disabled="disabled"
    )
</template>

<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader'
export default {
  mixins: [ ExtraBlock ],
  components: { MediaUploader },
  data() {
    return {
      editor: ClassicEditor
    }
  },
  methods: {
    onAdded(media) {
      this.model.media.push(media)
    },
    onDeleted(fileName, id) {
      this.model.media = this.model.media.filter(x => x.filename != fileName)
    }
  }
}
</script>

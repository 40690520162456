<template>
  <div>
    <v-card flat filled>
      <v-card-title>{{ $t('Affiliate link') }}</v-card-title>
      <v-card-text>
        <code ref="deeplink" v-if="deepLink">{{ deepLink }}</code>
        <template v-else>
          No affiliate generated
        </template>
      </v-card-text>
      <v-card-actions>
        <div class="grid">
          <v-btn
            v-if="deepLink"
            :color="isCopied ? 'warning' : 'primary'"
            elevation="0"
            class="text-capitalize"
            @click="doCopy"
          >
            {{ isCopied ? 'Copied!' : 'Copy' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    isCopied: false,
    interval: undefined,
  }),
  computed: {
    deepLink() {
      // const url = process.env.VUE_APP_BACKEND
      const urlOrigin = location.origin;
      console.error({
        location,
        urlOrigin,
        env: process.env.VUE_APP_BACKEND,
        deeplink: `${urlOrigin}/?inviteBy=${this.userId}`,
      });
      return `${urlOrigin}/?inviteBy=${this.userId}`;
    },
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async doCopy() {
      if (this.isCopied) {
        return false;
      }
      const cb = navigator.clipboard;
      const ref = this.$refs.deeplink;
      await cb.writeText(ref.innerText);
      this.isCopied = true;
      this.interval = setInterval(async () => {
        this.isCopied = false;
        clearInterval(this.interval);
      }, 2000);
    },
  },
};
</script>
<style scoped>
.grid {
  display: grid;
  grid-template-columns: 100px 100px;
  grid-gap: 10px;
}
</style>

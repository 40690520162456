<template lang="pug">
  .drag-state(ref="dragstate")
    .drag-state
      slot(name="drag")
    .normal-state(ref="normal" v-if="!drag")
      slot(name="normal")

</template>

<script>
export default {
  props: {
    handle: {
      type: String,
      default: 'handle'
    },

    drag: {
      type: Boolean,
      default: false
    },

    return: {
      type: String,
      default: null
    }
  },

  computed: {
    _handle() {
      const h =  this.$refs.dragstate.querySelector('.handle')
      return h
    }
  },

  methods: {
    pressed(e) {
      //this.$refs.normal.style.display = 'none'
      this.$emit('pressed', this.return)
    },
    released(e) {
      //this.$refs.normal.style.display = 'block'
      this.$emit('released', this.return)
    }
  },

  mounted(){
    this._handle.addEventListener('mousedown', this.pressed)
    this._handle.addEventListener('mouseup', this.released)

  },

  beforeDestroy() {
    this._handle.removeEventListener('mousedown', this.pressed)
    this._handle.removeEventListener('mouseup', this.released)
  }
}
</script>
import { mix } from 'mixwith'
import Document from '@abs/document'
import TranslationsSchema from './schema'

/**
 * Translation model
 */
class Translations extends mix(Document).with(TranslationsSchema) {
  static create(data) {
    return new this(this.parse(data))
  }
}

export default Translations

<template>
  <v-container>
    <v-card :disabled="submiting" depressed flat>
      <v-card-title>
        <h4>Instructions video URL</h4>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(instruction, lan) in instructions" :key="lan">
            <v-expansion-panel-header>
              <div style="display: flex; align-items: center">
                <img
                  :src="require(`@/assets/images/flags/${languages[lan].icon}`)"
                  alt=""
                  style="height: 25px; width: 25px; display: block"
                />
                <div class="mx-2"></div>
                {{ languages[lan].name }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <template v-for="(value, k) in instruction">
                  <div :key="k" class="flex">
                    <div class="subtitle-1 mt-2 mb-2">{{ k }}</div>
                    <v-text-field
                      :disabled="!isUserAdmin"
                      :value="value"
                      clearable
                      filled
                      hide-details
                      @input="(v) => (instruction[k] = v)"
                      @keydown.enter="save"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          :disabled="!instruction[k]"
                          depressed
                          fab
                          small
                          style="transform: translateY(-8px)"
                          title="Preview"
                          @click="openVideo(instruction[k])"
                        >
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </template>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
          v-if="isUserAdmin"
          :fixed="!$vuetify.breakpoint.xs"
          :loading="submiting"
          bottom
          color="primary"
          depressed
          right
          @click="save"
          >Save
        </v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isOpen" max-width="500">
      <v-card>
        <div v-if="videoCode" class="frame-video">
          <iframe
            v-if="isOpen"
            :src="videoCode"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            frameborder="0"
            title="YouTube video player"
          ></iframe>
        </div>
        <template v-else>
          <v-card-title></v-card-title>
          <v-card-text style="display: flex; align-items: center; justify-content: center">
            Video URL invalid
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000" app color="primary">
      <div style="text-align: center">{{ text }}</div>
    </v-snackbar>
  </v-container>
</template>
<script>
import _ from 'lodash';
import { languagesFlag } from '@/i18n';
import Api from '@/plugins/api';
import { converToIframeYoutube } from '@/helpers';

export default {
  data: () => ({
    t: 'tutorials',
    submiting: false,
    snackbar: false,
    text: 'Instructions video has been updated!',
    instructions: {
      bg: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      en: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      es: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      lt: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      pl: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      ro: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      ru: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
      lv: {
        tutorial: '',
        basic_and_company: '',
        categories: '',
        content: '',
        logo_and_style: '',
        payments: '',
        products: '',
        resources: '',
        sitemap: '',
        fixes: '',
      },
    },
    isOpen: false,
    youtubeAddr: null,
  }),

  mounted() {
    this.fetchInstructions();
  },
  computed: {
    isUserAdmin() {
      return this.$user() && this.$user().isAdmin();
    },
    languages() {
      return languagesFlag.reduce((r, b, c) => {
        r[b.code] = b;
        return r;
      }, {});
    },
    videoCode() {
      return converToIframeYoutube(this.youtubeAddr, '?controls=0');
    },
  },

  methods: {
    async fetchInstructions() {
      try {
        const res = await Api.get('translation-meta/list');
        let item = res.find((r) => r.key === this.t);
        if (item) {
          const parseJSON = JSON.parse(item.meta_data);
          this.setInstructions(parseJSON);
        } else {
          this.updateInstructions();
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    setInstructions(meta_data) {
      Object.entries(meta_data).forEach(
        ([key, value]) => (this.instructions[key] = { ...this.instructions[key], ...value })
      );
    },
    save() {
      this.submiting = true;
      let timeout = setTimeout(() => {
        this.submiting = false;
        this.updateInstructions();
        clearTimeout(timeout);
      }, 2000);
    },
    async updateInstructions() {
      try {
        this.submiting = true;
        let instructions = JSON.stringify(this.instructions);
        localStorage.setItem(this.t, instructions);

        let data = new FormData();
        data.append('key', this.t);
        data.append('meta_data', JSON.stringify(this.instructions));
        await Api.post('translation-meta', data);
        this.snackbar = true;
      } finally {
        this.submiting = false;
      }
    },
    openVideo(url) {
      if (!url?.trim()) {
        alert('Video Url invalid!');
      }
      this.isOpen = true;
      this.youtubeAddr = url;
    },
  },
};
</script>
<style lang="scss">
.frame-video {
  position: relative;
  padding-top: 56.25%;
  padding-top: 75%;
  width: 100%;
  overflow: hidden;

  iframe {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>

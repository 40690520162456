<template lang="pug">
  .extra-block.features
    //- group-container(
    //-   :title="$t('text.section_title')"
    //-   :subtitle="$t('text.section_description_advantages')"
    //- )
    //-   v-text-field(
    //-     filled 
    //-     v-model="model.title"
    //-     :label="$t('text.title')"
    //-     :readonly="disabled || isAutoFilled"
    //-     :disabled="isAutoFilled"
    //-   )
    group-container(
      :title="$t('text.List_of_advantages')"
    )
    div(
      v-for="(item, key) in model.items"
      :key="item.id"
    )

      hr.my-4(v-if="key > 0")
      label.block.text-222222.mb-4.text-16 {{$t('text.advantages_features_title')}}
      v-text-field(
        filled
        v-model="item.title"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )
      label.block.text-222222.mb-4.text-16 {{$t('text.advantages_features_description')}}
      v-textarea(
        rows="6"
        filled
        v-model="item.description"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )
      div.flex.items-center.gap-2.cursor-pointer( @click="deleteField(item.id)")
        img.block.cursor-pointer.ml-auto(src="@/assets/images/delete.svg")
        span.text-error.text-sm {{ $t('text.Delete') }}
      //- media-uploader(
      //-   :documents="item.media"
      //-   resourceName="projects"
      //-   :uuid="projectUUID"
      //-   :return="item.id"
      //-   @added="onAdded"
      //-   @deleted="onDeleted"
      //-   :disabled="disabled"
      //- )
    div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addField()" v-if="!disabled")
      v-icon(color="#222222") mdi-plus
      | {{ $t("text.add_item")}}
    //- v-btn(color="primary" rounded depressed  @click="addField" :disabled="disabled") {{ $t('text.add_item') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';

export default {
  components: { MediaUploader },
  mixins: [ExtraBlock],
  methods: {
    addField() {
      this.model.items.push({
        id: this.$uuid.v4(),
        link: null,
        title: null,
        description: null,
        media: [],
      });
    },
    deleteField(id) {
      this.model.items = this.model.items.filter((x) => x.id != id);
    },
    onAdded(model, id) {
      this.model.items.find((x) => x.id === id).media.push(model);
    },
    onDeleted(fileName, id) {
      this.model.items.find((x) => x.id === id).media = this.model.items
        .find((x) => x.id === id)
        .media.filter((x) => x.filename != fileName);
    },
  },
};
</script>

import ModMixin from './ModMixin';
import PassivesMixin from './PassivesMixin';
import AbstractForm from '@/components/forms/AbstractForm';
import vuetize from '@/scripts/vuetize';
import Button from '@/components/common/buttons/Button';
import { generateFormFields } from '@/components/forms/fieldGenerator';
import { VSpacer } from 'vuetify/lib';

export default (formName) => {
  return {
    components: { parent: AbstractForm, Button },
    mixins: [ModMixin, PassivesMixin],

    data: function () {
      return {
        title: '',
        subtitle: '',
        showFormSubtitle: false,
        modName: formName,
        validateFields: true,
      };
    },

    computed: {
      formBaseActions() {
        let isMobile = this.$vuetify.breakpoint.xs;
        let isProjectRoutes = ['projectEdit', 'projectCreate'].includes(this.$route.name);
        let isShow = !isProjectRoutes && (this.$user().isAdmin() || this.$user().isSuperAdmin());

        let _options = {
          cancel: {
            label: this.$t('text.cancel'),
            key: 'cancel',
            icon: 'ic-close',
            evinput: this.formBaseActionCancel,
            component: Button,
            flat: true,
            small: true,
            color: '#FF9900',
            order: isMobile ? 1000 : 98,
            class: isMobile ? 'w-100 my-1' : 'ml-auto',
            show: true,
          },
          save: {
            label: this.$t('text.save'),
            key: 'save',
            icon: 'ic-done',
            evinput: this.formBaseActionSave,
            component: Button,
            flat: true,
            small: true,
            color: '#00D079',
            loading: this.$store.getters.getCoordinate('saveBase'),
            order: isMobile ? 1001 : 99,
            class: isMobile ? 'w-100 my-1' : '',
            show: true,
          },
        };
        let df = {
          spacer: {
            component: VSpacer,
            order: 50,
          },
        };
        df = isShow ? { ...df, ..._options } : { ...df };
        return df;
      },
      isHistoryChanged() {
        return false;
      },
      formRef() {
        return this.$refs.parent.$refs.form;
      },

      formBind() {
        return {
          formActions: vuetize(this._formActions),
          ref: 'parent',
          title: this.formState.title ?? '',
          subtitle: this.formState.subtitle ?? '',
          showSubtitle: this.showFormSubtitle,
          _options: this._options,
        };
      },

      _options() {
        return {};
      },

      formActions() {
        return {};
      },

      _formActions() {
        const keyComplete = 'complete';
        const keySendFixes = 'fixes';
        const keyNext = 'next';
        const keyPrev = 'prev';
        const keySendComplete = [keyComplete, keySendFixes];
        const keyNextPrev = [keyNext, keyPrev];

        const mergeWith = _.merge(this.formBaseActions, this.formActions);

        // Handle update condition show component button in Page Project
        Object.entries(this.formActions).forEach(([key, value]) => {
          if (_.has(value, 'tabActive')) {
            if (value.tabActive === 'fix') {
              keyNextPrev.forEach((key) => {
                if (_.has(mergeWith, key)) {
                  mergeWith[key].show = _.has(mergeWith[key], 'show') && false;
                }
              });
              keySendComplete.forEach((key) => {
                if (_.has(mergeWith, key)) {
                  mergeWith[key].show = _.has(mergeWith[key], 'show') && true;
                }
              });
            } else {
              keyNextPrev.forEach((key) => {
                if (_.has(mergeWith, key)) {
                  mergeWith[key].show = _.has(mergeWith[key], 'show') && true;
                }
              });

              keySendComplete.forEach((key) => {
                if (_.has(mergeWith, key)) {
                  mergeWith[key].show = _.has(mergeWith[key], 'show') && false;
                }
              });
            }
          }
        });
        return mergeWith;
      },

      errors() {
        return this.formState.validationErrors;
      },

      modelData() {
        return this.formState.model;
      },

      ...generateFormFields(formName, 'fields'),
    },

    methods: {
      async formBaseActionCancel() {
        console.error('confirmed', this.formState);
        if (this.isHistoryChanged) {
          let confirmed = await window.confirm(
            'Please, make sure you save the form before leave or your data will be lost'
          );
          if (confirmed) {
            this.$emit('form-cancel');
          }
        } else {
          this.$emit('form-cancel');
        }
      },
      formBaseActionSave() {
        if (this.$store.getters.getCoordinate('saveBase')) {
          return console.log(
            '%c doing update, Please wait...!',
            'background: #222; color: #bada55'
          );
        }
        this.$store.commit('REMOVE_HISTORY_CHANGES');
        if (this.validateFields && this.formRef.validate()) {
          this.formDispatch('VALIDATE_FIELDS').then(() => {
            this.formEmitData();
          });
        } else if (!this.validateFields) {
          this.formEmitData();
        }
      },

      formEmitData() {
        const ev = this.formGetter('IS_EDITING') ? 'form-update' : 'form-create';
        this.$emit(ev, this.formState.fields);
      },
    },
  };
};

import Vue from 'vue';
import Router from 'vue-router';
import { ERROR_404, routes } from './routes';

Vue.use(Router);

function makeRoute(route, withParams = true) {
  const params = route.params && withParams ? makeParams(route.params) : '';
  const path = (route.path ? route.path : route.name) + params;
  const rewrite = route.absolute && route.path ? route.absolute : false;

  let r = {
    name: withParams ? route.name : route.name + '-root',
    path: rewrite ? path : `/${path}`,
    //beforeEnter: IfGuest
  };

  if (route.refresh) {
    r.beforeEnter = refreshRoute;
  }

  if (route.page) {
    const component = route.page;
    r['component'] = require(`@/pages/${component}.vue`).default;
  }

  if (route.meta) {
    r.meta = route.meta;
  }

  return r;
}

function makeParams(params) {
  let result = '';
  params.forEach(function (p) {
    result += `/:${p}`;
  });
  return result;
}

function makeRoutes() {
  let result = [];
  Object.keys(routes).forEach((r) => {
    const route = routes[r];

    if (route.params && route.paramsRoot) {
      result.push(makeRoute(route, false)); // root path, without params...
      result.push(makeRoute(route)); // with params
    } else {
      result.push(makeRoute(route));
    }
  });
  return result;
}

const isExistPath = (to) => {
  const nameRoutes = Object.values(routes).map((value) => value.name);
  return nameRoutes.includes(to.name);
};

const router = new Router({
  mode: 'history',
  routes: makeRoutes(),
});

router.beforeEach((to, from, next) => {
  if (!isExistPath(to)) {
    next({ name: ERROR_404 });
  }
  // const i18n = router.app.$options.i18n
  // const lang = i18n.locale;
  clientCheckInvite(to);
  // loadLanguageAsync(lang).then(() => next());
  // eslint-disabled
  if (!router.app) {
    next({ path: '/login' });
  }
  const auth = router.app.$options.store.state.auth;

  if (to.meta.auth !== false && !auth.is_logged_in) {
    // 'not authorized: to login'
    clientCheckInvite(to);
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else if (to.query.redirect && auth.is_logged_in) {
    // 'authorized with redirect'
    next({ path: to.query.redirect });
  }
  // redirecting to home if user still tries to enter login/register/reset/change pages after logged in.
  else if (
    auth.is_logged_in &&
    ['login', 'register', 'reset-password', 'version', 'impersonate'].includes(to.name)
  ) {
    next({ path: '/' });
  } else {
    next();
  }
});

function refreshRoute(to, from, next) {
  next();
  router.go();
}

import { setStorage } from '@/helpers';

function clientCheckInvite(route) {
  const { inviteBy } = route.query;
  if (inviteBy) {
    setStorage('inviteBy', inviteBy);
  }
}

export default router;

<template lang="pug">
  v-container
    div(v-if="false")
      h1.text-center.my-10.capitalize.my-2.text-left.text-sm-center.my-sm-10.tform {{ $t('projects') }}
      div(:class="['grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4']")
        div(v-for="(project, index) in dataItems" :key="index")
          v-card.tcard(color="#EBEFFF" flat)
            div.pa-5.text-black
              .relative.text-black.pt-10
                div.absolute(style="top: 0px; left: 0px;")
                  v-chip(label color="tag") {{ project.status_name }}
                div
                  div.d-flex.align-end
                    div
                      p(:class="['md:text-md text-clamp-2']").font-weight-bold.text-black.text-24.mb-5 {{ project.name }}
                      p.text-16.mb-2
                        strong Manager  
                        | {{ project.manager_uuid }}
                      p.text-16.mb-2
                        strong Domain: 
                        | {{ project.website.domain_name }}
                    img.block(:src="require('@/assets/images/icon_project.svg')" style="max-width: 60%;")
                div(class="absolute cursor-pointer" style="top: 0px; right: 0px;" @click="tableItemActionEdit(project)")
                  v-icon mdi-pencil-outline
                div(class="absolute cursor-pointer" style="bottom: 0px; right: 0px;")
                  v-icon(color="error") mdi-trash-can-outline
        div.tcard.min-h-full.p-10.d-flex.justify-center.align-center.cursor-pointer(@click="tableActionCreate")
          img.block(:src="require('@/assets/images/icon_plus.svg')")
    template(v-if="isCorrectDepartment")
      data-page(v-bind="dataBind")
        template(v-slot:item-name="{ item }")
          a(@click="tableItemActionEdit(item)") {{ item.name }}
        template(v-slot:placeholder)
          .full-height.centerize
            .placeholder.compact
              v-img(
                :src="require('@/assets/images/placeholders/main.png')",
                width="700",
                height="200"
              )
              h1.text-center.mt-10 {{ $t('text.welcome') }}!
              p.text-center.hpad
                | {{ $t('text.dna_is_a_molecule_that_contains_our_unique_genetic_code') }}
                br
                br
                | {{ $t('text.be_the_creator_and_give_life_to_your_website') }}
              v-btn.mb-10(color="primary", @click="tableActionCreate", x-large)
                | {{ $t('text.create_application') }}
    template(v-else)
      section.mt-6
        move-department(:domain="domainDepartment")
</template>
<script>
import * as helpers from '@/helpers';
import * as ROLES from '@/constants/roles';
import * as STATUSES from '@models/projects/statuses';
import PageMixin from '@front/mixins/DataPageAdvanceMixin';
import Button from '@/components/tables/parts/ToolbarBtn';
import FilterTextField from '@/components/tables/parts/FilterTextField';
import FilterDatePicker from '@/components/tables/parts/FilterDatePicker';
import FilterSelect from '@/components/tables/parts/FilterSelect';
import MoveDepartment from '@/components/MoveDepartment';

const pipe = (...fns) => {
  return fns.reduceRight((f, g) => (x) => f(g(x)));
};
const _filterByManager = (users) => {
  return users.filter((i) => i.user_type_id === 3);
};
const _filterByDesigner = (users) => {
  return users.filter((i) => i.user_type_id === 2);
};
const _map = (users) => {
  return users.map((user) => ({
    text: user.email,
    value: user.uuid,
  }));
};
const usersAsManager = pipe(_filterByManager, _map);
const usersAsDesigner = pipe(_filterByDesigner, _map);
const allUsers = pipe(_map);
export default {
  mixins: [PageMixin],
  components: {
    MoveDepartment,
  },
  props: {
    tableActionsProp: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data: function () {
    return {
      isCorrectDepartment: true,
      domainDepartment: '',
      modName: 'projects',
      formHasDocuments: false,
      tableItemActions: {
        delete: false,
      },
      initProjectStatus: [
        STATUSES.PROJECT_NEW,
        STATUSES.PROJECT_FREE,
        STATUSES.PROJECT_CHECK,
        STATUSES.PROJECT_REQUIRES_CORRECTIONS,
        STATUSES.PROJECT_READY_FOR_DEV,
        STATUSES.PROJECT_DEV,
        STATUSES.PROJECT_IN_PROGRESS,
        STATUSES.PROJECT_FINAL_CHECK,
        STATUSES.PROJECT_READY_FOR_REVIEW,
        STATUSES.PROJECT_FIX_CHECK,
        STATUSES.PROJECT_FIX_DEV,
        STATUSES.PROJECT_FIX_IN_PROGRESS,
        STATUSES.PROJECT_FIX_FINAL_CHECK,
        STATUSES.PROJECT_FIX_READY_FOR_REVIEW,
      ],
      //placeholder: this.$refs.dtp.showCustomPlaceholder
    };
  },
  computed: {
    pageTitle() {
      if (this.$props.title) {
        return this.$props.title;
      }

      return this.$t('text.menu_projects');
    },

    tableActions() {
      if (this.$props.tableActionsProp) {
        return this.$props.tableActionsProp;
      }

      const video = {
        icon: 'ic-help',
        component: Button,
        link: 'https://www.youtube.com/watch?v=fg9bn_7t6cE&ab_channel=WAcademyEurope',
        target: '_blank',
      };

      const bulkUploadButton = {
        label: 'Bulk Update',
        component: Button,
        evclick: () => this.$router.push({ name: 'projectsBulkUpdate' }),
      };

      const isAdminRole = this.$user().isAdmin() || this.$user().isSuperAdmin();
      return isAdminRole
        ? {
            bulkUploadButton,
            video,
            add: false,
          }
        : !this.$user().isClient()
        ? { video, add: false }
        : { video, add: { evclick: this.onAddButtonClick } };
    },

    tableActionsFilters() {
      let filterSet = [];
      const queries = this.$route.query;
      const projectStatus = _.get(queries, 'project_statuses', []);
      const formatStatus =
        Array.isArray(projectStatus) && projectStatus.length
          ? projectStatus?.map((val) => +val)
          : [+projectStatus];
      const isInitStatus = !this.$user().isClient() && !projectStatus.length;
      const defaultStatus = _.filter(this.projectStatuses, (item) =>
        (isInitStatus ? this.initProjectStatus : formatStatus).includes(item.value)
      );

      if (this.$user().isEmployee()) {
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterTextField,
          field: 'q',
          label: this.$t('text.search'),
          value: this.tableFilter['q'],
        });
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterTextField,
          field: 'user_email',
          label: this.$t('text.client_email'),
          value: this.tableFilter['client'],
        });
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterSelect,
          field: 'project_statuses',
          label: this.$t('text.status'),
          value: defaultStatus,
          options: this.projectStatuses,
        });
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterDatePicker,
          field: 'start_date',
          label: this.$t('text.date_from'),
          value: this.tableFilter['start_date'],
        });
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterDatePicker,
          field: 'end_date',
          label: this.$t('text.date_to'),
          value: this.tableFilter['end_date'],
        });
      }
      if (this.$user().isAdmin()) {
        // isDesigner
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterSelect,
          field: 'managers',
          label: `${this.$t('text.managers')}`,
          value: this.tableFilter['managers'],
          options: this.managers,
        });
        filterSet.push({
          evupdate: this.setFieldFilter,
          component: FilterSelect,
          field: 'designers',
          label: `${this.$t('text.designers')}`,
          value: this.tableFilter['designers'] || [],
          options: this.designers,
        });
        //filterSet[] = { evupdate: this.setFieldFilter, component: FilterSelect, field: 'managers', label: this.$t('text.manager'), value: this.tableFilter['managers'], options: this.managers },
        //filterSet[] = { evupdate: this.setFieldFilter, component: FilterSelect, field: 'users', label: this.$t('text.created_by'), value: this.tableFilter['users'], options: this.users },
      }
      //filterSet[] = { evupdate: this.setFieldFilter, component: FilterSelect, field: 'managers', label: this.$t('text.manager'), value: this.tableFilter['managers'], options: this.managers },
      //filterSet[] = { evupdate: this.setFieldFilter, component: FilterSelect, field: 'users', label: this.$t('text.created_by'), value: this.tableFilter['users'], options: this.users },
      return filterSet;
    },

    tableFilter() {
      return this.$store.getters[this.repoPath('TABLE_FILTER')];
    },

    tableHeaders() {
      const headers = [
        {
          text: this.$t('text.name'),
          align: 'left',
          value: 'name',
          class: 'text-capitalize',
        },
        {
          text: this.$t('text.manager'),
          value: 'manager_name',
          class: 'text-capitalize',
        },
        {
          text: this.$t('text.statuses.completed'),
          value: 'completed_at',
          class: 'text-capitalize',
        },
        {
          text: this.$t('text.status'),
          value: 'status_name',
          class: 'text-capitalize',
        },
        {
          text: this.$t('text.actions'),
          value: 'action',
          sortable: false,
          align: 'right',
          class: 'text-capitalize',
        },
      ];

      if (!this.$user().isClient()) {
        headers.splice(1, 0, {
          text: this.$t('text.domain_name'),
          value: 'website.domain_name',
          class: 'text-capitalize',
        });
        headers.splice(3, 0, {
          text: this.$t('text.designer'),
          value: 'designer_name',
          class: 'text-capitalize',
        });
        headers.splice(4, 0, {
          text: this.$t('text.reminder'),
          value: 'delegate.reminder',
          class: 'text-capitalize',
        });
        headers.splice(5, 0, {
          text: this.$t('text.deadline'),
          value: 'delegate.deadline',
          class: 'text-capitalize',
        });
      }

      return headers;
    },

    managers() {
      let users = this.$store.state.workers || [];
      return usersAsManager([...users]);
    },
    designers() {
      let users = this.$store.state.workers || [];
      return usersAsDesigner([...users]);
    },
    users() {
      let users = this.$store.state.workers || [];
      return allUsers([...users]);
    },

    projectStatuses() {
      return this.$store.state.passives.project_statuses.map((item) => {
        return {
          text: this.$t('text.statuses.' + item.name_code),
          value: item.id,
        };
      });
    },
  },

  methods: {
    onAddButtonClick() {
      if (this.$user().project_count > 0)
        this.$store.commit('ADD_NOTIFICATION', this.$t('text.you_can_t_create_more_projects'));
      else this.tableActionCreate();
    },

    onCreateClick() {
      this.formCommit('UPDATE_STATE', {
        field: 'title',
        value: 'text.create_new_project',
      });
      this.formCommit('UPDATE_FIELD', {
        field: 'uuid',
        value: this.$uuid.v5(new Date().toString(), this.$user().uuid),
      });
      this.formCommit('SET_DEPARTMENT', this.$store.state.config.department_id);
      this.$router.push({ name: 'projectCreate' });
    },

    onEditClick(model) {
      this.$router.push({ name: 'projectEdit', params: { id: model.uuid } });
      const name = model.name;
      this.formCommit('UPDATE_STATE', {
        field: 'title',
        value: name && name !== '' ? name : this.$t('text.undefined_project'),
      });
    },

    setFilters() {
      const { query } = this.$route;
      if (Object.keys(query).length) {
        let project_statuses = [];
        switch (typeof query.project_statuses) {
          case 'string':
            project_statuses = [parseInt(query.project_statuses)];
            break;
          case 'object':
            project_statuses = query.project_statuses.map((i) => parseInt(i));
            break;
        }
        this.repoCommit('SET_FILTERS', {
          page: parseInt(query.page) || 1,
          per_page: parseInt(query.per_page) || 10,
          q: query.q || undefined,
          project_statuses,
          managers: typeof query.managers === 'object' ? query.managers : [],
          users: typeof query.users === 'object' ? query.users : [],
          start_date: query.start_date || undefined,
          end_date: query.end_date || undefined,
        });
      }
    },

    checkRedirect(items) {
      if (!this.$store.state.auth.is_logged_in) {
        return this.$router.push('login');
      } else if (this.$user().isClient()) {
        const checkDepartment = _.get(this.$user(), 'check_department', {});
        this.isCorrectDepartment = helpers.checkDepartment(checkDepartment);

        if (!this.isCorrectDepartment) {
          this.domainDepartment = checkDepartment.domain;
          return;
        }

        if (this.$user().project_count === 0) {
          this.tableActionCreate();
          return;
        }
        if (items.length) {
          const project = items[0] || {};
          if (project.uuid) {
            this.tableItemActionEdit(project);
          }
        }
      }
    },
  },
  mounted() {
    if (!this.$store.state.auth.is_logged_in) {
      return this.$router.push('login');
    }

    const queries = this.$route.query;
    const projectStatus = _.get(queries, 'project_statuses', []);

    if (!this.$user().isClient() && !projectStatus.length) {
      this.setFieldFilter({
        field: 'project_statuses',
        value: this.initProjectStatus,
      });
    }

    this.$watch('dataItems', this.checkRedirect);
  },
};
</script>

export default {
  preloadedData: null,
  menu_drawer: false,
  workers: [],
  notifications: [],
  config_preloading: false,
  config: false,
  coordinates: [],
  historyChanges: [],
}

<template lang="pug">
  v-container(fluid)
    v-card(flat)
      data-page(v-bind="dataBind")
</template>
<script>
import PageMixin from '@front/mixins/DataPageAdvanceMixin';
import PassivesMixin from '@/mixins/PassivesMixin';
import FilterTextField from '@/components/tables/parts/FilterTextField';
import FilterSelect from '@/components/tables/parts/FilterSelect';

export default {
  mixins: [PageMixin, PassivesMixin],

  data: function () {
    return {
      modName: 'users',
      formHasDocuments: false,
    };
  },
  computed: {
    tableActionsFilters() {
      return [
        {
          evupdate: this.setFieldFilter,
          component: FilterTextField,
          field: 'q',
          label: this.$t('text.search'),
          value: this.tableFilter['q'],
        },
        {
          evupdate: this.setFieldFilter,
          component: FilterSelect,
          field: 'types',
          label: this.$t('text.type'),
          value: this.tableFilter['types'],
          options: this.passiveUserTypes,
        },
        {
          evupdate: this.setFieldFilter,
          component: FilterSelect,
          field: 'department_ids',
          label: this.$t('text.department'),
          value: this.tableFilter['department_ids'],
          options: this.passiveDepartments,
        },
      ];
    },
    pageTitle() {
      return this.$t('text.menu_users');
    },
    tableHeaders() {
      return [
        { text: this.$t('text.name'), align: 'left', value: 'username' },
        { text: this.$t('text.company'), align: 'left', value: 'account_name' },
        { text: this.$t('text.email'), align: 'left', value: 'email' },
        { text: this.$t('text.type'), value: 'type.name' },
        {
          text: this.$t('text.department'),
          sortable: false,
          align: 'left',
          value: 'department_id',
        },
        { text: this.$t('text.actions'), sortable: false, value: 'action', align: 'right' },
      ];
    },
  },
  methods: {
    onCreateClick() {
      this.$router.push({ name: 'userCreate' });
    },

    onEditClick(model) {
      this.$router.push({ name: 'userEdit', params: { id: model.uuid } });
    },
  },
};
</script>

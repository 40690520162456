// AUTH & LANDING
import {
  ROLE_CLIENT,
  ROLE_PROJECT_DESIGNER,
  ROLE_EMPLOYEE,
  ROLE_PROJECT_MANAGER,
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
} from '@constant/roles';

export const HOME = 'home';

export const LOGIN = 'login';
export const REGISTER = 'register';
export const RESET_PASSWORD = 'reset-password';
export const UPDATE_PASSWORD = 'update-password';
export const IMPERSONATE = 'impersonate';

// AUTHENTICATED
export const PROJECTS = 'projects';
export const PROJECT_EDIT = 'projectEdit';
export const PROJECT_CREATE = 'projectCreate';
export const PROJECTS_BULK_UPDATE = 'projectsBulkUpdate';
export const PROJECT_COMPLETE = 'projectComplete';
export const USERS = 'users';
export const USER_EDIT = 'userEdit';
export const USER_CREATE = 'userCreate';
export const PROFILE = 'profile';
export const TRANSLATIONS = 'translations';
export const TRANSLATION_EDIT = 'translationEdit';
export const TRANSLATION_CREATE = 'translationCreate';
export const LET_GET_STARTED = 'started';
export const INSTRUCTIONS = 'instructions';

// ERRORS
export const ERROR_404 = '404';
// VERSION
export const VERSION = 'version';
/**
 * Possible params
 @param {name} String  - route name
 @param {path} String - route path ( default: '/{$name}' )
 @param {absolute} Boolean - determines if path should be absolute, without leading slash
 @param {page} String - path to vue file ( root is: pages folder )
 @param {params} Array - array of params accepting on router: params=['id','name'] -> ${path}/:id/:name
 @param {paramsRoot} Boolean - allows to access route without parameters a.k.a root route, default = false
 @param {refresh} Boolean - refreshes route, if we need to jump outside vue-router
 */

export const routes = {
  //
  //blog: { name: BLOG,  refresh: true},  page: '_public/blog/Blog', params: ['name'], paramsRoot: true, meta:{new_meta: true}},
  login: { name: LOGIN, meta: { auth: false }, roles: [] },
  register: { name: REGISTER, meta: { auth: false }, roles: [] },
  reset_password: { name: RESET_PASSWORD, meta: { auth: false }, roles: [] },
  update_password: { name: UPDATE_PASSWORD, meta: { auth: false }, roles: [] },
  impersonate: {
    name: IMPERSONATE,
    page: 'Impersonate',
    path: 'impersonate',
    meta: { auth: false },
    roles: [],
  },

  //protected routes
  home: { name: HOME, path: '/', page: 'Home', roles: [] },
  projects: { name: PROJECTS, page: 'projects/Index', roles: [] },
  projectCreate: {
    name: PROJECT_CREATE,
    path: 'projects/create',
    page: 'projects/Project',
    roles: [ROLE_CLIENT],
  },
  projectEdit: {
    name: PROJECT_EDIT,
    path: 'projects/:id',
    page: 'projects/Project',
    roles: [
      ROLE_CLIENT,
      ROLE_EMPLOYEE,
      ROLE_PROJECT_DESIGNER,
      ROLE_PROJECT_MANAGER,
      ROLE_ADMIN,
      ROLE_SUPER_ADMIN,
    ],
  },
  projectsBulkUpdate: {
    name: PROJECTS_BULK_UPDATE,
    page: 'projects/BulkUpdate',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  projectsComplete: {
    name: PROJECT_COMPLETE,
    path: 'projects-complete',
    page: 'projects/Complete',
    roles: [ROLE_CLIENT],
  },
  users: {
    name: USERS,
    page: 'users/Index',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  userCreate: {
    name: USER_CREATE,
    path: 'users/create',
    page: 'users/User',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  userEdit: {
    name: USER_EDIT,
    path: 'users/:id',
    page: 'users/User',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  profile: {
    name: PROFILE,
    page: 'profile/Index',
    roles: [
      ROLE_CLIENT,
      ROLE_EMPLOYEE,
      ROLE_PROJECT_DESIGNER,
      ROLE_PROJECT_MANAGER,
      ROLE_ADMIN,
      ROLE_SUPER_ADMIN,
    ],
  },
  translations: {
    name: TRANSLATIONS,
    page: 'translations/Index',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  translationCreate: {
    name: TRANSLATION_CREATE,
    path: 'translations/create',
    page: 'translations/Translation',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  translationEdit: {
    name: TRANSLATION_EDIT,
    path: 'translations/:id',
    page: 'translations/Translation',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },
  letGetStarted: { name: LET_GET_STARTED, page: 'LetGetStarted', roles: [ROLE_CLIENT] },
  instructions: {
    name: INSTRUCTIONS,
    page: 'instructions/Index',
    roles: [ROLE_ADMIN, ROLE_SUPER_ADMIN],
  },

  err404: { name: ERROR_404, page: 'errors/404', roles: [] },

  version: { name: VERSION, page: 'Version', meta: { auth: false }, roles: [] },
};

export default {
  computed: {
    routes() {
      return routes;
    },
  },
};

<template lang="pug">
  v-tooltip(v-model="show" top content-class="tooltip-custom" v-if="active")
        template(v-slot:activator="{ on, attrs }")
          span.tip-toggle(@click="show = !show")
            icon-tip
        .tip-body
          h3.label {{ label.length ? label : $t('text.tip') }}
            v-btn(icon)
              v-icon(color="#000000" @click="show = !show") mdi-close
          .tips-content
            | {{ tip }}
</template>

<script>
  import IconTip from './IconTip.vue';
  export default {
    name: 'TipContent',
    components: {
      IconTip,
    },
    props: {
      tip: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        show: false,
        active: process.env.NODE_ENV === 'development' // pending ON PROD
      }
    }
  }
  </script>

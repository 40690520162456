<template>
  <v-navigation-drawer v-model="drawerState" app height="100%" style="z-index: 99999" temporary>
    <!--<h3 class="pa-3">{{ $t('text.menu') }}</h3>-->
    <v-list dense nav>
      <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
        <v-list-item to="/profile">
          <v-list-item-icon>
            <v-icon>ic-person</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('text.menu_profile') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isUserAdmin" to="/projects">
          <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('text.menu_projects') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isUserAdmin" to="/users">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('text.menu_users') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isUserAdmin" to="/translations">
          <v-list-item-icon>
            <v-icon>mdi-google-translate</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('text.menu_translations') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isUserAdmin" to="/instructions">
          <v-list-item-icon>
            <v-icon>mdi-youtube</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('Instructions') }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <v-list-item @click="signOut">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('text.menu_logout') }}</v-list-item-title>
      </v-list-item>
      <AppLangSelect>
        <template v-slot:activator="{ on, code, icon, attrs }">
          <v-list-item v-on="on">
            <v-list-item-icon>
              <img :src="icon" alt="" class="img-flag" />
            </v-list-item-icon>
            <v-list-item-title class="text-capitalize" v-bind="attrs">{{ code }}</v-list-item-title>
          </v-list-item>
        </template>
      </AppLangSelect>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { routes } from '@/router/routes';
import AppLangSelect from '@front/components/molecules/AppLangSelect.vue';

export default {
  components: {
    AppLangSelect,
  },
  data() {
    return {
      group: null,
    };
  },
  methods: {
    signOut() {
      this.$store.dispatch('auth/LOGOUT');
    },
  },
  computed: {
    isUserAdmin() {
      return this.$user() && this.$user().isAdmin();
    },
    drawerState: {
      get() {
        return this.$store.state.menu_drawer;
      },
      set(value) {
        this.$store.commit('SET_DRAWER', value);
      },
    },
  },
};
</script>


import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      sm: 768,
      md: 1000,
      lg: 1280,
      // lg: 1280
      // md: 1000
      // sm: 768
      // xs: 600
    },
    scrollBarWidth: 24,
  },
  theme: {
    themes: {
      light: {
        primary: '#4262ff',
        secondary: '#F5F7FF',
        accent: '#b64e9a',
        black: '#222222',
        //--
        error: '#ff4242',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FF9900',
        tag: '#FFDA4C',
      }
    }
  },
  icons: {
    values: {
      prev: 'ic-chevron-left',
      next: 'ic-chevron-right',
      dropdown: 'ic-expand-more',
      radioOff: 'ic-radio-button-unchecked',
      radioOn: 'ic-radio-button-checked',
      // checkboxOff: 'ic-check-box-outline-blank',
      checkboxOn: 'ic-check-box',
      delete: 'ic-close',
      complete: 'ic-done',
    }
  }
})

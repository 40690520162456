import { mix } from 'mixwith'
import Document from '@abs/document'
// import BelongsToCompany from '../concerns/belongs-to-company'
import ProjectSchema from './schema'
// import ProductFaker from './faker'

/**
 * Project model
 */
class Project extends mix(Document).with(ProjectSchema) {
  static create(data) {
    return new this(this.parse(data))
  }
}

export default Project

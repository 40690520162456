<template lang="pug">
  draggable.main-drag.rounded.p-2(
    tag="div"
    :list="list"
    :value="value"
    :group="{ name: 'ggg1' }"
    animation="500"
    :fallbackOnBody="true"
    swapThreshold="0.9"
    ghostClass="ghost"
    @input="emitter"
    @change="onChange"
    handle=".handle"
    :disabled="disabled"
  )
    .item-group(
      v-for="e in realValue" v-if="isItemVisible(e)"
      :key="e.uuid"
      :class="{ 'single': !nestable, 'bg-F3F3F3 mt-4': nestable }"
    )
      div.label(elevation="0")
        v-text-field(
          v-model="e.name"
          filled
          single-line
          required
          :rules="[rules.required]"
          :readonly="disabled"
          maxLength="150"
          hide-details
        )
        div.d-flex.right-control.pr-2.gap-2
          v-btn(icon small color="red"  @click.stop="remove(e)" :disabled="disabled").pa-2
            icon-delete.cursor-pointer
          v-btn.handle(icon small :disabled="disabled" @mousedown="onSelectedItem(e)")
            icon-drag.cursor-pointer.handle
      nested-draggable.drag-area.pl-4.pb-8(
        v-if="nestable"
        :list="e.elements"
        :child="true"
        :class="dragAreaClasses(e)"
        @change="onChange"
        :disabled="disabled"
        :nestable="nestable"
      )
</template>
<script>
import draggable from 'vuedraggable';
import IconDrag from '@/pages/projects/icons/IconDrag.vue';
import IconDelete from '@/pages/projects/icons/IconDelete.vue';

export default {
  name: 'nested-draggable',
  props: {
    list: {
      type: Array,
      required: false,
      default: null,
    },
    value: {
      required: false,
      type: Array,
      default: null,
    },
    root: {
      required: false,
      type: Array,
      default: () => [],
    },
    child: false,
    disabled: false,
    nestable: true,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'This field required.',
        name: [(val) => (val || '').length > 0 || 'This field is required'],
      },
      rootClone: [],
      rootIds: [],
      itemSelected: '',
      sessionStorageKey: 'draggable-root',
    };
  },
  components: { draggable, IconDrag, IconDelete },
  computed: {
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  watch: {
    root: {
      handler(val) {
        if (val && val.length) {
          sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(val));
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onSelectedItem(item) {
      this.itemSelected = item.uuid;
    },
    dragAreaClasses(item) {
      const items = item.elements ?? [];
      return {
        'has-items': items.length,
      };
    },
    emitter(value) {
      this.$emit('input', value);
    },
    onChange: function () {
      if (this.child === true) {
        this.$emit('change');
      } else {
        this.emitter(this.value);
      }
    },
    remove(item) {
      this.$root.$emit('remove-item', item);
    },
    isItemVisible(item) {
      return !item.deleted;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-drag {
  width: 100%;

  .item-group {
    &.single:not(:last-child) {
      margin-bottom: 10px;
    }

    .handle:hover {
      cursor: grab !important;
    }

    .label {
      display: flex;
      // padding: 10px;
      border-radius: 5px;
      // background: rgba($cl-primary, 0.05);
      position: relative;

      .right-control {
        position: absolute;
        // border: solid 1px red;
        align-items: center;
        height: 100%;
        right: 0;
        top: 0;
      }
    }
  }
}

.drag-area {
  padding: 20px;

  &.show-border {
    border-color: rgba($cl-primary, 0.4);
  }

  &.has-items {
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    border-color: transparent;
  }
}
</style>

<template lang="pug">
  .d-flex.flex-column.mb-5
    .input-group.mb-4
      div.flex.gap-2
        div
          v-text-field(
            :label="$t('text.your_domain_name') + ' (example.com)'"
            :rules="[rules.domain_name, rules.domain_top_level]"
            v-model="realvalue"
            :readonly="isLocked"
            filled
          )
            v-icon(v-if="showStatusSuccess" slot="append" color="green") ic-check
            //-v-icon(v-else-if="showStatusError" slot="append" color="red") ic-close
          div.text-sm.text-warning {{ hint }}
        div
          v-btn(color="primary" style="height: 56px !important;" depressed @click="btnClick" :loading="isLoading" :disabled="isLocked || !isValidTopLevelDomain") {{ btnTitle }}
    .error-text.mt-1(v-if="error") {{ error }}
    div.mt-4(v-if="checked && !excludeDomains")
      v-form(ref="form_com")
        .field-container
          .subtitle.mb-2 <span class="red--text">*</span> Country
          v-autocomplete(placeholder="Country" filled :items="countries" item-text="name" item-value="code2" :readonly="isLocked" :rules="[rules.required]" ref="com_country" v-model="com_country")
          // v-text-field(placeholder="Country" filled :readonly="isLocked" :rules="[rules.required]" ref="com_country" v-model="com_country")
        .field-container
          .subtitle.mb-2 <span class="red--text">*</span> State
          v-autocomplete(placeholder="State" filled :items="states" item-text="name" item-value="name" :readonly="isLocked" :rules="[rules.required]" ref="com_county" v-model="com_county")
          // v-text-field(placeholder="County" filled :readonly="isLocked" :rules="[rules.required]" ref="com_county" v-model="com_county")  
        .field-container
          .subtitle.mb-2 <span class="red--text">*</span> City
          v-text-field(placeholder="City" filled :readonly="isLocked" :rules="[rules.required]" ref="com_city" v-model="com_city")
        .field-container
          .subtitle.mb-2 <span class="red--text">*</span> Street
          v-text-field(placeholder="Street address" filled :readonly="isLocked" :rules="[rules.required]" ref="com_street_address" v-model="com_street_address")
        .field-container
          .subtitle.mb-2 <span class="red--text">*</span> ZIP
          v-text-field(placeholder="ZIP" filled :readonly="isLocked" :rules="[rules.required]" ref="com_zip" v-model="com_zip")
</template>
<script>
import countries from '../../../json/countries.json';
import domain from '@/scripts/domain';
/*eslint-disable*/
const hint = 'Only supported top-level-domains: .com, .eu, .net, .org, .co.uk';
export default {
  props: {
    error: { type: String, default: null },
    is_locked: { type: Boolean, default: false },
    registered: { type: String, default: null },
    value: { type: String, default: null },
    domain_whmcs: {},
  },
  /*eslint-disable*/
  data: function () {
    return {
      hint,
      isLoading: false,
      available: null,
      checked: false,
      realvalue: this.value,
      rules: {
        required: (value) => !!value || 'This field required.',
        domain_name: (value) => {
          if (!value?.trim()) {
            return true;
          }
          //- a.host.endsWith('.com')
          // .com, .eu, .net, .org"
          const pattern =
            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9]{2,256}(\.[a-zA-Z]{2,61})+\b([a-zA-Z0-9]*)$/;
          return (value?.trim().length && pattern.test(value)) || 'The domain name invalid';
        },
        domain_top_level: (value) => {
          //- a.host.endsWith('.com')
          // .com, .eu, .net, .org"
          value = value ?? '';
          let _url = value?.toString()?.trim();
          const isPassed =
            _url.endsWith('.com') ||
            _url.endsWith('.eu') ||
            _url.endsWith('.net') ||
            _url.endsWith('.org') ||
            _url.endsWith('.co.uk');
          return isPassed || hint;
        },
      },
      com_country: '',
      com_city: '',
      com_street_address: '',
      com_county: '',
      com_zip: '',

      formHasErrors: false,
      countries,
    };
  },

  computed: {
    isValidTopLevelDomain() {
      let value = this.realvalue ?? '';
      let _url = value?.toString().trim();
      return (
        _url.endsWith('.com') ||
        _url.endsWith('.eu') ||
        _url.endsWith('.net') ||
        _url.endsWith('.org') ||
        _url.endsWith('.co.uk')
      );
    },
    excludeDomains() {
      return domain.isUK();
    },
    states() {
      let [_country = {}] = this.countries.filter((c) => c.code2 == this.com_country);
      return _country?.states || [];
    },
    isLocked() {
      return this.is_locked || this.domain_whmcs ? true : false;
    },

    btnColor() {
      return this.checked || this.domain_whmcs ? 'primary' : '';
    },
    btnTitle() {
      if (this.domain_whmcs) return this.$t('text.registered');

      return this.checked ? this.$t('text.register_domain') : this.$t('text.check_domain');
    },

    isDisabled() {
      return this.disabled || this.registered;
    },

    showStatusSuccess() {
      return this.available;
    },

    showStatusError() {
      return this.available === false || this.error;
    },

    form() {
      if (this.excludeDomains) {
        return {};
      }
      return {
        com_country: this.com_country,
        com_city: this.com_city,
        com_street_address: this.com_street_address,
        com_zip: this.com_zip,
        com_county: this.com_county,
      };
    },
  },

  watch: {
    realvalue: function (newVal, oldVal) {
      this.reset();
    },
  },

  methods: {
    btnClick() {
      if (this.error) {
        this.$store.commit('forms/projects/SET_ERROR', {
          field: 'domain_whmcs',
          message: '',
        });
      }

      if (this.checked) {
        this.register();
      } else {
        this.check();
      }
    },

    register() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
        }
        this.$refs[f].validate(true);
      });
      if (this.formHasErrors) {
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch('forms/projects/DOMAIN_REGISTER', {
          ...this.form,
          domain_name: this.realvalue,
        })
        .then((res) => {
          console.error('RES', res.status);
          this.isLoading = false;
          if (res.status === 200) {
            this.$store.commit('ADD_NOTIFICATION', this.$t('text.domain_registered_successfully'));
            this.$emit('input', this.realvalue);
            this.$root.$emit('save-form');
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    reset() {
      this.isLoading = false;
      this.checked = false;
      this.available = null;
      //this.error = null
      this.$store.commit('forms/projects/SET_ERROR', { field: 'domain_whmcs', value: null });
    },

    check() {
      this.isLoading = true;
      this.$store
        .dispatch('forms/projects/DOMAIN_CHECK', this.realvalue)
        .then((resp) => {
          this.available = resp.available;
          this.isLoading = false;
          this.checked = resp.available;
          // this.checked = true
          if (resp.available === false) {
            this.$store.commit('forms/projects/SET_ERROR', {
              field: 'domain_whmcs',
              message: this.$t('text.domain_is_unavailable'),
            });
          }
        })
        .catch(() => {
          this.reset();
          this.$store.commit('forms/projects/SET_ERROR', {
            field: 'domain_whmcs',
            message: this.$t('text.oops_server_error_try_again_later'),
          });
        });
    },
  },
};
</script>
<style lang="scss">
.input-group {
  display: flex;
  //background:red;

  .v-btn {
    height: auto !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .v-input {
    border-top-right-radius: 0px;
  }
}
</style>

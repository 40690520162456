<template lang="pug">
  section
    div.public
      div.auth-page
        div.z-10.logo-auth(
          :class="[isAllowBanner ? '' : 'no-banner']"
        )
          div(:class="!isCompatibleBrowser && 'hidden'")
            router-link(to="/")
              img.img-logo.block(src="@/assets/images/logo.svg")
          AppLangSelect(:class="!isCompatibleBrowser && 'hidden'")
        template(v-if="isCompatibleBrowser")

          template(
            v-if="isAllowBanner"
          )
            div.auth-content(class="pt-4x md:pt-12")
              div.h-100.relative
                div.form-auth(:class="isRegisterPage ? 'pb-2' : 'py-4'")
                  login(v-if="isLoginPage")
                  register(v-if="isRegisterPage")
                  passwordReset(v-if="isForgotPasswordPage")
                  passwordUpdate(v-if="isUpdatePasswordPage")
                  slot(name="router")
            div.auth-banner
              img(v-if="isLoginPage" src="@/assets/images/signin.svg")
              img(v-if="isRegisterPage" src="@/assets/images/signup.svg")
              img(v-if="isForgotPasswordPage" src="@/assets/images/resetpassword.svg")
              img(v-if="isUpdatePasswordPage" src="@/assets/images/resetpassword.svg")
          template(v-else)
            slot(name="router")
        template(v-else)
          compatible-browser(:is-login="false" @click="onAcceptRisk")
</template>
<script>
import Login from './auth/Login';
import Register from './auth/Register';
import PasswordReset from './auth/PasswordReset';
import PasswordUpdate from './auth/PasswordUpdate';
import AppLangSelect from '@front/components/molecules/AppLangSelect.vue';
import CompatibleBrowser from '@/components/CompatibleBrowser';

export default {
  components: {
    Login,
    Register,
    PasswordReset,
    AppLangSelect,
    PasswordUpdate,
    CompatibleBrowser,
  },

  computed: {
    displayBrowserVersion() {
      let chainBrowsers = '';
      this.browserCompatible.forEach(({ name, version }) => {
        chainBrowsers += ` ${name} ${version} /`;
      });
      return chainBrowsers.slice(0, -1);
    },

    currentPage() {
      return this.$route.name;
    },

    isAllowBanner() {
      const allowBanners = ['login', 'reset-password', 'register', 'update-password'];
      return allowBanners.includes(this.currentPage);
    },

    isMainPage() {
      return this.currentPage === null || this.currentPage === '/';
    },

    isLoginPage() {
      return this.isMainPage || this.currentPage === 'login';
    },

    isRegisterPage() {
      return this.currentPage === 'register';
    },

    isForgotPasswordPage() {
      return this.currentPage === 'reset-password';
    },

    isUpdatePasswordPage() {
      return this.currentPage === 'update-password';
    },
  },
  created() {
    this.isCompatibleBrowser = this.$utils.checkCompatibleBrowser();
  },
  data() {
    return {
      isCompatibleBrowser: true,
    };
  },
  methods: {
    onAcceptRisk(value) {
      this.isCompatibleBrowser = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.auth-page {
  display: flex;
  width: 100%;
  background: #fff;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-wrap: wrap-reverse;
    flex-wrap: wrap;
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
  }
}

.logo-auth {
  width: 50%;
  padding: 0.5rem 3.5rem;
  background: #ffffff;

  &.no-banner {
    width: 100% !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    padding: 0 20px;
    background: #ffffff;
    box-shadow: 0 2px 16px rgba(160, 160, 160, 0.2);
    height: 61px;
    position: sticky;
    top: 0;
    left: 0;
  }
}

.img-logo {
  width: 128px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    transform: translateY(-5px);
    width: 100px;
  }
}

.auth-banner {
  background: #ebefff;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }

  img {
    display: block;
    max-width: 75%;
    margin: 0 auto;
  }
}

.auth-content {
  width: 50%;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    order: 1;
    padding: 20px;
  }

  .relative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      position: static;
    }
  }
}

.form-auth {
  max-width: 407px;
  width: 100%;
  margin: 0 auto;

  :deep .v-text-field {
    padding-top: 7px !important;
    margin-top: 0 !important;
  }
}
</style>

/**
 * Get key of the item.
 *
 * Most of the time, item key will be either
 * numeric id (e.g. 42), or uuid (e.g. 8724687b-0078-501d-8a68-302a5b45b5e7)
 * But there are cases (like vat info checks), when key may be
 * composed of item properties, in that case, we need to call a
 * function with passed item as a parameter, to get the actual
 * value of the item key.
 */
export function getItemKey(item, key) {
  // item is already presented as a key
  if (typeof item === 'string') {
    return item
  }
  if (typeof key === 'string') {
    return item[key]
  }
  if (typeof key === 'function') {
    return key(item)
  }
  return item
}

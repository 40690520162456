<template>
  <section class="d-flex flex-column xl:justify-center h-100 bg-white">
    <v-container class="fluid text-center pt-0 w-full">
      <h1 class="text-5xl font-bold text-222222">{{ $t('text.project_complete_title') }}</h1>
      <h2 class="text-2xl font-bold my-7 text-222222">
        {{ $t('text.project_complete_subtitle') }}
      </h2>
      <p class="text-md text-222222">
        {{ $t('text.project_complete_description_1') }}
        <br />
        {{ $t('text.project_complete_description_2') }}
      </p>
      <v-btn class="w-32 mt-2 capitalize" color="primary" large @click="onReview">
        {{ $t('text.review_btn') }}
      </v-btn>
      <div class="mt-13">
        <img
          alt="complete"
          class="w-3/5 max-w-xl mx-auto"
          src="@/assets/images/thank-you-bg-image.svg"
        />
      </div>
    </v-container>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    onReview() {
      this.$router.push({ name: 'projects' }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped></style>

<style lang="scss">
@import './sass/topbar.scss';
</style>
<template>
  <v-app-bar
    id="topbar-navigation"
    :extended="isExtendHeader"
    :extension-height="extendHeight"
    :height="heightTopBar"
    app
    clipped-left
    clipped-right
    color="white"
    elevation="0"
    flat
  >
    <div class="top-bar__wrap">
      <v-toolbar v-if="isWarning" class="top-bar__alert" flat>
        {{ $t('text.your_session_will_expire_in') }} {{ minutes }}:{{ seconds }}
      </v-toolbar>
      <div class="top-bar__header">
        <div class="logo-header" @click="gotoHomeDashboard">
          <img :width="128" alt="logo" class="block" src="@/assets/images/logo/logo.png" />
        </div>
        <v-spacer></v-spacer>
        <AppLangSelect class="block md:hidden self-center" />
        <div class="hidden md:block text-center">
          <div class="inline-block">
            <div class="flex gap-6">
              <div class="item-menu flex-center">
                <v-menu offset-y open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="w-100 text-center h-100 flex-center" v-bind="attrs" v-on="on">
                      <span>
                        <hide-late>{{ $t('text.hi') }},&nbsp;</hide-late>
                        {{ accountName }}
                      </span>
                      <v-icon class="text-black">mdi-chevron-down</v-icon>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item active-class="primary--text" to="/profile">
                      <v-list-item-icon>
                        <v-icon class="mr-2">ic-person</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('text.menu_profile') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="isUserAdmin" active-class="primary--text" to="/users">
                      <v-list-item-icon>
                        <v-icon class="mr-2">mdi-account-group</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('text.menu_users') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="isUserAdmin" active-class="primary--text" to="/projects">
                      <v-list-item-icon>
                        <v-icon class="mr-2">mdi-folder</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('text.projects') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="isUserAdmin" active-class="primary--text" to="/translations">
                      <v-list-item-icon>
                        <v-icon class="mr-2">mdi-google-translate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('text.menu_translations') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="isUserAdmin" active-class="primary--text" to="/instructions">
                      <v-list-item-icon>
                        <v-icon class="mr-2">mdi-youtube</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('Instructions') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item active-class="primary--text" @click="logout">
                      <v-list-item-icon>
                        <v-icon class="mr-2">ic-power-settings-new</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('text.menu_logout') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <AppLangSelect />
            </div>
          </div>
        </div>
        <div class="block md:hidden">
          <v-btn depressed icon @click="toggleDrawer">
            <v-icon>ic-menu</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <template v-if="isExtendHeader" v-slot:extension>
      <div id="top-bar-content" class="w-full">
        <div
          class="w-full relative md:static md:pr-6 md:pl-sideBar xl:pl-1 mx-auto xl:pr-1 extend-ctl"
        >
          <div class="flex justify-between items-center">
            <p class="text-md ma-0 text-semibold font-bold">
              {{ accountName }}
            </p>
            <v-btn
              v-if="showSaveProject"
              :disabled="Boolean($store.state.forms.projects.fields.is_locked)"
              :loading="loading"
              class="capitalize"
              color="warning"
              depressed
              style="text-transform: capitalize"
              @click="toSaveForm"
              >{{ isCreatingProject ? $t('text.Create_Project') : $t('text.Save_Project') }}
            </v-btn>
          </div>
        </div>
        <slot name="sticky-mobile"></slot>
      </div>
    </template>
    <v-dialog v-model="isDialog" max-width="300" persistent>
      <v-card>
        <v-card-text class="pa-6">
          {{ $t('text.your_session_will_expire_in') }} {{ minutes }}:{{ seconds }},
          {{ $t('text.please_save_and_login') }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" depressed rounded text @click="onAgreeDialog">
            {{ $t('text.btn_agree') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>
<script>
import HideLate from '@front/components/common/HideLate.vue';
import AppLangSelect from '@front/components/molecules/AppLangSelect.vue';
import FormMixin from '@/mixins/FormMixin';

export default {
  components: {
    HideLate,
    AppLangSelect,
  },
  mixins: [FormMixin('projects')],
  data() {
    return {
      iconSize: 15,
      heightTopBar: null,
      isWarning: false,
      isDialog: false,
      startTimeLeft: false,
      isLeave: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeLeft: 0,
      timer: null,
      timerInterval: null,
      currentTitle: '',
      alertTitle: '',
    };
  },

  computed: {
    isCreatingProject() {
      return ['projectCreate'].includes(this.$route.name);
    },
    isExtendHeader() {
      return ['projectEdit', 'projectCreate'].includes(this.$route.name);
    },
    extendHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '178px';
      }
      return '72px';
    },
    items() {
      return [
        {
          icon: 'ic-person',
          label: this.$t('text.menu_profile'),
          to: '/profile',
        },
        {
          icon: 'mdi-account-group',
          label: this.$t('text.menu_users'),
          to: '/users',
        },
      ];
    },
    isUserAdmin() {
      return this.$user() && this.$user().isAdmin();
    },

    accountName() {
      return this.$store.state.auth.account.name || '';
    },
    projectName() {
      if (['projectEdit', 'projectCreate'].includes(this.$route.name)) {
        return this.$store.state.forms.projects.fields.name || '';
      }
      return false;
    },

    loading() {
      return this.$store.getters.getCoordinate('saveBase');
    },

    showSaveProject() {
      return this.$user().isClient();
    },
  },
  watch: {
    seconds(val) {
      if (this.isLeave && this.startTimeLeft && this.minutes < 10) {
        document.title = `⚠ ${this.minutes}:${val} Warning`;
      }
      if (this.minutes === 5 && !+val) {
        this.$store.dispatch(
          'repositories/projects/API_UPDATE',
          this.$store.state.forms.projects.fields
        );
      }

      if (!this.minutes && +val < 1) {
        document.title = this.currentTitle;
      }

      if (this.minutes < 1 && !+val) {
        this.$store.dispatch(
          'repositories/projects/API_UPDATE',
          this.$store.state.forms.projects.fields
        );
        setTimeout(() => {
          clearInterval(this.timerInterval);
          clearInterval(this.timer);
          this.logout();
        }, 2000);
      }
    },
    minutes(val) {
      if (this.isLeave) {
        if (this.startTimeLeft && val < 10) {
          this.flashTitle();
        }
      }
    },
    '$route.params': {
      handler(val) {
        if (_.has(val, 'id')) {
          if (this.$user().isClient() && val.id) {
            this.timerInterval = setInterval(() => {
              this.onCheckTimeLeft();
            }, 300000);
          }
          if (!this.startTimeLeft) {
            if (this.$user().isClient() && this.$route.params.id) {
              this.onCheckTimeLeft();
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    gotoHomeDashboard() {
      this.$router.push('/');
    },
    logout() {
      clearTimeout(this.timer);
      clearInterval(this.timerInterval);

      this.$store.dispatch('auth/LOGOUT');
      this.isWarning = false;
      this.startTimeLeft = false;
      this.heightTopBar = this.timer = this.timerInterval = null;
      this.hours = this.minutes = this.seconds = this.timeLeft = 0;

      document.title = this.currentTitle;

      const liveChatEl = document.querySelector('[data-b24-crm-button-cont]');
      if (!liveChatEl) return;
      if (liveChatEl.classList.contains('live-chat-custom-position')) {
        liveChatEl.classList.remove('live-chat-custom-position');
      }
    },

    toggleDrawer() {
      this.$store.commit('SET_DRAWER', true);
    },

    toSaveForm() {
      this.$eventBus.$emit('save-form');
    },
    onAgreeDialog() {
      this.isWarning = true;
      this.heightTopBar = 108;
      this.isDialog = false;

      setTimeout(() => {
        const topBarEl = document.querySelector('#topbar-navigation');
        const sideBarMenuEl = document.querySelector('#sidebar-menu');
        const panelMenuEl = document.querySelector('#panel-menu');
        panelMenuEl.style.top = sideBarMenuEl.style.top = `${topBarEl.offsetHeight}px`;
        panelMenuEl.style.height = sideBarMenuEl.style.height = `calc(${
          '100vh' - topBarEl.offsetHeight
        })`;
      }, 50);
    },
    startCountdown(totalSeconds) {
      const interval = 1000;
      clearInterval(this.timer);
      this.updateCountdown(totalSeconds);
      this.timer = setInterval(() => {
        totalSeconds--;
        this.updateCountdown(totalSeconds);

        if (totalSeconds <= 0) {
          clearInterval(this.timer);
        }
      }, interval);
    },
    updateCountdown(totalSeconds) {
      this.hours = Math.floor(totalSeconds / 3600);
      this.minutes = Math.floor(totalSeconds / 60) - 1;
      this.seconds = totalSeconds % 60 < 10 ? `0${totalSeconds % 60}` : totalSeconds % 60;
    },
    onCheckTimeLeft() {
      clearInterval(this.timer);
      this.$store
        .dispatch('auth/CHECK_SESSION')
        .then((res) => {
          this.timeLeft = res.time_left || 0;
          this.startCountdown(this.timeLeft);
          this.startTimeLeft = true;
          if (!this.isWarning && this.minutes < 10) {
            this.isDialog = true;
            this.heightTopBar = 108;
          }
        })
        .catch(() => this.logout());
    },
    flashTitle() {
      if (!this.isLeave) {
        document.title = this.currentTitle;
      }
    },
    listenUserLeavePage() {
      this.isLeave = document.hidden;

      if (!Object.keys(this.$route.params).length) return;

      if (!this.isLeave) {
        document.title = this.currentTitle;
      }
    },
  },
  mounted() {
    this.currentTitle = document.title;
    document.addEventListener('visibilitychange', this.listenUserLeavePage);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.listenUserLeavePage);
  },
  beforeRouteEnter() {
    clearTimeout(this.timer);
    clearInterval(this.timerInterval);
  },
  beforeRouteLeave() {
    clearTimeout(this.timer);
    clearInterval(this.timerInterval);
  },
};
</script>
<style lang="scss">
.extend-ctl {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    // padding: 10px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    // background: red;
    box-shadow: 0px 0px 4px rgba(160, 160, 160, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      box-shadow: none;
      box-shadow: 0px 0px 4px rgba(160, 160, 160, 0.25);
      width: calc(100% + 200px);
      left: -100px;
    }
  }
}
</style>

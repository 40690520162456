<template lang="pug">
  v-btn(@click="$emit('click')" :color="color" :href="link" :target="target") {{ title }}
    v-icon(:small="small" :large="large") {{ icon }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    link: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    }
  }
}
</script>
<template lang="pug">
  .version version {{ version }}
</template>
<script>

export default {
  computed: {
    version() {
      return require('@front/../package.json').version
    }
  }
}
</script>

<style lang="scss">
  .version {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 11px;

    &.topbar, &.auth {
      top: auto;
      bottom: 0px;
      left: auto;
      right: 31px;
    }

    &.auth {
      bottom: 5px;
      right: 75px;
    }
  }
</style>
<template lang="pug">
  .font-chooser.mb-5
    v-radio-group(v-model="activeFont" @change="$emit('change', activeFont)")
      v-carousel.pb-10(
        delimiter-icon="ic-brightness-1"
        prev-icon="ic-chevron-left"
        next-icon="ic-chevron-right"
        hide-delimiters
        :disabled="disabled"
        @change="change"
        ref="carousel"
        height="auto"
        :cycle="!activeFont ? true : false"
        :show-arrows="false"
      )
        v-carousel-item(
          v-for="(item,i) in itemsNew"
          :key="i"
          position="right"
        )
          div.h-full.relative
            div.grid.grid-cols-1.gap-4(:class="['md:grid-cols-2 lg:grid-cols-3']")
              template(v-for="(font, i) in item")
                .font-preview.overflow-hidden.rounded.shadow-lg.space-y-4(:key="i" :font-to="font.value" :class="[activeFont == font.value ? 'row_active' : '']").border.relative
                  div.absolute.w-full.h-full.top-0.left-0.z-10.border.opacity-0
                    v-radio(:value="font.value" name="tlc").w-full.h-full
                  .paragraph.space-y-2
                    h4.px-4.font-semibold About us
                    .text-left.px-4.font-normal.text-12.text-clamp-3 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  div.w-full.bg-gray(style="height: 1px; width: 100%; background: #EBEFFF;")
                  .headings.px-4.space-y-2.mt-4
                    h1 Headings    
                    p.mb-4.font-semibold Oswald
                    h3 Paragraphs    
                    p.mb-4.font-normal.text-18 {{ font.text }}
        p.text-xs.text-error.mt-3(v-if="errorMessage.length")
          | {{ errorMessage }}
        div.my-4.right-4.bottom-4.flex.gap-8.items-center
          div.flex.items-center.gap-4.justify-end
            span {{ carousel.internalIndex }} 
            span(style="height: 1px; width: 34px; background: black;")
            span {{ carousel.total }}
          div.grid.grid-cols-2.rounded.overflow-hidden
            div(@click="carousel.prev" style="background: #4262FF;").w-8.h-8.cursor-pointer.flex-center
              v-icon(small) mdi-arrow-left
            div(@click="carousel.next" style="background: #EBEFFF;").w-8.h-8.cursor-pointer.flex-center
              v-icon(color="primary" small) mdi-arrow-right       
      //- v-autocomplete(
      //-   v-model="activeFont"
      //-   :items="items"
      //-   append-icon="ic-expand-more"
      //-   cache-items
      //-   flat
      //-   hide-details
      //-   :label="$t('text.select_a_font')"
      //-   filled
      //-   :disabled="disabled"
      //-   @change="$emit('change', activeFont)"
      //- )
      //- div.grid.grid-cols-3.gap-4.py-4.rounded.shadown
      //-   .font-preview(v-if="activeFont" :class="classes").border
      //-     .paragraph.space-y-2
      //-       h4.font-semibold About us
      //-       .text-left.font-normal.text-12 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      //-     .headings.space-y-2.mt-4
      //-       h1 Headings    
      //-       p.mb-4.font-semibold Oswald
      //-       h3 Paragraphs    
      //-       p.mb-4.font-normal.text-18 Inter {{ activeFont }}
</template>
<script>
import { FontManager } from '@samuelmeuli/font-manager';
import chunk from 'lodash/chunk';

export default {
  props: ['apiKey', 'paragraph', 'disabled', 'value', 'id', 'errorMessage'],
  data() {
    return {
      fontManager: null,
      loading: false,
      items: [],
      itemsNew: [],
      search: null,
      activeFont: this.value,
      carouselRef: undefined,
    };
  },
  computed: {
    columns() {
      return 3;
    },
    classes() {
      let cls = {};
      cls['apply-font-' + this.id] = true;
      return cls;
    },

    defaultFont() {
      return this.value ? this.value : 'Open Sans';
    },

    carousel() {
      let internalIndex = this.carouselRef?.internalIndex || 0;
      internalIndex = internalIndex + 1;
      return {
        internalIndex: internalIndex < 10 ? '0' + internalIndex : internalIndex,
        total: this.itemsNew.length,
        prev: () => {
          try {
            this.carouselRef?.prev();
          } catch (err) {
            // console.log(err.message);
          }
        },
        next: () => {
          try {
            console.log(this.carouselRef);
            this.carouselRef?.next();
          } catch (err) {
            // console.log(err.message);
          }
        },
      };
    },
  },
  watch: {
    errorMessage(msg) {
      console.log('watch errorMessage', msg);
    },
    //- activeFont (font) {
    //-   this.fontManager && this.activeFont && this.fontManager.setActiveFont(font)
    //-   //val && val !== this.select && this.querySelections(val)
    //- },
  },
  methods: {
    addScriptCustomFonts(fonts = []) {
      let fontToString = (font) => `
		[font-to="${font}"] {
			font-family: "${font}";
		}`;
      let css = '';
      fonts.forEach((font) => {
        css += fontToString(font.value);
      });
      const isMacOs = navigator.userAgent.indexOf('Mac OS X') !== -1;
      let head = document.head || document.getElementsByTagName('head')[0];
      let id = 'font-custom-manager';
      let fontElm = document.getElementById(id);
      if (fontElm) {
        fontElm.innerHTML = css;
      } else {
        let head = document.head || document.getElementsByTagName('head')[0];
        let style = document.createElement('style');
        style.id = id;
        head.appendChild(style);
        style.type = 'text/css';
        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
      }
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter((e) => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },
    change() {
      this.carouselRef = this.$refs['carousel'];
      // console.log(this.carousel, this.$refs['carousel'])
    },
  },

  mounted() {
    this.fontManager = new FontManager(this.apiKey, this.defaultFont, {
      limit: 100,
      pickerId: this.id,
    });
    // console.log(this.fontManager);
    this.fontManager
      .init()
      .then(() => {
        // font list has finished loading
        //console.log(Array.from(this.fontManager.getFonts()))
        //console.log(this.fontManager.getActiveFont())
        //this.fontManager.setActiveFont('Roboto')
        //console.log(this.fontManager.getActiveFont())
        let items = Array.from(this.fontManager.getFonts()).map((item) => {
          // console.error(item)
          return {
            text: item[0],
            value: item[0],
          };
        });
        this.items = items;
        this.itemsNew = chunk(
          Array.from(this.fontManager.getFonts()).map((item) => {
            // console.error(item)
            return {
              text: item[0],
              value: item[0],
            };
          }),
          3
        );
        this.carouselRef = this.$refs['carouselF'];
        this.addScriptCustomFonts(items);
      })
      .catch((err) => {
        // error while loading font list
        // console.error(err);
      });
  },
};
</script>
<style lang="scss">
.font-chooser {
  .font-preview {
    //- padding: $content-pad;
    border-bottom-left-radius: $border-radius-root;
    border-bottom-right-radius: $border-radius-root;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .font-preview {
      padding: 10px;
    }
  }
}
</style>

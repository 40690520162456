<template lang="pug">
  .content-extras(ref="extras")
    v-sheet.extra-sheet.border-primary.mb-4(
      v-for="extra in model"
      v-if="extra.added && extraComponent(extra.id)"
      :key="extra.uuid"
    )

      group-container(v-bind="extraTitles(extra.id)")
        v-btn.close(icon small @click="closeExtra(extra)" :disabled="disabled")
          img.block.cursor-pointer(src="@/assets/images/delete.svg")
        //- :is="extraComponent(extra.id)"
        component(
          :is="extraComponent(extra.id)"
          :model="extra"
          :disabled="disabled"
          :isAutoFilled="isAutoFilled"
        )


    div.border-custom-image.p-6
      .text-24.text-222222.text-center.font-medium.mb-6.break-words
        | {{ $t('text.Add_a_new_section') }}
      draggable(
        tag="div"
        :list="items"
        :group="{ name: 'extras' }"
        animation="300" :fallbackOnBody="true" swapThreshold="0.9"
        ghostClass="ghost"
        handle=".handle"
        @change="reOrder"
        :disabled="disabled" class="bg-FBFBFB grid gridcols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4"
      )
        .box6.relative.rounded.bg-white(
          v-for="(extra, index) in sortedITems"
          :key="extra.id"
          style="border-color: #4262FF;"
        )
          v-btn.handle(icon small :disabled="disabled" absolute top right v-if="$vuetify.breakpoint.xs")
            v-icon(small) ic-open-with
          .d-flex.flex-column
            div.overflow-hidden.border-b.pa-2(:class="['h-150']").flex.items-center.justify-center.group-able-tip.tip-content
              tip-content(:tip="extra.tip")
              img.block.mx-auto.max-h-full(:src="extra.thumbnail" alt="")
            div.pa-4.flex.justify-between.items-center
              h2.name.capitalize.break-all {{ $t(extra.name_key) }}
              template(v-if="extra.multiple")
                v-btn(
                  @click="addExtra(extra)"
                  color="primary" icon
                depressed small class="capitalize"
                  :disabled="disabled")
                  //- | {{ $t('text.add') }} 
                  v-icon ic-add
              template(v-else)
                v-btn(
                  @click="addExtra(extra)"
                  v-if="!extra.added"
                  color="primary" icon
                depressed small class="capitalize"
                  :disabled="disabled")
                  //- | {{ $t('text.add') }} 
                  v-icon ic-add
            //- .desc {{ $t(extra.description_key) }}
          //- v-spacer
          //- div.d-flex.justify-end.flex-wrap   
          //-   v-btn.handle(icon small :disabled="disabled" v-if="!$vuetify.breakpoint.xs")
          //-     v-icon ic-open-with
          //-   div(style="width: 100%; height: 10px;") 
          //-   v-btn.mt-5(
          //-     large
          //-     @click="addExtra(extra)" 
          //-     v-if="!extra.added" 
          //-     rounded
          //-     color="primary"
          //-     width="175px" 
          //-     depressed
          //-     :block="$vuetify.breakpoint.xs"
          //-     :disabled="disabled") {{ $t('text.add') }} 
          //-       v-icon(right) ic-add
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';

import ExtraFeatures from './Features';
import ExtraFeaturesOld from './FeaturesOld';

import ExtraGallery from './Gallery';
import ExtraGalleryOld from './GalleryOld';

import ExtraContacts from './Contacts';
import ExtraContactsOld from './ContactsOld';

import ExtraRegistration from './Registration';
import ExtraRegistrationOld from './RegistrationOld';

import ExtraTestimonials from './Testimonials';
import ExtraTestimonialsOld from './TestimonialsOld';

import ExtraPartners from './Partners';
import ExtraPartnersOld from './PartnersOld';

import ExtraTextBlockMedia from './TextBlockMedia';
import ExtraPrice from './Price';

import DragState from '@/components/forms/parts/DragState';
import draggable from 'vuedraggable';
import vuetize from '@/scripts/vuetize';

import { doGenerateText } from '@/helpers';

import TipContent from '@/components/forms/parts/TipContent';

export default {
  components: { GroupContainer, draggable, DragState, TipContent },
  props: {
    model: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAutoFilled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      items: [],
    };
  },
  computed: {
    sortedITems() {
      return this.items.filter((item) => !item.hide);
    },

    leftExtras() {
      let count = 0;
      Object.keys(this.model).forEach((key) => {
        if (!this.model[key].added) count++;
      });
      return count;
    },
  },

  methods: {
    sortItems() {
      this.model.forEach((extra, index) => {
        const item = this.items.find((x) => x.id == extra.id);
        item.added = extra.added;
        item.order = index;
        if (!extra.uuid) {
          extra.uuid = this.$uuid.v4();
        }
      });

      this.items = this.items.sort((a, b) => {
        return a.order - b.order;
      });
    },

    extraComponent(id) {
      let item = this.items.find((x) => x.id == id);
      if (item) {
        if (item.hide) {
          return false;
        }
        if (!item.component) {
          return false;
        }
        return item.component;
      }
      return false;
      //return this.items[extra.id].component
    },

    extraTitles(id) {
      const ex = this.items.find((x) => x.id == id);
      return {
        title: ex?.name,
        subtitle: ex?.description,
      };
    },

    addExtra(extra) {
      extra.added = true;
      if (extra.multiple && extra.id == 'block_media') {
        this.model.push({
          id: 'block_media',
          added: true,
          items: [],
          title: '',
          description: '',
          media: [],
          linksVideo: [],
          uuid: this.$uuid.v4(),
        });
      } else {
        this.model.map((x) => {
          if (x.id == extra.id) {
            x.added = true;
          }
          return x;
        });
      }
    },
    closeExtra(extra) {
      this.items.find((x) => x.id == extra.id).added = false;
      if (extra.id == 'block_media') {
        //- this.model = this.model.filter(md => md.uuid != extra.uuid)
      }
      this.model.map((x) => {
        if (x.uuid == extra.uuid) {
          x.added = false;
        }
      });
    },

    reOrder(state) {
      console.log(state);
      this.model.move(state.moved.oldIndex, state.moved.newIndex);
      //this.model[state.moved.element.id].order = state.moved.newIndex
      //this.model['features'].order = 6

      /*this.items.forEach((x, index) => {
        this.model[x.id].order = index
      })*/
    },
  },
  created() {
    const { extras } = this.$route.query;
    let isNew = extras == 'new';

    this.items = [
      {
        id: 'block_media',
        name_key: 'text.text_block_media',
        description_key: 'text.text_block_media_description',
        name: this.$t('text.text_block_media'),
        description: this.$t('text.text_block_media_description'),
        tip: this.$t('text.tip_contents.content.media'),
        //- component: isNew ? ExtraTextBlockMedia : false,
        component: ExtraTextBlockMedia,
        added: false,
        order: 1,
        thumbnail: require('@/assets/images/extras/text.svg'),
        multiple: true,
        // hide: !isNew,
      },
      {
        id: 'price',
        name_key: 'text.price',
        description_key: 'text.price_description',
        name: this.$t('text.price'),
        description: this.$t('text.price_description'),
        tip: this.$t('text.tip_contents.content.price'),
        component: isNew ? ExtraPrice : false,
        added: false,
        order: 6,
        thumbnail: require('@/assets/images/extras/prices.svg'),
        hide: !isNew,
      },
      {
        id: 'features',
        name_key: 'text.advantages_features',
        description_key:
          'text.tell_your_website_visitors_why_you_are_better_than_others_pictures_description',
        name: this.$t('text.advantages_features'),
        description: this.$t(
          'text.tell_your_website_visitors_why_you_are_better_than_others_pictures_description'
        ),
        tip: this.$t('text.tip_contents.content.features'),
        component: isNew ? ExtraFeatures : ExtraFeaturesOld,
        added: false,
        order: 2,
        thumbnail: require('@/assets/images/extras/advantages.svg'),
      },
      {
        id: 'partners',
        name_key: 'text.partner_logos',
        description_key:
          'text.add_logos_of_your_partners_and_show_your_website_visitors_how_trustworthy_you_are_logos_links',
        name: this.$t('text.partner_logos'),
        description: this.$t(
          'text.add_logos_of_your_partners_and_show_your_website_visitors_how_trustworthy_you_are_logos_links'
        ),
        tip: this.$t('text.tip_contents.content.partners'),
        component: isNew ? ExtraPartners : ExtraPartnersOld,
        added: false,
        order: 3,
        thumbnail: require('@/assets/images/extras/logos.svg'),
      },
      {
        id: 'testimonials',
        name_key: 'text.testimonials',
        description_key:
          'text.add_testimonials_of_your_existing_customers_and_show_your_website_visitors_that_you_are_good_at_what_you_do',
        name: this.$t('text.testimonials'),
        description: this.$t(
          'text.add_testimonials_of_your_existing_customers_and_show_your_website_visitors_that_you_are_good_at_what_you_do'
        ),
        tip: this.$t('text.tip_contents.content.testimonials'),
        component: isNew ? ExtraTestimonials : ExtraTestimonialsOld,
        added: false,
        order: 4,
        thumbnail: require('@/assets/images/extras/cards.svg'),
      },
      {
        id: 'gallery',
        name_key: 'text.gallery',
        description_key:
          'text.add_an_image_gallery_of_your_team_work_or_office_title_description_photos',
        name: this.$t('text.gallery'),
        description: this.$t(
          'text.add_an_image_gallery_of_your_team_work_or_office_title_description_photos'
        ),
        tip: this.$t('text.tip_contents.content.gallery'),
        //- component: ExtraGallery,
        component: isNew ? ExtraGallery : ExtraGalleryOld,
        added: false,
        order: 5,
        thumbnail: require('@/assets/images/extras/gallery.svg'),
      },

      {
        id: 'contacts',
        name_key: 'text.contacts',
        description_key:
          'text.provide_your_contact_details_and_encourage_your_website_visitors_to_contact_you_your_contact_information_will_be_taken',
        name: this.$t('text.contacts'),
        description: this.$t(
          'text.provide_your_contact_details_and_encourage_your_website_visitors_to_contact_you_your_contact_information_will_be_taken'
        ),
        tip: this.$t('text.tip_contents.content.contracts'),
        //- component: ExtraContacts,
        component: isNew ? ExtraContacts : ExtraContactsOld,
        added: false,
        order: 7,
        thumbnail: require('@/assets/images/extras/contacts.svg'),
      },
      {
        id: 'registration_form',
        name_key: 'text.registration_form',
        description_key:
          'text.add_a_registration_form_generate_leads_and_get_in_touch_your_customers_create_form_fields',
        name: this.$t('text.registration_form'),
        description: this.$t(
          'text.add_a_registration_form_generate_leads_and_get_in_touch_your_customers_create_form_fields'
        ),
        tip: this.$t('text.tip_contents.content.registration_form'),
        //- component: ExtraRegistration,
        component: isNew ? ExtraRegistration : ExtraRegistrationOld,
        added: false,
        order: 8,
        thumbnail: require('@/assets/images/extras/form.svg'),
      },
    ];
  },
  mounted() {
    this.sortItems();
  },
};
</script>
<style lang="scss">
.content-extras {
  .v-card {
    border-radius: 5px;
    //padding: 20px;
  }

  .content {
    display: flex;
    align-items: flex-start;
    padding: $content-pad / 2;
  }

  .v-input.v-input--checkbox {
    margin-top: 0px;
    padding-top: 0px;
  }

  .extra-sheet {
    position: relative;
    padding: 30px;

    // padding: 0px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 10px 10px;
    }

    .field-title {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding-right: 30px;
      }
    }

    .close {
      position: absolute;
      top: 28px;
      right: 20px;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        top: 10px;
        right: 10px;
      }
    }
  }

  .name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    /* color/typography/black */
    color: #222222;
  }

  .desc {
    font-size: 16px;
  }

  .extra-block {
    .separator {
      height: 10px;
      background: rgba($cl-primary, 0.5);
    }

    .separator:nth-last-child(1) {
      display: none;
    }
  }
}
</style>

//import _ from 'lodash'
//import Api from '@/api'
import store from '@/store';
import {
  ROLE_ADMIN,
  ROLE_CLIENT,
  ROLE_EMPLOYEE,
  ROLE_PROJECT_DESIGNER,
  ROLE_PROJECT_MANAGER,
  ROLE_SUPER_ADMIN,
} from '@constant/roles';
//import { getRepositoryName, getDocumentType } from '@/modules/documents/helpers'
//import Client from '../client'
//import Vendor from '../vendor'
//import Company from '../company'

const UserMethods = (superclass) =>
  class extends superclass {
    isMe() {
      return store.state.auth.user && store.state.auth.user.uuid === this.uuid;
    }

    isClient() {
      return this.user_type_id === ROLE_CLIENT;
    }

    isAdmin() {
      return this.user_type_id >= ROLE_ADMIN;
    }

    isSuperAdmin() {
      return this.user_type_id === ROLE_SUPER_ADMIN;
    }

    isManager() {
      return this.user_type_id === ROLE_PROJECT_MANAGER;
    }

    isDesigner() {
      return this.user_type_id === ROLE_PROJECT_DESIGNER;
    }

    isEmployee() {
      // greater than or euqual
      return this.user_type_id >= ROLE_EMPLOYEE;
    }

    getShortName() {
      const names = this.name.split(' ');
      let inicials = '';
      names.forEach((name, index) => {
        if (index == 0) inicials += name + ' ';
        else inicials += name[0] + '. ';
      });
      return inicials;
      //return this.name
    }
  };

export default UserMethods;

import '@/styles/app.scss';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from '@/i18n';
import '@/plugins/prototypes';
import '@/plugins/bus';
// JQuery
// window.$ = window.jQuery = require('jquery')

// Lodash
window._ = require('lodash');

/* Http Client */
import VueResource from 'vue-resource';

Vue.use(VueResource);

import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

/* API plugin */
import Api from '@/plugins/api';

Vue.use(Api);

/* Utils plugin */
import '@/plugins/util';

/* Auth plugin */
import Auth from '@/plugins/auth';

Vue.use(Auth);

import config from '@/plugins/config';

config();

import preloader from '@/plugins/preloader';

preloader();

import CKEditor from '@ckeditor/ckeditor5-vue';

Vue.use(CKEditor);

import UUID from 'vue-uuid';

Vue.use(UUID);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

//import products from '@models/products/repository'
//import product_types from '@models/product_types/repository'
//import product_materials from '@models/product_materials/repository'
//import upload_history from '@models/upload_history/repository'

import projects from '@models/projects/repository'
import users from '@models/users/repository'
import translations from '@models/translations/repository'

export default {
  namespaced: true,
  modules: {
    // products, product_types, product_materials, upload_history,
    projects,
    users,
    translations,
  }
}
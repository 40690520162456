/**
 * Base getters of the repository
 */
 //import Statuses from '@front/app/statuses'
 //import { SELECTED_COMPANY_ITEMS } from '@front/app/helpers/filters'
 import { getItemKey } from './helpers'

export default (getters) => Object.assign({
  /**
   * Return list of available items
   *
   * Item is not available, if it's parent is not
   * available (e.g. it was soft deleted).
   */

  AVAILABLE_ITEMS(state) {
    return state.items.filter((item) => {
      return !item.isDisabled
    })
  },

  /**
   * Return list of active items
   * Active items are neither deleted nor archived
   */
  ACTIVE_ITEMS(state, getters) {
    return getters.AVAILABLE_ITEMS.filter((item) => {
      // return Statuses.generic.active.meetsCondition(item)
      return !item.archivedAt && !item.deletedAt
    })
  },

  /**
   * Return company items

  AVAILABLE_COMPANY_ITEMS(state, getters) {
    return getters.AVAILABLE_ITEMS.filter(SELECTED_COMPANY_ITEMS)
  },
  */

  /**
   * Find item
  */
  FIND_ITEM(state) {
    return (itemData) => state.items.find((item) => {
      return getItemKey(item, state.key) === getItemKey(itemData, state.key)
    })
  },

  FIND_ITEM_BY_KEY(state) {
    return (itemKey) => state.items.find((item) => {
      return getItemKey(item, state.key) === itemKey
    })
  },

  /*
  ACTIVE_COMPANY_ITEMS(state, getters) {
    return getters.ACTIVE_ITEMS.filter(SELECTED_COMPANY_ITEMS)
  },
  */

  /**
   * AA - Active and Archived
 
  AA_ITEMS(state, getters) {
    return getters.AVAILABLE_ITEMS.filter((item) => {
      return Statuses.generic.active.meetsCondition(item) || Statuses.generic.archived.meetsCondition(item)
    })
  },
  */

  /*
  AA_COMPANY_ITEMS(state, getters) {
    return getters.AVAILABLE_COMPANY_ITEMS.filter((item) => {
      return Statuses.generic.active.meetsCondition(item) || Statuses.generic.archived.meetsCondition(item)
    })
  },
  */

  API_NAME: () => {
    return 'UNDEFINED_API_NAME'
  },
  API_RESOURCE_NAME: () => {
    return 'UNDEFINED_API_RESOURCE_NAME'
  },
  API_FETCH_URL: (state, getters) => {
    return `${getters.API_NAME}/list`
  },
  API_CREATE_URL: (state, getters) => {
    return `${getters.API_NAME}`
  },
  API_SHOW_URL: (state, getters) => {
    return (uuid) => `${getters.API_NAME}/${uuid}`
  },
  API_UPDATE_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.uuid}`
  },
  API_PATCH_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.uuid}`
  },
  API_ARCHIVE_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.uuid}/archive`
  },
  API_ARCHIVE_MANY_URL: (state, getters) => {
    return `${getters.API_NAME}-archive`
  },
  API_DELETE_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.uuid}`
  },
  API_DELETE_MANY_URL: (state, getters) => {
    return `${getters.API_NAME}-delete`
  },
  API_UNARCHIVE_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.uuid}/unarchive`
  },
  API_UNARCHIVE_MANY_URL: (state, getters) => {
    return `${getters.API_NAME}-unarchive`
  },
  API_RESTORE_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.uuid}/restore`
  },
  API_DOCUMENT_DELETE_URL: (state, getters) => {
    return (item) => `${getters.API_NAME}/${item.media_uuid}/document-delete`
  },
  RESTORE_MANY_URL: (state, getters) => {
    return `${getters.API_NAME}-restore`
  },
  IS_FETCHING: (state) => {
    return state.is_fetch
  },
  PAGINATION: (state) => {
    return state.pagination
  },
  TABLE_FILTER: (state) => {
    return state.filters
  }
}, getters)
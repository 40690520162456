<template>
  <section class="block h-100 w-100">
    <div class="flex align-center justify-center h-100">
      <span class="text-blue-400 cursor-pointer" @click="onRedirectLogin">{{ generateUrl }}</span>
    </div>
  </section>
</template>
<script>
import { routes } from '@/router/routes';
import { LOGIN_IMPERSONATE } from '@constant';

export default {
  computed: {
    generateUrl() {
      return window.location;
    },
  },
  mounted() {
    const loginImpersonate = JSON.parse(sessionStorage.getItem(LOGIN_IMPERSONATE));
    if (!loginImpersonate) {
      this.$router.replace({ name: routes.login.name });
    }
  },
  methods: {
    async onRedirectLogin() {
      const dataImpersonate = JSON.parse(sessionStorage.getItem(LOGIN_IMPERSONATE));
      const urlParams = new URL(window.location);
      dataImpersonate.email = urlParams.searchParams.get('client');
      sessionStorage.setItem(LOGIN_IMPERSONATE, JSON.stringify(dataImpersonate));
      await this.$router.replace({ name: routes.login.name });
    },
  },
};
</script>
<style scoped></style>

<template lang="pug">
  .extra-block.testimonials
    .items.d-flex.flex-column(
      v-for="(item, key) in model.items"
      :key="item.id"
    )

      .separator.mt-2.mb-4(v-if="key > 0")

      v-text-field(
        outlined
        v-model="item.name"
        :label="$t('text.testimonial_item_title')"
        :value="generateLorem(item, {_key: 'name'})"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )

      v-text-field(
        outlined
        v-model="item.text"
        :label="$t('text.text')"
        :value="generateLorem(item, {_key: 'text'})"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )

      v-text-field(
        outlined
        v-model="item.date"
        :label="$t('text.date')"
        :value="generateLorem(item, {_key: 'date'})"
        :readonly="disabled || isAutoFilled"
        :disabled="isAutoFilled"
      )

      media-uploader(
        :documents="item.media"
        resourceName="projects"
        :return="item.id"
        :uuid="projectUUID"
        @added="onAdded"
        @deleted="onDeleted"
        :disabled="disabled"
        :allowMultiple="false"
      )
    v-btn.capitalize(color="black" text rounded depressed  @click="addField" :disabled="disabled")
      v-icon(left) mdi-plus
      | {{ $t('text.add_item') }}
    //- v-btn(color="primary" rounded depressed  @click="addField" :disabled="disabled") {{ $t('text.add_item') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';
import MediaUploader from '@/components/forms/parts/uploaders/MediaUploader';

export default {
  components: { MediaUploader },
  mixins: [ExtraBlock],
  methods: {
    addField() {
      //console.log(this.model)
      this.model.items.push({ id: this.$uuid.v4(), name: null, text: null, date: null, media: [] });
    },
    onAdded(model, id) {
      this.model.items.find((x) => x.id === id).media.push(model);
    },
    onDeleted(fileName, id) {
      this.model.items.find((x) => x.id === id).media = this.model.items
        .find((x) => x.id === id)
        .media.filter((x) => x.filename != fileName);
    },
  },
};
</script>

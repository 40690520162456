export const ACCEPT_RISK_BROWSER_KEY = 'ACCEPT_RISK_BROWSER';
export const LOGIN_IMPERSONATE = 'LOGIN_IMPERSONATE';
export const STYLE = {
  font_google: 'google',
  font_any: 'any',
  font_selected: 'selected',
  color_logo: 'logo',
  color_colors: 'colors',
  color_any: 'any',
  color_perfect: 'perfect',
};

export const BROWSER_COMPATIBLE = {
  chrome: 'Chrome',
  safari: 'Safari',
  firefox: 'Firefox',
  edge: 'Microsoft Edge',
  ios: 'iOS',
  android: 'Android',
};

export const BROWSER_VERSION = {
  chrome: 84,
  safari: 14.1,
  firefox: 63,
  edge: 84,
  ios: 14.5,
  android: 84,
};

<template lang="pug">
  .chat-message(:class="classes")
    .avatar {{ avatar }}
    v-card.message(elevation="0") {{ model.message }}

</template>

<script>

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      owner: null, // faker.image.avatar(),
      receiver: null //faker.image.avatar(),
    }
  },
  computed: {

    isOwner() {
      return this.$user().uuid == this.model.sender_uuid
    },

    avatar() {
      let avatar = '?'

      if (this.model.sender_type >= 4)
        avatar = 'A'

      if (this.model.sender_type == 3)
        avatar = 'M'

      if (this.model.sender_type == 2)
        avatar = 'D'

      return avatar
    },

    classes() {
      return {
        'owner': this.isOwner,
        'receiver': !this.isOwner
      }
    }
  },
}
</script>


<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 5.74C20.0008 5.60839 19.9756 5.47793 19.9258 5.35609C19.876 5.23426 19.8027 5.12344 19.71 5.03L15.47 0.790001C15.3766 0.69732 15.2658 0.623995 15.1439 0.57423C15.0221 0.524466 14.8916 0.499241 14.76 0.500001C14.6284 0.499241 14.4979 0.524466 14.3761 0.57423C14.2543 0.623995 14.1435 0.69732 14.05 0.790001L11.22 3.62L0.290017 14.55C0.197335 14.6434 0.12401 14.7543 0.0742455 14.8761C0.0244809 14.9979 -0.000744179 15.1284 1.67143e-05 15.26V19.5C1.67143e-05 19.7652 0.105374 20.0196 0.29291 20.2071C0.480446 20.3946 0.7348 20.5 1.00002 20.5H5.24002C5.37994 20.5076 5.51991 20.4857 5.65084 20.4358C5.78176 20.3858 5.90073 20.3089 6.00002 20.21L16.87 9.28L19.71 6.5C19.8013 6.40308 19.8757 6.29153 19.93 6.17C19.9397 6.09029 19.9397 6.00971 19.93 5.93C19.9347 5.88345 19.9347 5.83655 19.93 5.79L20 5.74ZM4.83002 18.5H2.00002V15.67L11.93 5.74L14.76 8.57L4.83002 18.5ZM16.17 7.16L13.34 4.33L14.76 2.92L17.58 5.74L16.17 7.16Z" fill="#5F5F5F"/>
</svg>

</template>
<script>
export default {}
</script>


<template lang="pug">
  .language-select
    v-menu(offset-y open-on-hover transition="fade")
      template(v-slot:activator='{ on, attrs }')
        slot(name="activator"
          :on="on"
          :attrs="attrs"
          :code="locale.code"
          :icon='require(`@/assets/images/flags/${locale.icon}`)')
          div(
            v-bind="attrs"
            v-on="on"
            :data-language="locale.code"
            :id="`app-language-${locale.code}`"
          ).flex.items-center.justify-center
            div.w-6.h-6.rounded-full.flex.justify-center.items-center(:class="['lg:w-10 lg:h-10']")
              img.img-flag(:src='require(`@/assets/images/flags/${locale.icon}`)'  style="height: 25px; width: 25px;")
            span(
              class="text-16 mx-2"
              style="text-transform: capitalize;"
            ) {{ locale.name }}
            v-icon.text-black mdi-chevron-down
      v-list(:value="locale.code")
        v-list-item(v-for='item in languages'
          active-class="primary--text"
          :key='item.code'
          :input-value="locale.code === item.code"
          :value="item.code"
          @click="onChange(item)"
        )
          v-list-item-icon
            img.img-flag.mr-3(:src='require(`@/assets/images/flags/${item.icon}`)' style="height: 25px; width: 25px;")
          v-list-item-title.drop-down-language-item
            span {{ item.name }}
</template>
<script>
import { languagesFlag, loadLanguageAsync } from '@/i18n';

export default {
  data() {
    return {
      activeLanguage: null,
      //languages: languagesFlag
    };
  },
  computed: {
    userPreferences() {
      return this.$store.state?.auth?.user;
    },
    supportedLanguages() {
      return this.$store.state.config.languages;
    },

    configLanguage() {
      return this.$store.state.config.language;
    },

    languages() {
      let langs = [...this.supportedLanguages];
      return languagesFlag.filter((x) => langs.includes(x.code));
    },

    isAuthenticated() {
      return this.$store.state.auth.is_logged_in;
    },

    locale() {
      let localeUser = null;
      if (this.isAuthenticated) {
        localeUser = this.$store.state.auth.user?.preferences?.locale;
      } else if (this.activeLanguage) {
        localeUser = this.$i18n.locale;
      }
      // let localeUser = this.isAuthenticated
      //   ? this.$store.state.auth.user.preferences.locale
      //   : this.activeLanguage ?? this.$i18n.locale ?? this.supportedLanguages[0];

      if (!this.supportedLanguages.includes(localeUser)) {
        localeUser = this.configLanguage;
      }
      return this.languages.find((i) => i.code == localeUser);
    },
  },
  async mounted() {
    const langEls = document.querySelector('[id^="app-language"]');

    if (langEls) {
      await loadLanguageAsync(langEls ? langEls.dataset.language : 'en');
    }
  },
  methods: {
    async onChange(locale) {
      this.activeLanguage = locale.code;
      //sync language from api
      await loadLanguageAsync(locale.code);
      this.$i18n.locale = locale.code;
      if (!this.$store.state.auth.user) {
        return;
      }
      this.$store.commit('auth/SET_PREFERENCE', {
        key: 'locale',
        value: locale.code,
      });
      this.$store.dispatch('auth/UPDATE_PREFERENCES', [
        {
          key: 'locale',
          value: locale.code,
        },
      ]);
    },
  },
};
</script>

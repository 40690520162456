import {
  RepositoryState,
  RepositoryMutations,
  RepositoryActions,
  RepositoryGetters,
  RepositoryMethods,
} from '@abs/repository';
import Translations from './model';

const state = RepositoryState({
  filters: {
    page: 1,
    per_page: 10,
    q: undefined,
    types: [],
  },
});

const mutations = RepositoryMutations();
const actions = RepositoryActions(Translations);
const getters = RepositoryGetters({
  API_NAME: () => 'translations',
  API_RESOURCE_NAME: () => 'translation',
});

/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  mutations,
  actions,
  getters,
};
export const methods = RepositoryMethods('translations');

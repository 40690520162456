<template lang="pug">
  .extra-block.registration
    //- group-container(
    //-       :title="$t('text.Section title')"
    //-       :subtitle="$t('text.It_will_inform_users_what_the_section_is_about')"
    //-     )
    //-   v-text-field(
    //-     filled 
    //-     v-model="model.title"
    //-     :placeholder="$t('We will answer all your questions')"
    //-     :readonly="disabled || isAutoFilled"
    //-     :disabled="isAutoFilled"
    //-   )
    group-container(
          :title="$t('text.Form_fields')"
          :subtitle="$t('text.It_will_inform_users_what_the_section_is_about')"
        )
    div(
      v-for="field in model.fields"
      :key="field.id"
    )
      div.grid.grid-cols-12.gap-3
        div.col-span-12(:class="['md:col-span-3']")
          label.block.mb-2 {{ $t('text.Field_type') }}
          v-select(:menu-props="{offsetY: true}" color="primary" filled v-model="field.type"  :items="dataTypes" hide-details)
        div.col-span-12(:class="['md:col-span-8']")
          label.block.mb-2 {{ $t('text.Field_name') }}
          v-text-field(v-model="field.value" filled :readonly="disabled || isAutoFilled" :disabled="isAutoFilled")          
        div.col-span-1.flex.items-center
          img.block.cursor-pointer.mx-auto(src="@/assets/images/delete.svg" @click="removeField(field)")

    //- v-btn(color="primary" @click="addField" rounded depressed  :disabled="disabled") {{ $t('text.add_item') }}
    div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addField" v-if="!disabled")
        v-icon(color="#222222") mdi-plus 
        | {{ $t("text.add_item")}}
</template>

<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin'

export default {
  mixins: [ ExtraBlock ],
  data: function() {
    return {
      dataTypes: [
        { text: this.$t('text.text_field'), value: 'text' },
        { text: this.$t('text.email_field'), value: 'email' },
        { text: this.$t('text.textarea'), value: 'textarea' },
        { text: this.$t('text.tel'), value: 'tel' },
        // { text: this.$t('Radio'), value: 'radio' },
        // { text: this.$t('CheckBox'), value: 'checkbox' },
        // { text: this.$t('Acceptance'), value: 'acceptance' },
        // { text: this.$t('Upload file'), value: 'file' },
        { text: this.$t('Select'), value: 'select' },
        { text: this.$t('Number'), value: 'number' },
        // { text: this.$t('Date'), value: 'date' },
      ]
    }
  },
  methods: {
    addField() {
      //console.log(this.model)
      this.model.fields.push({ id: this.$uuid.v4(),  name: this.$t('text.field_name'), type: 'text' })
    },
    removeField(item) {
      this.model.fields = this.model.fields.filter((x) => x.id != item.id);
    },
  }
}
</script>

<style lang="scss">

</style>

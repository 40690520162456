<template lang="pug">
  div.space-y-4
    div.bg-white.rounded-lg.pa-4.relative(v-for="item in websites" :key="item.uuid")
      div.field-subtitle.mb-1 {{ $t(labelLink) }}
      v-text-field(
        :value="item.link"
        @input="(value) => onInput(value, item.uuid)"
        validate-on-blur
        :rules="[rules.address, rules.required]"
        filled
        v-bind="binds" @click:append-outer="openLink"
      )
      div.field-subtitle.mb-1 {{ $t(labelComment) }}
      v-textarea(
        :value="item.comment"
        @input="(value) => onInput(value, item.uuid, true)"
        filled
        :readonly="isLocked"
      )
      div.absolute.top-4.right-4.z-10.cursor-pointer.text-sm(@click="removeLink(item.uuid)")
        v-icon(small) mdi-close
    div.cursor-pointer.flex.items-center.gap-2.text-black(@click="addLink" v-show ="!isDisableBtn")
      v-icon(color="#222222") mdi-plus
      | {{ $t("text.Add_website")}}
    div(v-if="error && error.length").text-error {{ error }}
</template>
<script>
import { genFields } from '@/components/forms/fieldGenerator';
import { url, required } from '@/modules/abstracts/form/validators';
import Store from '@/store';

export default {
  props: {
    name: { type: String },
    isLocked: { type: Boolean },
    error: { required: false },
  },
  mounted() {
    console.error('TG', this);
  },
  data() {
    return {
      labelLink: 'text.label_link',
      labelComment: 'text.label_comment',

      rules: {
        address: (value) => {
          if (!value?.trim()) {
            return true;
          }
          /*eslint-disable*/
          const pattern =
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
          return (value?.trim().length && pattern.test(value)) || 'The address invalid';
        },
        required: (value) => !!value || this.$t('text.required_link_address'),
      },
      linkss: [
        //- {
        //-   link: 'x',
        //-   comment: '111',
        //-   uuid: this.$uuid.v4()
        //- },
        //- {
        //-   link: 'y',
        //-   comment: '222',
        //-   uuid: this.$uuid.v4()
        //- }
      ],
    };
  },
  computed: {
    binds() {
      return {
        placeholder: 'https://www.example.com',
        readonly: this.isLocked,
      };
    },
    isDisableBtn() {
      return (
        this.$user().isAdmin() ||
        this.$user().isSuperAdmin() ||
        this.$user().isManager() ||
        this.$user().isDesigner()
      );
    },
    model: {
      get() {
        //- return this[this.name]
        return this[this.name];
      },
      set(val) {
        this[this.name] = val;
      },
    },
    ...genFields('projects', [
      'style.link1',
      'style.link2',
      'style.link3',
      'style.links',
      'style.website_like',
    ]),
    websites() {
      let items = this.website_like ?? [];
      return items.map((i) => ({
        uuid: i.uuid || this.$uuid.v4(),
        ...i,
      }));
    },
  },

  methods: {
    addLink() {
      let value = {
        link: '',
        comment: '',
        uuid: this.$uuid.v4(),
      };
      let items = this.websites;
      items.push(value);
      this.website_like = items;
    },
    removeLink(uuid) {
      this.website_like = this.websites.filter((item) => item.uuid !== uuid).slice();
    },
    openLink() {
      window.open(this.model, '_blank').focus();
    },
    onInput(value, uuid, isCommented = false) {
      let items = [...this.websites];
      let item = this.websites.find((_it) => _it.uuid == uuid);
      if (item) {
        let idx = this.websites.findIndex((it) => item.uuid == it.uuid);
        if (idx >= 0) {
          if (isCommented) {
            console.error('isCommented', isCommented);
            item.comment = value;
          } else {
            item.link = value;
          }
          items[idx] = item;
          console.error(item);
          this.website_like = items;
        }
      }
    },
  },
};
</script>

import GroupContainer from '@/components/forms/parts/GroupContainer';
import { doGenerateText } from '@/helpers';

export default {
  components: { GroupContainer },
  props: {
    model: {
      type: Object,
    },
    name: { type: String },
    description: { type: String },
    id: { type: Number },
    disabled: { type: Boolean, default: false },
    isAutoFilled: { type: Boolean, default: false },
  },

  computed: {
    title() {
      return 'Extra: ' + this.name;
    },

    projectUUID() {
      return this.$store.state.forms.projects.fields.uuid;
    },
    
    generateLorem() {
      return (c, options) => doGenerateText(c, { ...options, isAutoFilled: this.isAutoFilled })
    },
  },
};

import Vue from 'vue'
import store from '@/store'
import Auth from './auth'
import Router from '@/router'
//import Echo from '@/echo'

/**
 * API Plugin
 *
 * Handles HTTP requests to project API.
 */
export default {
  /**
   * Install the API class.
   *
   * @param {Object} Vue The global Vue.
   * @return {void}
   */
  install(Vue) {
    //console.log(Vue.http, _vue.http)
    Vue.http.options.root = process.env.VUE_APP_API
    console.log("@API URL", process.env.VUE_APP_API)

    Vue.http.interceptors.push((request, next) => {
      // request.credentials = true

      // Auth header
      const token = store.state.auth.access_token
      const hasAuthHeader = request.headers.has('Authorization')

      if (token && !hasAuthHeader) {
        Auth.setAuthHeaders(request)
      }

      request.headers.set('Origins', window.location.origin)
      // X-Socket-ID
      /*const socketId = Echo.socketId()
      const hasSocketIdHeader = request.headers.has('X-Socket-ID')

      if (socketId && !hasSocketIdHeader) {
        request.headers.set('X-Socket-ID', socketId)
      }*/

      console.log('INTERCEPTING @API: ' + request.url)
      console.log('INTERCEPTING @API: ' + request)
      /*
      if ([
        // Do not check response for invalid token
        // in the routes listed below
        'login/refresh',
        'logout'
      ].indexOf(request.url) > -1) {
        next()
      } else {
        next((response) => {
          if (Auth._isInvalidToken(response)) {
            return Auth._refreshToken(request)
          }
        })
      }
      */
    })

    console.log('INSTALLING @API')
    Vue.prototype.$api = Vue.api = this
  },

  handleError(response) {
    if (parseInt(response.status) === 403 && response.body && response.body.error && response.body.error.message === 'invalid_token') {
      Auth.logout()
      return
    }

    if (parseInt(response.status) === 404) {
      Router.push('/404')
    }

    return Promise.reject(response)
  },

  get(...args) {
    return Vue.http.get(...args).then((response) => response.body).catch(this.handleError)
  },

  download(...args) {
    return Vue.http.get(...args).then((response) => response).catch(this.handleError)
  },

  post(...args) {
    return Vue.http.post(...args).then((response) => response.body).catch(this.handleError)
  },

  put(...args) {
    return Vue.http.put(...args).then((response) => response.body).catch(this.handleError)
  },

  delete(...args) {
    return Vue.http.delete(...args).then((response) => response.body).catch(this.handleError)
  },

  patch(...args) {
    return Vue.http.patch(...args).then((response) => response.body).catch(this.handleError)
  }
}

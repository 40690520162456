<template lang="pug">
  .view-chat.shadow-xl.border.border-primary.rounded-lg.overflow-hidden
    .messages.chpad(ref="scrollChat")
      chat-message(
        v-for="(msg, key) in messages"
        :key="msg.uuid"
        :model="msg"
      )


    v-text-field.mt-5(v-model="message" filled :label="$t('text.messages')" append-icon="ic-send" @click:append="send" hide-details)

</template>

<script>
import ChatMessage from './parts/ChatMessage'

export default {
  components: { ChatMessage },
  props: {
    model: {
      required: true
    },
  },

  data() {
    return {
      message: ''
    }
  },


  computed: {
    messages() {
      return (this.model) ? this.sortedMessages() : []
      /*[
        { sender_uuid: this.$user().uuid, message: 'This is owners messages'},
        { sender_uuid: 'random', message: 'message received'},
        { sender_uuid: 'random', message: 'message received'}
      ]*/
    }
  },

  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollChat()
      })
    }
  },

  methods: {
    scrollChat() {
      this.$refs.scrollChat.scrollTop = this.$refs.scrollChat.scrollTopMax
    },

    sortedMessages() {
      return this.model.messages.sortBy(x => new Date(x.created_at))
    },

    send() {
      if (!this.message || this.message == '')
        return

      const data = { message: this.message }
      this.$api.post('projects/message/send/' + this.model.uuid, data)
        .then((responseModel) => {
          this.model.messages.push(responseModel)
          this.message = ''
        })
    }
  },

  mounted() {
    this.scrollChat()
  }
}
</script>

import { mix } from 'mixwith';
import Model from '@abs/model';
import UserMethods from './methods';

class User extends mix(Model).with(UserMethods) {
  static create(data) {
    return new this(this.parse(data));
  }

  static parse(data) {
    return {
      // ...super.parse(data),

      uuid: _.get(data, 'uuid', ''),
      name: _.get(data, 'username', ''),
      email: _.get(data, 'email', ''),
      is_super: _.get(data, 'is_super', false),
      is_admin: _.get(data, 'is_admin', false),
      user_type_id: _.get(data, 'user_type_id', 0),
      department_id: _.get(data, 'department_id', 0),
      project_count: _.get(data, 'project_count', 0),
      account_name: _.get(data, 'account_name', ''),
      username: _.get(data, 'username', ''),
      check_department: _.get(data, 'checkDepartment', {
        check: true,
        domain: '',
      }),
    };
  }
}

export default User;

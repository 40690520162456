<template lang="pug">
  .media-uploader
    v-btn.mb-5(
      rounded
      depressed
      color="primary"
      v-show="this.documents.length && disabled"
      @click="downloadComponent"
      :loading="loadingDownloadComponent")
      | {{ $t('text.download_component_images') }}
    v-row.only-preview(v-if="showPreview")
      v-col(
        v-for="file in previewFiles",
        cols="12",
        sm="6",
        md="4",
        :key="file.uuid"
      )
        a(:href="file.src", target="_blank")
          v-img.image.hover(:src="file.src", width="100%", height="200px")
    .relative(v-else)
      div.border-custom-image.mb-4.flex.w-full.items-center(:class="[errorMessages ? 'error--text' : '']")
        //- img.absolute.w-full.h-full.top-0.left-0.block.border(src="@/assets/images/icon-border.svg" alt="")
        FilePond.w-full(
          name="document",
          ref="pond",
          :labelIdle="$t('text.click_or_drop_here_media_files') + '<br>' + `${$t('text.maximum_file_size_2mb')}`",
          :label-max-file-size-exceeded="$t('text.file_too_large')"
          :accepted-file-types="acceptedFileTypes",
          allowRevert="true",
          :allowMultiple="allowMultiple",
          instantUpload="true",
          :server="server",
          :files="files",
          max-file-size="2MB"
          :disabled="disabled"
          @removefile="handleDeleteFile",
          @onprocessfile="handleDeleteFile",
        )
      div.text-error.text-xs(:class="[errorMessages ? 'error--text' : '']" v-if="errorMessages") {{ errorMessages }}
</template>
<script>
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import Auth from '@/plugins/auth';
import ModMixin from '@/mixins/ModMixin.js';

export default {
  components: {
    FilePond: vueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginFileValidateSize,
      FilePondPluginImageResize,
      FilePondPluginImageCrop,
      FilePondPluginImagePreview,
      FilePondPluginImageTransform
    ),
  },
  mixins: [ModMixin],
  props: {
    documents: {
      type: Array,
      default: () => {
        return [];
      },
    },
    uuid: {
      type: String,
      default: null,
    },
    uuidContent: {
      type: String,
      default: '',
    },
    resourceName: {
      type: String,
      default: null,
    },
    return: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    acceptedFileTypes: {
      type: [String, Array],
      default: () => 'image/*',
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modName: this.resourceName,
      loadingDownloadComponent: false,
      files: this.documents.map((x) => {
        return {
          source: x.filename,
          options: {
            type: 'local',
          },
        };
      }),
    };
  },
  computed: {
    server() {
      return {
        headers: {
          ...Auth.getAuthHeaders(),
          'x-api-key': process.env.VUE_APP_MEDIA_KEY_API,
        },
        url: process.env.VUE_APP_MEDIA_DOMAIN_API + `/${this.resourceName}/${this.uuid}/`,
        load: {
          url: 'document-load/',
        },
        process: {
          url: 'document-upload',
          onload: this.handleProcessFile,
        },
        revert: {
          url: 'document-delete',
          onload: this.handleRevertFile,
        },
        restore: null,
        fetch: null,
      };
    },
    showPreview() {
      return this.disabled || !this.$user().isClient();
    },

    previewFiles() {
      return this.documents.map((x) => {
        return {
          uuid: x.uuid,
          src:
            process.env.VUE_APP_MEDIA_DOMAIN_PUBLIC +
            '/' +
            x.directory.replaceAll('\\', '/') +
            '/' +
            x.filename,
        };
      });
    },
  },

  methods: {
    handleProcessFile(resp) {
      let model = JSON.parse(resp);
      this.$emit('added', model, this.return, { uuidContent: this.uuidContent });
      return model.filename;
    },

    processUpload() {
      return this.$refs.pond.processFiles();
    },

    handleDeleteFile(error, file) {
      if (error) {
        return '';
      }
      this.$emit('deleted', file.filename, this.return, { uuidContent: this.uuidContent });
    },

    handleRevertFile(uuid) {
      this.$emit('deleted', uuid, this.return);
    },

    activatedFile(file) {
      console.log('activated file', file);
    },

    async downloadComponent() {
      this.loadingDownloadComponent = true;
      await this.formDispatch('DOWNLOAD_ALL_MEDIA', {
        files: this.documents.map((i) => i.filename),
      });
      this.loadingDownloadComponent = false;
    },
  },
};
</script>

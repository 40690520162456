export function addScriptCustomScrollBar() {
    let css = `::-webkit-scrollbar {
      width: 16px;
      width: 10px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color:#f4f4f4;
      border-radius:16px;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track:hover {
      background-color:#f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      background-color:#babac0;
      border-radius:16px;
      border: 5px solid #fff;
      border-radius: 8px;
      border: 2px solid #fff;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border: 4px solid #f4f4f4;
      border: 2px solid #f4f4f4;
    }
    ::-webkit-scrollbar-button {
      display:none;
    }`
    const isMacOs = navigator.userAgent.indexOf('Mac OS X') !== -1
    if (!isMacOs) {
      let head = document.head || document.getElementsByTagName('head')[0]
      let style = document.createElement('style')
      head.appendChild(style)
      style.type = 'text/css'
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
    }
  }


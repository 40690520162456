<template lang="pug">
  div
    v-radio-group(v-model="model" @change="$emit('change', model)")
      v-carousel(
        delimiter-icon="ic-brightness-1"
        prev-icon="ic-chevron-left"
        next-icon="ic-chevron-right"
        hide-delimiters
        :disabled="disabled"
        @change="change"
        ref="carousel"
        height="auto"
        :cycle="!model ? true : false"
        :show-arrows="false"
        :value="model"
      )
        v-carousel-item(
          v-for="(item,i) in items"
          :key="item.id"
          :value="item.id"
          position="right"
        )
          div.h-full.relative
            div.absolute.w-full.h-full.top-0.left-0.z-10.border.opacity-0
              v-radio(:value="item.id" name="tlc").w-full.h-full
            div.grid.grid-cols-12(:class="[model == item.id ? 'row_active' : '']")
              div.col-span-12(:class="['md:grid-cols-7']")
                div.grid.grid-cols-12
                  div.col-span-5
                    div.p-4.h-full
                      p.text-16.font-medium {{ item.title }}
                      p(style="font-size: 8px;") {{ item.description }}
                      v-btn.normal-case.tracking-normal.font-normal(
                        @click="$emit('add-social')"
                        small :color="item.paletteColor"
                        rounded dark
                        depressed) Learn more
                  div.col-span-5
                    div.p-4.h-full
                      img.block.max-w-full(:src="item.src")
              div.col-span-12(:class="['md:grid-cols-5']")
                .space-y-4.p-4
                  //- p.font-semibold.text-16 Palette #1
                  div.grid.grid-cols-5
                    template(v-for="color in item.palettes")
                      div
                        div(:style="{background: color}").border.w-8.h-8.rounded-full.overflow-hidden
                  div.text-14
                    | {{ item.paletteDescription }} 
        .error-text.mt-2.pl-4(v-if="showError") {{ errorMessages }}
        div.my-4.right-4.bottom-4.flex.gap-8.items-center
          div.flex.items-center.gap-4.justify-end
            span {{ carousel.internalIndex }} 
            span(style="height: 1px; width: 34px; background: black;")
            span {{ carousel.total }}
          div.grid.grid-cols-2.rounded.overflow-hidden
            div(@click="carousel.prev" style="background: #4262FF;").w-8.h-8.cursor-pointer.flex-center
              v-icon(small) mdi-arrow-left
            div(@click="carousel.next" style="background: #EBEFFF;").w-8.h-8.cursor-pointer.flex-center
              v-icon(color="primary" small) mdi-arrow-right
</template>
<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: String },
    errorMessages: null,
  },
  data() {
    return {
      model: this.value,
      items: [
        {
          id: 'blue-and-refreshing',
          src: require('@/assets/images/color-schemes/1.svg'),
          thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
          title: 'Our global family',
          description:
            'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
          paletteDescription: this.$t('text.style.color.blue_and_refreshing'),
          palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
          paletteColor: '#5CACA8',
        },
        {
          id: 'bright-accent-colons',
          src: require('@/assets/images/color-schemes/2.svg'),
          thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
          title: 'Our global family',
          description:
            'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
          paletteDescription: this.$t('text.style.color.bright_accent_colons'),
          palettes: ['#282828', '#203BA6', '#EB9E46', '#F1F1F1', '#FFFFFF'],
          paletteColor: '#EB9E46',
        },
        {
          id: 'close-to-nature',
          src: require('@/assets/images/color-schemes/3.svg'),
          thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
          title: 'Our global family',
          description:
            'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
          paletteDescription: this.$t('text.style.color.close_to_nature'),
          palettes: ['#282828', '#E05FF5', '#6728FF', '#F1EDFC', '#FFFFFF'],
          paletteColor: '#6728FF',
        },
        {
          id: 'contemporary-and-bold',
          src: require('@/assets/images/color-schemes/4.svg'),
          thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
          title: 'Our global family',
          description:
            'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
          paletteDescription: this.$t('text.style.color.contemporary_and_bold'),
          palettes: ['#422211', '#D98E88', '#EDD7BF', '#FCF2E8', '#E4E4E4'],
          paletteColor: '#D98E88',
        },
        // {
        //   id: 'deep-purples',
        //   src: require('@/assets/images/color-schemes/deeppurples.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },

        // {
        //   id: 'elegant-yet-approachable',
        //   src: require('@/assets/images/color-schemes/elegant-yet-approachable.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },
        // {
        //   id: 'gorgeous-contrast',
        //   src: require('@/assets/images/color-schemes/gorgeous-contrast.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: '',
        //   description: '',
        //   paletteDescription: '',
        //   palettes: [],
        // },
        // {
        //   id: 'lively-yet-soothing',
        //   src: require('@/assets/images/color-schemes/lively-yet-soothing.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },
        // {
        //   id: 'minimal-yet-warm',
        //   src: require('@/assets/images/color-schemes/minimal-yet-warm.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },
        // {
        //   id: 'snow-yet-warm',
        //   src: require('@/assets/images/color-schemes/snow-yet-warm.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },
        // {
        //   id: 'stylish-sophisticated',
        //   src: require('@/assets/images/color-schemes/stylish-sophisticated.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },
        // {
        //   id: 'vivid-and-sharp',
        //   src: require('@/assets/images/color-schemes/vivid-and-sharp.png'),
        //   thumbnail: require('@/assets/images/color-schemes-thumbs/blue-and-refreshing.svg'),
        //   title: 'Our global family',
        //   description:
        //     'Our goal is simple: to provide the easiest possible route for you to kickstart and maintain your online presence. We are a team of dedicated and passionate professionals working together to create the best possible results for our client.',
        //   paletteDescription:
        //     'Calming and natural, this Greeny Blue is the perfect choice for a healthy brand. It is also a great color choice to convey eco-friendliness and sustainability.',
        //   palettes: ['#1A2429', '#5CACA8', '#71828A', '#E2F1F1', '#FFFFFF'],
        // },
      ],
      carouselRef: undefined,
    };
  },
  methods: {
    change() {
      this.carouselRef = this.$refs['carousel'];
      // this.$emit('change', this.model);
    },
  },
  mounted() {
    this.carouselRef = this.$refs['carousel'];
  },
  computed: {
    showError() {
      return this.errorMessages && this.errorMessages.length;
    },
    carousel() {
      let internalIndex = this.carouselRef?.internalIndex || 0;
      internalIndex = internalIndex + 1;
      return {
        internalIndex: internalIndex < 10 ? '0' + internalIndex : internalIndex,
        total: this.items.length,
        prev: () => {
          try {
            this.carouselRef?.prev();
          } catch (err) {
            // console.log(err.message);
          }
        },
        next: () => {
          try {
            this.carouselRef?.next();
          } catch (err) {
            // console.log(err.message);
          }
        },
      };
    },
  },
};
</script>

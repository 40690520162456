import Api from '@/plugins/api';
import i18n from '@/i18n';
export default {
  async LOAD_CONFIG({ commit }) {
    commit('SET_CONFIG_PRELOAD', true);
    try {
      const config = await Api.get('auth/config');
      // config.department_id = 3
      console.error("SET_CONFIG", config);
      commit('SET_CONFIG', config, { root: true });
      commit('SET_CONFIG_PRELOAD', false);
      if (config.language) {
        i18n.locale = config.language;
      }
      return true
    } catch (err) {
      commit('SET_CONFIG_PRELOAD', false);
      return false
    }
  },
};

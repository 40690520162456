<template lang="pug">
	.progress-wrap
		.value {{ value }}%
		v-progress-linear(:rounded="true", height=15 :value="value")
</template>

<script>
export default {
  props: {
		value: {
			type:Number,
			default: 0
		}
  }
}
</script>

<style lang="scss">
	.progress-wrap {
		display: flex;
		align-items: center;

		.value { font-weight:  600; margin-right: 10px; }
	}
</style>
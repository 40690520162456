<template lang="pug">
  .tab-content
    group-container.mt-6(
      :title="$t('text.extra_paid_services')"
      :subtitle="$t('text.choose_an_extra_paid_services')"
      commentField="services.paid_services"
    )
      .ib-1
        v-checkbox.mt-0(
          v-for="item in extrasModel"
          :key="item.code"
          hide-details
          v-model="item.checked"
          @change="onChange"
          :disabled="isLocked"
        )
          template(v-slot:label) {{ $t(`text.services.${item.code}`) }}
      .pl-3.mt-3.text-sm(v-if="isAllowedShowingByDepartment" )
        | *{{ $t(`text.all_prices_quoted_are_net_prices`) }}
</template>
<script>
import GroupContainer from '@/components/forms/parts/GroupContainer';
import domain from '@/scripts/domain';

export default {
  components: { GroupContainer },
  props: {
    model: {
      type: Array,
      required: true,
    },
    isLocked: false,
    videoByStep: {
      type: String,
    },
  },
  methods: {
    onChange(val) {
      this.$store.dispatch('forms/projects/UPDATE_FIELD', {
        field: 'extra_services',
        value: this.extrasModel,
      });
    },
  },
  computed: {
    extras() {
      return this.extrasLt;
      //return domain.isLT() ? this.extrasLt : this.extrasEn
    },
    isAllowedShowingByDepartment() {
      return domain.isPL();
    },
  },
  mounted() {
    //console.log('extrasssss',this.extras)

    this.extrasModel = this.extras.map((x) => {
      //define default extra
      let ex = {
        code: x.code,
        checked: false,
      };
      const found = this.model.find((y) => y.code == ex.code);
      ex.checked = found && found.checked ? true : false;
      return ex;
    }, this);
  },
  data: function () {
    return {
      extrasModel: [],
      extrasLt: [
        { code: 'kalendorius_tvarkarasciai' },
        { code: 'papildomi_puslapiai' },
        { code: 'seo' },
        { code: 'rezervacijos_sistema' },
        { code: 'saskaitos_fakturos_formavimas' },
        { code: 'skaiciuokle' },
        { code: 'daugiau_nei_50_prekiu_paslaugu_ikelimas' },
        { code: 'daugiau_nei_10_kategoriju_ar_10_subkategoriju_ikelimas' },
        { code: 'daugiau_nei_20_medijos_failu_ikelimas' },
        { code: 'papildomos_kalbos_diegimas' },
        { code: 'issokstantis_langas_pop_up_simple_popup' },
        { code: 'datos_oro_nustatymas_svetaineje' },
        { code: 'paypal_paysera_kaip_antrasis_papildomas_mokejimu_variklis' },
        { code: 'square_imoku_surinkimo_irankis' },
        { code: '2_checkout_imoku_surinkimo_irankis' },
        { code: 'authorize_net_imoku_surinkimo_irankis' },
        { code: 'amazon_pay_imoku_surinkimo_irankis' },
        { code: 'dotpay_imoku_surinkimo_irankis' },
        { code: 'facebook_instagram_naujienos_arba_like_subcribe_news_feed' },
        { code: 'under_construction' },
        { code: 'professional_logotype_creation' },
        { code: 'google_analytics' },
        { code: 'facebook_pixels' },
        { code: 'newsletters_integration' },
        { code: 'wordpress_system_maitenance' },
        { code: 'accounting_system' },
        { code: 'kita' },
      ],
      extrasEn: [
        { code: 'design_hour', price: 30, checked: false },
        { code: 'dev_hour', price: 50, checked: false },
        { code: 'project_manage_hour', price: 40, checked: false },
        { code: 'privacy_policy_pop', price: 50, checked: false },
        { code: 'instagram_feed', price: 80, checked: false },
        { code: 'facebook_feed', price: 80, checked: false },
        { code: 'seo_www', price: 300, checked: false },
        { code: 'seo_shop', price: 500 },
        { code: 'www_optimize', price: 200, checked: false },
        { code: 'extra_page_subpage', price: 10, checked: false },
        { code: 'extra_category', price: 10, checked: false },
        { code: 'popup_page_cookies', price: 60, checked: false },
        { code: 'popup_gdpr', price: 70, checked: false },
        { code: 'under_construction_page', price: 50, checked: false },
        { code: 'invoices_for_shop', price: 100, checked: false },
        { code: 'lang_up_10', price: 80, checked: false },
        { code: 'lang_up_20', price: 100, checked: false },
        { code: 'lang_up_30', price: 120, checked: false },
        { code: 'live_chat', price: 60, checked: false },
        { code: 'extended_contact_form', price: 30, estimate: true, checked: false },
        { code: 'fb_page_implement', price: 100, checked: false },
        { code: 'fb_page_manage', price: 100, checked: false },
        { code: 'calendar_simple', price: 50, checked: false },
        { code: 'calendar_complex', price: 200, estimate: true, checked: false },
        { code: 'reserve_with_pay', price: 200, estimate: true, checked: false },
        { code: 'logo_3v', price: 200, checked: false },
        { code: 'additional_gateway', price: 150, estimate: true, checked: false },
        { code: 'catalogue', price: 100, checked: false },
        { code: 'subscribe', price: 100, checked: false },
        { code: 'google_analytics', price: 50, checked: false },
        {
          code: 'adwords',
          price: 'Client defined budget (25% administration fee)',
          checked: false,
        },
        { code: 'products_more_50', price: 100, estimate: true, checked: false },
        { code: 'under_construction' },
        { code: 'professional_logotype_creation' },
        { code: 'facebook_pixels' },
        { code: 'newsletters_integration' },
        { code: 'wordpress_system_maitenance' },
        { code: 'accounting_system' },
      ],
    };
  },
};
</script>

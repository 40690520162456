<template lang="pug">
  .working-hours
    .item(v-for="item in model")
      v-checkbox(v-model="item.check" :label="$t(`text.${item.name}`)" hide-details)
      v-scale-transition(origin="center center")
        .d-flex(v-if="item.check")
          v-icon.mr-4 ic-globe
          v-menu(
            :ref="`${item.name}_menu_from`"
            v-model="item.menu_from"
            :close-on-content-click="false"
            :nudge-right="32"
            :return-value.sync="item.time_from"
            offset-y
            max-width="290px"
            min-width="290px"
            
          )
            template(v-slot:activator="{ on, attrs }")
              v-text-field.time-field(
                filled
                v-model="item.time_from"
                :label="$t('text.from')"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                dense
              )

            v-time-picker(
              v-if="item.menu_from"
              v-model="item.time_from"
              full-width
              format="24hr"
              @click:minute="$refs[`${item.name}_menu_from`][0].save(item.time_from)"
            )

          .sep -

          v-menu(
            :ref="`${item.name}_menu_till`"
            v-model="item.menu_till"
            :close-on-content-click="false"
            :nudge-right="32"
            :return-value.sync="item.time_till"
            offset-y
            max-width="290px"
            min-width="290px"
          )
            template(v-slot:activator="{ on, attrs }")
              v-text-field.time-field(
                filled
                v-model="item.time_till"
                :label="$t('text.until')"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                dense
              )

            v-time-picker(
              v-if="item.menu_till"
              v-model="item.time_till"
              full-width
              format="24hr"
              @click:minute="$refs[`${item.name}_menu_till`][0].save(item.time_till)"
            )
</template>

<script>
  export default {
    props: {
      model: {
        type: Array
      }
    },
    data () {
      return {
        /*week: {
          monday: { name:'monday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
          tuesday: { name:'tuesday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
          wednesday: { name:'wednesday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
          thursday: { name:'thursday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
          friday: { name:'friday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
          saturday: { name:'saturday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
          sunday: { name:'sunday', time_from: null, time_till: null,  menu_from: false, menu_till: false },
        }*/
      }
    },
  }
</script>


<style lang="scss">
.working-hours {
  .item {
    display: flex;

    .v-input--checkbox {
      padding-right: 20px;
    }

    .time-field {
      max-width: 100px;

      .v-text-field__slot {
        input { text-align: center; }
        label {
          width: 100%; text-align: center;
          transform-origin: top center;
          -webkit-transform-origin: top center;
        }
      }
    }

    .time-selects {
      display: flex;
    }

    .sep {
      padding: 0px 10px;
      display: flex;
      align-self: center;
    }

    .v-input--checkbox {
      width: 200px;
    }

  }

  
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .item {
      flex-direction: column;
      
      .d-flex {
        margin-top: 10px;
      }
    }
  }
}
</style>

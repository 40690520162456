import store from '@/store';
import {
  DEPARTMENT_ID_BG,
  DEPARTMENT_ID_COM,
  DEPARTMENT_ID_DEV,
  DEPARTMENT_ID_ES,
  DEPARTMENT_ID_HU,
  DEPARTMENT_ID_LT,
  DEPARTMENT_ID_PL,
  DEPARTMENT_ID_RO,
  DEPARTMENT_ID_RU,
  DEPARTMENT_ID_UK,
  DEPARTMENT_ID_LV,
} from '@/constants/departments';

export default {
  isUK() {
    return store.state.config.department_id === DEPARTMENT_ID_UK;
  },

  isRU() {
    return store.state.config.department_id === DEPARTMENT_ID_RU;
  },

  isLV() {
    return store.state.config.department_id === DEPARTMENT_ID_LV;
  },

  isLT() {
    return store.state.config.department_id === DEPARTMENT_ID_LT;
  },

  isPL() {
    return store.state.config.department_id === DEPARTMENT_ID_PL;
  },

  isES() {
    return store.state.config.department_id === DEPARTMENT_ID_ES;
  },

  isBG() {
    return store.state.config.department_id === DEPARTMENT_ID_BG;
  },

  isRO() {
    return store.state.config.department_id === DEPARTMENT_ID_RO;
  },

  isHU() {
    return store.state.config.department_id === DEPARTMENT_ID_HU;
  },

  isDev() {
    return store.state.config.department_id === DEPARTMENT_ID_DEV;
  },

  isCOM() {
    return store.state.config.department_id === DEPARTMENT_ID_COM;
  },
  // 1_en', '2_ru', '3_lt', '4_pl', '5_es', '6_bg', '7_ro', '8_hu', '9_lv'
  // auth, forms, repositories, page, passives
  // Eu. Uk. Lt. Pl. start.WacAdemy.ro, bg.
};

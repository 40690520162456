import {
  FormState,
  FormGetters,
  FormMutations,
  FormActions,
} from "@front/modules/abstracts/form";
import Api from "@/plugins/api";
import Store from "@/store";

const state = FormState({});
const mutations = FormMutations();
const getters = FormGetters();
const actions = FormActions();
/**
 * Exports
 */
export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};

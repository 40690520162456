<template lang="pug">
  .color-picker
    v-row.selected
      v-menu(
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="32"
            offset-y
            max-width="290px"
            min-width="290px"
          )
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                v-show="showAddButton"
                v-bind="attrs"
                v-on="on"
                class="ma-2"
                x-small
                fab
                filled
                color="primary"
                :disabled="disabled"
              )
                v-icon ic-add

            v-sheet.color-picker-wrapper
              v-color-picker(
                ref="picker"
                v-if="menu"
                v-model="colorNew"
              )

              v-btn.btn--add(
                class="ma-5"
                color="primary"
                @click="addColor"
              ) {{ $t('text.add') }}


      v-chip(
        v-for="c in colors"
        :key="c"
        class="ma-2"
        close
        :color="c"
        :text-color="getColor(c)"
        label
        @click:close="colors.remove(c)"
      ) {{ c }}

    .error-text.mt-2(v-if="showError") {{ errorMessages }}
</template>

<script>
export default {
  props: {
    colors: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: null
  },
  data() {
    return {
      colorNew: null,
      menu: false,
      limit: 5,
      showAddButton: true
    }
  },
  watch: {
    colors: {
      handler: function() {
        this.checkAddButton()

        console.log('cought...', this.colors.length)
      },
      deep: true
    }
  },
  computed: {
    showError() {
      return this.errorMessages && this.errorMessages.length
    }
  },
  methods: {
    checkAddButton() {
      this.showAddButton = (this.colors.length < this.limit) ? true : false
    },

    addColor() {

      this.colors.push(this.colorNew.hexa)
      this.menu = false

      this.$store.commit('forms/projects/SET_ERROR',  { field: 'colors_selected', message: [], parent: 'style' })
    },

    getColor(hex) {
      return (this.hex_is_light(hex)) ? "balck" : "white"
    },

    hex_is_light(color) {
      const hex = color.replace('#', '')
      const c_r = parseInt(hex.substr(0, 2), 16)
      const c_g = parseInt(hex.substr(2, 2), 16)
      const c_b = parseInt(hex.substr(4, 2), 16)
      const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000
      return brightness > 155
    }
  },
}
</script>

<style lang="scss">

.color-picker-wrapper {
  display: flex;
  flex-direction: column;


  .btn-add {
    justify-self: flex-end;
  }
}

</style>

<template lang="pug">
  .extra-block.registration

    .spacer(style="height:5px;")


    .d-block.d-sm-flex.mb-4.mb-sm-0(
      v-for="field in model.fields"
      :key="field.id"
    )
      .d-md-flex.w-100
        .d-md-flex.flex-column.w-full
          label.font-bold.mb-1.font-medium {{ $t('text.extra_block_registration_form_field_name') }}
          v-text-field.w-full(
            outlined
            :value="generateLorem(field, {_key: 'name', fallback: $t('text.extra_block_registration_form_field_name')})"
            v-model="field.name"
            :placeholder="$t('text.extra_block_registration_form_field_placeholder')"
            :readonly="disabled || isAutoFilled"
            :disabled="isAutoFilled"
          )
        v-select.ml-sm-5.pt-md-7(:items="dataTypes" outlined v-model="field.type" :disabled="disabled")
    v-btn.capitalize(color="black" text rounded depressed  @click="addField" :disabled="disabled")
      v-icon(left) mdi-plus
      | {{ $t('text.add_item') }}
    //- v-btn(color="primary" @click="addField" rounded depressed  :disabled="disabled") {{ $t('text.add_item') }}
</template>
<script>
import ExtraBlock from '@/mixins/ExtraBlockMixin';

export default {
  mixins: [ExtraBlock],
  data: function () {
    return {
      dataTypes: [
        { text: this.$t('text.text_field'), value: 'text' },
        { text: this.$t('text.email_field'), value: 'email' },
        { text: this.$t('text.textarea'), value: 'textarea' },
      ],
    };
  },
  methods: {
    addField() {
      this.model.fields.push({
        id: this.$uuid.v4(),
        name: '',
        type: 'text',
      });
    },
  },
};
</script>
<style lang="scss">
.extra-block.registration {
  .v-select {
    flex: none;
    max-width: 200px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: unset;
    }
  }
}
</style>
